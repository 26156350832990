import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  LayoutDashboard,
  Layers,
  Package,
  Tag,
  Zap,
  ShoppingCart,
  Users,
  FileSpreadsheet,
  Shield,
  Image,
} from 'lucide-react';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const Sidebar = ({ isOpen }) => {
  const { canView, isSuperAdmin } = usePermissions();

  const menuItems = [
    {
      title: 'Dashboard',
      path: '/admin',
      icon: <LayoutDashboard size={20} />,
      module: MODULES.DASHBOARD,
      show: true, // Dashboard luôn hiển thị
    },
    {
      title: 'Quản lý Hero Section',
      path: '/admin/hero',
      icon: <Image size={20} />, // Sửa thành JSX element
      module: MODULES.HERO,
      show: isSuperAdmin || canView(MODULES.HERO),
    },
    {
      title: 'Danh mục',
      path: '/admin/categories',
      icon: <Layers size={20} />,
      module: MODULES.CATEGORIES,
      show: isSuperAdmin || canView(MODULES.CATEGORIES),
    },
    {
      title: 'Sản phẩm',
      path: '/admin/products',
      icon: <Package size={20} />,
      module: MODULES.PRODUCTS,
      show: isSuperAdmin || canView(MODULES.PRODUCTS),
    },
    {
      title: 'Thông số kỹ thuật',
      path: '/admin/specs',
      icon: <FileSpreadsheet size={20} />,
      module: MODULES.SPECS,
      show: isSuperAdmin || canView(MODULES.SPECS),
    },
    {
      title: 'Khuyến mãi',
      path: '/admin/promotions',
      icon: <Tag size={20} />,
      module: MODULES.PROMOTIONS,
      show: isSuperAdmin || canView(MODULES.PROMOTIONS),
    },
    {
      title: 'Flash Sale',
      path: '/admin/flash-sales',
      icon: <Zap size={20} />,
      module: MODULES.FLASH_SALES,
      show: isSuperAdmin || canView(MODULES.FLASH_SALES),
    },
    {
      title: 'Đơn hàng',
      path: '/admin/orders',
      icon: <ShoppingCart size={20} />,
      module: MODULES.ORDERS,
      show: isSuperAdmin || canView(MODULES.ORDERS),
    },
    {
      title: 'Khách hàng',
      path: '/admin/customers',
      icon: <Users size={20} />,
      module: MODULES.CUSTOMERS,
      show: isSuperAdmin || canView(MODULES.CUSTOMERS),
    },
  ];

  const systemMenuItems = [
    {
      title: 'Người dùng',
      path: '/admin/users',
      icon: <Users size={20} />,
      module: MODULES.USERS,
      show: isSuperAdmin || canView(MODULES.USERS),
    },
    {
      title: 'Phân quyền',
      path: '/admin/roles',
      icon: <Shield size={20} />,
      module: MODULES.ROLES,
      show: isSuperAdmin || canView(MODULES.ROLES),
    },
  ];

  const visibleMenuItems = menuItems.filter((item) => item.show);
  const visibleSystemItems = systemMenuItems.filter((item) => item.show);

  return (
    <aside
      className={`fixed left-0 top-16 h-[calc(100vh-4rem)] bg-white shadow-sm transition-all duration-300 ${
        isOpen ? 'w-64' : 'w-0'
      } overflow-hidden`}
    >
      <nav className="h-full py-4">
        <ul className="space-y-1">
          {/* Regular menu items */}
          {visibleMenuItems.map((item) => (
            <li key={item.path}>
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `flex items-center space-x-3 px-4 py-3 text-gray-600 hover:bg-gray-100 hover:text-primary transition-colors ${
                    isActive ? 'bg-blue-50 text-primary font-medium' : ''
                  }`
                }
              >
                {item.icon}
                <span>{item.title}</span>
              </NavLink>
            </li>
          ))}

          {/* System menu items */}
          {visibleSystemItems.length > 0 && (
            <>
              <li className="px-4 pt-4">
                <div className="text-xs font-semibold text-gray-400 uppercase tracking-wider">
                  Hệ thống
                </div>
              </li>
              {visibleSystemItems.map((item) => (
                <li key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      `flex items-center space-x-3 px-4 py-3 text-gray-600 hover:bg-gray-100 hover:text-primary transition-colors ${
                        isActive ? 'bg-blue-50 text-primary font-medium' : ''
                      }`
                    }
                  >
                    {item.icon}
                    <span>{item.title}</span>
                  </NavLink>
                </li>
              ))}
            </>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
