import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../../../store/productSlice';
import Select from 'react-select';

const ProductSelector = ({ selectedProducts, onChange }) => {
  const dispatch = useDispatch();
  const { items: allProducts, status } = useSelector((state) => state.products);
  const promotionProducts = useSelector((state) => state.promotionProducts.items);

  // Định nghĩa hàm formatPrice trước khi sử dụng
  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchProducts());
    }
  }, [status, dispatch]);

  // Lọc ra những sản phẩm chưa được thêm vào khuyến mãi
  const availableProducts = allProducts.filter(
    (product) =>
      !promotionProducts.find((pp) => pp.productId === product.id) &&
      !selectedProducts.find((sp) => sp.id === product.id)
  );

  const options = availableProducts.map((product) => ({
    value: product.id,
    label: `${product.name} - ${formatPrice(product.price)}`,
    product: product,
  }));

  const handleSelect = (selected) => {
    if (selected) {
      onChange([
        ...selectedProducts,
        {
          id: selected.product.id,
          name: selected.product.name,
          originalPrice: selected.product.price,
          discountPrice: selected.product.price,
          maxQuantity: null,
        },
      ]);
    }
  };

  if (status === 'loading') {
    return <div>Đang tải danh sách sản phẩm...</div>;
  }

  if (status === 'failed') {
    return <div className="text-red-500">Không thể tải danh sách sản phẩm</div>;
  }

  return (
    <div className="space-y-4">
      <Select
        options={options}
        onChange={handleSelect}
        placeholder="Chọn sản phẩm..."
        noOptionsMessage={() => 'Không có sản phẩm phù hợp'}
        isClearable
        className="basic-single"
        classNamePrefix="select"
      />
      {availableProducts.length === 0 && (
        <div className="text-sm text-gray-500">
          Tất cả sản phẩm đã được thêm vào chương trình khuyến mãi
        </div>
      )}
    </div>
  );
};

export default ProductSelector;
