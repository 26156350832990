import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEditingProduct } from '../../../store/uiSlice';
import { deleteProduct } from '../../../store/productSlice';
import { fetchCategories } from '../../../store/categorySlice';
import { Edit2, Trash2, Eye, Tag } from 'lucide-react';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const ProductList = ({ products }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items: categories, status: categoryStatus } = useSelector((state) => state.categories);

  const { canUpdate, canDelete } = usePermissions();
  const hasUpdatePermission = canUpdate(MODULES.PRODUCTS);
  const hasDeletePermission = canDelete(MODULES.PRODUCTS);

  useEffect(() => {
    if (categoryStatus === 'idle') {
      dispatch(fetchCategories());
    }
  }, [dispatch, categoryStatus]);

  const handleEdit = (product) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền chỉnh sửa sản phẩm');
      return;
    }
    dispatch(setEditingProduct(product));
  };

  const handleDelete = async (product) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa sản phẩm');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa sản phẩm này?')) {
      try {
        await dispatch(deleteProduct(product.id)).unwrap();
        toast.success('Xóa sản phẩm thành công');
      } catch (error) {
        toast.error('Xóa sản phẩm thất bại: ' + error.message);
      }
    }
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  const getCategoryNames = (categoryIds) => {
    if (!categoryIds || !Array.isArray(categoryIds)) {
      return 'Chưa phân loại';
    }

    if (categoryStatus === 'loading') {
      return 'Đang tải...';
    }

    if (categoryStatus === 'failed') {
      return 'Lỗi tải danh mục';
    }

    if (categoryStatus === 'succeeded' && categories?.length) {
      const categoryNames = categoryIds
        .map((id) => categories.find((cat) => cat.id === id)?.name)
        .filter(Boolean);
      return categoryNames.length > 0 ? categoryNames.join(', ') : 'Chưa phân loại';
    }

    return 'Chưa phân loại';
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
            >
              Sản phẩm
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
            >
              SKU
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
            >
              Danh mục
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
            >
              Giá & Kho
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
            >
              Bảo hành
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
            >
              Trạng thái
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase"
            >
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {products.map((product) => (
            <tr key={product.id} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <div className="h-10 w-10 flex-shrink-0">
                    {product.images?.[0] ? (
                      <img
                        className="h-10 w-10 rounded object-cover"
                        src={product.images[0]}
                        alt=""
                      />
                    ) : (
                      <div className="h-10 w-10 rounded bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500 text-xs">No image</span>
                      </div>
                    )}
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">{product.name}</div>
                    {product.tags && product.tags.length > 0 && (
                      <div className="flex gap-1 mt-1">
                        {product.tags.map((tag, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            <Tag size={12} className="mr-1" />
                            {tag}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </td>

              <td className="px-6 py-4 text-sm text-gray-500">{product.sku || 'N/A'}</td>

              <td className="px-6 py-4">
                <div className="text-sm text-gray-900">{getCategoryNames(product.categoryIds)}</div>
              </td>

              <td className="px-6 py-4">
                <div className="text-sm">
                  <div className="font-medium text-gray-900">{formatPrice(product.price)}</div>
                  <div className="text-gray-500">
                    Kho: {product.stock} | Đã bán: {product.sold || 0}
                  </div>
                </div>
              </td>

              <td className="px-6 py-4">
                <div className="text-sm text-gray-900">
                  {product.warranty?.months > 0 ? (
                    <>
                      <div>{product.warranty.months} tháng</div>
                      {product.warranty.description && (
                        <div className="text-gray-500 text-xs">{product.warranty.description}</div>
                      )}
                    </>
                  ) : (
                    'Không bảo hành'
                  )}
                </div>
              </td>

              <td className="px-6 py-4">
                <div className="flex flex-col gap-1">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                    ${product.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}
                  >
                    {product.isActive ? 'Đang bán' : 'Ngừng bán'}
                  </span>
                  {product.isHot && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                      HOT
                    </span>
                  )}
                  {product.isFeatured && (
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                      Nổi bật
                    </span>
                  )}
                </div>
              </td>

              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <div className="flex items-center justify-center space-x-3">
                  <button
                    onClick={() => handleEdit(product)}
                    className="text-indigo-600 hover:text-indigo-900"
                    title="Sửa"
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={() => handleDelete(product)}
                    className="text-red-600 hover:text-red-900"
                    title="Xóa"
                  >
                    <Trash2 size={16} />
                  </button>
                  <button
                    onClick={() => navigate(`/admin/products/${product.id}`)}
                    className="text-gray-600 hover:text-gray-900"
                    title="Chi tiết"
                  >
                    <Eye size={16} />
                  </button>
                </div>
              </td>
            </tr>
          ))}

          {products.length === 0 && (
            <tr>
              <td colSpan={7} className="px-6 py-8 text-center text-gray-500">
                Chưa có sản phẩm nào trong hệ thống
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;
