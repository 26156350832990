import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ShieldOff, ArrowLeft } from 'lucide-react';

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-4rem)] bg-gray-50">
      <div className="text-center">
        <ShieldOff className="w-16 h-16 mx-auto text-red-500 mb-4" />
        <h1 className="text-2xl font-bold text-gray-900 mb-2">Không có quyền truy cập</h1>
        <p className="text-gray-600 mb-6">
          Bạn không có quyền truy cập vào trang này. Vui lòng liên hệ quản trị viên để được cấp
          quyền.
        </p>
        <button
          onClick={() => navigate(-1)}
          className="btn btn-primary inline-flex items-center gap-2"
        >
          <ArrowLeft size={20} />
          Quay lại
        </button>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
