import { usePermissions } from './usePermissions';
import { MODULES } from '../models/userModels';
import { toast } from 'react-hot-toast';

export const useOrderPermissions = () => {
  const { canView, canCreate, canUpdate, canDelete, isSuperAdmin } = usePermissions();

  const hasViewPermission = canView(MODULES.ORDERS);
  const hasCreatePermission = canCreate(MODULES.ORDERS);
  const hasUpdatePermission = canUpdate(MODULES.ORDERS);
  const hasDeletePermission = canDelete(MODULES.ORDERS);

  // Hàm kiểm tra quyền chung
  const checkPermission = (action, message) => {
    let hasPermission = false;

    switch (action) {
      case 'view':
        hasPermission = hasViewPermission;
        break;
      case 'create':
        hasPermission = hasCreatePermission;
        break;
      case 'update':
        hasPermission = hasUpdatePermission;
        break;
      case 'delete':
        hasPermission = hasDeletePermission;
        break;
      default:
        hasPermission = false;
    }

    if (!hasPermission && message) {
      toast.error(message);
    }

    return hasPermission;
  };

  // Helper function để kiểm tra điều kiện xóa đơn hàng
  const canDeleteOrder = (order) => {
    // Cần có quyền xóa và đơn hàng phải ở trạng thái hủy hoặc hoàn trả
    return hasDeletePermission && ['cancelled', 'returned'].includes(order.status);
  };

  // Helper function để kiểm tra điều kiện cập nhật trạng thái
  const canUpdateStatus = (order) => {
    // Không cho phép cập nhật đơn đã hủy hoặc đã hoàn trả
    if (['cancelled', 'returned'].includes(order.status)) {
      return false;
    }
    return hasUpdatePermission;
  };

  // Helper function để kiểm tra điều kiện hủy đơn
  const canCancelOrder = (order) => {
    // Chỉ cho phép hủy đơn trong các trạng thái: chờ xử lý, đang xử lý, đang giao
    const cancelableStatuses = ['pending', 'processing', 'shipping'];
    return hasUpdatePermission && cancelableStatuses.includes(order.status);
  };

  // Helper function để kiểm tra điều kiện chỉnh sửa thông tin
  const canEditOrderInfo = (order) => {
    // Không cho phép chỉnh sửa đơn đã hủy, đã hoàn trả hoặc đã giao
    const nonEditableStatuses = ['cancelled', 'returned', 'delivered'];
    return hasUpdatePermission && !nonEditableStatuses.includes(order.status);
  };

  return {
    // Basic permissions
    hasViewPermission,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    isSuperAdmin,

    // Helper functions
    checkPermission,
    canDeleteOrder,
    canUpdateStatus,
    canCancelOrder,
    canEditOrderInfo,

    // Additional utility functions
    isOrderEditable: (order) => {
      // Kiểm tra tổng hợp các điều kiện cho phép chỉnh sửa
      if (!hasUpdatePermission) return false;
      if (['cancelled', 'returned', 'delivered'].includes(order.status)) return false;
      return true;
    },

    getAvailableActions: (order) => {
      // Trả về danh sách các hành động có thể thực hiện với đơn hàng
      return {
        canView: hasViewPermission,
        canDelete: canDeleteOrder(order),
        canUpdate: canUpdateStatus(order),
        canCancel: canCancelOrder(order),
        canEdit: canEditOrderInfo(order),
      };
    },

    validateAction: (action, order) => {
      // Kiểm tra và trả về thông tin về tính hợp lệ của một hành động
      switch (action) {
        case 'delete':
          return {
            isValid: canDeleteOrder(order),
            message: canDeleteOrder(order) ? '' : 'Không thể xóa đơn hàng này',
          };
        case 'cancel':
          return {
            isValid: canCancelOrder(order),
            message: canCancelOrder(order) ? '' : 'Không thể hủy đơn hàng này',
          };
        case 'update':
          return {
            isValid: canUpdateStatus(order),
            message: canUpdateStatus(order) ? '' : 'Không thể cập nhật trạng thái đơn hàng này',
          };
        case 'edit':
          return {
            isValid: canEditOrderInfo(order),
            message: canEditOrderInfo(order) ? '' : 'Không thể chỉnh sửa đơn hàng này',
          };
        default:
          return {
            isValid: false,
            message: 'Hành động không hợp lệ',
          };
      }
    },
  };
};

export default useOrderPermissions;
