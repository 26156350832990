// src/utils/dateUtils.js
export const formatDate = (timestamp, options = {}) => {
  if (!timestamp) return 'Không có thông tin';

  try {
    // Xử lý Firebase Timestamp
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);

    // Kiểm tra date có hợp lệ không
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Không có thông tin';
    }

    // Định dạng mặc định
    const defaultOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };

    // Chỉ hiển thị ngày tháng nếu onlyDate = true
    if (options.onlyDate) {
      return date.toLocaleDateString('vi-VN', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    }

    // Format đầy đủ ngày giờ
    return date.toLocaleString('vi-VN', defaultOptions);
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Không có thông tin';
  }
};

export const getJoinDate = (timestamp) => {
  if (!timestamp) return 'Không có thông tin';

  try {
    // Xử lý Firebase Timestamp
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);

    // Kiểm tra date có hợp lệ không
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Không có thông tin';
    }

    // Format chỉ lấy tháng và năm
    return new Intl.DateTimeFormat('vi-VN', {
      year: 'numeric',
      month: 'long',
    }).format(date);
  } catch (error) {
    console.error('Error getting join date:', error);
    return 'Không có thông tin';
  }
};

export const formatDateTime = (timestamp) => {
  if (!timestamp) return 'Không có thông tin';

  try {
    // Xử lý Firebase Timestamp
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);

    // Kiểm tra date có hợp lệ không
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Không có thông tin';
    }

    // Format đầy đủ ngày giờ
    return new Intl.DateTimeFormat('vi-VN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).format(date);
  } catch (error) {
    console.error('Error formatting datetime:', error);
    return 'Không có thông tin';
  }
};

// Format relative time (e.g., "2 giờ trước")
export const getRelativeTime = (timestamp) => {
  if (!timestamp) return 'Không có thông tin';

  try {
    // Xử lý Firebase Timestamp
    const date = timestamp?.toDate ? timestamp.toDate() : new Date(timestamp);

    // Kiểm tra date có hợp lệ không
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Không có thông tin';
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) return 'Vừa xong';
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} phút trước`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} giờ trước`;
    if (diffInSeconds < 2592000) return `${Math.floor(diffInSeconds / 86400)} ngày trước`;
    if (diffInSeconds < 31536000) return `${Math.floor(diffInSeconds / 2592000)} tháng trước`;
    return `${Math.floor(diffInSeconds / 31536000)} năm trước`;
  } catch (error) {
    console.error('Error getting relative time:', error);
    return 'Không có thông tin';
  }
};
