// src/admin/components/HeroManager/index.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Trash2, Edit, Image, Save, X, ArrowUp, ArrowDown } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  fetchHeroSlides,
  addHeroSlide,
  updateHeroSlide,
  deleteHeroSlide,
} from '../../../store/heroSlice';
import { uploadToCloudinary } from '../../../utils/cloudinary';
import { toast } from 'react-hot-toast';

const HeroManager = () => {
  const dispatch = useDispatch();
  const { items: slides, status } = useSelector((state) => state.hero);
  const [editingSlide, setEditingSlide] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchHeroSlides());
    }
  }, [status, dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Create preview URL
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleMoveSlide = async (slideId, direction) => {
    const currentIndex = slides.findIndex((slide) => slide.id === slideId);
    if (
      (direction === 'up' && currentIndex === 0) ||
      (direction === 'down' && currentIndex === slides.length - 1)
    ) {
      return;
    }

    const newOrder =
      direction === 'up' ? slides[currentIndex - 1].order : slides[currentIndex + 1].order;

    try {
      await dispatch(
        updateHeroSlide({
          id: slideId,
          data: { ...slides[currentIndex], order: newOrder },
        })
      ).unwrap();

      // Update other slide
      const otherSlideId =
        direction === 'up' ? slides[currentIndex - 1].id : slides[currentIndex + 1].id;

      await dispatch(
        updateHeroSlide({
          id: otherSlideId,
          data: { ...slides[currentIndex], order: slides[currentIndex].order },
        })
      ).unwrap();

      toast.success('Đã thay đổi vị trí');
    } catch (error) {
      toast.error('Không thể thay đổi vị trí');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      // Get image file from input
      const imageFile = e.target.imageFile.files[0];

      // Handle image upload if there's a new file
      let imageUrl = editingSlide?.image; // Keep old image if editing
      if (imageFile) {
        imageUrl = await uploadToCloudinary(imageFile, {
          folder: 'hero-slides',
          transformation: 'w_1920,h_600,c_fill',
          maxFileSize: 2 * 1024 * 1024, // 2MB
        });
      }

      // Prepare data
      const data = {
        title: formData.get('title'),
        description: formData.get('description'),
        link: formData.get('link'),
        image: imageUrl,
        bgColor: formData.get('bgColor') || 'bg-primary-600',
        isActive: true,
        order: parseInt(formData.get('order')) || slides.length + 1,
      };

      let resultAction;
      if (editingSlide) {
        resultAction = await dispatch(
          updateHeroSlide({
            id: editingSlide.id,
            data,
          })
        ).unwrap();
        toast.success('Slide đã được cập nhật');
      } else {
        resultAction = await dispatch(addHeroSlide(data)).unwrap();
        toast.success('Đã thêm slide mới');
      }

      setIsFormOpen(false);
      setEditingSlide(null);
      setImagePreview(null);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Bạn có chắc muốn xóa slide này?')) {
      try {
        await dispatch(deleteHeroSlide(id)).unwrap();
        toast.success('Đã xóa slide');
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Quản lý Hero Section</h1>
        <button
          onClick={() => {
            setEditingSlide(null);
            setImagePreview(null);
            setIsFormOpen(true);
          }}
          className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          <Plus size={20} />
          Thêm slide
        </button>
      </div>

      {/* Slides Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {slides.map((slide) => (
          <div key={slide.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="aspect-w-16 aspect-h-9 bg-gray-100">
              <img src={slide.image} alt={slide.title} className="object-cover" />
            </div>
            <div className="p-4">
              <h3 className="font-medium text-lg mb-2">{slide.title}</h3>
              <p className="text-gray-500 text-sm mb-4">{slide.description}</p>
              <div className="flex justify-between items-center">
                <div className="text-sm text-gray-500">Vị trí: {slide.order}</div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleMoveSlide(slide.id, 'up')}
                    disabled={slides.indexOf(slide) === 0}
                    className="p-1.5 text-gray-600 hover:bg-gray-100 rounded-lg disabled:opacity-50"
                  >
                    <ArrowUp size={16} />
                  </button>
                  <button
                    onClick={() => handleMoveSlide(slide.id, 'down')}
                    disabled={slides.indexOf(slide) === slides.length - 1}
                    className="p-1.5 text-gray-600 hover:bg-gray-100 rounded-lg disabled:opacity-50"
                  >
                    <ArrowDown size={16} />
                  </button>
                  <button
                    onClick={() => {
                      setEditingSlide(slide);
                      setImagePreview(slide.image);
                      setIsFormOpen(true);
                    }}
                    className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                  >
                    <Edit size={18} />
                  </button>
                  <button
                    onClick={() => handleDelete(slide.id)}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                  >
                    <Trash2 size={18} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Slide Form Modal */}
      <AnimatePresence>
        {isFormOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
          >
            <motion.div
              initial={{ y: '100%' }}
              animate={{ y: 0 }}
              exit={{ y: '100%' }}
              className="absolute bottom-0 left-0 right-0 bg-white rounded-t-xl max-h-[90vh] overflow-y-auto"
            >
              <div className="sticky top-0 bg-white border-b px-6 py-4 flex justify-between items-center">
                <h2 className="text-lg font-semibold">
                  {editingSlide ? 'Chỉnh sửa slide' : 'Thêm slide mới'}
                </h2>
                <button
                  onClick={() => {
                    setIsFormOpen(false);
                    setEditingSlide(null);
                    setImagePreview(null);
                  }}
                  className="p-2 hover:bg-gray-100 rounded-lg"
                >
                  <X size={20} />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="p-6 space-y-6">
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Tiêu đề</label>
                    <input
                      type="text"
                      name="title"
                      required
                      defaultValue={editingSlide?.title}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Mô tả</label>
                    <textarea
                      name="description"
                      rows={3}
                      defaultValue={editingSlide?.description}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Link</label>
                    <input
                      type="text"
                      name="link"
                      defaultValue={editingSlide?.link}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Màu nền</label>
                    <input
                      type="text"
                      name="bgColor"
                      defaultValue={editingSlide?.bgColor || 'bg-primary-600'}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Thứ tự hiển thị
                    </label>
                    <input
                      type="number"
                      name="order"
                      min="1"
                      defaultValue={editingSlide?.order || slides.length + 1}
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Hình ảnh (1920x600px)
                    </label>
                    <div className="flex items-center gap-4">
                      <label className="flex-1">
                        <input
                          type="file"
                          name="imageFile"
                          accept="image/*"
                          className="hidden"
                          onChange={handleImageChange}
                        />
                        <div className="h-40 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-primary-500 transition-colors">
                          <div className="text-center">
                            <Image className="mx-auto h-12 w-12 text-gray-400" />
                            <span className="mt-2 block text-sm font-medium text-gray-600">
                              Chọn hình ảnh
                            </span>
                          </div>
                        </div>
                      </label>
                      {imagePreview && (
                        <div className="w-60 h-40 bg-gray-100 rounded-lg overflow-hidden">
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className="w-full h-full object-cover"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex justify-end gap-4 pt-4 border-t">
                  <button
                    type="button"
                    onClick={() => {
                      setIsFormOpen(false);
                      setEditingSlide(null);
                      setImagePreview(null);
                    }}
                    className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
                  >
                    Hủy
                  </button>
                  <button
                    type="submit"
                    className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
                  >
                    <Save size={20} />
                    {editingSlide ? 'Cập nhật' : 'Thêm mới'}
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeroManager;
