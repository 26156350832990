// src/client/components/common/WishlistButton.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heart } from 'lucide-react';
import { toast } from 'react-hot-toast';
import {
  addToWishlist,
  removeFromWishlist,
  selectIsInWishlist,
} from '../../features/wishlistSlice';

const WishlistButton = ({ product, size = 'medium', className = '' }) => {
  const dispatch = useDispatch();
  const isInWishlist = useSelector(selectIsInWishlist(product.id));

  const handleWishlistToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (isInWishlist) {
        dispatch(removeFromWishlist(product.id));
        toast.success(`Đã xóa ${product.name} khỏi danh sách yêu thích`);
      } else {
        dispatch(addToWishlist(product));
        toast.success(`Đã thêm ${product.name} vào danh sách yêu thích`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const sizeClasses = {
    small: 'p-1.5',
    medium: 'p-2',
    large: 'p-3',
  };

  const iconSizes = {
    small: 'w-4 h-4',
    medium: 'w-5 h-5',
    large: 'w-6 h-6',
  };

  return (
    <button
      onClick={handleWishlistToggle}
      className={`rounded-lg transition-colors ${
        isInWishlist ? 'bg-red-50 text-red-500 hover:bg-red-100' : 'hover:bg-gray-100'
      } ${sizeClasses[size]} ${className}`}
    >
      <Heart className={`${iconSizes[size]} ${isInWishlist ? 'fill-current' : ''}`} />
    </button>
  );
};

export default WishlistButton;
