// src/admin/components/OrderManager/components/CreateOrder/components/SelectedProducts.jsx
import React from 'react';
import { X, Package } from 'lucide-react';
import CurrencyInput from '../../../../common/CurrencyInput';
import { formatCurrency } from '../../../utils';

const SelectedProducts = ({ products, onChange, disabled }) => {
  if (products.length === 0) {
    return (
      <div className="text-center py-8 bg-gray-50 rounded-lg border-2 border-dashed">
        <Package className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-500">Chưa có sản phẩm nào được chọn</p>
      </div>
    );
  }

  const handleRemove = (productId) => {
    onChange(products.filter((p) => p.id !== productId));
  };

  const handleQuantityChange = (productId, quantity) => {
    const numQuantity = parseInt(quantity);
    if (isNaN(numQuantity)) return;

    onChange(
      products.map((p) =>
        p.id === productId
          ? {
              ...p,
              quantity: Math.min(Math.max(1, numQuantity), p.maxQuantity || Infinity),
            }
          : p
      )
    );
  };

  const handlePriceChange = (productId, price) => {
    onChange(products.map((p) => (p.id === productId ? { ...p, price: Number(price) } : p)));
  };

  return (
    <div className="space-y-4">
      {products.map((product) => (
        <div key={product.id} className="bg-gray-50 rounded-lg p-4">
          {/* Header - Image & Name */}
          <div className="flex items-start gap-4 mb-4">
            <div className="w-16 h-16 flex-shrink-0">
              {product.image ? (
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-full object-cover rounded"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 rounded flex items-center justify-center">
                  <Package className="w-6 h-6 text-gray-400" />
                </div>
              )}
            </div>

            <div className="flex-1 min-w-0">
              <h4 className="font-medium text-gray-900 text-lg">{product.name}</h4>
              {product.sku && <div className="text-sm text-gray-500">SKU: {product.sku}</div>}
              {product.maxQuantity && (
                <div className="text-sm text-gray-500">Còn {product.maxQuantity} sản phẩm</div>
              )}
            </div>

            {!disabled && (
              <button
                onClick={() => handleRemove(product.id)}
                className="p-1 text-gray-400 hover:text-red-500"
                title="Xóa sản phẩm"
              >
                <X size={20} />
              </button>
            )}
          </div>

          {/* Footer - Price & Quantity */}
          <div className="grid grid-cols-3 gap-4 pt-3 border-t">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Đơn giá</label>
              <CurrencyInput
                value={product.price}
                onChange={(value) => handlePriceChange(product.id, value)}
                className="w-full"
                disabled={disabled}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Số lượng</label>
              <input
                type="number"
                min="1"
                max={product.maxQuantity}
                value={product.quantity}
                onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                className="input w-full"
                disabled={disabled}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Thành tiền</label>
              <div className="text-lg font-medium text-primary-600">
                {formatCurrency(product.price * product.quantity)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectedProducts;
