import React from 'react';
import { X } from 'lucide-react';

export const Modal = ({ isOpen, onClose, children, maxWidth = '2xl', className = '' }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div
        className={`
          bg-white rounded-lg shadow-xl w-full mx-4 
          max-w-${maxWidth} max-h-[90vh] overflow-hidden
          ${className}
        `}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export const ModalHeader = ({ title, onClose, className = '' }) => (
  <div className={`px-6 py-4 border-b flex items-center justify-between ${className}`}>
    <h2 className="text-xl font-bold">{title}</h2>
    {onClose && (
      <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded transition-colors">
        <X className="w-5 h-5" />
      </button>
    )}
  </div>
);

export const ModalBody = ({ children, className = '' }) => (
  <div className={`p-6 overflow-y-auto ${className}`}>{children}</div>
);

export const ModalFooter = ({ children, className = '' }) => (
  <div className={`px-6 py-4 bg-gray-50 border-t ${className}`}>{children}</div>
);

export const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  confirmText = 'Xác nhận',
  cancelText = 'Hủy',
  type = 'danger',
}) => (
  <Modal isOpen={isOpen} onClose={onClose} maxWidth="md">
    <ModalHeader title={title} onClose={onClose} />
    <ModalBody>
      <p className="text-gray-600">{message}</p>
    </ModalBody>
    <ModalFooter>
      <div className="flex justify-end gap-3">
        <button onClick={onClose} className="btn btn-secondary">
          {cancelText}
        </button>
        <button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          className={`btn ${type === 'danger' ? 'btn-danger' : 'btn-primary'}`}
        >
          {confirmText}
        </button>
      </div>
    </ModalFooter>
  </Modal>
);

export const useModal = (initialState = false) => {
  const [isOpen, setIsOpen] = React.useState(initialState);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const toggle = () => setIsOpen(!isOpen);

  return { isOpen, open, close, toggle };
};
