// src/client/pages/Account/index.jsx
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import {
  User,
  Box,
  Heart,
  Settings,
  LogOut,
  ChevronRight,
  Clock,
  Mail,
  Phone,
  Calendar,
  AlertCircle,
} from 'lucide-react';
import { motion } from 'framer-motion';
import ProfileTab from './tabs/ProfileTab';
import OrdersTab from './tabs/OrdersTab';
import WishlistTab from './tabs/WishlistTab';
import SecurityTab from './tabs/SecurityTab';
import { formatDate } from '../../../utils/dateUtils';
import { logout } from '../../features/authSlice';
import { secureStorage } from '../../../utils/cryptoUtils';
import { useDispatch } from 'react-redux';

const AccountPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.authClient.user);
  const dispatch = useDispatch();
  // Get current tab from URL path
  const currentPath = location.pathname.split('/').pop();
  const activeTab = currentPath === 'account' ? 'profile' : currentPath;

  if (!user) {
    return <Navigate to="/login" />;
  }

  const tabs = [
    {
      id: 'profile',
      label: 'Thông tin tài khoản',
      icon: User,
      component: ProfileTab,
    },
    {
      id: 'orders',
      label: 'Đơn hàng của tôi',
      icon: Box,
      component: OrdersTab,
    },
    {
      id: 'wishlist',
      label: 'Sản phẩm yêu thích',
      icon: Heart,
      badge: user.wishlist?.length || 0,
      component: WishlistTab,
    },
    {
      id: 'security',
      label: 'Bảo mật tài khoản',
      icon: Settings,
      component: SecurityTab,
    },
  ];

  // Get current tab component
  const TabComponent = tabs.find((tab) => tab.id === activeTab)?.component || ProfileTab;

  const handleLogout = () => {
    dispatch(logout());
    secureStorage.removeItem('user');
    navigate('/login');
  };

  const getUserStatus = () => {
    if (user.isDefaultPassword) {
      return {
        icon: AlertCircle,
        text: 'Vui lòng đổi mật khẩu mặc định',
        color: 'text-yellow-600',
        bgColor: 'bg-yellow-100',
      };
    }
    if (user.status === 'active') {
      return {
        icon: Clock,
        text: 'Đang hoạt động',
        color: 'text-green-600',
        bgColor: 'bg-green-100',
      };
    }
    return {
      icon: AlertCircle,
      text: 'Tài khoản bị khóa',
      color: 'text-red-600',
      bgColor: 'bg-red-100',
    };
  };

  const status = getUserStatus();

  return (
    <div className="bg-gray-50 min-h-screen py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Sidebar */}
          <div className="w-full lg:w-80 flex-shrink-0">
            <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
              {/* User Info */}
              <div className="pb-6 border-b">
                <div className="flex items-center gap-4 mb-4">
                  <div className="relative">
                    {user.avatar ? (
                      <img
                        src={user.avatar}
                        alt={user.fullName}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-16 h-16 rounded-full bg-primary-50 flex items-center justify-center">
                        <User className="w-8 h-8 text-primary-600" />
                      </div>
                    )}
                    <div className="absolute -bottom-1 -right-1 w-4 h-4 bg-green-500 rounded-full border-2 border-white" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h2 className="font-semibold text-lg truncate">{user.fullName}</h2>
                    <p className="text-sm text-gray-500 truncate">{user.username}</p>
                  </div>
                </div>

                {/* Contact Info */}
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-sm text-gray-500">
                    <Mail className="w-4 h-4" />
                    <span className="truncate">{user.email}</span>
                  </div>
                  {user.phone && (
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      <Phone className="w-4 h-4" />
                      <span>{user.phone}</span>
                    </div>
                  )}
                </div>
              </div>

              {/* Status Badge */}
              <div className={`flex items-center gap-2 p-3 rounded-lg ${status.bgColor}`}>
                <status.icon className={`w-5 h-5 ${status.color}`} />
                <span className={`text-sm font-medium ${status.color}`}>{status.text}</span>
              </div>

              {/* Navigation */}
              <nav className="space-y-2">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => navigate(`/account/${tab.id === 'profile' ? '' : tab.id}`)}
                    className={`w-full flex items-center justify-between p-3 rounded-lg transition-colors
                      ${
                        activeTab === tab.id
                          ? 'bg-primary-50 text-primary-600'
                          : 'hover:bg-gray-50 text-gray-700'
                      }`}
                  >
                    <div className="flex items-center gap-3">
                      <tab.icon size={20} />
                      <span className="font-medium">{tab.label}</span>
                    </div>
                    {tab.badge ? (
                      <span className="bg-primary-100 text-primary-600 px-2 py-0.5 rounded-full text-sm">
                        {tab.badge}
                      </span>
                    ) : (
                      <ChevronRight size={16} className="text-gray-400" />
                    )}
                  </button>
                ))}

                {/* Logout Button */}
                <button
                  onClick={handleLogout}
                  className="w-full flex items-center gap-3 p-3 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                >
                  <LogOut size={20} />
                  <span className="font-medium">Đăng xuất</span>
                </button>
              </nav>

              {/* Account Meta */}
              <div className="pt-6 border-t space-y-2">
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <Calendar className="w-4 h-4" />
                  <span>Tham gia từ {formatDate(user.createdAt, { onlyDate: true })}</span>
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-500">
                  <Clock className="w-4 h-4" />
                  <span>Đăng nhập gần đây: {formatDate(user.lastLoginAt)}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <motion.div
            className="flex-1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <div className="bg-white rounded-xl shadow-sm p-6">
              <TabComponent user={user} />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
