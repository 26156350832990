import React, { useState } from 'react';
import { Trash2, Edit2, Save, X } from 'lucide-react';
import { usePermissions } from '../../../../hooks/usePermissions';
import { MODULES } from '../../../../models/userModels';
import toast from 'react-hot-toast';

const ProductList = ({ products, onUpdate, onRemove }) => {
  const [editingId, setEditingId] = useState(null);
  const [editForm, setEditForm] = useState({});

  const { canUpdate, canDelete } = usePermissions();
  const hasUpdatePermission = canUpdate(MODULES.PROMOTIONS);
  const hasDeletePermission = canDelete(MODULES.PROMOTIONS);

  const handleEdit = (product) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền chỉnh sửa sản phẩm khuyến mãi');
      return;
    }
    setEditingId(product.id);
    setEditForm(product);
  };

  const handleSave = async (id) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền cập nhật sản phẩm khuyến mãi');
      return;
    }
    await onUpdate(id, editForm);
    setEditingId(null);
    setEditForm({});
  };

  const handleCancel = () => {
    setEditingId(null);
    setEditForm({});
  };
  const handleRemove = (productId) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa sản phẩm khỏi khuyến mãi');
      return;
    }
    onRemove([productId]);
  };
  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Sản phẩm
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Giá gốc
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Giá khuyến mãi
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Số lượng tối đa
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {products.map((product) => (
            <tr key={product.id}>
              {editingId === product.id ? (
                <>
                  <td className="px-6 py-4">{product.name}</td>
                  <td className="px-6 py-4">{formatPrice(product.originalPrice)}</td>
                  <td className="px-6 py-4">
                    <input
                      type="number"
                      value={editForm.discountPrice}
                      onChange={(e) =>
                        setEditForm({ ...editForm, discountPrice: Number(e.target.value) })
                      }
                      className="input"
                    />
                  </td>
                  <td className="px-6 py-4">
                    <input
                      type="number"
                      value={editForm.maxQuantity || ''}
                      onChange={(e) =>
                        setEditForm({ ...editForm, maxQuantity: Number(e.target.value) })
                      }
                      className="input"
                      placeholder="Không giới hạn"
                    />
                  </td>
                  <td className="px-6 py-4 text-center">
                    <div className="flex items-center justify-center space-x-2">
                      {hasUpdatePermission && (
                        <button
                          onClick={() => handleSave(product.id)}
                          className="text-green-600 hover:text-green-900"
                        >
                          <Save size={16} />
                        </button>
                      )}
                      <button onClick={handleCancel} className="text-gray-600 hover:text-gray-900">
                        <X size={16} />
                      </button>
                    </div>
                  </td>
                </>
              ) : (
                <>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">{product.name}</div>
                  </td>
                  <td className="px-6 py-4">{formatPrice(product.originalPrice)}</td>
                  <td className="px-6 py-4">{formatPrice(product.discountPrice)}</td>
                  <td className="px-6 py-4">
                    {product.maxQuantity ? `${product.maxQuantity} sản phẩm` : 'Không giới hạn'}
                  </td>
                  <td className="px-6 py-4 text-center">
                    <div className="flex items-center justify-center space-x-2">
                      {hasUpdatePermission && (
                        <button
                          onClick={() => handleEdit(product)}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          <Edit2 size={16} />
                        </button>
                      )}
                      {hasDeletePermission && (
                        <button
                          onClick={() => handleRemove(product.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <Trash2 size={16} />
                        </button>
                      )}
                    </div>
                  </td>
                </>
              )}
            </tr>
          ))}
          {products.length === 0 && (
            <tr>
              <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                Chưa có sản phẩm nào trong chương trình khuyến mãi
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;
