import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { hashPassword } from '../utils/passwordUtils';
import { uploadToCloudinary } from '../utils/cloudinary';
import { USER_TYPE } from '../models/userModels';

// Mật khẩu mặc định cho admin mới
const ADMIN_DEFAULT_PASSWORD = 'mapple@123';

// Initial state
const initialState = {
  items: [],
  customers: [], // Thêm state cho customers
  status: 'idle',
  error: null,
};

// Thunk actions
export const fetchUsers = createAsyncThunk('users/fetchAll', async (_, { rejectWithValue }) => {
  try {
    const q = query(collection(db, 'users'), where('type', '==', USER_TYPE.ADMIN));
    const querySnapshot = await getDocs(q);

    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return users;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Thêm action fetchCustomers
export const fetchCustomers = createAsyncThunk(
  'users/fetchCustomers',
  async (_, { rejectWithValue }) => {
    try {
      const q = query(collection(db, 'users'), where('type', '==', USER_TYPE.CUSTOMER));
      const querySnapshot = await getDocs(q);

      const customers = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return customers;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addUser = createAsyncThunk('users/add', async (userData, { rejectWithValue }) => {
  try {
    let avatarUrl = null;
    if (userData.avatar?.file) {
      avatarUrl = await uploadToCloudinary(userData.avatar.file);
    }

    // Hash password dựa vào lựa chọn password mặc định hoặc tùy chỉnh
    let hashedPassword;
    if (userData.useDefaultPassword) {
      hashedPassword = await hashPassword(ADMIN_DEFAULT_PASSWORD);
    } else {
      hashedPassword = await hashPassword(userData.password);
    }

    const userDataToSave = {
      username: userData.username,
      fullName: userData.fullName,
      email: userData.email || '',
      phone: userData.phone || '',
      type: userData.type,
      status: userData.status,
      roleId: userData.roleId,
      avatar: avatarUrl,
      password: hashedPassword,
      isDefaultPassword: userData.useDefaultPassword,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    };

    const docRef = await addDoc(collection(db, 'users'), userDataToSave);

    // Trả về dữ liệu không bao gồm password
    const { password: _, ...userWithoutPassword } = userDataToSave;
    return {
      id: docRef.id,
      ...userWithoutPassword,
    };
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateUser = createAsyncThunk(
  'users/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      let avatarUrl = data.avatar;
      if (data.avatar?.file) {
        avatarUrl = await uploadToCloudinary(data.avatar.file);
      }

      const docRef = doc(db, 'users', id);
      const updateData = {
        ...data,
        avatar: avatarUrl,
        updatedAt: serverTimestamp(),
      };

      // Không cập nhật password trong updateUser
      delete updateData.password;

      await updateDoc(docRef, updateData);
      return { id, ...updateData };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk('users/delete', async (id, { rejectWithValue }) => {
  try {
    await deleteDoc(doc(db, 'users', id));
    return id;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

// Slice
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch admin users
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Fetch customers
      .addCase(fetchCustomers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.customers = action.payload;
        state.error = null;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Add user
      .addCase(addUser.fulfilled, (state, action) => {
        if (action.payload.type === USER_TYPE.ADMIN) {
          state.items.push(action.payload);
        } else {
          state.customers.push(action.payload);
        }
      })

      // Update user
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload.type === USER_TYPE.ADMIN) {
          const index = state.items.findIndex((item) => item.id === action.payload.id);
          if (index !== -1) {
            state.items[index] = action.payload;
          }
        } else {
          const index = state.customers.findIndex((item) => item.id === action.payload.id);
          if (index !== -1) {
            state.customers[index] = action.payload;
          }
        }
      })

      // Delete user
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload);
        state.customers = state.customers.filter((item) => item.id !== action.payload);
      });
  },
});

export const { resetStatus } = userSlice.actions;
export { ADMIN_DEFAULT_PASSWORD };
export default userSlice.reducer;
