// src/admin/components/PromotionManager/index.jsx

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPromotions } from '../../../store/promotionSlice';
import { clearEditingPromotion, togglePromotionForm } from '../../../store/uiSlice';
import PromotionList from './PromotionList';
import PromotionForm from './PromotionForm';
import { Plus, Tag, Calendar, Package, Users } from 'lucide-react';
import { MODULES } from '../../../models/userModels';
import { usePermissions } from '../../../hooks/usePermissions';
import toast from 'react-hot-toast';

const StatCard = ({ title, value, icon: Icon, color }) => (
  <div className="bg-white rounded-lg p-6 shadow-sm">
    <div className="flex items-start gap-4">
      <div className={`p-3 rounded-lg ${color}`}>
        <Icon className="w-6 h-6 text-white" />
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">{title}</p>
        <h3 className="text-2xl font-bold mt-1">{value}</h3>
      </div>
    </div>
  </div>
);

const PromotionManager = () => {
  const dispatch = useDispatch();
  const { items: promotions, status, error } = useSelector((state) => state.promotions);
  const { promotionFormOpen } = useSelector((state) => state.ui);

  const { canView, canCreate } = usePermissions();
  const hasViewPermission = canView(MODULES.PROMOTIONS);
  const hasCreatePermission = canCreate(MODULES.PROMOTIONS);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchPromotions());
    }
  }, [status, dispatch]);

  const handleAddNew = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền tạo khuyến mãi mới');
      return;
    }
    dispatch(clearEditingPromotion());
    dispatch(togglePromotionForm());
  };

  const getActivePromotions = () => {
    const now = new Date();
    return promotions.filter((promo) => {
      const startDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);
      return promo.isActive && now >= startDate && now <= endDate;
    });
  };

  const getUpcomingPromotions = () => {
    const now = new Date();
    return promotions.filter((promo) => {
      const startDate = new Date(promo.startDate);
      return promo.isActive && now < startDate;
    });
  };

  const getEndedPromotions = () => {
    const now = new Date();
    return promotions.filter((promo) => {
      const endDate = new Date(promo.endDate);
      return promo.isActive && now > endDate;
    });
  };

  const getTotalProducts = () => {
    return promotions.reduce((sum, promo) => sum + (promo.productCount || 0), 0);
  };
  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý khuyến mãi</p>
        </div>
      </div>
    );
  }
  if (status === 'loading') {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="flex flex-col items-center gap-4">
          <div className="w-12 h-12 border-4 border-primary-600 border-t-transparent rounded-full animate-spin" />
          <p className="text-gray-600">Đang tải dữ liệu...</p>
        </div>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <p className="text-red-500 mb-4">{error}</p>
        <button onClick={() => dispatch(fetchPromotions())} className="btn btn-primary">
          Thử lại
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Quản lý khuyến mãi</h1>
          <p className="text-sm text-gray-500 mt-1">
            Quản lý các chương trình giảm giá và khuyến mãi
          </p>
        </div>
        <button onClick={handleAddNew} className="btn btn-primary flex items-center gap-2">
          <Plus size={20} />
          Thêm khuyến mãi
        </button>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <StatCard
          title="Đang diễn ra"
          value={getActivePromotions().length}
          icon={Tag}
          color="bg-green-500"
        />
        <StatCard
          title="Sắp diễn ra"
          value={getUpcomingPromotions().length}
          icon={Calendar}
          color="bg-blue-500"
        />
        <StatCard
          title="Đã kết thúc"
          value={getEndedPromotions().length}
          icon={Users}
          color="bg-purple-500"
        />
        <StatCard
          title="Tổng sản phẩm"
          value={getTotalProducts()}
          icon={Package}
          color="bg-orange-500"
        />
      </div>

      {/* Filter & Search (có thể thêm sau) */}

      {/* Promotion List */}
      <div className="bg-white rounded-lg shadow">
        <PromotionList promotions={promotions} />
      </div>

      {/* Form Modal */}
      {promotionFormOpen && <PromotionForm />}
    </div>
  );
};

export default PromotionManager;
