import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeft, DollarSign, Package, ShoppingBag, TrendingUp } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { fetchFlashSales } from '../../../store/flashSaleSlice';
import { fetchFlashSaleProducts } from '../../../store/flashSaleProductSlice';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const formatPrice = (price) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(price);
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '';
  return date.toLocaleString('vi-VN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const StatCard = ({ title, value, icon: Icon, description, color }) => (
  <div className="bg-white rounded-lg p-6 shadow-sm">
    <div className="flex items-start gap-4">
      <div className={`p-3 rounded-lg ${color}`}>
        <Icon className="w-6 h-6 text-white" />
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-600">{title}</h3>
        <p className="text-2xl font-semibold mt-1">{value}</p>
        {description && <p className="text-sm text-gray-500 mt-1">{description}</p>}
      </div>
    </div>
  </div>
);

const ProductTable = ({ products }) => (
  <div className="bg-white rounded-lg shadow-sm">
    <div className="px-6 py-4 border-b border-gray-200">
      <h3 className="font-medium">Chi tiết sản phẩm</h3>
    </div>
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Sản phẩm
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
              Đã bán
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
              Doanh thu
            </th>
            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase">
              Còn lại
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {products.map((product) => (
            <tr key={product.id} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="font-medium text-gray-900">{product.name}</div>
                <div className="text-sm text-gray-500">{formatPrice(product.salePrice)}</div>
              </td>
              <td className="px-6 py-4 text-right">
                <div className="font-medium text-gray-900">{product.soldCount || 0}</div>
                <div className="text-sm text-gray-500">
                  {product.stockLimit
                    ? `${(((product.soldCount || 0) / product.stockLimit) * 100).toFixed(1)}%`
                    : '-'}
                </div>
              </td>
              <td className="px-6 py-4 text-right font-medium">
                {formatPrice((product.soldCount || 0) * product.salePrice)}
              </td>
              <td className="px-6 py-4 text-right">{product.stockLeft || 'Không giới hạn'}</td>
            </tr>
          ))}
          {products.length === 0 && (
            <tr>
              <td colSpan={4} className="px-6 py-8 text-center text-gray-500">
                Chưa có sản phẩm nào
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
);

const FlashSaleStats = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const flashSale = useSelector((state) => state.flashSales.items.find((f) => f.id === id));
  const flashSaleProducts = useSelector((state) => state.flashSaleProducts.items);
  const flashSalesStatus = useSelector((state) => state.flashSales.status);
  const productsStatus = useSelector((state) => state.flashSaleProducts.status);

  const [stats, setStats] = useState({
    totalRevenue: 0,
    totalSold: 0,
    totalProducts: 0,
    soldOutProducts: 0,
    averageDiscount: 0,
    highestDiscount: 0,
  });

  const { canView } = usePermissions();
  const hasViewPermission = canView(MODULES.FLASH_SALES);

  // Fetch data
  useEffect(() => {
    if (flashSalesStatus === 'idle') {
      dispatch(fetchFlashSales());
    }
    if (id) {
      dispatch(fetchFlashSaleProducts(id));
    }
  }, [id, flashSalesStatus, dispatch]);

  // Calculate stats
  useEffect(() => {
    if (flashSaleProducts.length > 0) {
      const calculations = flashSaleProducts.reduce(
        (acc, product) => {
          // Revenue
          acc.totalRevenue += (product.soldCount || 0) * product.salePrice;

          // Total sold
          acc.totalSold += product.soldCount || 0;

          // Discount percentage
          const discountPercent =
            ((product.originalPrice - product.salePrice) / product.originalPrice) * 100;
          acc.totalDiscountPercent += discountPercent;
          acc.highestDiscount = Math.max(acc.highestDiscount, discountPercent);

          return acc;
        },
        {
          totalRevenue: 0,
          totalSold: 0,
          totalDiscountPercent: 0,
          highestDiscount: 0,
        }
      );

      const soldOut = flashSaleProducts.filter((p) => p.stockLeft === 0).length;
      const averageDiscount = calculations.totalDiscountPercent / flashSaleProducts.length;

      setStats({
        totalRevenue: calculations.totalRevenue,
        totalSold: calculations.totalSold,
        totalProducts: flashSaleProducts.length,
        soldOutProducts: soldOut,
        averageDiscount: Math.round(averageDiscount),
        highestDiscount: Math.round(calculations.highestDiscount),
      });
    }
  }, [flashSaleProducts]);
  useEffect(() => {
    if (!hasViewPermission) {
      navigate('/admin/unauthorized');
      return;
    }

    if (flashSalesStatus === 'idle') {
      dispatch(fetchFlashSales());
    }
    if (id) {
      dispatch(fetchFlashSaleProducts(id));
    }
  }, [id, flashSalesStatus, hasViewPermission, dispatch, navigate]);

  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem thống kê Flash Sale</p>
        </div>
      </div>
    );
  }
  // Loading state
  if (flashSalesStatus === 'loading' || productsStatus === 'loading') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  // Error state
  if (flashSalesStatus === 'failed' || productsStatus === 'failed') {
    return (
      <div className="text-center py-12">
        <p className="text-red-500">Có lỗi xảy ra khi tải dữ liệu</p>
        <button
          onClick={() => {
            dispatch(fetchFlashSales());
            dispatch(fetchFlashSaleProducts(id));
          }}
          className="btn btn-primary mt-4"
        >
          Thử lại
        </button>
      </div>
    );
  }

  // Chart data
  const chartData = flashSaleProducts.map((product) => ({
    name: product.name,
    sold: product.soldCount || 0,
    revenue: (product.soldCount || 0) * product.salePrice,
  }));

  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-6">
        <button onClick={() => navigate('/admin/flash-sales')} className="btn btn-ghost">
          <ArrowLeft size={20} />
          Quay lại
        </button>
        <div>
          <h1 className="text-2xl font-bold">Thống kê Flash Sale: {flashSale?.name}</h1>
          <p className="text-sm text-gray-500">
            Thời gian: {formatDate(flashSale?.startTime)} - {formatDate(flashSale?.endTime)}
          </p>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <StatCard
          title="Doanh thu"
          value={formatPrice(stats.totalRevenue)}
          icon={DollarSign}
          color="bg-green-500"
        />
        <StatCard
          title="Số lượng đã bán"
          value={`${stats.totalSold} sản phẩm`}
          icon={ShoppingBag}
          description={`${stats.soldOutProducts}/${stats.totalProducts} sản phẩm hết hàng`}
          color="bg-blue-500"
        />
        <StatCard
          title="Giảm giá trung bình"
          value={`${stats.averageDiscount}%`}
          icon={Package}
          description={`Cao nhất ${stats.highestDiscount}%`}
          color="bg-purple-500"
        />
        <StatCard
          title="Tỉ lệ bán"
          value={`${stats.totalSold}/${stats.totalProducts}`}
          icon={TrendingUp}
          description="Số sản phẩm đã bán"
          color="bg-orange-500"
        />
      </div>

      {/* Chart */}
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        <h3 className="font-medium mb-4">Thống kê theo sản phẩm</h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis yAxisId="left" orientation="left" />
              <YAxis yAxisId="right" orientation="right" />
              <Tooltip
                formatter={(value, name) => {
                  if (name === 'revenue') return formatPrice(value);
                  return value;
                }}
              />
              <Legend />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="sold"
                stroke="#2563eb"
                name="Số lượng bán"
              />
              <Line
                yAxisId="right"
                type="monotone"
                dataKey="revenue"
                stroke="#16a34a"
                name="Doanh thu"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Products Table */}
      <ProductTable products={flashSaleProducts} />
    </div>
  );
};

export default FlashSaleStats;
