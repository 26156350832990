// src/components/AuthGuard.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from './components/Layout/LoadingScreen';

export const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { isInitializing, isLoading } = useSelector((state) => state.globalUI);
  const location = useLocation();

  // Show loading when checking auth or loading initial data
  if (isInitializing || isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/admin/login" state={{ from: location }} replace />;
  }

  return children;
};
