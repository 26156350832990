import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_CRYPTO_KEY || 'your-secret-key-here';

export const secureStorage = {
  setItem: (key, data) => {
    try {
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
      localStorage.setItem(key, encrypted);
      console.log('Data encrypted and stored successfully');
    } catch (error) {
      console.error('Encryption error:', error);
    }
  },

  getItem: (key) => {
    try {
      const encrypted = localStorage.getItem(key);
      if (!encrypted) return null;

      const decrypted = CryptoJS.AES.decrypt(encrypted, SECRET_KEY);
      const data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      console.log('Data decrypted successfully');
      return data;
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  },

  removeItem: (key) => {
    localStorage.removeItem(key);
    console.log('Data removed successfully');
  },
};
