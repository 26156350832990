import React from 'react';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';

const MigrateTemplateDialog = ({ isOpen, onClose, templates, onConfirm, productCount }) => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('');

  const handleConfirm = () => {
    if (!selectedTemplateId) {
      toast.error('Vui lòng chọn mẫu thông số mới');
      return;
    }
    onConfirm(selectedTemplateId);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Di chuyển sản phẩm</h3>
          <button onClick={onClose} className="p-1 hover:bg-gray-100 rounded">
            <X size={20} />
          </button>
        </div>

        <p className="text-gray-600 mb-4">
          Có {productCount} sản phẩm đang sử dụng mẫu này. Vui lòng chọn mẫu thông số mới để di
          chuyển sản phẩm.
        </p>

        <select
          value={selectedTemplateId}
          onChange={(e) => setSelectedTemplateId(e.target.value)}
          className="input w-full mb-4"
        >
          <option value="">Chọn mẫu thông số</option>
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>

        <div className="flex justify-end gap-3">
          <button onClick={onClose} className="btn btn-secondary">
            Hủy
          </button>
          <button
            onClick={handleConfirm}
            className="btn btn-primary"
            disabled={!selectedTemplateId}
          >
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  );
};

export default MigrateTemplateDialog;
