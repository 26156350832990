import { ORDER_STATUS, PAYMENT_STATUS } from './constants';

export const formatDate = (date) => {
  if (!date) return '';

  // Kiểm tra nếu là Firebase Timestamp
  if (date?.toDate) {
    date = date.toDate();
  } else if (typeof date === 'string' || typeof date === 'number') {
    date = new Date(date);
  }

  if (!(date instanceof Date) || isNaN(date)) {
    return '';
  }

  return date.toLocaleDateString('vi-VN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const formatPrice = (price) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(price);
};
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(amount);
};
// Tiếp tục phần getStatusColor
export const getStatusColor = (status) => {
  const colors = {
    [ORDER_STATUS.PENDING]: 'yellow',
    [ORDER_STATUS.PROCESSING]: 'blue',
    [ORDER_STATUS.SHIPPING]: 'purple',
    [ORDER_STATUS.DELIVERED]: 'green',
    [ORDER_STATUS.CANCELLED]: 'red',
    [ORDER_STATUS.RETURNED]: 'gray',
  };
  return colors[status] || 'gray';
};

export const getPaymentStatusColor = (status) => {
  const colors = {
    [PAYMENT_STATUS.PENDING]: 'yellow',
    [PAYMENT_STATUS.PAID]: 'green',
    [PAYMENT_STATUS.PENDING_REFUND]: 'orange',
    [PAYMENT_STATUS.REFUNDED]: 'gray',
    [PAYMENT_STATUS.FAILED]: 'red',
  };
  return colors[status] || 'gray';
};

export const normalizePaymentStatus = (status) => {
  const validStatuses = Object.values(PAYMENT_STATUS);
  return validStatuses.includes(status) ? status : PAYMENT_STATUS.PENDING;
};

export const calculateStats = (orders = []) => {
  // Đếm số lượng theo trạng thái đơn hàng
  const ordersByStatus = orders.reduce((acc, order) => {
    const status = order.status || 'unknown';
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  // Đếm số lượng theo trạng thái thanh toán, loại trừ các đơn đã hủy/trả hàng chưa thanh toán
  const ordersByPaymentStatus = orders.reduce((acc, order) => {
    let paymentStatus = order.payment_status;

    // Nếu đơn đã hủy/trả hàng và chưa thanh toán thì không tính vào thống kê
    if (['cancelled', 'returned'].includes(order.status) && order.payment_status === 'pending') {
      return acc;
    }

    // Tính tổng theo trạng thái thanh toán
    acc[paymentStatus] = (acc[paymentStatus] || 0) + 1;
    return acc;
  }, {});

  // Tính tổng doanh thu từ các đơn đã thanh toán và hoàn thành
  const totalRevenue = orders.reduce((sum, order) => {
    if (order.payment_status === 'paid' && !['cancelled', 'returned'].includes(order.status)) {
      return sum + (order.total || 0);
    }
    return sum;
  }, 0);

  // Tính số đơn có vấn đề
  const problematicOrders =
    (ordersByStatus[ORDER_STATUS.CANCELLED] || 0) +
    (ordersByStatus[ORDER_STATUS.RETURNED] || 0) +
    (ordersByStatus[ORDER_STATUS.RETURNING] || 0);

  // Tính số đơn đã thanh toán (bao gồm cả hoàn tiền)
  const paidOrders =
    (ordersByPaymentStatus[PAYMENT_STATUS.PAID] || 0) +
    (ordersByPaymentStatus[PAYMENT_STATUS.REFUNDED] || 0);

  return {
    ordersByStatus,
    ordersByPaymentStatus,
    totalRevenue,
    problematicOrders,
    paidOrders,
    totalOrders: orders.length,
  };
};

export const validateStatusChange = (currentStatus, newStatus) => {
  const validTransitions = {
    [ORDER_STATUS.PENDING]: [ORDER_STATUS.PROCESSING, ORDER_STATUS.CANCELLED],
    [ORDER_STATUS.PROCESSING]: [ORDER_STATUS.SHIPPING, ORDER_STATUS.CANCELLED],
    [ORDER_STATUS.SHIPPING]: [ORDER_STATUS.DELIVERED, ORDER_STATUS.CANCELLED],
    [ORDER_STATUS.DELIVERED]: [ORDER_STATUS.RETURNED],
    [ORDER_STATUS.CANCELLED]: [],
    [ORDER_STATUS.RETURNED]: [],
  };

  return validTransitions[currentStatus]?.includes(newStatus) || false;
};

export const requiresNote = (currentStatus, newStatus) => {
  return [ORDER_STATUS.CANCELLED, ORDER_STATUS.RETURNED].includes(newStatus);
};

export const validateShippingInfo = (shippingInfo) => {
  const requiredFields = ['provider', 'tracking_number'];
  const missingFields = requiredFields.filter((field) => !shippingInfo[field]);

  if (missingFields.length > 0) {
    throw new Error('Vui lòng điền đầy đủ thông tin vận chuyển');
  }
};

export const canEditOrder = (order) => {
  const nonEditableStatuses = [
    ORDER_STATUS.DELIVERED,
    ORDER_STATUS.CANCELLED,
    ORDER_STATUS.RETURNED,
  ];

  return !nonEditableStatuses.includes(order.status);
};
