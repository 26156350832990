import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setEditingCategory } from '../../../store/uiSlice';
import { deleteCategory } from '../../../store/categorySlice';
import { Edit2, Trash2, ChevronDown, ChevronRight, Image as ImageIcon, X } from 'lucide-react';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';
import * as LucideIcons from 'lucide-react';
import CategoryIcon from '../../../common/CategoryIcon';

const ImagePreview = ({ image, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="relative bg-white p-4 rounded-lg max-w-2xl max-h-[90vh] w-full">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-full"
      >
        <X size={20} />
      </button>
      <img src={image} alt="Preview" className="w-full h-auto rounded-lg object-contain" />
    </div>
  </div>
);

const CategoryItem = ({ category, level = 0, categories }) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(true);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const childCategories = categories.filter((cat) => cat.parent_id === category.id);
  const { canUpdate, canDelete } = usePermissions();

  const handleEdit = () => {
    if (!canUpdate(MODULES.CATEGORIES)) {
      toast.error('Bạn không có quyền chỉnh sửa danh mục');
      return;
    }
    dispatch(setEditingCategory(category));
  };

  const handleDelete = async () => {
    if (!canDelete(MODULES.CATEGORIES)) {
      toast.error('Bạn không có quyền xóa danh mục');
      return;
    }
    if (childCategories.length > 0) {
      toast.error('Không thể xóa danh mục có danh mục con');
      return;
    }
    if (window.confirm('Bạn có chắc muốn xóa danh mục này?')) {
      try {
        await dispatch(deleteCategory(category.id)).unwrap();
        toast.success('Xóa danh mục thành công');
      } catch (error) {
        toast.error('Xóa danh mục thất bại: ' + error.message);
      }
    }
  };

  return (
    <div className="border-b border-gray-100 last:border-0">
      <div
        className={`group relative flex items-center px-4 py-3 hover:bg-gray-50 transition-all
        ${level > 0 ? 'ml-6 border-l border-gray-100' : ''}`}
      >
        {/* Expand/Collapse Button */}
        {childCategories.length > 0 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="absolute left-2 p-1.5 hover:bg-gray-200 rounded-full transition-colors"
          >
            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </button>
        )}

        {/* Category Content */}
        <div className="flex items-center gap-4 flex-1 ml-8">
          {/* Icon Container */}
          <div className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center">
            <CategoryIcon
              category={category}
              className="w-6 h-6 text-gray-600 group-hover:text-primary-600 
                transition-colors"
            />
          </div>
          {/* Category Info */}
          <div>
            <h3 className="font-medium text-gray-900">{category.name}</h3>
            {category.description && (
              <p className="text-sm text-gray-500">{category.description}</p>
            )}
          </div>

          {/* Status Badges */}
          <div className="flex items-center gap-2">
            <span className="text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded-full">
              #{category.display_order || 0}
            </span>
            <span
              className={`text-xs px-2 py-1 rounded-full ${
                category.isActive ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
              }`}
            >
              {category.isActive ? 'Hoạt động' : 'Vô hiệu'}
            </span>
            {category.isFeatured && (
              <span className="text-xs px-2 py-1 rounded-full bg-primary-100 text-primary-600">
                Hiển thị trang chủ
              </span>
            )}
            {category.image && (
              <button
                onClick={() => setShowImagePreview(true)}
                className="flex items-center gap-1 text-xs px-2 py-1 rounded-full 
                  bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors"
              >
                <ImageIcon size={12} />
                <span>Xem ảnh</span>
              </button>
            )}
          </div>

          {/* Actions */}
          <div className="flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity">
            <button
              onClick={handleEdit}
              className="p-2 hover:bg-blue-50 rounded-lg text-blue-600 transition-colors"
              title="Chỉnh sửa"
            >
              <Edit2 size={16} />
            </button>
            <button
              onClick={handleDelete}
              className="p-2 hover:bg-red-50 rounded-lg text-red-500 transition-colors"
              title="Xóa"
            >
              <Trash2 size={16} />
            </button>
          </div>
        </div>
      </div>

      {/* Children Categories */}
      {isExpanded && childCategories.length > 0 && (
        <div className="pl-4">
          {childCategories
            .sort((a, b) => (a.display_order || 0) - (b.display_order || 0))
            .map((child) => (
              <CategoryItem
                key={child.id}
                category={child}
                level={level + 1}
                categories={categories}
              />
            ))}
        </div>
      )}

      {/* Image Preview Modal */}
      {showImagePreview && (
        <ImagePreview image={category.image} onClose={() => setShowImagePreview(false)} />
      )}
    </div>
  );
};

const CategoryList = ({ categories }) => {
  if (!categories || categories.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6 text-center text-gray-500">
        Chưa có danh mục nào trong hệ thống
      </div>
    );
  }
  const rootCategories = categories.filter((cat) => !cat.parent_id);

  return (
    <div className="bg-white rounded-lg shadow-sm divide-y divide-gray-100">
      {rootCategories
        .sort((a, b) => (a.display_order || 0) - (b.display_order || 0))
        .map((category) => (
          <CategoryItem key={category.id} category={category} categories={categories} />
        ))}
    </div>
  );
};

export default CategoryList;
