import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { X, Upload, Save } from 'lucide-react';
import Select from 'react-select';
import { addUser, updateUser } from '../../../store/userSlice';
import { USER_STATUS, USER_TYPE } from '../../../models/userModels';
import imageCompression from 'browser-image-compression';
import { usePermissions } from '../../../hooks/usePermissions';
import toast from 'react-hot-toast';

const ImageUploader = ({ value, onChange }) => {
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange({
          file: compressedFile,
          preview: reader.result,
        });
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      toast.error('Lỗi xử lý ảnh: ' + error.message);
    }
  };

  return (
    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
      {value?.preview ? (
        <div className="relative">
          <img src={value.preview} alt="Preview" className="h-32 w-32 object-cover rounded-full" />
          <button
            type="button"
            onClick={() => onChange(null)}
            className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
          >
            <X size={16} />
          </button>
        </div>
      ) : (
        <div className="space-y-1 text-center">
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <div className="flex text-sm text-gray-600">
            <label
              htmlFor="avatar-upload"
              className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500"
            >
              <input
                id="avatar-upload"
                type="file"
                className="sr-only"
                onChange={handleImageChange}
                accept="image/*"
              />
              <span>Tải ảnh lên</span>
            </label>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG tối đa 1MB</p>
        </div>
      )}
    </div>
  );
};

const UserForm = ({ user, onClose, roles }) => {
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(user?.avatar ? { preview: user.avatar } : null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [useDefaultPassword, setUseDefaultPassword] = useState(!user);
  const { isSuperAdmin } = usePermissions();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: user || {
      username: '',
      email: '',
      fullName: '',
      phone: '',
      type: USER_TYPE.ADMIN,
      status: USER_STATUS.ACTIVE,
      roleId: '',
    },
  });

  // Lọc vai trò dựa trên quyền của người dùng hiện tại
  const filteredRoles = roles.filter((role) => {
    // Nếu là Super Admin, cho phép thấy tất cả roles
    if (isSuperAdmin) return true;
    // Người dùng khác không thấy role Super Admin
    return role.name !== 'Super Admin';
  });

  const roleOptions = filteredRoles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      if (!data.roleId) {
        toast.error('Vui lòng chọn vai trò');
        return;
      }

      const userData = {
        ...data,
        avatar: avatar,
        useDefaultPassword,
      };

      if (user) {
        await dispatch(updateUser({ id: user.id, data: userData })).unwrap();
        toast.success('Cập nhật người dùng thành công');
      } else {
        await dispatch(addUser(userData)).unwrap();
        toast.success('Thêm người dùng thành công');
      }

      onClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl mx-4 my-8">
        {/* Header */}
        <div className="px-6 py-4 border-b flex items-center justify-between">
          <h2 className="text-xl font-bold">
            {user ? 'Chỉnh sửa người dùng' : 'Thêm người dùng mới'}
          </h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
            <div className="space-y-6">
              {/* Avatar Upload */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Ảnh đại diện</label>
                <ImageUploader value={avatar} onChange={setAvatar} />
              </div>

              {/* Basic Info */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tài khoản <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    {...register('username', {
                      required: 'Tài khoản là bắt buộc',
                      pattern: {
                        value: /^[a-zA-Z0-9_]+$/,
                        message: 'Tài khoản chỉ được chứa chữ, số và dấu gạch dưới',
                      },
                      minLength: {
                        value: 3,
                        message: 'Tài khoản phải có ít nhất 3 ký tự',
                      },
                    })}
                    className={`input mt-1 w-full ${errors.username ? 'border-red-500' : ''}`}
                    disabled={!!user} // Disable nếu đang edit user
                  />
                  {errors.username && (
                    <p className="mt-1 text-sm text-red-500">{errors.username.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Họ tên <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    {...register('fullName', { required: 'Họ tên là bắt buộc' })}
                    className={`input mt-1 w-full ${errors.fullName ? 'border-red-500' : ''}`}
                  />
                  {errors.fullName && (
                    <p className="mt-1 text-sm text-red-500">{errors.fullName.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    {...register('email', {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Email không hợp lệ',
                      },
                    })}
                    className={`input mt-1 w-full ${errors.email ? 'border-red-500' : ''}`}
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Số điện thoại</label>
                  <input type="text" {...register('phone')} className="input mt-1 w-full" />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Vai trò <span className="text-red-500">*</span>
                  </label>
                  <Select
                    options={roleOptions}
                    value={roleOptions.find((option) => option.value === watch('roleId'))}
                    onChange={(selected) => setValue('roleId', selected?.value)}
                    className="mt-1"
                    classNamePrefix="select"
                    isDisabled={isSubmitting}
                    placeholder="Chọn vai trò"
                  />
                  {errors.roleId && (
                    <p className="mt-1 text-sm text-red-500">{errors.roleId.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Trạng thái</label>
                  <select {...register('status')} className="input mt-1 w-full">
                    <option value={USER_STATUS.ACTIVE}>Hoạt động</option>
                    <option value={USER_STATUS.INACTIVE}>Không hoạt động</option>
                    <option value={USER_STATUS.BANNED}>Khóa</option>
                  </select>
                </div>
              </div>

              {/* Password Section - Only for new users */}
              {!user && (
                <div>
                  <div className="flex items-center mb-4">
                    <input
                      type="checkbox"
                      id="useDefaultPassword"
                      checked={useDefaultPassword}
                      onChange={(e) => setUseDefaultPassword(e.target.checked)}
                      className="rounded text-primary-600 focus:ring-primary-500"
                    />
                    <label htmlFor="useDefaultPassword" className="ml-2 text-sm text-gray-700">
                      Tạo với mật khẩu mặc định
                      <span className="ml-2 text-sm text-primary-600 font-medium">
                        (mapple@123)
                      </span>
                    </label>
                  </div>

                  {!useDefaultPassword && (
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Mật khẩu <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="password"
                          {...register('password', {
                            required: !useDefaultPassword && 'Mật khẩu là bắt buộc',
                            minLength: {
                              value: 6,
                              message: 'Mật khẩu phải có ít nhất 6 ký tự',
                            },
                            pattern: {
                              value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{6,}$/,
                              message: 'Mật khẩu phải chứa ít nhất 1 chữ cái và 1 số',
                            },
                          })}
                          className={`input mt-1 w-full ${errors.password ? 'border-red-500' : ''}`}
                        />
                        {errors.password && (
                          <p className="mt-1 text-sm text-red-500">{errors.password.message}</p>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Xác nhận mật khẩu <span className="text-red-500">*</span>
                        </label>
                        <input
                          type="password"
                          {...register('confirmPassword', {
                            validate: (value) =>
                              !useDefaultPassword
                                ? value === watch('password') || 'Mật khẩu không khớp'
                                : true,
                          })}
                          className={`input mt-1 w-full ${errors.confirmPassword ? 'border-red-500' : ''}`}
                        />
                        {errors.confirmPassword && (
                          <p className="mt-1 text-sm text-red-500">
                            {errors.confirmPassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="px-6 py-4 bg-gray-50 border-t">
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Hủy
              </button>
              <button
                type="submit"
                className="btn btn-primary flex items-center gap-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Đang xử lý...
                  </>
                ) : (
                  <>
                    <Save size={20} />
                    {user ? 'Cập nhật' : 'Thêm mới'}
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;
