import { createSlice } from '@reduxjs/toolkit';

const loadCartFromStorage = () => {
  try {
    const cartData = localStorage.getItem('cart');
    return cartData ? JSON.parse(cartData) : { items: [], total: 0, itemCount: 0 };
  } catch (error) {
    console.error('Error loading cart from storage:', error);
    return { items: [], total: 0, itemCount: 0 };
  }
};

const saveCartToStorage = (cart) => {
  try {
    localStorage.setItem('cart', JSON.stringify(cart));
  } catch (error) {
    console.error('Error saving cart to storage:', error);
  }
};

const calculateTotals = (items) => {
  return items.reduce(
    (totals, item) => {
      const itemTotal = item.price * item.quantity;
      return {
        total: totals.total + itemTotal,
        itemCount: totals.itemCount + item.quantity,
      };
    },
    { total: 0, itemCount: 0 }
  );
};

const initialState = {
  ...loadCartFromStorage(),
  isOpen: false, // For cart drawer
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { product, quantity = 1 } = action.payload;
      const existingItem = state.items.find((item) => item.id === product.id);

      if (existingItem) {
        existingItem.quantity += quantity;
      } else {
        state.items.push({
          id: product.id,
          name: product.name,
          price: product.price,
          image: product.images[0],
          quantity: quantity,
          stock: product.stock,
        });
      }

      const { total, itemCount } = calculateTotals(state.items);
      state.total = total;
      state.itemCount = itemCount;
      saveCartToStorage({ items: state.items, total, itemCount });
    },

    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      const { total, itemCount } = calculateTotals(state.items);
      state.total = total;
      state.itemCount = itemCount;
      saveCartToStorage({ items: state.items, total, itemCount });
    },

    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.items.find((item) => item.id === id);
      if (item) {
        item.quantity = Math.max(1, Math.min(quantity, item.stock));
        const { total, itemCount } = calculateTotals(state.items);
        state.total = total;
        state.itemCount = itemCount;
        saveCartToStorage({ items: state.items, total, itemCount });
      }
    },

    clearCart: (state) => {
      state.items = [];
      state.total = 0;
      state.itemCount = 0;
      saveCartToStorage({ items: [], total: 0, itemCount: 0 });
    },

    toggleCart: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { addToCart, removeFromCart, updateQuantity, clearCart, toggleCart } =
  cartSlice.actions;

export default cartSlice.reducer;
