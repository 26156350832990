import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import * as Icons from 'lucide-react';
import { Box } from 'lucide-react';

import { fetchClientCategories, selectCategoryTree } from '../../../features/categorySlice';

const CategoryIcon = ({ category, className = '' }) => {
  if (!category?.iconType) return <Box className={`w-5 h-5 ${className}`} />;

  switch (category.iconType) {
    case 'lucide':
      const IconComponent = category.icon ? Icons[category.icon] : null;
      return IconComponent ? (
        <IconComponent className={`w-5 h-5 ${className}`} />
      ) : (
        <Box className={`w-5 h-5 ${className}`} />
      );

    case 'brand':
    case 'url':
      return (
        <img
          src={category.icon}
          alt=""
          className={`w-5 h-5 object-contain ${className}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/fallback-icon.png';
          }}
        />
      );

    default:
      return <Box className={`w-5 h-5 ${className}`} />;
  }
};

const SubCategoryPanel = ({ category, level = 0, parentIsHovered = false }) => {
  const [hoveredCategory, setHoveredCategory] = useState(null);

  if (!category.children?.length) return null;

  return (
    <motion.div
      initial={{ opacity: 0, x: 15 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 15 }}
      transition={{ duration: 0.2 }}
      className={`absolute left-full top-0 min-w-[280px] bg-white shadow-lg 
        rounded-lg border border-gray-100 py-2 z-[${100 - level}]`}
      style={{ marginLeft: '1px' }}
    >
      {category.children.map((child) => (
        <div
          key={child.id}
          className="relative"
          onMouseEnter={() => setHoveredCategory(child.id)}
          onMouseLeave={() => setHoveredCategory(null)}
        >
          <Link
            to={`/category/${child.id}`}
            className="flex items-center gap-3 px-4 py-3 hover:bg-gray-50 group relative"
          >
            {/* Icon Container */}
            <div
              className="w-10 h-10 flex items-center justify-center rounded-lg
              bg-gray-50 group-hover:bg-white transition-colors"
            >
              <CategoryIcon
                category={child}
                className="text-gray-500 group-hover:text-primary-600 transition-colors"
              />
            </div>

            {/* Content */}
            <div className="flex-1 min-w-0">
              <span
                className="font-medium text-gray-700 group-hover:text-primary-600 
                block transition-colors"
              >
                {child.name}
              </span>
              {child.description && (
                <p className="text-xs text-gray-500 mt-0.5 line-clamp-1">{child.description}</p>
              )}
            </div>

            {/* Arrow if has children */}
            {child.children?.length > 0 && (
              <Icons.ChevronRight
                className="w-5 h-5 text-gray-400 group-hover:text-primary-600
                transition-colors"
              />
            )}
          </Link>

          {/* Nested SubCategory */}
          <AnimatePresence>
            {hoveredCategory === child.id && child.children?.length > 0 && (
              <SubCategoryPanel
                category={child}
                level={level + 1}
                parentIsHovered={hoveredCategory === child.id}
              />
            )}
          </AnimatePresence>
        </div>
      ))}
    </motion.div>
  );
};
const CategoryMenuItem = ({ category, level = 0, onClose }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link
        to={`/category/${category.id}`}
        onClick={onClose}
        className={`flex items-center gap-3 px-4 py-3 hover:bg-gray-50 group relative
          ${level === 0 ? 'mx-2 rounded-lg' : ''}`}
      >
        {/* Icon cho tất cả các level */}
        <div
          className={`flex items-center justify-center rounded-lg
          bg-gray-50 group-hover:bg-white transition-colors
          ${level === 0 ? 'w-10 h-10' : 'w-8 h-8'}`} // Size nhỏ hơn cho submenu
        >
          <CategoryIcon
            category={category}
            className={`text-gray-500 group-hover:text-primary-600 transition-colors
              ${level === 0 ? 'w-5 h-5' : 'w-4 h-4'}`} // Icon nhỏ hơn cho submenu
          />
        </div>

        <div className="flex-1 min-w-0">
          <span
            className="font-medium text-gray-700 group-hover:text-primary-600 
            block transition-colors truncate"
          >
            {category.name}
          </span>
          {category.description && (
            <p className="text-xs text-gray-500 mt-0.5 truncate">{category.description}</p>
          )}
        </div>

        {category.children?.length > 0 && (
          <Icons.ChevronRight
            className="w-5 h-5 text-gray-400 group-hover:text-primary-600
            transition-colors"
          />
        )}
      </Link>

      {/* Submenu */}
      <AnimatePresence>
        {isHovered && category.children?.length > 0 && (
          <motion.div
            initial={{ opacity: 0, x: 15 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 15 }}
            transition={{ duration: 0.2 }}
            className={`absolute left-full top-0 min-w-[280px] bg-white shadow-lg 
              rounded-lg border border-gray-100 py-2`}
            style={{
              marginLeft: '1px',
              zIndex: 100 + level,
            }}
          >
            {category.children.map((child) => (
              <CategoryMenuItem
                key={child.id}
                category={child}
                level={level + 1}
                onClose={onClose}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
const CategoryMenu = ({ isMobile = false, onClose }) => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategoryTree);
  const status = useSelector((state) => state.clientCategories.status);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchClientCategories());
    }
  }, [status, dispatch]);

  if (status === 'loading') {
    return (
      <div className="w-[280px] bg-white rounded-lg shadow-sm p-2 space-y-2">
        {[1, 2, 3, 4, 5].map((i) => (
          <div key={i} className="animate-pulse flex items-center gap-3 px-4 py-3">
            <div className="w-10 h-10 bg-gray-200 rounded-lg"></div>
            <div className="flex-1 space-y-2">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="h-3 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      className={`bg-white rounded-lg shadow-sm overflow-visible
      ${isMobile ? 'w-full' : 'w-[280px]'}`}
    >
      {/* Header */}
      <div className="flex items-center gap-3 px-6 py-4 border-b border-gray-100">
        <Icons.Menu size={22} className="text-primary-600" />
        <h3 className="font-medium text-gray-800">Danh mục sản phẩm</h3>
      </div>

      {/* Categories List */}
      <div className="py-2">
        {categories.map((category) => (
          <CategoryMenuItem key={category.id} category={category} level={0} onClose={onClose} />
        ))}
      </div>
    </div>
  );
};

export default CategoryMenu;
