import React, { useState } from 'react';
import { Edit2, Trash2, Save, X, Package } from 'lucide-react';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';
import toast from 'react-hot-toast';

const StatusBadge = ({ status }) => {
  const styles = {
    available: 'bg-green-100 text-green-800',
    out_of_stock: 'bg-red-100 text-red-800',
    ended: 'bg-gray-100 text-gray-800',
  };

  const labels = {
    available: 'Có sẵn',
    out_of_stock: 'Hết hàng',
    ended: 'Kết thúc',
  };

  return (
    <span
      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${styles[status]}`}
    >
      {labels[status]}
    </span>
  );
};

const numberInputStyles = 'w-full input';

const handleFocus = (e) => {
  if (e.target.value === '0') {
    e.target.value = '';
  }
};

const handleBlur = (e, setForm, field, form) => {
  if (e.target.value === '') {
    setForm({ ...form, [field]: '0' });
  }
};

const EditableRow = ({ product, onSave, onCancel }) => {
  const [form, setForm] = useState({
    salePrice: product.salePrice,
    stockLimit: product.stockLimit || '',
    limitPerCustomer: product.limitPerCustomer || '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(product.id, {
      ...form,
      stockLimit: form.stockLimit === '' ? null : Number(form.stockLimit),
      limitPerCustomer: form.limitPerCustomer === '' ? null : Number(form.limitPerCustomer),
    });
  };

  return (
    <tr>
      <td className="px-6 py-4">
        <div className="flex items-center gap-3">
          {product.images?.[0] ? (
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-12 h-12 rounded object-cover"
            />
          ) : (
            <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
              <Package className="w-6 h-6 text-gray-400" />
            </div>
          )}
          <div>
            <div className="font-medium text-gray-900">{product.name}</div>
            {product.sku && <div className="text-sm text-gray-500">SKU: {product.sku}</div>}
          </div>
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="space-y-2">
          <div className="text-sm text-gray-500">Giá gốc: {formatPrice(product.originalPrice)}</div>
          <div>
            <label className="block text-xs text-gray-500 mb-1">Giá Flash Sale</label>
            <input
              type="number"
              value={form.salePrice}
              onChange={(e) => setForm({ ...form, salePrice: Number(e.target.value) })}
              className={numberInputStyles}
              min="0"
            />
          </div>
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="space-y-2">
          <div>
            <label className="block text-xs text-gray-500 mb-1">Số lượng giới hạn</label>
            <input
              type="number"
              value={form.stockLimit}
              onChange={(e) => setForm({ ...form, stockLimit: e.target.value })}
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, setForm, 'stockLimit', form)}
              className={numberInputStyles}
              placeholder="Không giới hạn"
              min="0"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 mb-1">Giới hạn mỗi khách</label>
            <input
              type="number"
              value={form.limitPerCustomer}
              onChange={(e) => setForm({ ...form, limitPerCustomer: e.target.value })}
              onFocus={handleFocus}
              onBlur={(e) => handleBlur(e, setForm, 'limitPerCustomer', form)}
              className={numberInputStyles}
              placeholder="Không giới hạn"
              min="0"
            />
          </div>
        </div>
      </td>

      <td className="px-6 py-4">
        <StatusBadge status={product.status} />
      </td>

      <td className="px-6 py-4 text-center">
        <div className="flex items-center justify-center space-x-2">
          <button
            onClick={handleSubmit}
            className="p-1 text-green-600 hover:text-green-900"
            title="Lưu"
          >
            <Save size={16} />
          </button>
          <button onClick={onCancel} className="p-1 text-gray-600 hover:text-gray-900" title="Hủy">
            <X size={16} />
          </button>
        </div>
      </td>
    </tr>
  );
};

const DisplayRow = ({ product, onEdit, onDelete }) => {
  const calculateDiscount = (originalPrice, salePrice) => {
    const discount = ((originalPrice - salePrice) / originalPrice) * 100;
    return Math.round(discount);
  };

  return (
    <tr className="hover:bg-gray-50">
      <td className="px-6 py-4">
        <div className="flex items-center gap-3">
          {product.images?.[0] ? (
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-12 h-12 rounded object-cover"
            />
          ) : (
            <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center">
              <Package className="w-6 h-6 text-gray-400" />
            </div>
          )}
          <div>
            <div className="font-medium text-gray-900">{product.name}</div>
            {product.sku && <div className="text-sm text-gray-500">SKU: {product.sku}</div>}
          </div>
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="space-y-1">
          <div className="text-sm text-gray-500">Giá gốc: {formatPrice(product.originalPrice)}</div>
          <div className="font-medium">Giá sale: {formatPrice(product.salePrice)}</div>
          <div className="text-sm text-green-600">
            Giảm {calculateDiscount(product.originalPrice, product.salePrice)}%
          </div>
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="space-y-1">
          <div className="text-sm">
            Đã bán: {product.soldCount || 0}/{product.stockLimit || 'Không giới hạn'}
          </div>
          <div className="text-sm">Còn lại: {product.stockLeft || 'Không giới hạn'}</div>
          {product.limitPerCustomer && (
            <div className="text-sm">Giới hạn: {product.limitPerCustomer}/khách</div>
          )}
        </div>
      </td>

      <td className="px-6 py-4">
        <StatusBadge status={product.status} />
      </td>

      <td className="px-6 py-4 text-center">
        <div className="flex items-center justify-center space-x-2">
          <button onClick={onEdit} className="p-1 text-blue-600 hover:text-blue-900" title="Sửa">
            <Edit2 size={16} />
          </button>
          <button onClick={onDelete} className="p-1 text-red-600 hover:text-red-900" title="Xóa">
            <Trash2 size={16} />
          </button>
        </div>
      </td>
    </tr>
  );
};

const formatPrice = (price) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(price);
};

const ProductList = ({ products, onUpdate, onRemove }) => {
  const [editingId, setEditingId] = useState(null);
  const { canUpdate, canDelete } = usePermissions();
  const hasUpdatePermission = canUpdate(MODULES.FLASH_SALES);
  const hasDeletePermission = canDelete(MODULES.FLASH_SALES);

  const handleEdit = (product) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền chỉnh sửa sản phẩm Flash Sale');
      return;
    }
    setEditingId(product.id);
  };

  const handleSave = async (id, data) => {
    try {
      await onUpdate(id, data);
      setEditingId(null);
      toast.success('Cập nhật thành công');
    } catch (error) {
      toast.error('Cập nhật thất bại: ' + error.message);
    }
  };

  const handleDelete = async (productId) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa sản phẩm khỏi Flash Sale');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa sản phẩm này khỏi Flash Sale?')) {
      try {
        await onRemove([productId]);
        toast.success('Xóa sản phẩm thành công');
      } catch (error) {
        toast.error('Xóa sản phẩm thất bại: ' + error.message);
      }
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Sản phẩm
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Giá & Giảm giá
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Số lượng & Đã bán
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Trạng thái
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {products.map((product) =>
            editingId === product.id ? (
              <EditableRow
                key={product.id}
                product={product}
                onSave={handleSave}
                onCancel={() => setEditingId(null)}
              />
            ) : (
              <DisplayRow
                key={product.id}
                product={product}
                onEdit={() => handleEdit(product)}
                onDelete={() => handleDelete(product.id)}
              />
            )
          )}
          {products.length === 0 && (
            <tr>
              <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                Chưa có sản phẩm nào trong Flash Sale
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProductList;
