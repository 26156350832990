import React from 'react';
import Select from 'react-select';
import { ORDER_SOURCES } from '../../../constants';

export const OrderSourceSection = ({
  source,
  onSourceChange,
  externalId,
  onExternalIdChange,
  disabled,
}) => (
  <div className="bg-white p-6 rounded-lg border space-y-4">
    <h3 className="text-lg font-medium text-gray-900">1. Nguồn đơn hàng</h3>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Nguồn đơn hàng <span className="text-red-500">*</span>
        </label>
        <Select
          options={ORDER_SOURCES}
          value={ORDER_SOURCES.find((x) => x.value === source)}
          onChange={onSourceChange}
          isDisabled={disabled}
          placeholder="Chọn nguồn đơn hàng..."
          className="mt-1"
          classNamePrefix="select"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Mã đơn nguồn</label>
        <input
          type="text"
          value={externalId}
          onChange={(e) => onExternalIdChange(e.target.value)}
          disabled={disabled}
          className="input mt-1 w-full"
          placeholder="Mã đơn từ nguồn khác..."
        />
      </div>
    </div>
  </div>
);
