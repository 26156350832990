// promotionSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { uploadToCloudinary } from '../utils/cloudinary';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
};

// Convert timestamps
const convertTimestamps = (data) => {
  const converted = { ...data };
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  if (converted.startDate?.toDate) {
    converted.startDate = converted.startDate.toDate().toISOString();
  }
  if (converted.endDate?.toDate) {
    converted.endDate = converted.endDate.toDate().toISOString();
  }
  return converted;
};

export const fetchPromotions = createAsyncThunk('promotions/fetchPromotions', async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'promotions'));
    return querySnapshot.docs.map((doc) => convertTimestamps({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw new Error('Failed to fetch promotions: ' + error.message);
  }
});
const processImages = async (images) => {
  const processedImages = {};
  for (const [key, value] of Object.entries(images)) {
    if (value?.file) {
      // Nếu là file mới upload
      try {
        const url = await uploadToCloudinary(value.file);
        processedImages[key] = url;
      } catch (error) {
        console.error(`Error uploading ${key} image:`, error);
      }
    } else if (typeof value === 'string') {
      // Nếu là URL đã có
      processedImages[key] = value;
    }
  }
  return processedImages;
};
export const addPromotion = createAsyncThunk('promotions/addPromotion', async (promotionData) => {
  try {
    // Xử lý ảnh trước khi lưu
    const processedImages = await processImages(promotionData.images);

    const docRef = await addDoc(collection(db, 'promotions'), {
      ...promotionData,
      images: processedImages,
      productCount: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    return {
      id: docRef.id,
      ...promotionData,
      images: processedImages,
      productCount: 0,
    };
  } catch (error) {
    throw new Error('Failed to add promotion: ' + error.message);
  }
});

export const updatePromotion = createAsyncThunk(
  'promotions/updatePromotion',
  async ({ id, data }) => {
    try {
      const processedImages = await processImages(data.images);

      const docRef = doc(db, 'promotions', id);
      const updateData = {
        ...data,
        images: processedImages,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(docRef, updateData);
      return { id, ...data, images: processedImages };
    } catch (error) {
      throw new Error('Failed to update promotion: ' + error.message);
    }
  }
);

export const deletePromotion = createAsyncThunk('promotions/deletePromotion', async (id) => {
  try {
    await deleteDoc(doc(db, 'promotions', id));
    return id;
  } catch (error) {
    throw new Error('Failed to delete promotion: ' + error.message);
  }
});

export const updatePromotionProductCount = createAsyncThunk(
  'promotions/updateProductCount',
  async ({ promotionId, count }) => {
    try {
      const docRef = doc(db, 'promotions', promotionId);
      await updateDoc(docRef, {
        productCount: count,
        updatedAt: serverTimestamp(),
      });
      return { id: promotionId, productCount: count };
    } catch (error) {
      throw new Error('Failed to update product count: ' + error.message);
    }
  }
);

const promotionSlice = createSlice({
  name: 'promotions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch promotions
      .addCase(fetchPromotions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchPromotions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add promotion
      .addCase(addPromotion.fulfilled, (state, action) => {
        state.items.push(action.payload);
      })
      // Update promotion
      .addCase(updatePromotion.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      // Delete promotion
      .addCase(deletePromotion.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload);
      })
      // Update product count
      .addCase(updatePromotionProductCount.fulfilled, (state, action) => {
        const promotion = state.items.find((item) => item.id === action.payload.id);
        if (promotion) {
          promotion.productCount = action.payload.productCount;
        }
      });
  },
});

export default promotionSlice.reducer;
