import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { comparePassword, hashPassword } from '../utils/passwordUtils';
import { secureStorage } from '../utils/cryptoUtils';

const DEFAULT_PASSWORD = 'Admin@123'; // Mật khẩu mặc định cho tài khoản admin mới

// Khôi phục trạng thái từ localStorage
const loadAuthState = () => {
  try {
    const authState = secureStorage.getItem('authState');
    if (authState) {
      return authState;
    }
  } catch (error) {
    console.error('Error loading auth state:', error);
  }
  return { isAuthenticated: false, user: null };
};

const initialState = {
  ...loadAuthState(),
  loading: false,
  error: null,
};

// Thunk để lấy mật khẩu mặc định đã hash
export const getDefaultHashedPassword = async () => {
  return await hashPassword(DEFAULT_PASSWORD);
};

// Thunk action để xử lý đăng nhập
export const login = createAsyncThunk('auth/login', async (credentials) => {
  const { username, password } = credentials;

  const q = query(
    collection(db, 'users'),
    where('username', '==', username),
    where('status', '==', 'active')
  );

  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    throw new Error('Tài khoản hoặc mật khẩu không đúng');
  }

  const user = {
    id: snapshot.docs[0].id,
    ...snapshot.docs[0].data(),
  };

  // Kiểm tra tài khoản có bị khóa không
  if (user.status !== 'active') {
    throw new Error('Tài khoản đã bị khóa');
  }

  // So sánh password đã hash
  const isPasswordValid = await comparePassword(password, user.password);
  if (!isPasswordValid) {
    throw new Error('Tài khoản hoặc mật khẩu không đúng');
  }

  // Cập nhật lastLoginAt
  await updateDoc(doc(db, 'users', user.id), {
    lastLoginAt: serverTimestamp(),
  });

  // Không lưu password vào state và localStorage
  const { password: _, ...userData } = user;

  // Lưu vào localStorage với mã hóa
  secureStorage.setItem('authState', {
    isAuthenticated: true,
    user: userData,
  });

  return userData;
});

// Thunk action để xử lý đổi mật khẩu
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ userId, newPassword, oldPassword, isDefaultPassword }) => {
    try {
      const userRef = doc(db, 'users', userId);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        throw new Error('Không tìm thấy người dùng');
      }

      const userData = userSnap.data();

      // Kiểm tra mật khẩu cũ nếu không phải đổi mật khẩu mặc định
      if (!isDefaultPassword) {
        const isOldPasswordValid = await comparePassword(oldPassword, userData.password);
        if (!isOldPasswordValid) {
          throw new Error('Mật khẩu cũ không đúng');
        }
      }

      // Hash mật khẩu mới
      const hashedNewPassword = await hashPassword(newPassword);

      // Cập nhật mật khẩu mới
      await updateDoc(userRef, {
        password: hashedNewPassword,
        isDefaultPassword: false,
        updatedAt: serverTimestamp(),
      });

      // Cập nhật lại thông tin người dùng trong localStorage
      const authState = secureStorage.getItem('authState');
      if (authState && authState.user.id === userId) {
        authState.user.isDefaultPassword = false;
        secureStorage.setItem('authState', authState);
      }

      return { userId };
    } catch (error) {
      throw new Error(`Lỗi đổi mật khẩu: ${error.message}`);
    }
  }
);

// Thunk action để xử lý đăng xuất
export const logout = createAsyncThunk('auth/logout', async () => {
  // Xóa dữ liệu từ localStorage
  secureStorage.removeItem('authState');
  return null;
});

// Thunk action để kiểm tra trạng thái đăng nhập
export const checkAuthState = createAsyncThunk('auth/checkState', async (_, { getState }) => {
  const { auth } = getState();
  if (!auth.user) return null;

  try {
    // Kiểm tra user trong database
    const userRef = doc(db, 'users', auth.user.id);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      throw new Error('User không tồn tại');
    }

    const userData = userSnap.data();

    // Kiểm tra trạng thái tài khoản
    if (userData.status !== 'active') {
      throw new Error('Tài khoản đã bị khóa');
    }

    const { password: _, ...userWithoutPassword } = userData;

    return {
      ...userWithoutPassword,
      id: auth.user.id,
    };
  } catch (error) {
    // Nếu có lỗi, xóa dữ liệu đăng nhập
    secureStorage.removeItem('authState');
    throw error;
  }
});

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Logout
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        state.user = null;
        state.error = null;
      })
      // Check Auth State
      .addCase(checkAuthState.fulfilled, (state, action) => {
        if (action.payload) {
          state.isAuthenticated = true;
          state.user = action.payload;
        }
      })
      .addCase(checkAuthState.rejected, (state) => {
        state.isAuthenticated = false;
        state.user = null;
      })
      // Change Password
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        if (state.user?.id === action.payload.userId) {
          state.user.isDefaultPassword = false;
        }
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setError, clearError } = authSlice.actions;
export { DEFAULT_PASSWORD };
export default authSlice.reducer;
