import React from 'react';
import { Tag } from 'lucide-react';

const TAG_COLORS = {
  gaming: 'bg-purple-100 text-purple-800',
  laptop: 'bg-blue-100 text-blue-800',
  desktop: 'bg-green-100 text-green-800',
  monitor: 'bg-yellow-100 text-yellow-800',
  default: 'bg-gray-100 text-gray-800',
};

const TagBadge = ({ tag, className = '' }) => {
  // Helper function to get color based on tag name
  const getTagColor = (tagName) => {
    const key = Object.keys(TAG_COLORS).find((k) => tagName.toLowerCase().includes(k));
    return TAG_COLORS[key] || TAG_COLORS.default;
  };

  return (
    <span
      className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium ${getTagColor(tag)} ${className}`}
    >
      <Tag size={12} />
      {tag}
    </span>
  );
};

export default TagBadge;
