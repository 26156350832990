import { useSelector } from 'react-redux';
import { generatePermissionCode } from '../models/userModels';
import { Navigate } from 'react-router-dom';

export const usePermissions = () => {
  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.roles.roles);

  const userRole = roles.find((role) => role.id === user?.roleId);
  const isSuperAdmin = userRole?.name === 'Super Admin';

  const hasPermission = (module, action) => {
    if (!user || !userRole) return false;
    if (isSuperAdmin) return true;

    const permissionCode = generatePermissionCode(module, action);
    return userRole.permissions?.includes(permissionCode) || false;
  };

  // Kiểm tra quyền cụ thể
  const canView = (module) => hasPermission(module, 'view');
  const canCreate = (module) => hasPermission(module, 'create');
  const canUpdate = (module) => hasPermission(module, 'update');
  const canDelete = (module) => hasPermission(module, 'delete');

  return {
    hasPermission,
    canView,
    canCreate,
    canUpdate,
    canDelete,
    isSuperAdmin,
    userRole,
  };
};

// HOC bảo vệ route dựa trên quyền
export const withPermission = (WrappedComponent, module, action) => {
  return function WithPermissionComponent(props) {
    const { hasPermission, isSuperAdmin } = usePermissions();

    if (!isSuperAdmin && !hasPermission(module, action)) {
      return <Navigate to="/admin/unauthorized" />;
    }

    return <WrappedComponent {...props} />;
  };
};
