// src/client/components/home/HeroWithMenu.jsx
import React from 'react';
import CategoryMenu from '../layout/Header/CategoryMenu';
import HeroSection from './HeroSection';

const HeroWithMenu = () => {
  return (
    <section className="container mx-auto px-4 py-6">
      {/* Desktop Layout */}
      <div className="flex flex-col lg:flex-row gap-6">
        {/* Category Menu - Hidden on mobile, shown on desktop */}
        <div className="hidden lg:block relative z-50 w-[280px] flex-shrink-0">
          <CategoryMenu />
        </div>

        {/* Hero Section */}
        <div className="flex-1 relative z-10 min-h-[200px] md:min-h-[400px] lg:min-h-[500px]">
          <HeroSection />
        </div>
      </div>
    </section>
  );
};

export default HeroWithMenu;
