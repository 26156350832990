// src/client/pages/Account/tabs/OrdersTab.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Package, Eye, AlertTriangle } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { db } from '../../../../firebase/config';
import { collection, query, where, getDocs, orderBy, getDoc, doc } from 'firebase/firestore';
import OrderDetailModal from './OrderDetailModal';
import OrderStatusStats from '../../../components/common/order/OrderStatusStats';

const OrdersTab = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.authClient.user);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetail, setShowDetail] = useState(false);

  // Lấy active status từ URL params
  const activeStatus = searchParams.get('status') || 'all';
  const calculateOrderTotal = (order) => {
    const subtotal =
      order.items?.reduce((sum, item) => {
        const price = Number(item.price) || 0;
        const quantity = Number(item.quantity) || 0;
        return sum + price * quantity;
      }, 0) || 0;

    const shippingFee = Number(order.shipping_fee) || 0;
    const discount = Number(order.discount) || 0;

    return subtotal + shippingFee - discount;
  };
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (!user?.id) {
          console.log('No user ID found');
          return;
        }

        setLoading(true);
        setError(null);

        // 1. Fetch orders
        const ordersRef = collection(db, 'orders');
        let q = query(ordersRef, where('userId', '==', user.id), orderBy('createdAt', 'desc'));

        const querySnapshot = await getDocs(q);

        const ordersWithDetails = await Promise.all(
          querySnapshot.docs.map(async (orderDoc) => {
            const orderData = orderDoc.data();

            // Fetch order items
            const orderItemsRef = collection(db, 'order_items');
            const itemsQuery = query(orderItemsRef, where('orderId', '==', orderDoc.id));
            const itemsSnapshot = await getDocs(itemsQuery);

            // Fetch product details and process items
            const itemsWithDetails = await Promise.all(
              itemsSnapshot.docs.map(async (itemDoc) => {
                const itemData = itemDoc.data();

                // Ensure numeric values
                const price = Number(itemData.price) || 0;
                const quantity = Number(itemData.quantity) || 0;
                const originalPrice = Number(itemData.originalPrice) || price;

                const productRef = doc(db, 'products', itemData.productId);
                const productSnap = await getDoc(productRef);
                const productData = productSnap.exists() ? productSnap.data() : null;

                return {
                  id: itemDoc.id,
                  ...itemData,
                  price,
                  quantity,
                  originalPrice,
                  subtotal: price * quantity,
                  productDetails: productData,
                  image: productData?.images?.[0] || itemData.image,
                };
              })
            );

            // Process order data with proper number conversion
            const processedOrder = {
              id: orderDoc.id,
              ...orderData,
              items: itemsWithDetails,
              subtotal: itemsWithDetails.reduce((sum, item) => sum + item.subtotal, 0),
              shipping_fee: Number(orderData.shipping_fee) || 0,
              discount: Number(orderData.discount) || 0,
              createdAt: orderData.createdAt?.toDate?.() || new Date(),
              orderDate: orderData.orderDate?.toDate?.() || new Date(),
              updatedAt: orderData.updatedAt?.toDate?.() || new Date(),
            };

            // Calculate total
            processedOrder.total =
              processedOrder.subtotal + processedOrder.shipping_fee - processedOrder.discount;

            return processedOrder;
          })
        );

        console.log('Fetched orders with details:', ordersWithDetails);
        setOrders(ordersWithDetails);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setError('Không thể tải danh sách đơn hàng');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user?.id]);

  // Filter orders based on status
  const filteredOrders =
    activeStatus === 'all' ? orders : orders.filter((order) => order.status === activeStatus);

  const handleViewDetail = (order) => {
    setSelectedOrder(order);
    setShowDetail(true);
  };

  const handleCloseDetail = () => {
    setSelectedOrder(null);
    setShowDetail(false);
  };

  // Update URL when status changes
  const handleStatusChange = (status) => {
    navigate(`/account/orders?status=${status}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <AlertTriangle className="w-12 h-12 text-red-500 mx-auto mb-4" />
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Đơn hàng của tôi</h2>
      </div>

      {/* Stats Overview */}
      <OrderStatusStats orders={orders} />

      {/* Status Filters */}
      <div className="flex overflow-x-auto hide-scrollbar gap-2 pb-2">
        {[
          { value: 'all', label: 'Tất cả' },
          { value: 'pending', label: 'Chờ xử lý' },
          { value: 'processing', label: 'Đang xử lý' },
          { value: 'shipping', label: 'Đang giao' },
          { value: 'delivered', label: 'Đã giao' },
          { value: 'cancelled', label: 'Đã hủy' },
          { value: 'returned', label: 'Đã trả hàng' },
        ].map((status) => (
          <button
            key={status.value}
            onClick={() => handleStatusChange(status.value)}
            className={`px-4 py-2 rounded-full whitespace-nowrap transition-colors ${
              activeStatus === status.value
                ? 'bg-primary-600 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            {status.label}
          </button>
        ))}
      </div>

      {/* Orders List */}
      <div className="space-y-4">
        <AnimatePresence>
          {filteredOrders.length === 0 ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="text-center py-12"
            >
              <Package className="w-16 h-16 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900">Chưa có đơn hàng nào</h3>
              <p className="text-gray-500 mt-2">Bạn chưa có đơn hàng nào trong mục này</p>
            </motion.div>
          ) : (
            filteredOrders.map((order) => (
              <motion.div
                key={order.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-white rounded-lg border p-6 space-y-4"
              >
                {/* Order Header */}
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="font-medium">Đơn hàng #{order.orderNumber}</h3>
                    <p className="text-sm text-gray-500">
                      {new Date(order.orderDate).toLocaleDateString('vi-VN', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </p>
                  </div>
                  <div
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      order.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800'
                        : order.status === 'processing'
                          ? 'bg-blue-100 text-blue-800'
                          : order.status === 'shipping'
                            ? 'bg-purple-100 text-purple-800'
                            : order.status === 'delivered'
                              ? 'bg-green-100 text-green-800'
                              : order.status === 'cancelled'
                                ? 'bg-red-100 text-red-800'
                                : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {order.status === 'pending'
                      ? 'Chờ xử lý'
                      : order.status === 'processing'
                        ? 'Đang xử lý'
                        : order.status === 'shipping'
                          ? 'Đang giao'
                          : order.status === 'delivered'
                            ? 'Đã giao'
                            : order.status === 'cancelled'
                              ? 'Đã hủy'
                              : order.status === 'returned'
                                ? 'Đã trả hàng'
                                : order.status}
                  </div>
                </div>

                {/* Order Items */}
                <div className="divide-y">
                  {order.items?.map((item) => (
                    <div key={item.id} className="py-4 first:pt-0 last:pb-0">
                      <div className="flex gap-4">
                        {/* ... phần image giữ nguyên ... */}
                        <div className="flex-1">
                          <h4 className="font-medium">{item.name}</h4>
                          {item.productDetails?.sku && (
                            <p className="text-xs text-gray-500">SKU: {item.productDetails.sku}</p>
                          )}
                          <p className="text-sm text-gray-500">
                            {item.quantity} x{' '}
                            {new Intl.NumberFormat('vi-VN', {
                              style: 'currency',
                              currency: 'VND',
                            }).format(item.price)}
                          </p>
                          {item.specifications && (
                            <p className="text-xs text-gray-500 mt-1">
                              {Object.entries(item.specifications)
                                .map(([key, value]) => `${key}: ${value}`)
                                .join(', ')}
                            </p>
                          )}
                        </div>
                        <div className="text-right">
                          <p className="font-medium text-primary-600">
                            {new Intl.NumberFormat('vi-VN', {
                              style: 'currency',
                              currency: 'VND',
                            }).format(item.subtotal)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Order Summary */}
                <div className="flex justify-between items-center pt-4 border-t">
                  <div className="space-y-1">
                    {order.subtotal > 0 && (
                      <div className="text-sm text-gray-500">
                        Tạm tính:{' '}
                        {new Intl.NumberFormat('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        }).format(order.subtotal)}
                      </div>
                    )}
                    {order.shipping_fee > 0 && (
                      <div className="text-sm text-gray-500">
                        Phí vận chuyển:{' '}
                        {new Intl.NumberFormat('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        }).format(order.shipping_fee)}
                      </div>
                    )}
                    {order.discount > 0 && (
                      <div className="text-sm text-green-600">
                        Giảm giá: -
                        {new Intl.NumberFormat('vi-VN', {
                          style: 'currency',
                          currency: 'VND',
                        }).format(order.discount)}
                      </div>
                    )}
                    <div className="text-base font-bold text-primary-600">
                      Tổng cộng:{' '}
                      {new Intl.NumberFormat('vi-VN', {
                        style: 'currency',
                        currency: 'VND',
                      }).format(order.total)}
                    </div>
                  </div>
                  <button
                    onClick={() => handleViewDetail(order)}
                    className="flex items-center gap-2 text-primary-600 hover:text-primary-700"
                  >
                    <Eye className="w-4 h-4" />
                    <span>Xem chi tiết</span>
                  </button>
                </div>
              </motion.div>
            ))
          )}
        </AnimatePresence>
      </div>

      {/* Order Detail Modal */}
      <AnimatePresence>
        {showDetail && selectedOrder && (
          <OrderDetailModal order={selectedOrder} onClose={handleCloseDetail} />
        )}
      </AnimatePresence>
    </div>
  );
};

export default OrdersTab;
