// src/components/admin/OrderManager/OrderDetailModal/index.jsx
import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import {
  FileText,
  DollarSign,
  Truck,
  History,
  Package,
  X,
  User,
  CheckCircle,
  RotateCcw,
  RefreshCcw,
} from 'lucide-react';
import { PaymentForm } from './components/PaymentForm';
import { ShippingForm } from './components/ShippingForm';
import { CustomerInfoForm } from './components/CustomerInfoForm';
import toast from 'react-hot-toast';
import { ORDER_STATUS, PAYMENT_STATUS, PAYMENT_STATUS_FLOW } from '../constants';
import { formatCurrency } from '../utils';
import { LoadingOverlay } from '../../common/Loading';
import { OrderStatusBadge, PaymentStatusBadge } from './Badges';
import { useOrderPermissions } from '../../../../hooks/useOrderPermissions';

const TABS = [
  { id: 'details', icon: FileText, label: 'Chi tiết đơn hàng' },
  { id: 'customer', icon: User, label: 'Thông tin khách hàng' },
  { id: 'payment', icon: DollarSign, label: 'Thanh toán' },
  { id: 'shipping', icon: Truck, label: 'Vận chuyển' },
  { id: 'history', icon: History, label: 'Lịch sử' },
];

const OrderDetailModal = ({
  order: initialOrder,
  onClose,
  onUpdateStatus,
  onUpdateShipping,
  onUpdatePayment,
  onUpdateCustomer,
  isSubmitting,
}) => {
  const [selectedOrder, setSelectedOrder] = useState(initialOrder);
  const [selectedTab, setSelectedTab] = useState('details');
  const [note, setNote] = useState('');

  const { hasUpdatePermission } = useOrderPermissions();

  // Handle payment status update
  const handlePaymentUpdate = async (status, paymentNote) => {
    try {
      // Validate payment flow
      const allowedStatuses =
        PAYMENT_STATUS_FLOW[selectedOrder.status]?.[selectedOrder.payment_status] || [];
      if (!allowedStatuses.includes(status)) {
        toast.error('Không thể chuyển sang trạng thái thanh toán này');
        return;
      }

      if (!paymentNote?.trim()) {
        toast.error('Vui lòng nhập ghi chú cho thay đổi trạng thái thanh toán');
        return;
      }

      await onUpdatePayment(selectedOrder.id, status, paymentNote);

      setSelectedOrder((prev) => ({
        ...prev,
        payment_status: status,
        payment_history: [
          ...(prev.payment_history || []),
          { status, note: paymentNote, timestamp: new Date().toISOString() },
        ],
      }));

      toast.success('Cập nhật trạng thái thanh toán thành công');
    } catch (error) {
      toast.error('Lỗi cập nhật trạng thái thanh toán');
      throw error;
    }
  };

  // Handle shipping update
  const handleShippingUpdate = async (shippingInfo) => {
    try {
      if (
        !shippingInfo.provider ||
        !shippingInfo.tracking_number ||
        typeof shippingInfo.fee !== 'number'
      ) {
        toast.error('Vui lòng điền đầy đủ thông tin vận chuyển');
        return;
      }

      await onUpdateShipping(selectedOrder.id, shippingInfo);

      // Recalculate total
      const newTotal =
        (selectedOrder.subtotal || 0) -
        (selectedOrder.discount || 0) +
        Number(shippingInfo.fee || 0);

      setSelectedOrder((prev) => ({
        ...prev,
        shipping_info: shippingInfo,
        shipping_fee: Number(shippingInfo.fee || 0),
        total: newTotal,
      }));

      toast.success('Cập nhật thông tin vận chuyển thành công');
    } catch (error) {
      toast.error('Lỗi cập nhật thông tin vận chuyển');
      throw error;
    }
  };

  // Handle customer info update
  const handleCustomerUpdate = async (customerInfo, customerNote) => {
    try {
      if (!customerNote?.trim()) {
        toast.error('Vui lòng nhập lý do thay đổi thông tin');
        return;
      }

      await onUpdateCustomer(selectedOrder.id, customerInfo, customerNote);

      setSelectedOrder((prev) => ({
        ...prev,
        shipping_address: customerInfo,
        customer_history: [
          ...(prev.customer_history || []),
          {
            timestamp: new Date().toISOString(),
            note: customerNote,
            old_data: prev.shipping_address,
            new_data: customerInfo,
          },
        ],
      }));

      toast.success('Cập nhật thông tin khách hàng thành công');
    } catch (error) {
      toast.error('Lỗi cập nhật thông tin khách hàng');
      throw error;
    }
  };
  const renderPaymentStatus = () => {
    // Nếu đơn đã hủy/trả hàng và chưa thanh toán thì chuyển sang NONE
    if (
      ['cancelled', 'returned'].includes(selectedOrder.status) &&
      selectedOrder.payment_status === PAYMENT_STATUS.PENDING
    ) {
      return <PaymentStatusBadge status={PAYMENT_STATUS.NONE} orderStatus={selectedOrder.status} />;
    }
    return (
      <PaymentStatusBadge
        status={selectedOrder.payment_status}
        orderStatus={selectedOrder.status}
      />
    );
  };
  // Handle order status update
  const handleStatusUpdate = async (status) => {
    // Validate note for certain status changes
    if ([ORDER_STATUS.RETURNED, ORDER_STATUS.CANCELLED].includes(status) && !note?.trim()) {
      toast.error(
        `Vui lòng nhập lý do ${status === ORDER_STATUS.CANCELLED ? 'hủy' : 'hoàn trả'} đơn hàng`
      );
      return;
    }

    try {
      const updatePayload = {
        status,
        status_history: [
          ...(selectedOrder.status_history || []),
          { status, note, timestamp: new Date().toISOString() },
        ],
      };

      // Xử lý trạng thái thanh toán
      if (
        status === ORDER_STATUS.RETURNED &&
        selectedOrder.payment_status === PAYMENT_STATUS.PAID
      ) {
        // Nếu xác nhận hoàn trả và đơn đã thanh toán -> chuyển sang chờ hoàn tiền
        updatePayload.payment_status = PAYMENT_STATUS.PENDING_REFUND;
        updatePayload.payment_history = [
          ...(selectedOrder.payment_history || []),
          {
            status: PAYMENT_STATUS.PENDING_REFUND,
            note: 'Chuyển sang chờ hoàn tiền do đơn hàng đã hoàn trả',
            timestamp: new Date().toISOString(),
          },
        ];
      } else if (
        status === ORDER_STATUS.DELIVERED &&
        selectedOrder.status === ORDER_STATUS.RETURNING
      ) {
        // Nếu hủy hoàn trả -> giữ nguyên trạng thái thanh toán trước đó
        updatePayload.payment_status = selectedOrder.payment_status;
        updatePayload.payment_history = [
          ...(selectedOrder.payment_history || []),
          {
            status: selectedOrder.payment_status,
            note: 'Giữ nguyên trạng thái thanh toán do hủy yêu cầu hoàn trả',
            timestamp: new Date().toISOString(),
          },
        ];
      } else if (
        [ORDER_STATUS.CANCELLED, ORDER_STATUS.RETURNED].includes(status) &&
        selectedOrder.payment_status === PAYMENT_STATUS.PENDING
      ) {
        // Chuyển sang NONE nếu đơn hủy/hoàn trả mà chưa thanh toán
        updatePayload.payment_status = PAYMENT_STATUS.NONE;
        updatePayload.payment_history = [
          ...(selectedOrder.payment_history || []),
          {
            status: PAYMENT_STATUS.NONE,
            note: `Chuyển sang không có thanh toán do đơn hàng ${status === ORDER_STATUS.CANCELLED ? 'đã hủy' : 'đã hoàn trả'}`,
            timestamp: new Date().toISOString(),
          },
        ];
      }

      // Gọi API cập nhật
      const result = await onUpdateStatus(selectedOrder.id, status, note);

      // Kết hợp kết quả
      const finalUpdateData = {
        ...updatePayload,
        ...(result?.payment_status &&
          !updatePayload.payment_status && {
            payment_status: result.payment_status,
            payment_history: [...(selectedOrder.payment_history || []), result.payment_history],
          }),
      };

      // Cập nhật local state
      setSelectedOrder((prev) => ({
        ...prev,
        ...finalUpdateData,
      }));

      setNote('');
      toast.success('Cập nhật trạng thái đơn hàng thành công');
    } catch (error) {
      toast.error('Lỗi cập nhật trạng thái đơn hàng');
    }
  };

  // Render order status action buttons
  const renderOrderActions = () => {
    const actions = [];

    switch (selectedOrder.status) {
      case ORDER_STATUS.PENDING:
        actions.push(
          <button
            key="process"
            onClick={() => handleStatusUpdate(ORDER_STATUS.PROCESSING)}
            className="btn btn-primary flex items-center gap-2"
            disabled={isSubmitting}
          >
            <FileText className="w-4 h-4" />
            Xác nhận đơn
          </button>
        );
        break;

      case ORDER_STATUS.PROCESSING:
        actions.push(
          <button
            key="ship"
            onClick={() => handleStatusUpdate(ORDER_STATUS.SHIPPING)}
            className="btn btn-primary flex items-center gap-2"
            disabled={isSubmitting}
          >
            <Truck className="w-4 h-4" />
            Giao hàng
          </button>
        );
        break;

      case ORDER_STATUS.SHIPPING:
        actions.push(
          <button
            key="deliver"
            onClick={() => handleStatusUpdate(ORDER_STATUS.DELIVERED)}
            className="btn btn-success flex items-center gap-2"
            disabled={isSubmitting}
          >
            <CheckCircle className="w-4 h-4" />
            Đã giao hàng
          </button>
        );
        break;

      case ORDER_STATUS.DELIVERED:
        actions.push(
          <button
            key="return-request"
            onClick={() => handleStatusUpdate(ORDER_STATUS.RETURNING)}
            className="btn btn-warning flex items-center gap-2"
            disabled={isSubmitting}
          >
            <RotateCcw className="w-4 h-4" />
            Yêu cầu hoàn trả
          </button>
        );
        break;

      case ORDER_STATUS.RETURNING:
        actions.push(
          <>
            <button
              key="confirm-return"
              onClick={() => handleStatusUpdate(ORDER_STATUS.RETURNED)}
              className="btn btn-warning flex items-center gap-2"
              disabled={isSubmitting || !note.trim()}
            >
              <CheckCircle className="w-4 h-4" />
              Xác nhận hoàn trả
            </button>
            <button
              key="cancel-return"
              onClick={() => handleStatusUpdate(ORDER_STATUS.DELIVERED)}
              className="btn btn-secondary flex items-center gap-2"
              disabled={isSubmitting}
            >
              <X className="w-4 h-4" />
              Hủy hoàn trả
            </button>
          </>
        );
        break;
      default:
        break;
    }

    // Add cancel button for applicable statuses
    if (['pending', 'processing', 'shipping'].includes(selectedOrder.status)) {
      actions.push(
        <button
          key="cancel"
          onClick={() => handleStatusUpdate(ORDER_STATUS.CANCELLED)}
          className="btn btn-danger flex items-center gap-2"
          disabled={isSubmitting}
        >
          <X className="w-4 h-4" />
          Hủy đơn
        </button>
      );
    }

    return actions;
  };

  // Render payment action buttons based on current state
  const renderPaymentActions = () => {
    const { status, payment_status } = selectedOrder;
    const allowedStatuses = PAYMENT_STATUS_FLOW[status]?.[payment_status] || [];

    const actions = [];

    // Action for confirming payment
    if (allowedStatuses.includes(PAYMENT_STATUS.PAID) && hasUpdatePermission) {
      actions.push(
        <button
          key="paid"
          onClick={() => handlePaymentUpdate(PAYMENT_STATUS.PAID, 'Xác nhận đã thanh toán')}
          className="btn btn-success flex items-center gap-2"
          disabled={isSubmitting}
        >
          <CheckCircle className="w-4 h-4" />
          Xác nhận thanh toán
        </button>
      );
    }

    // Action for initiating refund
    if (allowedStatuses.includes(PAYMENT_STATUS.PENDING_REFUND) && hasUpdatePermission) {
      actions.push(
        <button
          key="pending-refund"
          onClick={() =>
            handlePaymentUpdate(
              PAYMENT_STATUS.PENDING_REFUND,
              'Chuyển sang trạng thái chờ hoàn tiền'
            )
          }
          className="btn btn-warning flex items-center gap-2"
          disabled={isSubmitting}
        >
          <RefreshCcw className="w-4 h-4" />
          Chuyển trạng thái hoàn tiền
        </button>
      );
    }

    // Action for confirming refund
    if (allowedStatuses.includes(PAYMENT_STATUS.REFUNDED) && hasUpdatePermission) {
      actions.push(
        <button
          key="refunded"
          onClick={() =>
            handlePaymentUpdate(PAYMENT_STATUS.REFUNDED, 'Xác nhận đã hoàn tiền cho khách hàng')
          }
          className="btn btn-success flex items-center gap-2"
          disabled={isSubmitting}
        >
          <CheckCircle className="w-4 h-4" />
          Xác nhận đã hoàn tiền
        </button>
      );
    }

    return actions;
  };

  // Render content based on selected tab
  const renderContent = () => {
    switch (selectedTab) {
      case 'details':
        return (
          <div className="space-y-4">
            {/* Product List */}
            <div className="bg-white rounded-lg border max-h-[calc(100vh-24rem)] overflow-y-auto">
              <div className="p-6">
                <h3 className="text-lg font-medium mb-4">Chi tiết sản phẩm</h3>
                <div className="space-y-4">
                  {selectedOrder.items?.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-start gap-4 py-4 border-b last:border-b-0"
                    >
                      <div className="w-16 h-16 bg-gray-100 rounded flex-shrink-0">
                        {item.image ? (
                          <img
                            src={item.image}
                            alt={item.name}
                            className="w-full h-full object-cover rounded"
                          />
                        ) : (
                          <div className="w-full h-full flex items-center justify-center">
                            <Package className="w-8 h-8 text-gray-400" />
                          </div>
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <h4 className="font-medium truncate">{item.name}</h4>
                        {item.sku && <div className="text-sm text-gray-500">SKU: {item.sku}</div>}
                        <div className="text-sm text-gray-500 mt-1">
                          Đơn giá: {formatCurrency(item.price)}
                        </div>
                        <div className="text-sm text-gray-500">Số lượng: {item.quantity}</div>
                      </div>
                      <div className="text-right whitespace-nowrap">
                        <div className="font-medium">
                          {formatCurrency(item.price * item.quantity)}
                        </div>
                      </div>
                    </div>
                  ))}

                  {/* Order Summary */}
                  <div className="space-y-2 pt-4 border-t mt-4">
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Tạm tính</span>
                      <span>{formatCurrency(selectedOrder.subtotal || 0)}</span>
                    </div>
                    {selectedOrder.discount > 0 && (
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Giảm giá</span>
                        <span className="text-green-600">
                          -{formatCurrency(selectedOrder.discount)}
                        </span>
                      </div>
                    )}
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Phí vận chuyển</span>
                      <span>{formatCurrency(selectedOrder.shipping_fee || 0)}</span>
                    </div>
                    <div className="flex justify-between pt-4 border-t">
                      <span className="font-medium">Tổng thanh toán</span>
                      <span className="text-xl font-bold text-primary-600">
                        {formatCurrency(selectedOrder.total || 0)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );

      case 'customer':
        return (
          <div className="max-h-[calc(100vh-24rem)] overflow-y-auto">
            <CustomerInfoForm
              order={selectedOrder}
              onUpdate={handleCustomerUpdate}
              isSubmitting={isSubmitting}
              canUpdate={hasUpdatePermission}
            />
          </div>
        );

      case 'payment':
        return (
          <div className="max-h-[calc(100vh-24rem)] overflow-y-auto">
            <PaymentForm
              order={selectedOrder}
              onUpdatePayment={handlePaymentUpdate}
              renderPaymentActions={renderPaymentActions}
              isSubmitting={isSubmitting}
              canUpdate={hasUpdatePermission}
            />
          </div>
        );

      case 'shipping':
        return (
          <div className="max-h-[calc(100vh-24rem)] overflow-y-auto">
            <ShippingForm
              order={selectedOrder}
              onUpdateShipping={handleShippingUpdate}
              isSubmitting={isSubmitting}
              canUpdate={hasUpdatePermission}
            />
          </div>
        );

      case 'history':
        return (
          <div className="max-h-[calc(100vh-24rem)] overflow-y-auto space-y-4">
            {selectedOrder.status_history?.map((history, index) => (
              <div key={index} className="bg-white rounded-lg border p-4">
                <div className="flex justify-between items-start">
                  <div>
                    <OrderStatusBadge status={history.status} />
                    {history.note && <p className="text-sm text-gray-600 mt-2">{history.note}</p>}
                  </div>
                  <time className="text-sm text-gray-500">
                    {new Date(history.timestamp).toLocaleString()}
                  </time>
                </div>
              </div>
            ))}

            {selectedOrder.customer_history?.length > 0 && (
              <div className="mt-6">
                <h4 className="font-medium mb-4">Lịch sử thay đổi thông tin</h4>
                <div className="space-y-4">
                  {selectedOrder.customer_history.map((history, index) => (
                    <div key={index} className="bg-white rounded-lg border p-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <p className="font-medium">{history.note}</p>
                          <div className="mt-2 text-sm text-gray-600">
                            <p>Thông tin cũ: {history.old_data.address}</p>
                            <p>Thông tin mới: {history.new_data.address}</p>
                          </div>
                        </div>
                        <time className="text-sm text-gray-500">
                          {new Date(history.timestamp).toLocaleString()}
                        </time>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {selectedOrder.payment_history?.length > 0 && (
              <div className="mt-6">
                <h4 className="font-medium mb-4">Lịch sử thanh toán</h4>
                <div className="space-y-4">
                  {selectedOrder.payment_history.map((history, index) => (
                    <div key={index} className="bg-white rounded-lg border p-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <PaymentStatusBadge status={history.status} />
                          {history.note && (
                            <p className="text-sm text-gray-600 mt-2">{history.note}</p>
                          )}
                        </div>
                        <time className="text-sm text-gray-500">
                          {new Date(history.timestamp).toLocaleString()}
                        </time>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-6xl flex flex-col h-[90vh] overflow-hidden">
        {isSubmitting && <LoadingOverlay />}

        {/* Header */}
        <div className="px-6 py-4 border-b flex items-center justify-between flex-shrink-0">
          <div className="flex items-center gap-4">
            <h2 className="text-xl font-bold">Đơn hàng #{selectedOrder.orderNumber}</h2>
            <OrderStatusBadge status={selectedOrder.status} />
            {renderPaymentStatus()}
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            disabled={isSubmitting}
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Tabs */}
        <div className="border-b px-6 flex-shrink-0">
          <Tab.Group
            selectedIndex={TABS.findIndex((t) => t.id === selectedTab)}
            onChange={(index) => setSelectedTab(TABS[index].id)}
          >
            <Tab.List className="flex overflow-x-auto hide-scrollbar">
              {TABS.map((tab) => (
                <Tab
                  key={tab.id}
                  className={({ selected }) =>
                    `flex items-center gap-2 py-3 px-4 text-sm font-medium border-b-2 outline-none transition-colors whitespace-nowrap
                    ${
                      selected
                        ? 'border-primary-500 text-primary-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700'
                    }`
                  }
                >
                  <tab.icon className="w-4 h-4" />
                  {tab.label}
                </Tab>
              ))}
            </Tab.List>
          </Tab.Group>
        </div>

        {/* Content */}
        <div className="flex-1 p-6 overflow-hidden">{renderContent()}</div>

        {/* Footer - Actions */}
        {!['cancelled', 'returned'].includes(selectedOrder.status) && hasUpdatePermission && (
          <div className="px-6 py-4 bg-gray-50 border-t flex-shrink-0">
            <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center gap-4">
              <div className="flex flex-wrap gap-2">{renderOrderActions()}</div>

              {/* Status Note */}
              <div className="flex-1 max-w-lg">
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  placeholder="Ghi chú khi thay đổi trạng thái..."
                  className="input w-full text-sm resize-none"
                  rows={1}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetailModal;
