import { formatCurrency } from '../../../utils';

// components/CreateOrder/components/OrderSummary.jsx
export const OrderSummary = ({ subtotal, shippingFee = 0, total }) => {
  const finalShippingFee = Number(shippingFee) || 0;
  const finalTotal = subtotal + finalShippingFee;

  return (
    <div className="bg-white p-6 rounded-lg border space-y-4">
      <div className="flex justify-between items-center text-sm">
        <span className="text-gray-600">Tạm tính</span>
        <span className="font-medium">{formatCurrency(subtotal)}</span>
      </div>
      <div className="flex justify-between items-center text-sm">
        <span className="text-gray-600">Phí vận chuyển</span>
        <span className="font-medium">{formatCurrency(finalShippingFee)}</span>
      </div>
      <div className="pt-4 border-t">
        <div className="flex justify-between items-center">
          <span className="text-lg font-medium text-gray-900">Tổng tiền</span>
          <span className="text-xl font-bold text-primary-600">{formatCurrency(finalTotal)}</span>
        </div>
      </div>
    </div>
  );
};
