// src/client/pages/Account/tabs/WishlistTab.jsx
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Heart, Package, Trash2, ShoppingCart } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { db } from '../../../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { addToCart } from '../../../features/cartSlice';
import { removeFromWishlist, selectWishlistItems } from '../../../features/wishlistSlice';
import { toast } from 'react-hot-toast';

const WishlistTab = () => {
  const dispatch = useDispatch();
  const wishlistItems = useSelector(selectWishlistItems);
  const [wishlistProducts, setWishlistProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWishlistProducts = async () => {
      try {
        if (!wishlistItems.length) {
          setWishlistProducts([]);
          setLoading(false);
          return;
        }

        const productIds = wishlistItems.map((item) => item.productId);
        const productsRef = collection(db, 'products');

        // Split into chunks of 10 due to Firestore limitations
        const chunkSize = 10;
        const chunks = [];
        for (let i = 0; i < productIds.length; i += chunkSize) {
          chunks.push(productIds.slice(i, i + chunkSize));
        }

        // Fetch each chunk
        const products = [];
        for (const chunk of chunks) {
          const q = query(productsRef, where('id', 'in', chunk));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            const wishlistItem = wishlistItems.find((item) => item.productId === doc.id);
            products.push({
              id: doc.id,
              ...doc.data(),
              addedAt: wishlistItem?.addedAt,
            });
          });
        }

        setWishlistProducts(products);
      } catch (error) {
        console.error('Error fetching wishlist products:', error);
        toast.error('Không thể tải danh sách yêu thích');
      } finally {
        setLoading(false);
      }
    };

    fetchWishlistProducts();
  }, [wishlistItems]);

  const handleRemoveFromWishlist = async (productId) => {
    try {
      await dispatch(removeFromWishlist(productId)).unwrap();
      toast.success('Đã xóa khỏi danh sách yêu thích');
    } catch (error) {
      toast.error('Không thể xóa sản phẩm');
    }
  };

  const handleAddToCart = (product) => {
    if (!product.isActive) {
      toast.error('Sản phẩm đã ngừng kinh doanh');
      return;
    }

    if (product.stock === 0) {
      toast.error('Sản phẩm đã hết hàng');
      return;
    }

    dispatch(
      addToCart({
        product: {
          id: product.id,
          name: product.name,
          price: product.price,
          image: product.images?.[0],
          stock: product.stock,
        },
        quantity: 1,
      })
    );
    toast.success('Đã thêm vào giỏ hàng');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (wishlistProducts.length === 0) {
    return (
      <div className="text-center py-12">
        <Heart className="w-16 h-16 text-gray-400 mx-auto mb-4" />
        <h3 className="text-lg font-medium text-gray-900">Chưa có sản phẩm yêu thích</h3>
        <p className="text-gray-500 mt-2">Hãy thêm sản phẩm vào danh sách yêu thích của bạn</p>
        <Link
          to="/products"
          className="inline-flex items-center px-4 py-2 mt-4 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          Tiếp tục mua sắm
        </Link>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <AnimatePresence>
        {wishlistProducts.map((product) => (
          <motion.div
            key={product.id}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="bg-white rounded-lg border p-4 hover:shadow-md transition-shadow"
          >
            {/* Product Image */}
            <Link to={`/product/${product.id}`} className="block relative">
              <div className="aspect-video w-full bg-gray-100 rounded-lg overflow-hidden">
                {product.images?.[0] ? (
                  <img
                    src={product.images[0]}
                    alt={product.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center">
                    <Package className="w-8 h-8 text-gray-400" />
                  </div>
                )}
              </div>
            </Link>

            {/* Product Info */}
            <div className="mt-4">
              <Link
                to={`/product/${product.id}`}
                className="text-lg font-medium hover:text-primary-600"
              >
                {product.name}
              </Link>
              <div className="mt-2 flex items-center justify-between">
                <span className="text-lg font-bold text-primary-600">
                  {new Intl.NumberFormat('vi-VN', {
                    style: 'currency',
                    currency: 'VND',
                  }).format(product.price)}
                </span>
                {product.stock > 0 ? (
                  <span className="text-sm text-green-600">Còn hàng</span>
                ) : (
                  <span className="text-sm text-red-600">Hết hàng</span>
                )}
              </div>
            </div>

            {/* Actions */}
            <div className="mt-4 flex gap-2">
              <button
                onClick={() => handleAddToCart(product)}
                disabled={product.stock === 0}
                className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg
                  hover:bg-primary-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
              >
                <ShoppingCart className="w-4 h-4" />
                Thêm vào giỏ
              </button>
              <button
                onClick={() => handleRemoveFromWishlist(product.id)}
                className="p-2 text-red-500 hover:bg-red-50 rounded-lg"
                title="Xóa khỏi danh sách yêu thích"
              >
                <Trash2 className="w-5 h-5" />
              </button>
            </div>

            {/* Added Date */}
            <div className="mt-2 text-xs text-gray-500">
              Đã thêm vào: {new Date(product.addedAt).toLocaleDateString('vi-VN')}
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default WishlistTab;
