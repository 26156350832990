import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ShoppingCart, Trash2, Plus, Minus } from 'lucide-react';
import { removeFromCart, toggleCart, updateQuantity } from '../../features/cartSlice';

// Separate CartItem component with enhanced animations
const CartItem = ({ item }) => {
  const dispatch = useDispatch();

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, x: -100 }}
      className="flex gap-4 p-4 border-b hover:bg-gray-50 transition-colors"
    >
      <div className="w-24 h-24 flex-shrink-0 overflow-hidden rounded-lg">
        <img
          src={item.image}
          alt={item.name}
          className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-300"
        />
      </div>

      <div className="flex-1 min-w-0">
        <Link
          to={`/product/${item.id}`}
          className="text-sm font-medium text-gray-900 hover:text-primary-600 line-clamp-2 mb-2"
        >
          {item.name}
        </Link>

        <div className="flex items-center justify-between mb-2">
          <span className="text-primary-600 font-semibold">
            {new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND',
            }).format(item.price)}
          </span>
          <span className="text-sm text-gray-500">
            Tổng:{' '}
            {new Intl.NumberFormat('vi-VN', {
              style: 'currency',
              currency: 'VND',
            }).format(item.price * item.quantity)}
          </span>
        </div>

        <div className="flex items-center justify-between">
          <div className="flex items-center border border-gray-300 rounded-lg overflow-hidden">
            <button
              onClick={() => dispatch(updateQuantity({ id: item.id, quantity: item.quantity - 1 }))}
              disabled={item.quantity <= 1}
              className="p-2 hover:bg-gray-100 disabled:opacity-50 transition-colors"
            >
              <Minus size={14} />
            </button>
            <input
              type="number"
              min="1"
              max={item.stock}
              value={item.quantity}
              onChange={(e) =>
                dispatch(
                  updateQuantity({
                    id: item.id,
                    quantity: parseInt(e.target.value),
                  })
                )
              }
              className="w-20 text-center border-x border-gray-300 py-1" // Changed from w-12 to w-14
            />

            <button
              onClick={() => dispatch(updateQuantity({ id: item.id, quantity: item.quantity + 1 }))}
              disabled={item.quantity >= item.stock}
              className="p-2 hover:bg-gray-100 disabled:opacity-50 transition-colors"
            >
              <Plus size={14} />
            </button>
          </div>

          <button
            onClick={() => dispatch(removeFromCart(item.id))}
            className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
          >
            <Trash2 size={18} />
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const CartDrawer = () => {
  const dispatch = useDispatch();
  const { items, total, isOpen } = useSelector((state) => state.cart);

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => dispatch(toggleCart())}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50"
          />

          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', damping: 20 }}
            className="fixed right-0 top-0 h-full w-full max-w-md bg-white shadow-2xl z-50 flex flex-col"
          >
            <div className="flex items-center justify-between px-6 py-4 border-b">
              <div className="flex items-center gap-3">
                <ShoppingCart className="text-primary-600" size={24} />
                <div>
                  <h2 className="text-lg font-semibold">Giỏ hàng</h2>
                  <p className="text-sm text-gray-500">{items.length} sản phẩm</p>
                </div>
              </div>
              <button
                onClick={() => dispatch(toggleCart())}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              >
                <X size={24} />
              </button>
            </div>

            <div className="flex-1 overflow-y-auto">
              <AnimatePresence>
                {items.length > 0 ? (
                  items.map((item) => <CartItem key={item.id} item={item} />)
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="flex flex-col items-center justify-center h-full text-gray-500 p-8"
                  >
                    <ShoppingCart size={64} className="mb-4 opacity-50" />
                    <p className="text-lg font-medium mb-2">Giỏ hàng trống</p>
                    <p className="text-sm text-center">Hãy thêm sản phẩm vào giỏ hàng của bạn</p>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            {items.length > 0 && (
              <div className="border-t p-6 space-y-4 bg-gray-50">
                <div className="flex items-center justify-between">
                  <span className="text-lg font-medium">Tổng tiền:</span>
                  <span className="text-xl font-bold text-primary-600">
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(total)}
                  </span>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <Link
                    to="/cart"
                    onClick={() => dispatch(toggleCart())}
                    className="btn-secondary py-3 px-6 rounded-lg text-center font-medium 
                    border border-primary-600 text-primary-600 hover:bg-primary-50 transition-colors"
                  >
                    Xem giỏ hàng
                  </Link>
                  <Link
                    to="/checkout"
                    onClick={() => dispatch(toggleCart())}
                    className="btn-primary py-3 px-6 rounded-lg text-center font-medium
                    bg-primary-600 text-white hover:bg-primary-700 transition-colors"
                  >
                    Thanh toán
                  </Link>
                </div>
              </div>
            )}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default CartDrawer;
