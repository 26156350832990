// src/client/components/layout/Header/TopBar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Mail, Clock } from 'lucide-react';

const TopBar = () => {
  return (
    <div className="bg-primary-600 text-white py-2">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <a href="tel:0938486358" className="flex items-center space-x-2 hover:text-primary-200">
              <Phone size={16} />
              <span className="text-sm">09.3848.6358</span>
            </a>
            <a
              href="mailto:cuadt2.work@gmail.com"
              className="hidden md:flex items-center space-x-2 hover:text-primary-200"
            >
              <Mail size={16} />
              <span className="text-sm">cuadt2.work@gmail.com</span>
            </a>
          </div>
          <div className="hidden md:flex items-center space-x-4 text-sm">
            <Link to="/gioi-thieu" className="hover:text-primary-200">
              Giới thiệu
            </Link>
            <Link to="/lien-he" className="hover:text-primary-200">
              Liên hệ
            </Link>
            <Link to="/chinh-sach" className="hover:text-primary-200">
              Chính sách
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
