import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, Clock, Tag, ArrowRight, X } from 'lucide-react';
import clsx from 'clsx';

// Hàm để làm nổi bật từ khóa tìm kiếm trong text
const highlightText = (text, query) => {
  if (!query.trim()) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  const parts = text.split(regex);
  return parts.map((part, index) =>
    regex.test(part) ? (
      <span key={index} className="bg-yellow-200">
        {part}
      </span>
    ) : (
      part
    )
  );
};

const SearchBar = () => {
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);

  // Selectors
  const products = useSelector((state) => state.products.items);
  const categories = useSelector((state) => state.clientCategories.flat);

  // Load recent searches from localStorage
  useEffect(() => {
    const savedSearches = localStorage.getItem('recentSearches');
    if (savedSearches) {
      setRecentSearches(JSON.parse(savedSearches));
    }
  }, []);

  // Quick search results
  const quickSearchResults = React.useMemo(() => {
    if (!searchTerm.trim()) return { products: [], categories: [] };

    const query = searchTerm.toLowerCase();
    return {
      products: products
        .filter(
          (product) =>
            product.isActive &&
            (product.name.toLowerCase().includes(query) ||
              product.tags?.some((tag) => tag.toLowerCase().includes(query)))
        )
        .slice(0, 5),
      categories: categories
        .filter(
          (category) =>
            category.isActive &&
            (category.name.toLowerCase().includes(query) ||
              category.description?.toLowerCase().includes(query))
        )
        .slice(0, 3),
    };
  }, [searchTerm, products, categories]);

  // Handle submit search
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) return;

    // Save to recent searches
    const newRecentSearches = [searchTerm, ...recentSearches.filter((s) => s !== searchTerm)].slice(
      0,
      5
    );
    setRecentSearches(newRecentSearches);
    localStorage.setItem('recentSearches', JSON.stringify(newRecentSearches));

    // Navigate to search results
    navigate(`/search?q=${encodeURIComponent(searchTerm.trim())}`);
    setSearchTerm('');
    setIsDropdownOpen(false);
  };

  // Handle click outside to close dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Clear recent searches
  const clearRecentSearches = () => {
    setRecentSearches([]);
    localStorage.removeItem('recentSearches');
  };

  // Remove single recent search
  const removeRecentSearch = (searchToRemove) => {
    const newRecentSearches = recentSearches.filter((s) => s !== searchToRemove);
    setRecentSearches(newRecentSearches);
    localStorage.setItem('recentSearches', JSON.stringify(newRecentSearches));
  };

  return (
    <div ref={searchRef} className="relative flex-1 max-w-xl">
      <form onSubmit={handleSubmit}>
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setIsDropdownOpen(true);
            }}
            onFocus={() => setIsDropdownOpen(true)}
            placeholder="Tìm kiếm sản phẩm..."
            className={clsx(
              'w-full pl-12 pr-4 py-2.5 rounded-xl',
              'border border-gray-300 focus:border-primary-500',
              'bg-gray-50 focus:bg-white',
              'transition-all duration-200',
              'focus:outline-none focus:ring-2 focus:ring-primary-500/20'
            )}
          />
          <Search className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400" size={20} />
          {searchTerm && (
            <button
              type="button"
              onClick={() => setSearchTerm('')}
              className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 
                hover:text-gray-600 p-1 rounded-full hover:bg-gray-100"
            >
              <X size={16} />
            </button>
          )}
        </div>
      </form>

      {/* Search Dropdown */}
      <AnimatePresence>
        {isDropdownOpen && (searchTerm || recentSearches.length > 0) && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute left-0 right-0 top-full mt-2 bg-white rounded-xl 
              shadow-xl border border-gray-200 overflow-hidden z-50"
          >
            {/* Recent Searches */}
            {!searchTerm && recentSearches.length > 0 && (
              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-2 text-sm text-gray-500">
                    <Clock size={16} />
                    <span>Tìm kiếm gần đây</span>
                  </div>
                  <button
                    onClick={clearRecentSearches}
                    className="text-xs text-gray-500 hover:text-gray-700"
                  >
                    Xóa tất cả
                  </button>
                </div>
                <div className="space-y-2">
                  {recentSearches.map((search, index) => (
                    <div key={index} className="flex items-center justify-between group">
                      <button
                        onClick={() => {
                          setSearchTerm(search);
                          navigate(`/search?q=${encodeURIComponent(search)}`);
                          setIsDropdownOpen(false);
                        }}
                        className="flex items-center gap-2 text-sm text-gray-700 
                          hover:text-primary-600"
                      >
                        <Clock size={14} className="text-gray-400" />
                        <span>{search}</span>
                      </button>
                      <button
                        onClick={() => removeRecentSearch(search)}
                        className="opacity-0 group-hover:opacity-100 p-1 
                          text-gray-400 hover:text-gray-600 rounded-full 
                          hover:bg-gray-100 transition-all"
                      >
                        <X size={14} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Quick Search Results */}
            {searchTerm && (
              <div className="divide-y">
                {/* Categories */}
                {quickSearchResults.categories.length > 0 && (
                  <div className="p-4">
                    <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
                      <Tag size={16} />
                      <span>Danh mục phù hợp</span>
                    </div>
                    <div className="space-y-2">
                      {quickSearchResults.categories.map((category) => (
                        <button
                          key={category.id}
                          onClick={() => {
                            navigate(`/category/${category.id}`);
                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center justify-between w-full 
                            p-2 rounded-lg hover:bg-gray-50 group"
                        >
                          <div className="flex items-center gap-3">
                            {category.icon && (
                              <div
                                className="w-8 h-8 rounded-lg bg-gray-100 
                                flex items-center justify-center"
                              >
                                <img
                                  src={category.icon}
                                  alt=""
                                  className="w-5 h-5 object-contain"
                                />
                              </div>
                            )}
                            <div className="text-left">
                              <div className="text-sm font-medium text-gray-900">
                                {highlightText(category.name, searchTerm)}
                              </div>
                              {category.description && (
                                <div className="text-xs text-gray-500">
                                  {highlightText(category.description, searchTerm)}
                                </div>
                              )}
                            </div>
                          </div>
                          <ArrowRight
                            size={16}
                            className="text-gray-400 group-hover:text-primary-600
                              transform group-hover:translate-x-1 transition-all"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                )}

                {/* Products */}
                {quickSearchResults.products.length > 0 && (
                  <div className="p-4">
                    <div className="flex items-center gap-2 text-sm text-gray-500 mb-2">
                      <Search size={16} />
                      <span>Sản phẩm phù hợp</span>
                    </div>
                    <div className="space-y-2">
                      {quickSearchResults.products.map((product) => (
                        <button
                          key={product.id}
                          onClick={() => {
                            navigate(`/product/${product.id}`);
                            setIsDropdownOpen(false);
                          }}
                          className="flex items-center gap-3 w-full p-2 
                            rounded-lg hover:bg-gray-50 group"
                        >
                          <img
                            src={product.images[0]}
                            alt={product.name}
                            className="w-12 h-12 object-cover rounded-lg"
                          />
                          <div className="flex-1 text-left min-w-0">
                            <div className="text-sm font-medium text-gray-900 truncate">
                              {highlightText(product.name, searchTerm)}
                            </div>
                            <div className="text-sm text-primary-600 font-medium">
                              {new Intl.NumberFormat('vi-VN', {
                                style: 'currency',
                                currency: 'VND',
                              }).format(product.price)}
                            </div>
                          </div>
                          <ArrowRight
                            size={16}
                            className="text-gray-400 group-hover:text-primary-600
                              transform group-hover:translate-x-1 transition-all"
                          />
                        </button>
                      ))}
                    </div>

                    {/* View All Results */}
                    <button
                      onClick={() => {
                        navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
                        setIsDropdownOpen(false);
                      }}
                      className="flex items-center justify-center gap-2 w-full mt-4 
                        py-2 text-sm text-primary-600 hover:text-primary-700 
                        font-medium"
                    >
                      <span>Xem tất cả kết quả</span>
                      <ArrowRight size={16} />
                    </button>
                  </div>
                )}

                {/* No Results */}
                {quickSearchResults.products.length === 0 &&
                  quickSearchResults.categories.length === 0 && (
                    <div className="p-8 text-center">
                      <Search className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                      <p className="text-gray-500">
                        Không tìm thấy kết quả phù hợp với "{searchTerm}"
                      </p>
                    </div>
                  )}
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;
