// src/client/components/home/NewArrivals.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Star } from 'lucide-react';
import ProductGrid from '../common/product/ProductGrid';

const NewArrivals = () => {
  const products = useSelector((state) => state.products.items);

  // Get newest products based on createdAt
  const newProducts = products
    .filter((p) => p.isActive)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .slice(0, 4);

  if (newProducts.length === 0) return null;

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center mb-8">
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Star className="w-6 h-6 text-primary-600" />
            <h2 className="text-2xl font-bold text-gray-900">Sản phẩm mới</h2>
          </div>
          <p className="text-gray-600">Khám phá những sản phẩm mới nhất của chúng tôi</p>
        </div>
      </div>
      <ProductGrid products={newProducts} />
    </div>
  );
};

export default NewArrivals;
