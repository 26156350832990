import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Shield, Users } from 'lucide-react';
import { fetchRoles } from '../../../store/roleSlice';
import RoleList from './RoleList';
import RoleForm from './RoleForm';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';
import toast from 'react-hot-toast';
import LoadingScreen from '../Layout/LoadingScreen';

const RoleManager = () => {
  const dispatch = useDispatch();
  const { roles, status, error } = useSelector((state) => state.roles);
  const [showForm, setShowForm] = useState(false);
  const [editingRole, setEditingRole] = useState(null);

  // Kiểm tra các quyền cụ thể cho module ROLES
  const {
    canView: canViewRole,
    canCreate: canCreateRole,
    canUpdate: canUpdateRole,
    canDelete: canDeleteRole,
    isSuperAdmin,
  } = usePermissions();

  const hasViewPermission = canViewRole(MODULES.ROLES);
  const hasCreatePermission = canCreateRole(MODULES.ROLES);
  const hasUpdatePermission = canUpdateRole(MODULES.ROLES);
  const hasDeletePermission = canDeleteRole(MODULES.ROLES);

  useEffect(() => {
    // Chỉ fetch roles khi có quyền xem
    if (status === 'idle' && hasViewPermission) {
      dispatch(fetchRoles());
    }
  }, [status, dispatch, hasViewPermission]);

  // Tính toán thống kê
  const roleStats = {
    total: roles.length,
    totalUsers: roles.reduce((sum, role) => sum + (role.userCount || 0), 0),
    superAdminCount: roles.filter((role) => role.name === 'Super Admin').length,
    avgPermissions: roles.length
      ? Math.round(
          roles.reduce((sum, role) => sum + (role.permissions?.length || 0), 0) / roles.length
        )
      : 0,
  };

  const handleAddRole = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền thêm vai trò mới');
      return;
    }
    setEditingRole(null);
    setShowForm(true);
  };

  const handleEditRole = (role) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền chỉnh sửa vai trò');
      return;
    }

    // Chỉ Super Admin mới có thể chỉnh sửa role Super Admin
    if (role.name === 'Super Admin' && !isSuperAdmin) {
      toast.error('Chỉ Super Admin mới có thể chỉnh sửa vai trò này');
      return;
    }

    setEditingRole(role);
    setShowForm(true);
  };

  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý vai trò</p>
        </div>
      </div>
    );
  }

  if (status === 'loading') {
    return <LoadingScreen />;
  }

  if (status === 'failed') {
    toast.error(error);
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-600">{error}</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Quản lý vai trò</h1>
          <p className="text-sm text-gray-500 mt-1">
            Quản lý vai trò và phân quyền cho người dùng trong hệ thống
          </p>
        </div>
        {hasCreatePermission && (
          <button onClick={handleAddRole} className="btn btn-primary flex items-center gap-2">
            <Plus size={20} />
            Thêm vai trò
          </button>
        )}
      </div>

      {/* Stats */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Tổng vai trò</div>
          <div className="mt-2 flex items-center gap-2">
            <Shield className="w-5 h-5 text-blue-500" />
            <span className="text-2xl font-semibold text-gray-900">{roleStats.total}</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Tổng người dùng</div>
          <div className="mt-2 flex items-center gap-2">
            <Users className="w-5 h-5 text-green-500" />
            <span className="text-2xl font-semibold text-green-600">{roleStats.totalUsers}</span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Trung bình quyền/vai trò</div>
          <div className="mt-2 flex items-center gap-2">
            <Shield className="w-5 h-5 text-purple-500" />
            <span className="text-2xl font-semibold text-purple-600">
              {roleStats.avgPermissions}
            </span>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Super Admins</div>
          <div className="mt-2 flex items-center gap-2">
            <Shield className="w-5 h-5 text-red-500" />
            <span className="text-2xl font-semibold text-red-600">{roleStats.superAdminCount}</span>
          </div>
        </div>
      </div>

      {/* Role List */}
      <div className="bg-white rounded-lg shadow">
        <RoleList
          roles={roles}
          onEdit={handleEditRole}
          hasUpdatePermission={hasUpdatePermission}
          hasDeletePermission={hasDeletePermission}
          isSuperAdmin={isSuperAdmin}
        />
      </div>

      {/* Role Form Modal */}
      {showForm && (
        <RoleForm
          role={editingRole}
          onClose={() => {
            setShowForm(false);
            setEditingRole(null);
          }}
        />
      )}
    </div>
  );
};

export default RoleManager;
