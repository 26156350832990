// src/client/components/home/FeaturedProducts.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ArrowRight, ArrowLeft } from 'lucide-react';
import ProductGrid from '../common/product/ProductGrid';

const FeaturedProducts = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const products = useSelector((state) => state.products.items);
  const productsPerPage = 10;

  // Filter featured and active products
  const featuredProducts = products
    .filter((product) => product.isFeatured && product.isActive)
    .sort((a, b) => b.sold - a.sold); // Sort by best selling

  const totalPages = Math.ceil(featuredProducts.length / productsPerPage);

  const visibleProducts = featuredProducts.slice(
    currentPage * productsPerPage,
    (currentPage + 1) * productsPerPage
  );

  const handleNext = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const handlePrev = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  if (featuredProducts.length === 0) return null;

  return (
    <div className="container mx-auto px-4">
      {/* Section Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">Sản phẩm nổi bật</h2>
          <p className="text-gray-600 mt-1">Các sản phẩm được yêu thích nhất</p>
        </div>
        <Link
          to="/hot-products"
          className="text-primary-600 hover:text-primary-700 font-medium hidden md:block"
        >
          Xem tất cả
        </Link>
        {totalPages > 1 && (
          <div className="flex space-x-2">
            <button
              onClick={handlePrev}
              className="p-2 rounded-lg border border-gray-300 hover:bg-gray-100 
                transition-colors disabled:opacity-50"
              disabled={currentPage === 0}
            >
              <ArrowLeft size={20} />
            </button>
            <button
              onClick={handleNext}
              className="p-2 rounded-lg border border-gray-300 hover:bg-gray-100 
                transition-colors disabled:opacity-50"
              disabled={currentPage === totalPages - 1}
            >
              <ArrowRight size={20} />
            </button>
          </div>
        )}
      </div>

      {/* Products Grid */}
      <ProductGrid products={visibleProducts} gap="gap-4 md:gap-6" />
    </div>
  );
};

export default FeaturedProducts;
