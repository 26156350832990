// src/store/globalUISlice.js
import { createSlice } from '@reduxjs/toolkit';
import { checkAuthState } from './authSlice';
import { fetchRoles } from './roleSlice';

const initialState = {
  isInitializing: true, // Start with true
  isLoading: false,
};

const globalUISlice = createSlice({
  name: 'globalUI',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Auth state
      .addCase(checkAuthState.pending, (state) => {
        state.isInitializing = true;
      })
      .addCase(checkAuthState.fulfilled, (state) => {
        state.isInitializing = false;
      })
      .addCase(checkAuthState.rejected, (state) => {
        state.isInitializing = false;
      })
      // Roles
      .addCase(fetchRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRoles.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchRoles.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setLoading } = globalUISlice.actions;
export default globalUISlice.reducer;
