import React from 'react';

const Dashboard = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 text-sm font-medium">Tổng đơn hàng</h3>
          <p className="text-3xl font-bold mt-2">123</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 text-sm font-medium">Doanh thu</h3>
          <p className="text-3xl font-bold mt-2">45.6M</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 text-sm font-medium">Sản phẩm</h3>
          <p className="text-3xl font-bold mt-2">89</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-gray-500 text-sm font-medium">Khách hàng</h3>
          <p className="text-3xl font-bold mt-2">234</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
