import React from 'react';
import { X } from 'lucide-react';

const CurrencyInput = ({ value, onChange, className = '', placeholder = '' }) => {
  const formatCurrency = (value) => {
    const number = value.replace(/\D/g, '');
    return new Intl.NumberFormat('vi-VN').format(number);
  };

  const parseCurrency = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleChange = (e) => {
    const formatted = formatCurrency(e.target.value);
    const parsed = parseCurrency(e.target.value);
    e.target.value = formatted;
    onChange(Number(parsed));
  };

  return (
    <input
      type="text"
      value={value ? new Intl.NumberFormat('vi-VN').format(value) : ''}
      onChange={handleChange}
      className={`input ${className}`}
      placeholder={placeholder}
    />
  );
};

const SelectedProducts = ({ products, onChange }) => {
  const handleRemove = (productId) => {
    onChange(products.filter((p) => p.id !== productId));
  };

  const handlePriceChange = (productId, price) => {
    onChange(products.map((p) => (p.id === productId ? { ...p, discountPrice: price } : p)));
  };

  const handleQuantityChange = (productId, quantity) => {
    onChange(
      products.map((p) => (p.id === productId ? { ...p, maxQuantity: Number(quantity) } : p))
    );
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  return (
    <div className="mt-4">
      {products.map((product) => (
        <div key={product.id} className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg mb-2">
          <div className="flex-1">
            <div className="font-medium">{product.name}</div>
            <div className="text-sm text-gray-500">
              Giá gốc: {formatPrice(product.originalPrice)}
            </div>
          </div>

          <div className="w-48">
            <label className="block text-sm font-medium text-gray-700 mb-1">Giá khuyến mãi</label>
            <CurrencyInput
              value={product.discountPrice}
              onChange={(value) => handlePriceChange(product.id, value)}
              placeholder="Nhập giá khuyến mãi"
            />
          </div>

          <div className="w-48">
            <label className="block text-sm font-medium text-gray-700 mb-1">Số lượng tối đa</label>
            <input
              type="number"
              value={product.maxQuantity || ''}
              onChange={(e) => handleQuantityChange(product.id, e.target.value)}
              className="input"
              placeholder="Không giới hạn"
              min="0"
            />
          </div>

          <button
            onClick={() => handleRemove(product.id)}
            className="p-2 text-red-600 hover:bg-red-50 rounded-full"
            title="Xóa sản phẩm"
          >
            <X size={20} />
          </button>
        </div>
      ))}

      {products.length === 0 && (
        <div className="text-center py-4 text-gray-500">Chưa có sản phẩm nào được chọn</div>
      )}
    </div>
  );
};

export default SelectedProducts;
