export const CATEGORIZED_ICONS = {
  // Brands/Manufacturers
  'Thương hiệu': {
    Monitor: { icon: 'Monitor', label: 'Màn hình/Display' },
    Laptop: { icon: 'Laptop', label: 'Laptop' },
    Smartphone: { icon: 'Smartphone', label: 'Smartphone' },
    TabletSmartphone: { icon: 'TabletSmartphone', label: 'Tablet' },
    Pc: { icon: 'Pc', label: 'PC/Desktop' },
    Server: { icon: 'Server', label: 'Server' },
    Printer: { icon: 'Printer', label: 'Printer' },
  },

  // Computer Components
  'Linh kiện máy tính': {
    Cpu: { icon: 'Cpu', label: 'CPU/Processor' },
    CircuitBoard: { icon: 'CircuitBoard', label: 'Mainboard' },
    HardDrive: { icon: 'HardDrive', label: 'Storage/HDD/SSD' },
    Memory: { icon: 'Memory', label: 'RAM/Memory' },
    Fan: { icon: 'Fan', label: 'Cooling/Fan' },
    Power: { icon: 'Power', label: 'PSU/Power Supply' },
    PcCase: { icon: 'PcCase', label: 'Case/Chassis' },
    Gpu: { icon: 'Gpu', label: 'GPU/Graphics Card' },
  },

  // Peripherals & Accessories
  'Thiết bị ngoại vi': {
    Mouse: { icon: 'Mouse', label: 'Mouse' },
    Keyboard: { icon: 'Keyboard', label: 'Keyboard' },
    Headphones: { icon: 'Headphones', label: 'Headphones/Audio' },
    Speaker: { icon: 'Speaker', label: 'Speaker' },
    Camera: { icon: 'Camera', label: 'Camera/Webcam' },
    Microphone: { icon: 'Microphone', label: 'Microphone' },
    Joystick: { icon: 'Joystick', label: 'Gaming Controller' },
    Gamepad2: { icon: 'Gamepad2', label: 'Gaming Gear' },
  },

  // Storage & Memory
  'Lưu trữ & Bộ nhớ': {
    Database: { icon: 'Database', label: 'Storage' },
    HardDrive: { icon: 'HardDrive', label: 'Hard Drive' },
    Disc: { icon: 'Disc', label: 'Optical Drive' },
    Usb: { icon: 'Usb', label: 'USB Storage' },
    SdCard: { icon: 'SdCard', label: 'Memory Card' },
  },

  // Networking
  'Mạng & Kết nối': {
    Wifi: { icon: 'Wifi', label: 'WiFi' },
    Router: { icon: 'Router', label: 'Router/Modem' },
    Network: { icon: 'Network', label: 'Network' },
    Bluetooth: { icon: 'Bluetooth', label: 'Bluetooth' },
    Cable: { icon: 'Cable', label: 'Cable/Connector' },
  },

  // Power & Battery
  'Nguồn & Pin': {
    Battery: { icon: 'Battery', label: 'Battery' },
    BatteryCharging: { icon: 'BatteryCharging', label: 'Charger' },
    Power: { icon: 'Power', label: 'Power Supply' },
  },

  // Others
  Khác: {
    Chip: { icon: 'Chip', label: 'Chip/IC' },
    Component: { icon: 'Component', label: 'Component' },
    Package: { icon: 'Package', label: 'Product' },
    ShoppingCart: { icon: 'ShoppingCart', label: 'Shopping' },
    Settings: { icon: 'Settings', label: 'Tools/Settings' },
  },
};

export const BRAND_ICONS = {
  'Laptop & PC': {
    ASUS: {
      icon: '/icons/asus.ico',
      label: 'ASUS',
      url: 'https://www.asus.com',
    },
    Dell: {
      icon: '/icons/dell.ico',
      label: 'Dell',
      url: 'https://www.dell.com',
    },
    HP: {
      icon: '/icons/hp.ico',
      label: 'HP',
      url: 'https://www.hp.com',
    },
    Lenovo: {
      icon: '/icons/lenovo.ico',
      label: 'Lenovo',
      url: 'https://www.lenovo.com',
    },
    Apple: {
      icon: '/icons/apple.ico',
      label: 'Apple',
      url: 'https://www.apple.com',
    },
    MSI: {
      icon: '/icons/msi.png',
      label: 'MSI',
      url: 'https://www.msi.com',
    },
    Acer: {
      icon: '/icons/acer.ico',
      label: 'Acer',
      url: 'https://www.acer.com',
    },
  },
  'Linh kiện & Phụ kiện': {
    Intel: {
      icon: '/icons/intel.ico',
      label: 'Intel',
      url: 'https://www.intel.com',
    },
    AMD: {
      icon: '/icons/amd.ico',
      label: 'AMD',
      url: 'https://www.amd.com',
    },
    NVIDIA: {
      icon: '/icons/nvidia.ico',
      label: 'NVIDIA',
      url: 'https://www.nvidia.com',
    },
    Gigabyte: {
      icon: '/icons/gigabyte.png',
      label: 'Gigabyte',
      url: 'https://www.gigabyte.com',
    },
    ASRock: {
      icon: '/icons/asrock.png',
      label: 'ASRock',
      url: 'https://www.asrock.com',
    },
    Corsair: {
      icon: '/icons/corsair.ico',
      label: 'Corsair',
      url: 'https://www.corsair.com',
    },
    Kingston: {
      icon: '/icons/kingston.png',
      label: 'Kingston',
      url: 'https://www.kingston.com',
    },
  },
  'Màn hình': {
    LG: {
      icon: '/icons/lg.png',
      label: 'LG',
      url: 'https://www.lg.com',
    },
    Samsung: {
      icon: '/icons/samsung.ico',
      label: 'Samsung',
      url: 'https://www.samsung.com',
    },
    ViewSonic: {
      icon: '/icons/viewsonic.png',
      label: 'ViewSonic',
      url: 'https://www.viewsonic.com',
    },
    BenQ: {
      icon: '/icons/benq.png',
      label: 'BenQ',
      url: 'https://www.benq.com',
    },
  },
  'Phụ kiện Gaming': {
    Logitech: {
      icon: '/icons/logitech.ico',
      label: 'Logitech',
      url: 'https://www.logitech.com',
    },
    Razer: {
      icon: '/icons/razer.png',
      label: 'Razer',
      url: 'https://www.razer.com',
    },
    SteelSeries: {
      icon: '/icons/steelseries.ico',
      label: 'SteelSeries',
      url: 'https://www.steelseries.com',
    },
  },
};
