import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit2, Trash2 } from 'lucide-react';
import { deleteUser } from '../../../store/userSlice';
import { USER_STATUS } from '../../../models/userModels';
import { usePermissions } from '../../../hooks/usePermissions';
import toast from 'react-hot-toast';

const UserStatusBadge = ({ status }) => {
  const colors = {
    [USER_STATUS.ACTIVE]: 'bg-green-100 text-green-800',
    [USER_STATUS.INACTIVE]: 'bg-gray-100 text-gray-800',
    [USER_STATUS.BANNED]: 'bg-red-100 text-red-800',
  };

  return (
    <span
      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${colors[status]}`}
    >
      {status}
    </span>
  );
};

const UserList = ({ users, onEdit }) => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.roles.roles);
  const currentUser = useSelector((state) => state.auth.user);
  const { isSuperAdmin, canUpdate, canDelete } = usePermissions();

  const getRoleName = (roleId) => {
    const role = roles.find((r) => r.id === roleId);
    return role?.name || 'N/A';
  };

  // Kiểm tra xem user có phải là Super Admin không
  const isUserSuperAdmin = (user) => {
    const userRole = roles.find((r) => r.id === user.roleId);
    return userRole?.name === 'Super Admin';
  };

  // Kiểm tra quyền thao tác trên user
  const canManageUser = (user) => {
    // Không cho phép thao tác với chính mình
    if (user.id === currentUser.id) return false;

    // Chỉ Super Admin mới có thể thao tác với tài khoản Super Admin khác
    if (isUserSuperAdmin(user) && !isSuperAdmin) return false;

    return true;
  };

  const handleEdit = (user) => {
    if (!canUpdate || !canManageUser(user)) {
      toast.error('Bạn không có quyền chỉnh sửa người dùng này');
      return;
    }
    onEdit(user);
  };

  const handleDelete = async (user) => {
    if (!canDelete || !canManageUser(user)) {
      toast.error('Bạn không có quyền xóa người dùng này');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa người dùng này?')) {
      try {
        await dispatch(deleteUser(user.id)).unwrap();
        toast.success('Xóa người dùng thành công');
      } catch (error) {
        toast.error('Xóa người dùng thất bại: ' + error.message);
      }
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Thông tin
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Vai trò
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Trạng thái
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Đăng nhập lần cuối
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user.id} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="flex items-center">
                  {user.avatar ? (
                    <img
                      src={user.avatar}
                      alt={user.fullName}
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <span className="text-xl font-medium text-gray-500">
                        {user.fullName?.[0]?.toUpperCase()}
                      </span>
                    </div>
                  )}
                  <div className="ml-4">
                    <div className="font-medium text-gray-900">{user.fullName}</div>
                    <div className="text-sm text-gray-500">{user.username}</div>
                    {user.id === currentUser.id && (
                      <span className="text-xs text-primary-600">(Bạn)</span>
                    )}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    isUserSuperAdmin(user)
                      ? 'bg-purple-100 text-purple-800'
                      : 'bg-blue-100 text-blue-800'
                  }`}
                >
                  {getRoleName(user.roleId)}
                </span>
              </td>
              <td className="px-6 py-4">
                <UserStatusBadge status={user.status} />
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">
                {user.lastLoginAt ? new Date(user.lastLoginAt).toLocaleString() : 'Chưa đăng nhập'}
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center justify-center space-x-3">
                  <button
                    onClick={() => handleEdit(user)}
                    className={`text-blue-600 hover:text-blue-900 ${
                      !canManageUser(user) ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    title={canManageUser(user) ? 'Sửa' : 'Không có quyền sửa'}
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={() => handleDelete(user)}
                    className={`text-red-600 hover:text-red-900 ${
                      !canManageUser(user) ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    title={canManageUser(user) ? 'Xóa' : 'Không có quyền xóa'}
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </td>
            </tr>
          ))}

          {users.length === 0 && (
            <tr>
              <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                Không có người dùng nào
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
