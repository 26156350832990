// src/admin/components/FlashSaleManager/ProductSelector/index.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../../../store/productSlice';
import Select from 'react-select';

const ProductSelector = ({ selectedProducts, onChange }) => {
  const dispatch = useDispatch();
  const { items: allProducts, status } = useSelector((state) => state.products);
  const flashSaleProducts = useSelector((state) => state.flashSaleProducts.items);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchProducts());
    }
  }, [status, dispatch]);

  // Filter out products already in flash sale or selected
  const availableProducts = allProducts.filter(
    (product) =>
      !flashSaleProducts.find((fp) => fp.productId === product.id) &&
      !selectedProducts.find((sp) => sp.id === product.id) &&
      product.isActive &&
      product.stock > 0
  );

  const options = availableProducts.map((product) => ({
    value: product.id,
    label: (
      <div className="flex justify-between items-center gap-4">
        <div>
          <div className="font-medium">{product.name}</div>
          {product.sku && <div className="text-sm text-gray-500">SKU: {product.sku}</div>}
        </div>
        <div className="text-right">
          <div className="font-medium">{formatPrice(product.price)}</div>
          <div className="text-sm text-gray-500">Kho: {product.stock}</div>
        </div>
      </div>
    ),
    product: product,
  }));

  const handleSelect = (selected) => {
    if (selected) {
      // Default discount 10%
      const defaultDiscount = 0.1;
      const salePrice = Math.floor(selected.product.price * (1 - defaultDiscount));

      onChange([
        ...selectedProducts,
        {
          id: selected.product.id,
          name: selected.product.name,
          originalPrice: selected.product.price,
          salePrice: salePrice,
          stock: selected.product.stock,
          stockLimit: null,
          limitPerCustomer: null,
          stockLeft: selected.product.stock,
          soldCount: 0,
          status: 'available',
        },
      ]);
    }
  };

  if (status === 'loading') {
    return <div>Đang tải danh sách sản phẩm...</div>;
  }

  if (status === 'failed') {
    return <div className="text-red-500">Không thể tải danh sách sản phẩm</div>;
  }

  return (
    <div className="space-y-2">
      <Select
        options={options}
        onChange={handleSelect}
        placeholder="Chọn sản phẩm để thêm vào Flash Sale..."
        noOptionsMessage={() => 'Không còn sản phẩm phù hợp để thêm'}
        formatOptionLabel={({ label }) => label}
        isSearchable
        isClearable
        className="basic-single"
        classNamePrefix="select"
      />
      <div className="text-sm text-gray-500">
        * Chỉ hiển thị sản phẩm đang bán và còn hàng trong kho
      </div>
    </div>
  );
};

export default ProductSelector;
