// Model
// Base Model với các trường common
export const BaseModel = {
  id: '',
  isActive: true,
  createdAt: null, // Timestamp
  updatedAt: null, // Timestamp
};

// Category Model
export const CategoryModel = {
  ...BaseModel,
  name: '', // string - required
  parent_id: null, // string | null
  image: '', // string (URL)
  description: '', // string
  display_order: 0, // number
  isActive: true,
  isFeatured: false, // Thêm trường này
  icon: '',
  iconType: '', // Loại icon: 'lucide' | 'custom' | 'url'
};
// Product Model
export const ProductModel = {
  ...BaseModel,
  name: '',
  sku: '', // Mã sản phẩm
  description: '',
  price: 0,
  images: [],
  categoryIds: [],
  tags: [], // Tags để tìm kiếm
  isHot: false,
  isFeatured: false,
  warranty: {
    months: 0, // Thời gian bảo hành
    description: '', // Chi tiết bảo hành
  },
  relatedProducts: [], // ID các sản phẩm liên quan
  stock: 0,
  sold: 0,
  rating: 0,
  reviews_count: 0,
};

// ProductSpec Model
export const ProductSpecModel = {
  ...BaseModel,
  productId: '', // string - required
  specType: '', // 'laptop' | 'pc' | 'monitor' | 'storage' | 'component'
  specifications: {}, // object - dynamic based on specType
};

// Promotion Model
export const PromotionModel = {
  ...BaseModel,
  name: '', // Tên chương trình
  code: '', // Mã giảm giá
  description: '', // Mô tả
  images: {
    banner: '', // Banner chính (1920x600)
    thumbnail: '', // Thumbnail nhỏ (400x400)
    mobile: '', // Banner mobile (600x800)
    popup: '', // Popup quảng cáo (800x600)
    social: '', // Ảnh chia sẻ MXH (1200x630)
  },
  type: '', // 'percentage' | 'fixed'
  value: 0, // Giá trị giảm
  min_order_value: 0, // Giá trị đơn tối thiểu
  usage_limit: null, // Giới hạn sử dụng
  used_count: 0, // Số lần đã dùng
  priority: 0, // Độ ưu tiên hiển thị
  startDate: null,
  endDate: null,
  // Thêm meta info cho SEO
  meta: {
    title: '', // Tiêu đề SEO
    description: '', // Mô tả SEO
    keywords: '', // Từ khóa SEO
  },
};

// Promotion Product Model
export const PromotionProductModel = {
  ...BaseModel,
  promotionId: '', // ID khuyến mãi
  productId: '', // ID sản phẩm
  discountPrice: 0, // Giá sau khuyến mãi
  originalPrice: 0, // Giá gốc
  maxQuantity: null, // Số lượng tối đa được mua
};

// Order Model Update
export const OrderModel = {
  ...BaseModel,
  orderNumber: '', // Mã đơn hàng tự động
  userId: '',
  // Định nghĩa rõ ràng các trạng thái có thể có
  status: '', // pending | processing | shipping | delivered | cancelled | returned
  status_history: [
    // Mảng lưu lịch sử thay đổi trạng thái
    {
      status: '',
      timestamp: null,
      note: '',
    },
  ],
  items: [], // Chi tiết đơn hàng
  subtotal: 0,
  discount: 0,
  shipping_fee: 0,
  total: 0,

  shipping_address: {
    fullName: '',
    phone: '',
    email: '',
    address: '',
    ward: '',
    district: '',
    city: '',
  },

  shipping_info: {
    provider: '', // Đơn vị vận chuyển
    tracking_number: '', // Mã vận đơn
    estimated_delivery: null, // Ngày dự kiến giao
    actual_delivery: null, // Ngày thực tế giao
  },

  payment_method: '', // COD | BANK | MOMO | ...
  payment_status: '', // pending | paid | refunded | pending_refund
  payment_history: [
    // Mảng lưu lịch sử thanh toán
    {
      status: '',
      timestamp: null,
      note: '',
    },
  ],
  customer_history: [
    {
      timestamp: null,
      note: '',
      old_data: {
        fullName: '',
        phone: '',
        email: '',
        address: '',
        ward: '',
        district: '',
        city: '',
      },
      new_data: {
        fullName: '',
        phone: '',
        email: '',
        address: '',
        ward: '',
        district: '',
        city: '',
      },
    },
  ],
  notes: '', // Ghi chú đơn hàng
  internal_notes: '', // Ghi chú nội bộ
  orderDate: null,
};

// OrderItem Model
export const OrderItemModel = {
  ...BaseModel,
  orderId: '', // string - required
  productId: '', // string - required
  quantity: 0, // number - required
  price: 0, // number - required
  total: 0, // number - required
};
