import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import AdminLayout from './admin/components/Layout/AdminLayout';
import LoginPage from './admin/pages/LoginPage';
import { checkAuthState } from './store/authSlice';

// Admin Pages
import Dashboard from './admin/pages/Dashboard';
import CategoryManager from './admin/components/CategoryManager';
import ProductManager from './admin/components/ProductManager';
import ProductDetail from './admin/components/ProductManager/ProductDetail';
import SpecTemplateManager from './admin/components/SpecTemplateManager';
import PromotionManager from './admin/components/PromotionManager';
import PromotionProductManager from './admin/components/PromotionManager/PromotionProductManager';
import FlashSaleManager from './admin/components/FlashSaleManager';
import FlashSaleProductManager from './admin/components/FlashSaleManager/FlashSaleProductManager';
import FlashSaleStats from './admin/components/FlashSaleManager/FlashSaleStats';
import OrderManager from './admin/components/OrderManager';
import UserManager from './admin/components/UserManager';
import RoleManager from './admin/components/RoleManager';
import CustomerManager from './admin/components/CustomerManager';
import UnauthorizedPage from './admin/pages/UnauthorizedPage';

// Import permission configs
import { MODULES, ACTIONS } from './models/userModels';
import { PrivateRoute } from './configs/auth';
import { useSelector } from 'react-redux';
import LoadingScreen from './admin/components/Layout/LoadingScreen';
import { fetchRoles } from './store/roleSlice';
import { AuthGuard } from './admin/AuthGuard';
import ClientRoutes from './client/routes.jsx';
import CartDrawer from './client/components/cart/CartDrawer.jsx';
import HeroManager from './admin/components/HeroManager/index.jsx';

function App() {
  const dispatch = useDispatch();
  const { isInitializing } = useSelector((state) => state.globalUI);
  const { status: authStatus } = useSelector((state) => state.auth);
  const { status: rolesStatus } = useSelector((state) => state.roles);

  useEffect(() => {
    // Ẩn initial loader
    const initialLoader = document.getElementById('initial-loader');
    if (initialLoader) {
      initialLoader.classList.add('hidden');
    }

    // Check auth và load roles
    dispatch(checkAuthState());
    dispatch(fetchRoles());
  }, [dispatch]);

  // Hiển thị loading khi đang khởi tạo hoặc đang load data quan trọng
  if (isInitializing || authStatus === 'loading' || rolesStatus === 'loading') {
    return <LoadingScreen />;
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<ClientRoutes />} />
          {/* Public Route - Login */}
          <Route path="/admin/login" element={<LoginPage />} />
          {/* Protected Admin Routes */}
          <Route
            path="/admin"
            element={
              <AuthGuard>
                <AdminLayout />
              </AuthGuard>
            }
          >
            {/* Dashboard */}
            <Route
              index
              element={
                <PrivateRoute module={MODULES.DASHBOARD} action={ACTIONS.VIEW}>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="hero"
              element={
                <PrivateRoute module={MODULES.HERO} action={ACTIONS.VIEW}>
                  <HeroManager />
                </PrivateRoute>
              }
            />

            {/* Categories */}
            <Route
              path="categories"
              element={
                <PrivateRoute module={MODULES.CATEGORIES} action={ACTIONS.VIEW}>
                  <CategoryManager />
                </PrivateRoute>
              }
            />

            {/* Products */}
            <Route
              path="products"
              element={
                <PrivateRoute module={MODULES.PRODUCTS} action={ACTIONS.VIEW}>
                  <ProductManager />
                </PrivateRoute>
              }
            />

            <Route
              path="products/:id"
              element={
                <PrivateRoute module={MODULES.PRODUCTS} action={ACTIONS.VIEW}>
                  <ProductDetail />
                </PrivateRoute>
              }
            />

            {/* Specifications */}
            <Route
              path="specs"
              element={
                <PrivateRoute module={MODULES.SPECS} action={ACTIONS.VIEW}>
                  <SpecTemplateManager />
                </PrivateRoute>
              }
            />

            {/* Promotions */}
            <Route
              path="promotions"
              element={
                <PrivateRoute module={MODULES.PROMOTIONS} action={ACTIONS.VIEW}>
                  <PromotionManager />
                </PrivateRoute>
              }
            />

            <Route
              path="promotions/:id/products"
              element={
                <PrivateRoute module={MODULES.PROMOTIONS} action={ACTIONS.VIEW}>
                  <PromotionProductManager />
                </PrivateRoute>
              }
            />

            {/* Flash Sales */}
            <Route
              path="flash-sales"
              element={
                <PrivateRoute module={MODULES.FLASH_SALES} action={ACTIONS.VIEW}>
                  <FlashSaleManager />
                </PrivateRoute>
              }
            />

            <Route
              path="flash-sales/:id/products"
              element={
                <PrivateRoute module={MODULES.FLASH_SALES} action={ACTIONS.VIEW}>
                  <FlashSaleProductManager />
                </PrivateRoute>
              }
            />

            <Route
              path="flash-sales/:id/stats"
              element={
                <PrivateRoute module={MODULES.FLASH_SALES} action={ACTIONS.VIEW}>
                  <FlashSaleStats />
                </PrivateRoute>
              }
            />

            {/* Orders */}
            <Route
              path="orders"
              element={
                <PrivateRoute module={MODULES.ORDERS} action={ACTIONS.VIEW}>
                  <OrderManager />
                </PrivateRoute>
              }
            />

            {/* Customers */}
            <Route
              path="customers"
              element={
                <PrivateRoute module={MODULES.CUSTOMERS} action={ACTIONS.VIEW}>
                  <CustomerManager />
                </PrivateRoute>
              }
            />

            {/* System Management */}
            <Route
              path="users"
              element={
                <PrivateRoute module={MODULES.USERS} action={ACTIONS.VIEW}>
                  <UserManager />
                </PrivateRoute>
              }
            />

            <Route
              path="roles"
              element={
                <PrivateRoute module={MODULES.ROLES} action={ACTIONS.VIEW}>
                  <RoleManager />
                </PrivateRoute>
              }
            />

            {/* Error Pages */}
            <Route path="unauthorized" element={<UnauthorizedPage />} />
          </Route>

          {/* Redirect root to admin */}
          {/* <Route path="/" element={<Navigate to="/admin" replace />} /> */}

          {/* Catch all - redirect to admin */}
          {/* <Route path="*" element={<Navigate to="/admin" replace />} /> */}
        </Routes>
        <CartDrawer />
      </BrowserRouter>
      {/* Toast Notifications */}
      <Toaster
        position="top-right"
        toastOptions={{
          duration: 4000,
          style: {
            padding: '16px',
            borderRadius: '8px',
            fontWeight: '500',
          },
          success: {
            style: {
              background: '#059669',
              color: '#fff',
            },
          },
          error: {
            style: {
              background: '#DC2626',
              color: '#fff',
            },
            duration: 5000,
          },
        }}
      />
    </>
  );
}

export default App;
