import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  serverTimestamp,
  getDoc,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import imageCompression from 'browser-image-compression'; // Thêm thư viện nén ảnh

// Convert timestamps và data
const convertProductData = (data) => {
  const converted = { ...data };
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  // Đảm bảo các trường mới luôn có giá trị mặc định
  converted.sku = converted.sku || '';
  converted.tags = converted.tags || [];
  converted.warranty = converted.warranty || { months: 0, description: '' };
  converted.relatedProducts = converted.relatedProducts || [];
  return converted;
};

// Nén ảnh trước khi upload
const compressImage = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    return await imageCompression(file, options);
  } catch (error) {
    console.error('Error compressing image:', error);
    return file;
  }
};

// Upload ảnh với retry logic
const uploadToCloudinary = async (file, maxRetries = 3) => {
  for (let i = 0; i < maxRetries; i++) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'mapple_store_preset');
      formData.append('cloud_name', 'dc59rpref');

      const response = await fetch(`https://api.cloudinary.com/v1_1/dc59rpref/image/upload`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Upload failed');
      return data.secure_url;
    } catch (error) {
      console.error(`Upload attempt ${i + 1} failed:`, error);
      if (i === maxRetries - 1) throw error;
      // Exponential backoff
      await new Promise((resolve) => setTimeout(resolve, 1000 * Math.pow(2, i)));
    }
  }
};

// Upload nhiều ảnh song song
const uploadImages = async (files) => {
  if (!files?.length) return [];

  // Nén tất cả ảnh song song
  const compressionPromises = files.map((file) => compressImage(file));
  const compressedFiles = await Promise.all(compressionPromises);

  // Upload tất cả ảnh đã nén song song
  const uploadPromises = compressedFiles.map((file) => uploadToCloudinary(file));
  return Promise.all(uploadPromises);
};

const initialState = {
  items: [],
  status: 'idle',
  error: null,
};

// Các thunks giữ nguyên cấu trúc nhưng được tối ưu
export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  try {
    const productsSnapshot = await getDocs(collection(db, 'products'));

    // Fetch specs song song cho tất cả sản phẩm
    const fetchPromises = productsSnapshot.docs.map(async (productDoc) => {
      const productData = productDoc.data();
      const productId = productDoc.id;

      const specsQuery = query(
        collection(db, 'product_specs'),
        where('productId', '==', productId)
      );
      const specsSnapshot = await getDocs(specsQuery);
      const specs = specsSnapshot.docs[0]?.data() || null;

      return convertProductData({
        id: productId,
        ...productData,
        specifications: specs ? specs.specs : null,
        specTemplateId: specs ? specs.templateId : null,
      });
    });

    const results = await Promise.all(fetchPromises);
    return results;
  } catch (error) {
    throw new Error('Failed to fetch products: ' + error.message);
  }
});

export const addProduct = createAsyncThunk(
  'products/addProduct',
  async ({ data, images }, { rejectWithValue }) => {
    try {
      const timestamp = serverTimestamp();
      const { specifications, specTemplateId, ...productData } = data;

      // Xử lý song song việc upload ảnh và tạo document sản phẩm
      const [imageUrls, productRef] = await Promise.all([
        uploadImages(images),
        addDoc(collection(db, 'products'), {
          ...productData,
          sku: productData.sku || `PRD${Date.now().toString().slice(-6)}`,
          images: [],
          categoryIds: Array.isArray(productData.categoryIds)
            ? productData.categoryIds
            : [productData.categoryIds].filter(Boolean),
          tags: productData.tags || [],
          warranty: productData.warranty || { months: 0, description: '' },
          relatedProducts: productData.relatedProducts || [],
          sold: 0,
          rating: 0,
          reviews_count: 0,
          createdAt: timestamp,
          updatedAt: timestamp,
        }),
      ]);

      // Cập nhật URLs ảnh và thêm specs nếu có
      const updateTasks = [updateDoc(productRef, { images: imageUrls })];

      if (specifications && specTemplateId) {
        updateTasks.push(
          addDoc(collection(db, 'product_specs'), {
            productId: productRef.id,
            templateId: specTemplateId,
            specs: specifications,
            createdAt: timestamp,
            updatedAt: timestamp,
          })
        );
      }

      await Promise.all(updateTasks);

      return {
        id: productRef.id,
        ...data,
        images: imageUrls,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async ({ id, data, images, existingImages }, { rejectWithValue }) => {
    try {
      const timestamp = serverTimestamp();
      const { specifications, specTemplateId, ...updateData } = data;

      // Tạo mảng chứa tất cả các promises để thực hiện song song
      const tasks = [];

      // 1. Upload ảnh mới (nếu có)
      if (images?.length) {
        tasks.push(uploadImages(images));
      }

      // 2. Chuẩn bị data cập nhật sản phẩm
      const productRef = doc(db, 'products', id);
      const productUpdate = {
        ...updateData,
        categoryIds: Array.isArray(updateData.categoryIds)
          ? updateData.categoryIds
          : [updateData.categoryIds].filter(Boolean),
        tags: Array.isArray(updateData.tags) ? updateData.tags : [],
        relatedProducts: Array.isArray(updateData.relatedProducts)
          ? updateData.relatedProducts
          : [],
        updatedAt: timestamp,
      };

      // 3. Cập nhật thông tin sản phẩm
      tasks.push(updateDoc(productRef, productUpdate));

      // 4. Xử lý specifications song song nếu có
      if (specifications && specTemplateId) {
        const specsQuery = query(collection(db, 'product_specs'), where('productId', '==', id));
        const specsSnapshot = await getDocs(specsQuery);

        if (!specsSnapshot.empty) {
          tasks.push(
            updateDoc(doc(db, 'product_specs', specsSnapshot.docs[0].id), {
              templateId: specTemplateId,
              specs: specifications,
              updatedAt: timestamp,
            })
          );
        } else {
          tasks.push(
            addDoc(collection(db, 'product_specs'), {
              productId: id,
              templateId: specTemplateId,
              specs: specifications,
              createdAt: timestamp,
              updatedAt: timestamp,
            })
          );
        }
      }

      // Thực hiện tất cả tasks song song
      const results = await Promise.all(tasks);
      const newImageUrls = results[0] || [];

      // Kết hợp ảnh cũ được giữ lại với ảnh mới
      const finalImages = [...(existingImages || []), ...newImageUrls];

      // Cập nhật danh sách ảnh cuối cùng
      if (finalImages.length > 0) {
        await updateDoc(productRef, {
          images: finalImages,
        });
      }

      // Lấy dữ liệu sản phẩm và specs đã cập nhật song song
      const [updatedDoc, specsData] = await Promise.all([
        getDoc(productRef),
        getDocs(query(collection(db, 'product_specs'), where('productId', '==', id))),
      ]);

      const specs = specsData.docs[0]?.data();

      // Chuyển đổi và trả về dữ liệu đã cập nhật
      return convertProductData({
        id,
        ...updatedDoc.data(),
        images: finalImages,
        specifications: specs ? specs.specs : null,
        specTemplateId: specs ? specs.templateId : null,
      });
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Delete product (đã tối ưu)
export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (id, { rejectWithValue }) => {
    try {
      const specsQuery = query(collection(db, 'product_specs'), where('productId', '==', id));
      const specsSnapshot = await getDocs(specsQuery);

      // Xóa song song product và specs
      await Promise.all([
        deleteDoc(doc(db, 'products', id)),
        ...specsSnapshot.docs.map((doc) => deleteDoc(doc.ref)),
      ]);

      return id;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Product slice (giữ nguyên)
const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(action.payload);
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = state.items.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetStatus } = productSlice.actions;
export default productSlice.reducer;
