import { configureStore } from '@reduxjs/toolkit';
import categoryReducer from './categorySlice';
import productReducer from './productSlice';
import uiReducer from './uiSlice';
import specTemplateReducer from './specTemplateSlice';
import promotionReducer from './promotionSlice';
import promotionProductReducer from './promotionProductSlice';
import flashSaleReducer from './flashSaleSlice';
import flashSaleProductReducer from './flashSaleProductSlice';
import orderReducer from './orderSlice';
import authReducer from './authSlice';
import roleReducer from './roleSlice';
import heroReducer from './heroSlice';
import globalUIReducer from './globalUISlice';
import userReducer from './userSlice';
import clientCategoriesReducer from '../client/features/categorySlice';
import cartReducer from '../client/features/cartSlice';
import authClinetReducer from '../client/features/authSlice';
import { authMiddleware } from '../client/middleware/authMiddleware';
import flashSaleDataReducer from '../client/features/flashSaleDataSlice';
import wishlistReducer from '../client/features/wishlistSlice';
import userOrdersReducer from '../client/features/orderSlice';
export const store = configureStore({
  reducer: {
    users: userReducer,
    auth: authReducer,
    roles: roleReducer,
    globalUI: globalUIReducer,
    categories: categoryReducer,
    products: productReducer,
    ui: uiReducer,
    specTemplates: specTemplateReducer,
    promotions: promotionReducer,
    promotionProducts: promotionProductReducer,
    flashSales: flashSaleReducer,
    flashSaleProducts: flashSaleProductReducer,
    orders: orderReducer,
    cart: cartReducer,
    authClient: authClinetReducer,
    hero: heroReducer,
    // Client reducers
    clientCategories: clientCategoriesReducer,
    flashSaleData: flashSaleDataReducer,
    wishlist: wishlistReducer,
    userOrders: userOrdersReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      // Tăng limit để xử lý nhiều actions cùng lúc tốt hơn
      immutableCheck: { warnAfter: 128 },
      // Tăng thời gian timeout cho các action phức tạp
      actionCreatorCheck: { warnAfter: 500 },
    }).prepend(authMiddleware.middleware),
  // Enable Redux DevTools
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: {},
});

export default store;
