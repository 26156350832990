// Model
import { BaseModel } from '.';

// User Model
export const UserModel = {
  ...BaseModel,
  email: '', // string - required - unique
  password: '', // string - required (đã hash)
  fullName: '', // string - required
  phone: '', // string
  avatar: '', // string - URL
  type: '', // 'admin' | 'customer'
  roleId: '', // string - chỉ dành cho admin
  status: '', // 'active' | 'inactive' | 'banned'
  lastLoginAt: null, // timestamp
  addresses: [], // array - chỉ dành cho customer
  wishlist: [], // array of product IDs - chỉ dành cho customer
  username: '',
  isDefaultPassword: false, // boolean - chỉ dành cho admin
};

export const RoleModel = {
  ...BaseModel,
  name: '', // string - required - unique
  description: '', // string
  permissions: [], // array of permission IDs
};

// Permission Model định nghĩa các quyền có thể có
export const PermissionModel = {
  ...BaseModel,
  code: '', // string - required - unique
  name: '', // string - required
  description: '', // string
  module: '', // string - required - module mà permission thuộc về
  action: '', // string - required - hành động: view, create, update, delete
};

// Constants cho User
export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  BANNED: 'banned',
};

export const USER_TYPE = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
};

// Định nghĩa các module trong hệ thống
export const MODULES = {
  DASHBOARD: 'dashboard',
  HERO: 'hero',
  CATEGORIES: 'categories',
  PRODUCTS: 'products',
  ORDERS: 'orders',
  PROMOTIONS: 'promotions',
  FLASH_SALES: 'flash_sales',
  CUSTOMERS: 'customers',
  USERS: 'users',
  ROLES: 'roles',
  SPECS: 'specs',
};

// Định nghĩa các action có thể thực hiện
export const ACTIONS = {
  VIEW: 'view',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

// Cấu trúc permission code: module_action
// VD: categories_view, products_create
export const generatePermissionCode = (module, action) => `${module}_${action}`;

// Danh sách permission mặc định
export const DEFAULT_PERMISSIONS = Object.entries(MODULES).reduce(
  (permissions, [moduleKey, moduleValue]) => {
    Object.entries(ACTIONS).forEach(([actionKey, actionValue]) => {
      const code = generatePermissionCode(moduleValue, actionValue);
      permissions.push({
        code,
        name: `${actionKey} ${moduleKey}`,
        description: `Ability to ${actionValue} ${moduleValue}`,
        module: moduleValue,
        action: actionValue,
      });
    });
    return permissions;
  },
  []
);

// Role mặc định cho Super Admin
export const SUPER_ADMIN_ROLE = {
  name: 'Super Admin',
  description: 'Full system access',
  permissions: DEFAULT_PERMISSIONS.map((p) => p.code),
};

// Role mặc định cho Staff
export const STAFF_ROLE = {
  name: 'Staff',
  description: 'Basic admin access',
  permissions: [
    // Dashboard
    generatePermissionCode(MODULES.DASHBOARD, ACTIONS.VIEW),

    // Categories
    generatePermissionCode(MODULES.CATEGORIES, ACTIONS.VIEW),

    // Products
    generatePermissionCode(MODULES.PRODUCTS, ACTIONS.VIEW),
    generatePermissionCode(MODULES.PRODUCTS, ACTIONS.UPDATE),

    // Orders
    generatePermissionCode(MODULES.ORDERS, ACTIONS.VIEW),
    generatePermissionCode(MODULES.ORDERS, ACTIONS.UPDATE),

    // Customers
    generatePermissionCode(MODULES.CUSTOMERS, ACTIONS.VIEW),
  ],
};
