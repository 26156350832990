import React from 'react';
import { Package } from 'lucide-react';
import clsx from 'clsx';
import ProductCard from './ProductCard';

const ProductGrid = ({
  products = [],
  viewMode = 'grid',
  displayType = 'normal',
  loading = false,
  error = null,
  onCompareProduct,
  comparedProducts = [],
  columns = {
    grid: { sm: 2, md: 3, lg: 4, xl: 5 },
    list: { sm: 1, md: 1, lg: 1, xl: 1 },
  },
  className,
}) => {
  const isMobile = window.innerWidth < 768;

  if (loading) {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-6">
        {[...Array(8)].map((_, index) => (
          <div key={index} className="animate-pulse">
            <div className="bg-gray-200 aspect-square rounded-lg"></div>
            <div className="mt-4 space-y-3">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <Package className="w-16 h-16 text-red-400 mx-auto mb-4" />
        <p className="text-lg text-red-600">Có lỗi xảy ra: {error}</p>
      </div>
    );
  }

  if (!products?.length) {
    return (
      <div className="text-center py-12">
        <Package className="w-16 h-16 text-gray-400 mx-auto mb-4" />
        <p className="text-lg text-gray-600">Không có sản phẩm nào</p>
      </div>
    );
  }

  // Grid columns based on view mode
  const getGridColumns = () => {
    const cols = viewMode === 'grid' ? columns.grid : columns.list;
    if (isMobile) {
      return `grid-cols-${cols.sm}`;
    }
    return `grid-cols-${cols.sm} md:grid-cols-${cols.md} lg:grid-cols-${cols.lg} xl:grid-cols-${cols.xl}`;
  };

  if (viewMode === 'horizontal-list') {
    return (
      <div className={clsx('space-y-4', className)}>
        {products.map((product, index) => (
          <ProductCard
            key={product.id}
            product={product}
            displayType={displayType}
            viewMode="horizontal-list"
            onCompare={onCompareProduct}
            isCompared={comparedProducts.includes(product.id)}
            index={index}
          />
        ))}
      </div>
    );
  }

  if (viewMode === 'vertical-list') {
    return (
      <div className={`grid ${getGridColumns()} gap-4 md:gap-6`}>
        {products.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            viewMode="vertical-list"
            displayType={displayType}
            onCompare={onCompareProduct}
            isCompared={comparedProducts.includes(product.id)}
            isMobile={isMobile}
          />
        ))}
      </div>
    );
  }

  return (
    <div className={clsx('grid', getGridColumns(), 'gap-4 md:gap-6', className)}>
      {products.map((product, index) => (
        <ProductCard
          key={product.id}
          product={product}
          displayType={displayType}
          viewMode={viewMode}
          onCompare={onCompareProduct}
          isCompared={comparedProducts.includes(product.id)}
          index={index}
        />
      ))}
    </div>
  );
};

export default ProductGrid;
