// src/components/common/CategoryIcon.jsx
import React from 'react';
import { Box } from 'lucide-react';
import * as Icons from 'lucide-react';

const CategoryIcon = ({ category, className = '', size = 'default' }) => {
  // Xác định kích thước dựa vào prop size
  const getIconSize = () => {
    switch (size) {
      case 'large':
        return 'w-16 h-16'; // Cho CategorySection
      case 'medium':
        return 'w-8 h-8';
      case 'small':
        return 'w-4 h-4';
      default:
        return 'w-5 h-5'; // Kích thước mặc định
    }
  };

  const iconSize = getIconSize();

  if (!category?.iconType) return <Box className={`${iconSize} ${className}`} />;

  switch (category.iconType) {
    case 'lucide':
      const IconComponent = category.icon ? Icons[category.icon] : null;
      return IconComponent ? (
        <IconComponent className={`${iconSize} ${className}`} />
      ) : (
        <Box className={`${iconSize} ${className}`} />
      );

    case 'brand':
    case 'url':
      return (
        <img
          src={category.icon}
          alt=""
          className={`${iconSize} object-contain ${className}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/fallback-icon.png';
          }}
        />
      );

    default:
      return <Box className={`${iconSize} ${className}`} />;
  }
};

export default CategoryIcon;
