import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckCircle, Truck, XCircle, Eye, Trash2 } from 'lucide-react';
import { ORDER_STATUS } from '../../constants';
import { ButtonLoading } from '../../../common/Loading';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../../common/Modal';
import { toast } from 'react-hot-toast';
import { useOrderPermissions } from '../../../../../hooks/useOrderPermissions';
import { deleteOrder } from '../../../../../store/orderSlice';

const OrderActions = ({ order, onViewDetail, onUpdateStatus, isSubmitting, canUpdate }) => {
  const dispatch = useDispatch();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  const [cancelNote, setCancelNote] = useState('');
  const [statusNote, setStatusNote] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { hasDeletePermission } = useOrderPermissions();

  // Kiểm tra điều kiện được xóa: đã hủy hoặc đã hoàn trả
  const canDelete = hasDeletePermission && ['cancelled', 'returned'].includes(order?.status);

  // Kiểm tra trạng thái cần note
  const isNoteRequired = (status) => {
    return [ORDER_STATUS.CANCELLED, ORDER_STATUS.RETURNED].includes(status);
  };

  const getNextStatus = () => {
    if (!order?.status) return null;

    switch (order.status) {
      case ORDER_STATUS.PENDING:
        return {
          status: ORDER_STATUS.PROCESSING,
          icon: CheckCircle,
          className: 'bg-blue-50 text-blue-600 hover:bg-blue-100',
          title: 'Xác nhận đơn',
        };
      case ORDER_STATUS.PROCESSING:
        return {
          status: ORDER_STATUS.SHIPPING,
          icon: Truck,
          className: 'bg-purple-50 text-purple-600 hover:bg-purple-100',
          title: 'Giao hàng',
        };
      case ORDER_STATUS.SHIPPING:
        return {
          status: ORDER_STATUS.DELIVERED,
          icon: CheckCircle,
          className: 'bg-green-50 text-green-600 hover:bg-green-100',
          title: 'Đã giao hàng',
        };
      default:
        return null;
    }
  };

  const nextStatus = getNextStatus();

  const handleStatusClick = async (status) => {
    if (!canUpdate) {
      toast.error('Bạn không có quyền cập nhật trạng thái đơn hàng');
      return;
    }

    // Kiểm tra điều kiện shipping
    if (status === ORDER_STATUS.SHIPPING) {
      // Nếu chuyển sang shipping, phải có shipping_info
      if (!order?.shipping_info?.tracking_number) {
        toast.error('Vui lòng cập nhật thông tin vận chuyển trong chi tiết đơn hàng trước');
        onViewDetail(order); // Mở modal detail để cập nhật shipping
        return;
      }
    }

    // Các trạng thái cần note
    if (isNoteRequired(status)) {
      setSelectedStatus(status);
      setShowStatusDialog(true);
      return;
    }

    // Các trạng thái không cần note
    try {
      await onUpdateStatus(order.id, status, '');
    } catch (error) {
      console.error('Status update error:', error);
    }
  };

  const handleStatusUpdate = async (status, note = '') => {
    try {
      if (isNoteRequired(status) && !note?.trim()) {
        toast.error('Vui lòng nhập lý do thay đổi trạng thái');
        return;
      }

      await onUpdateStatus(order.id, status, note);
      setShowStatusDialog(false);
      setStatusNote('');
      setSelectedStatus(null);
    } catch (error) {
      toast.error('Lỗi cập nhật trạng thái: ' + error.message);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch(deleteOrder(order.id)).unwrap();
      toast.success('Xóa đơn hàng thành công');
      setShowDeleteDialog(false);
    } catch (error) {
      toast.error('Lỗi xóa đơn hàng: ' + error.message);
    }
  };

  if (!order) return null;

  return (
    <>
      <div className="flex items-center gap-1.5">
        <button
          onClick={() => onViewDetail(order)}
          className="p-1.5 text-gray-500 hover:text-blue-600 hover:bg-blue-50 rounded transition-all"
          title="Xem chi tiết"
        >
          <Eye className="w-4 h-4" />
        </button>

        {canUpdate && nextStatus && (
          <button
            onClick={() => handleStatusClick(nextStatus.status)}
            disabled={isSubmitting}
            className={`p-1.5 rounded transition-all ${nextStatus.className}`}
            title={nextStatus.title}
          >
            <nextStatus.icon className="w-4 h-4" />
          </button>
        )}

        {canUpdate && ['pending', 'processing'].includes(order.status) && (
          <button
            onClick={() => setShowCancelDialog(true)}
            disabled={isSubmitting}
            className="p-1.5 rounded bg-red-50 text-red-600 hover:bg-red-100"
            title="Hủy đơn"
          >
            <XCircle className="w-4 h-4" />
          </button>
        )}

        {canDelete && (
          <button
            onClick={() => setShowDeleteDialog(true)}
            disabled={isSubmitting}
            className="p-1.5 text-red-500 hover:text-red-600 hover:bg-red-50 rounded"
            title="Xóa đơn hàng"
          >
            <Trash2 className="w-4 h-4" />
          </button>
        )}
      </div>

      {/* Cancel Dialog */}
      <Modal isOpen={showCancelDialog} onClose={() => setShowCancelDialog(false)}>
        <ModalHeader title={`Hủy đơn hàng #${order.orderNumber}`} />
        <ModalBody>
          <div className="space-y-3">
            <div className="p-3 bg-red-50 text-red-600 rounded-lg text-sm">
              Lưu ý: Hành động này không thể hoàn tác sau khi xác nhận.
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Lý do hủy đơn <span className="text-red-500">*</span>
              </label>
              <textarea
                value={cancelNote}
                onChange={(e) => setCancelNote(e.target.value)}
                className="input w-full text-sm"
                rows={3}
                placeholder="Vui lòng nhập lý do hủy đơn hàng..."
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => setShowCancelDialog(false)} className="btn btn-secondary">
            Đóng
          </button>
          <ButtonLoading
            onClick={() => {
              if (!cancelNote.trim()) {
                toast.error('Vui lòng nhập lý do hủy đơn hàng');
                return;
              }
              handleStatusUpdate(ORDER_STATUS.CANCELLED, cancelNote.trim());
              setShowCancelDialog(false);
              setCancelNote('');
            }}
            isLoading={isSubmitting}
            disabled={isSubmitting || !cancelNote.trim()}
            className="btn btn-danger"
          >
            Xác nhận hủy
          </ButtonLoading>
        </ModalFooter>
      </Modal>

      {/* Status Change Dialog */}
      <Modal isOpen={showStatusDialog} onClose={() => setShowStatusDialog(false)}>
        <ModalHeader
          title={`${selectedStatus === ORDER_STATUS.CANCELLED ? 'Hủy' : 'Hoàn trả'} đơn hàng #${order.orderNumber}`}
        />
        <ModalBody>
          <div className="space-y-3">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Lý do thay đổi trạng thái <span className="text-red-500">*</span>
              </label>
              <textarea
                value={statusNote}
                onChange={(e) => setStatusNote(e.target.value)}
                className="input w-full text-sm"
                rows={3}
                placeholder={`Vui lòng nhập lý do thay đổi trạng thái...`}
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button onClick={() => setShowStatusDialog(false)} className="btn btn-secondary">
            Đóng
          </button>
          <ButtonLoading
            onClick={() => {
              if (!statusNote.trim()) {
                toast.error('Vui lòng nhập lý do thay đổi trạng thái');
                return;
              }
              handleStatusUpdate(selectedStatus, statusNote.trim());
            }}
            isLoading={isSubmitting}
            disabled={isSubmitting || !statusNote.trim()}
            className="btn btn-primary"
          >
            Xác nhận
          </ButtonLoading>
        </ModalFooter>
      </Modal>

      {/* Delete Dialog */}
      <Modal isOpen={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <ModalHeader title={`Xóa đơn hàng #${order.orderNumber}`} />
        <ModalBody>
          <div className="space-y-4">
            <div className="flex items-center gap-4 p-4 bg-red-50 text-red-600 rounded-lg">
              <XCircle className="w-6 h-6 flex-shrink-0" />
              <div className="text-sm">
                Hành động này sẽ xóa vĩnh viễn đơn hàng và không thể khôi phục. Bạn có chắc chắn
                muốn tiếp tục?
              </div>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg">
              <h4 className="font-medium mb-2">Thông tin đơn hàng:</h4>
              <div className="text-sm text-gray-600">
                <p>Khách hàng: {order.shipping_address?.fullName}</p>
                <p>Số điện thoại: {order.shipping_address?.phone}</p>
                <p>Trạng thái: {order.status}</p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            onClick={() => setShowDeleteDialog(false)}
            className="btn btn-secondary"
            disabled={isSubmitting}
          >
            Hủy
          </button>
          <ButtonLoading onClick={handleDelete} isLoading={isSubmitting} className="btn btn-danger">
            Xác nhận xóa
          </ButtonLoading>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrderActions;
