// src/client/components/common/OptimizedImage.jsx
import React, { useState } from 'react';

const OptimizedImage = ({
  src,
  alt,
  className = '',
  containerClassName = '',
  aspectRatio = 'auto', // Thay đổi default sang 'auto'
  objectFit = 'contain', // Thêm prop objectFit
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const getAspectRatio = () => {
    switch (aspectRatio) {
      case 'wide':
        return 'aspect-video';
      case 'tall':
        return 'aspect-[3/4]';
      case 'square':
        return 'aspect-square';
      case 'auto':
      default:
        return ''; // Không áp dụng aspect ratio cố định
    }
  };

  return (
    <div
      className={`relative ${getAspectRatio()} bg-gray-100 overflow-hidden ${containerClassName}`}
    >
      {!loaded && !error && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="w-10 h-10 border-4 border-gray-200 border-t-primary-600 rounded-full animate-spin"></div>
        </div>
      )}

      {error && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
          <span className="text-gray-400">Không thể tải hình ảnh</span>
        </div>
      )}

      <img
        src={src}
        alt={alt}
        className={`w-full h-full object-${objectFit} transition-all duration-300 ${className} ${!loaded ? 'opacity-0' : 'opacity-100'}`}
        onLoad={() => setLoaded(true)}
        onError={() => {
          setError(true);
          setLoaded(true);
        }}
      />
    </div>
  );
};

export default OptimizedImage;
