// src/client/pages/About/index.jsx
import React from 'react';

const AboutPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Giới thiệu về Lappy</h1>

          <div className="bg-white rounded-xl shadow-sm p-8 space-y-6">
            <div className="prose prose-lg max-w-none">
              <p>
                Công ty chúng tôi hoạt động trong lĩnh vực kinh doanh các dòng sản phẩm laptop,
                macbook tình trạng likenew. Thành lập từ năm 2020 đến nay, Mapple đã tạo dựng được
                chỗ đứng vững chắc trên thị trường và trở thành cái tên quen thuộc đối với khách
                hàng.
              </p>

              <h2>Cam kết của chúng tôi</h2>
              <ul>
                <li>Sản phẩm đảm bảo chất lượng cao</li>
                <li>Nguồn gốc rõ ràng</li>
                <li>Giá cạnh tranh trên thị trường</li>
                <li>Bảo hành uy tín</li>
              </ul>

              <h2>Tầm nhìn</h2>
              <p>
                Trở thành đơn vị uy tín hàng đầu trong lĩnh vực kinh doanh laptop, macbook likenew
                tại Việt Nam.
              </p>

              <h2>Sứ mệnh</h2>
              <p>
                Mang đến cho khách hàng những sản phẩm chất lượng với giá thành hợp lý, kèm theo
                dịch vụ hậu mãi chu đáo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
