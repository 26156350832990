import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Plus,
  Calendar,
  Clock,
  Package,
  Activity,
  ShoppingBag,
  Percent,
  BarChart3,
  Edit2,
  Trash2,
} from 'lucide-react';
import { fetchFlashSales, deleteFlashSale } from '../../../store/flashSaleSlice';
import FlashSaleForm from './FlashSaleForm';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const StatCard = ({ title, value, icon: Icon, colorClass }) => (
  <div className="bg-white p-6 rounded-xl shadow-sm">
    <div className="flex items-center gap-4">
      <div className={`p-3 rounded-lg ${colorClass}`}>
        <Icon className="w-6 h-6 text-white" />
      </div>
      <div>
        <p className="text-sm font-medium text-gray-600">{title}</p>
        <h3 className="text-2xl font-bold mt-1">{value}</h3>
      </div>
    </div>
  </div>
);

const FlashSaleStatus = ({ startTime, endTime, isActive }) => {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  let status = {
    text: 'Không hoạt động',
    color: 'bg-gray-100 text-gray-800',
  };

  if (isActive) {
    if (now < start) {
      status = { text: 'Sắp diễn ra', color: 'bg-yellow-100 text-yellow-800' };
    } else if (now >= start && now <= end) {
      status = { text: 'Đang diễn ra', color: 'bg-green-100 text-green-800' };
    } else if (now > end) {
      status = { text: 'Đã kết thúc', color: 'bg-red-100 text-red-800' };
    }
  }

  return (
    <span className={`px-3 py-1.5 rounded-full text-sm font-medium ${status.color}`}>
      {status.text}
    </span>
  );
};

const FlashSaleManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items: flashSales, status, error } = useSelector((state) => state.flashSales);
  const [showForm, setShowForm] = useState(false);
  const [editingFlashSale, setEditingFlashSale] = useState(null);

  const { canView, canCreate, canUpdate, canDelete } = usePermissions();
  const hasViewPermission = canView(MODULES.FLASH_SALES);
  const hasCreatePermission = canCreate(MODULES.FLASH_SALES);
  const hasUpdatePermission = canUpdate(MODULES.FLASH_SALES);
  const hasDeletePermission = canDelete(MODULES.FLASH_SALES);

  useEffect(() => {
    if (status === 'idle' && hasViewPermission) {
      dispatch(fetchFlashSales());
    }
  }, [status, dispatch, hasViewPermission]);

  const currentFlashSales = flashSales.filter((sale) => {
    const now = new Date();
    const start = new Date(sale.startTime);
    const end = new Date(sale.endTime);
    return sale.isActive && now >= start && now <= end;
  });

  const upcomingFlashSales = flashSales.filter((sale) => {
    const now = new Date();
    const start = new Date(sale.startTime);
    return sale.isActive && now < start;
  });

  const endedFlashSales = flashSales.filter((sale) => {
    const now = new Date();
    const end = new Date(sale.endTime);
    return sale.isActive && now > end;
  });

  const handleAdd = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền tạo Flash Sale mới');
      return;
    }
    setEditingFlashSale(null);
    setShowForm(true);
  };

  const handleEdit = (flashSale) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền tạo Flash Sale mới');
      return;
    }
    setEditingFlashSale(flashSale);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền tạo Flash Sale mới');
      return;
    }
    if (!window.confirm('Bạn có chắc muốn xóa đợt Flash Sale này?')) return;
    try {
      await dispatch(deleteFlashSale(id)).unwrap();
      toast.success('Xóa Flash Sale thành công');
    } catch (error) {
      toast.error('Xóa Flash Sale thất bại: ' + error.message);
    }
  };
  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý Flash Sale</p>
        </div>
      </div>
    );
  }
  if (status === 'loading') {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <div className="flex flex-col items-center gap-4">
          <div className="w-12 h-12 border-4 border-primary-600 border-t-transparent rounded-full animate-spin" />
          <p className="text-gray-600">Đang tải dữ liệu...</p>
        </div>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-50">
        <div className="text-center">
          <p className="text-red-500 mb-4">{error}</p>
          <button onClick={() => dispatch(fetchFlashSales())} className="btn btn-primary">
            Thử lại
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 max-w-7xl mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Quản lý Flash Sale</h1>
          <p className="text-sm text-gray-500 mt-1">
            Quản lý các đợt giảm giá nhanh và khuyến mãi đặc biệt
          </p>
        </div>
        <button onClick={handleAdd} className="btn btn-primary inline-flex items-center gap-2">
          <Plus className="w-5 h-5" />
          Tạo Flash Sale
        </button>
      </div>

      {/* Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          title="Đang diễn ra"
          value={currentFlashSales.length}
          icon={Activity}
          colorClass="bg-green-500"
        />
        <StatCard
          title="Sắp diễn ra"
          value={upcomingFlashSales.length}
          icon={Clock}
          colorClass="bg-blue-500"
        />
        <StatCard
          title="Đã kết thúc"
          value={endedFlashSales.length}
          icon={Calendar}
          colorClass="bg-purple-500"
        />
        <StatCard
          title="Tổng sản phẩm"
          value={flashSales.reduce((sum, sale) => sum + (sale.productCount || 0), 0)}
          icon={Package}
          colorClass="bg-orange-500"
        />
      </div>

      {/* Flash Sales List */}
      <div className="bg-white rounded-xl shadow-sm overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">Danh sách Flash Sale</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Thông tin
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Thời gian
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Thống kê
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Trạng thái
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Thao tác
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {flashSales.map((sale) => (
                <tr key={sale.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    <div className="flex items-start gap-4">
                      {sale.thumbnailImage ? (
                        <img
                          src={sale.thumbnailImage}
                          alt={sale.name}
                          className="w-16 h-16 rounded object-cover"
                        />
                      ) : (
                        <div className="w-16 h-16 bg-gray-100 rounded flex items-center justify-center">
                          <Percent className="w-8 h-8 text-gray-400" />
                        </div>
                      )}
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">{sale.name}</h3>
                        {sale.description && (
                          <p className="text-sm text-gray-500 mt-1">{sale.description}</p>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="space-y-1 text-sm">
                      <div className="flex items-center text-gray-900">
                        <Calendar className="w-4 h-4 mr-1.5" />
                        {new Date(sale.startTime).toLocaleDateString('vi-VN')}
                      </div>
                      <div className="flex items-center text-gray-500">
                        <Clock className="w-4 h-4 mr-1.5" />
                        {new Date(sale.endTime).toLocaleDateString('vi-VN')}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="space-y-2 text-sm">
                      <div className="flex items-center gap-2">
                        <Package className="w-4 h-4 text-gray-400" />
                        <span>{sale.productCount || 0} sản phẩm</span>
                      </div>
                      {sale.totalSold > 0 && (
                        <div className="flex items-center gap-2">
                          <ShoppingBag className="w-4 h-4 text-gray-400" />
                          <span>{sale.totalSold} đã bán</span>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <FlashSaleStatus
                      startTime={sale.startTime}
                      endTime={sale.endTime}
                      isActive={sale.isActive}
                    />
                  </td>
                  <td className="px-6 py-4 text-right">
                    <div className="flex items-center justify-end gap-3">
                      <button
                        onClick={() => handleEdit(sale)}
                        className="p-2 text-gray-400 hover:text-blue-500 transition-colors"
                        title="Sửa"
                      >
                        <Edit2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(sale.id)}
                        className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                        title="Xóa"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => navigate(`/admin/flash-sales/${sale.id}/products`)}
                        className="p-2 text-gray-400 hover:text-purple-500 transition-colors"
                        title="Quản lý sản phẩm"
                      >
                        <Package className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => navigate(`/admin/flash-sales/${sale.id}/stats`)}
                        className="p-2 text-gray-400 hover:text-green-500 transition-colors"
                        title="Xem thống kê"
                      >
                        <BarChart3 className="w-5 h-5" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
              {flashSales.length === 0 && (
                <tr>
                  <td colSpan={5} className="px-6 py-12">
                    <div className="text-center">
                      <Package className="mx-auto h-12 w-12 text-gray-400" />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Chưa có Flash Sale nào
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Bắt đầu bằng cách tạo Flash Sale đầu tiên.
                      </p>
                      <div className="mt-6">
                        <button
                          onClick={handleAdd}
                          className="btn btn-primary inline-flex items-center gap-2"
                        >
                          <Plus className="w-5 h-5" />
                          Tạo Flash Sale
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Form Modal */}
      {showForm && (
        <FlashSaleForm
          flashSale={editingFlashSale}
          onClose={() => {
            setShowForm(false);
            setEditingFlashSale(null);
          }}
        />
      )}
    </div>
  );
};

export default FlashSaleManager;
