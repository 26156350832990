// src/admin/components/CustomerManager/CustomerList.jsx
import React from 'react';
import { useDispatch } from 'react-redux';
import { Edit2, Trash2, Ban } from 'lucide-react';
import { deleteUser, updateUser } from '../../../store/userSlice';
import { USER_STATUS } from '../../../models/userModels';
import toast from 'react-hot-toast';

const CustomerList = ({ customers, onEdit, hasUpdatePermission, hasDeletePermission }) => {
  const dispatch = useDispatch();

  const handleDelete = async (customer) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa khách hàng');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa khách hàng này?')) {
      try {
        await dispatch(deleteUser(customer.id)).unwrap();
        toast.success('Xóa khách hàng thành công');
      } catch (error) {
        toast.error('Xóa khách hàng thất bại: ' + error.message);
      }
    }
  };

  const handleToggleStatus = async (customer) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền cập nhật trạng thái khách hàng');
      return;
    }

    const newStatus =
      customer.status === USER_STATUS.ACTIVE ? USER_STATUS.BANNED : USER_STATUS.ACTIVE;

    try {
      await dispatch(
        updateUser({
          id: customer.id,
          data: { ...customer, status: newStatus },
        })
      ).unwrap();

      toast.success(
        newStatus === USER_STATUS.ACTIVE ? 'Đã mở khóa tài khoản' : 'Đã khóa tài khoản'
      );
    } catch (error) {
      toast.error('Cập nhật trạng thái thất bại: ' + error.message);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case USER_STATUS.ACTIVE:
        return 'bg-green-100 text-green-800';
      case USER_STATUS.BANNED:
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Thông tin
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Liên hệ
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Đơn hàng
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Trạng thái
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {customers.map((customer) => (
            <tr key={customer.id} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="flex items-center">
                  {customer.avatar ? (
                    <img
                      src={customer.avatar}
                      alt={customer.fullName}
                      className="h-10 w-10 rounded-full object-cover"
                    />
                  ) : (
                    <div className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center">
                      <span className="text-xl font-medium text-gray-500">
                        {customer.fullName?.[0]?.toUpperCase()}
                      </span>
                    </div>
                  )}
                  <div className="ml-4">
                    <div className="font-medium text-gray-900">{customer.fullName}</div>
                    <div className="text-sm text-gray-500">
                      Tham gia: {new Date(customer.createdAt).toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm">
                  <div>{customer.email}</div>
                  <div className="text-gray-500">{customer.phone}</div>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="text-sm">
                  <div>Đơn hàng: {customer.orderCount || 0}</div>
                  <div className="text-gray-500">
                    Tổng mua:{' '}
                    {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
                      customer.totalSpent || 0
                    )}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4">
                <span
                  className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                    customer.status
                  )}`}
                >
                  {customer.status}
                </span>
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center justify-center space-x-3">
                  {hasUpdatePermission && (
                    <>
                      <button
                        onClick={() => onEdit(customer)}
                        className="text-blue-600 hover:text-blue-900"
                        title="Sửa"
                      >
                        <Edit2 size={16} />
                      </button>
                      <button
                        onClick={() => handleToggleStatus(customer)}
                        className={
                          customer.status === USER_STATUS.ACTIVE
                            ? 'text-red-600 hover:text-red-900'
                            : 'text-green-600 hover:text-green-900'
                        }
                        title={
                          customer.status === USER_STATUS.ACTIVE ? 'Khóa tài khoản' : 'Mở khóa'
                        }
                      >
                        <Ban size={16} />
                      </button>
                    </>
                  )}
                  {hasDeletePermission && (
                    <button
                      onClick={() => handleDelete(customer)}
                      className="text-red-600 hover:text-red-900"
                      title="Xóa"
                    >
                      <Trash2 size={16} />
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}

          {customers.length === 0 && (
            <tr>
              <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                Không có khách hàng nào
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerList;
