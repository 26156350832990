import React, { useState } from 'react';
import { PAYMENT_STATUS } from '../../constants';
import { PaymentStatusBadge } from '../Badges';

export const PaymentForm = ({ order, onUpdatePayment, isSubmitting, renderPaymentActions }) => {
  const [note, setNote] = useState('');
  // Chỉ cho phép xác nhận hoàn tiền khi đơn đang chờ hoàn tiền
  const canConfirmRefund = order.payment_status === PAYMENT_STATUS.PENDING_REFUND;

  return (
    <div className="space-y-6">
      {/* Payment Info */}
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-lg font-medium">Thông tin thanh toán</h3>
          <div className="mt-4 grid grid-cols-2 gap-4">
            <div>
              <label className="text-sm text-gray-500">Phương thức</label>
              <p className="font-medium">{order.payment_method}</p>
            </div>
            <div>
              <label className="text-sm text-gray-500">Trạng thái</label>
              <div className="mt-1">
                <PaymentStatusBadge status={order.payment_status} />
              </div>
            </div>
          </div>
        </div>
        {renderPaymentActions?.()}
      </div>

      {/* Ghi chú thanh toán */}
      {canConfirmRefund && (
        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Lý do hoàn tiền <span className="text-red-500">*</span>
          </label>
          <textarea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            rows={3}
            className="input w-full"
            placeholder="Nhập lý do hoàn tiền..."
            disabled={isSubmitting}
          />
        </div>
      )}

      {/* Lịch sử thanh toán */}
      {order.payment_history?.length > 0 && (
        <div className="border-t pt-4 mt-4">
          <h4 className="font-medium mb-3">Lịch sử thanh toán</h4>
          <div className="space-y-4">
            {order.payment_history.map((history, index) => (
              <div key={index} className="flex justify-between items-start">
                <div>
                  <PaymentStatusBadge status={history.status} />
                  {history.note && <p className="text-sm text-gray-600 mt-1">{history.note}</p>}
                </div>
                <time className="text-sm text-gray-500">
                  {new Date(history.timestamp).toLocaleString()}
                </time>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
