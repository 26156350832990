import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct, updateProduct } from '../../../store/productSlice';
import { clearEditingProduct } from '../../../store/uiSlice';
import { X, Upload } from 'lucide-react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';
import CurrencyInput from '../common/CurrencyInput';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const suggestedTags = [
  { label: 'Gaming', value: 'gaming' },
  { label: 'Văn phòng', value: 'văn phòng' },
  { label: 'Đồ họa', value: 'đồ họa' },
  { label: 'Sinh viên', value: 'sinh viên' },
];

const ProductForm = () => {
  const dispatch = useDispatch();
  const editingProduct = useSelector((state) => state.ui.editingProduct);
  const categories = useSelector((state) => state.categories.items);
  const specTemplates = useSelector((state) => state.specTemplates.items);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { canCreate, canUpdate } = usePermissions();
  const hasCreatePermission = canCreate(MODULES.PRODUCTS);
  const hasUpdatePermission = canUpdate(MODULES.PRODUCTS);

  const [imageFiles, setImageFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState(() => {
    if (editingProduct?.images) {
      return editingProduct.images.map((url) => ({
        url,
        isExisting: true, // Đánh dấu ảnh có sẵn
      }));
    }
    return [];
  });
  const [selectedTemplate, setSelectedTemplate] = useState(
    editingProduct?.specTemplateId
      ? specTemplates.find((t) => t.id === editingProduct.specTemplateId)
      : null
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: editingProduct || {
      name: '',
      sku: '',
      description: '',
      price: 0,
      categoryIds: [],
      tags: [],
      stock: 0,
      isHot: false,
      isFeatured: false,
      isActive: true,
      warranty: {
        months: 0,
        description: '',
      },
      relatedProducts: [],
      specifications: {},
      specTemplateId: '',
    },
  });

  const handleClose = () => {
    dispatch(clearEditingProduct());
    reset();
    setImageFiles([]);
    setPreviewImages([]);
    setSelectedTemplate(null);
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    const maxSize = 5 * 1024 * 1024; // 5MB

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        toast.error(`File ${file.name} vượt quá 5MB`);
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      const previewPromises = validFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              url: reader.result,
              isExisting: false,
              file,
            });
          };
          reader.readAsDataURL(file);
        });
      });

      const newPreviews = await Promise.all(previewPromises);
      setImageFiles((prev) => [...prev, ...validFiles]);
      setPreviewImages((prev) => [...prev, ...newPreviews]);
    }
  };

  const removeImage = (index) => {
    setPreviewImages((prev) => prev.filter((_, i) => i !== index));
    // Chỉ xóa file trong imageFiles nếu là ảnh mới
    if (!previewImages[index].isExisting) {
      setImageFiles((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      setUploadProgress(0);

      // Validate permissions first
      if (editingProduct && !hasUpdatePermission) {
        toast.error('Bạn không có quyền cập nhật sản phẩm');
        return;
      }

      if (!editingProduct && !hasCreatePermission) {
        toast.error('Bạn không có quyền thêm sản phẩm');
        return;
      }

      const loadingToast = toast.loading(
        imageFiles.length ? 'Đang tải lên hình ảnh...' : 'Đang xử lý...'
      );

      // Get existing images that were kept
      const existingImages = previewImages.filter((img) => img.isExisting).map((img) => img.url);

      // Prepare form data with proper number conversions
      const formData = {
        ...data,
        price: Number(data.price),
        stock: Number(data.stock),
        warranty: {
          months: Number(data.warranty.months),
          description: data.warranty.description,
        },
        specifications: data.specifications || {},
        specTemplateId: data.specTemplateId || null,
      };

      // Handle update or create based on editingProduct
      if (editingProduct) {
        await dispatch(
          updateProduct({
            id: editingProduct.id,
            data: formData,
            images: imageFiles,
            existingImages,
            onProgress: (progress) => setUploadProgress(progress),
          })
        ).unwrap();
        toast.success('Cập nhật sản phẩm thành công');
      } else {
        await dispatch(
          addProduct({
            data: formData,
            images: imageFiles,
            onProgress: (progress) => setUploadProgress(progress),
          })
        ).unwrap();
        toast.success('Thêm sản phẩm thành công');
      }

      toast.dismiss(loadingToast);
      handleClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    } finally {
      setIsSubmitting(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center overflow-hidden z-50 pt-[5vh]">
      <div className="bg-white rounded-lg w-full max-w-4xl mx-4 flex flex-col h-[90vh]">
        {/* Progress bar khi đang upload */}
        {isSubmitting && uploadProgress > 0 && (
          <div className="absolute top-0 left-0 w-full h-1 bg-gray-200">
            <div
              className="h-full bg-primary-600 transition-all duration-300"
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
        )}

        {/* Header */}
        <div className="border-b px-6 py-4 flex items-center justify-between">
          <h2 className="text-xl font-bold">
            {editingProduct ? 'Sửa sản phẩm' : 'Thêm sản phẩm mới'}
          </h2>
          <button onClick={handleClose} className="p-2 hover:bg-gray-100 rounded-full">
            <X size={20} />
          </button>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col min-h-0 flex-1">
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto px-6 py-4">
            <div className="space-y-6">
              {/* Thông tin cơ bản */}
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Tên sản phẩm</label>
                    <input
                      type="text"
                      {...register('name', { required: 'Vui lòng nhập tên sản phẩm' })}
                      className="input mt-1"
                    />
                    {errors.name && (
                      <span className="text-sm text-red-500">{errors.name.message}</span>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Mã sản phẩm (SKU)
                    </label>
                    <input type="text" {...register('sku')} className="input mt-1" />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Tags</label>
                  <CreatableSelect
                    isMulti
                    options={suggestedTags}
                    value={watch('tags')?.map((tag) => ({ label: tag, value: tag }))}
                    onChange={(selected) =>
                      setValue('tags', selected?.map((item) => item.value) || [])
                    }
                    placeholder="Nhập tag và nhấn Enter để thêm..."
                    classNamePrefix="select"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Danh mục</label>
                  <Select
                    isMulti
                    options={categories.map((cat) => ({
                      value: cat.id,
                      label: cat.name,
                    }))}
                    value={watch('categoryIds')?.map((id) => ({
                      value: id,
                      label: categories.find((cat) => cat.id === id)?.name,
                    }))}
                    onChange={(selected) =>
                      setValue('categoryIds', selected?.map((item) => item.value) || [])
                    }
                    className="mt-1"
                    placeholder="Chọn danh mục..."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Mô tả</label>
                  <textarea {...register('description')} rows={3} className="input mt-1" />
                </div>
              </div>

              {/* Giá và số lượng */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Giá bán</label>
                  <CurrencyInput
                    value={watch('price')}
                    onChange={(value) => setValue('price', value)}
                    className="mt-1"
                    placeholder="Nhập giá bán"
                  />
                  {errors.price && (
                    <span className="text-sm text-red-500">{errors.price.message}</span>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Số lượng tồn kho
                  </label>
                  <input
                    type="text"
                    value={watch('stock')}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      setValue('stock', value ? parseInt(value) : 0);
                    }}
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setValue('stock', 0);
                      }
                    }}
                    className="input mt-1"
                    placeholder="Nhập số lượng"
                  />
                  {errors.stock && (
                    <span className="text-sm text-red-500">{errors.stock.message}</span>
                  )}
                </div>
              </div>

              {/* Bảo hành */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Thời gian bảo hành (tháng)
                  </label>
                  <input
                    type="text"
                    value={watch('warranty.months')}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, '');
                      setValue('warranty.months', value ? parseInt(value) : 0);
                    }}
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setValue('warranty.months', 0);
                      }
                    }}
                    className="input mt-1"
                    placeholder="Nhập số tháng"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Chi tiết bảo hành
                  </label>
                  <input type="text" {...register('warranty.description')} className="input mt-1" />
                </div>
              </div>

              {/* Hình ảnh */}
              <div className="space-y-4">
                <h3 className="font-medium">Hình ảnh sản phẩm</h3>
                <div className="mt-1 border-2 border-dashed border-gray-300 rounded-lg p-4">
                  <div className="text-center">
                    <Upload className="mx-auto h-12 w-12 text-gray-400" />
                    <div className="mt-2">
                      <input
                        type="file"
                        multiple
                        onChange={handleImageChange}
                        accept="image/*"
                        className="hidden"
                        id="image-upload"
                      />
                      <label
                        htmlFor="image-upload"
                        className="cursor-pointer text-primary-600 hover:text-primary-500"
                      >
                        Tải lên
                      </label>
                      <span className="text-gray-500"> hoặc kéo thả</span>
                    </div>
                    <p className="text-xs text-gray-500 mt-1">PNG, JPG, GIF tối đa 5MB</p>
                  </div>
                </div>

                {previewImages.length > 0 && (
                  <div className="mt-4 grid grid-cols-4 gap-4">
                    {previewImages.map((image, index) => (
                      <div key={index} className="relative group">
                        <img
                          src={image.url}
                          alt={`Preview ${index}`}
                          className="h-24 w-24 object-cover rounded-lg"
                        />
                        <button
                          type="button"
                          onClick={() => removeImage(index)}
                          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <X size={14} />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Trạng thái */}
              <div className="space-y-4">
                <h3 className="font-medium">Trạng thái</h3>
                <div className="flex flex-wrap gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      {...register('isActive')}
                      className="rounded text-primary-600"
                    />
                    <span className="text-sm font-medium text-gray-700">Đang bán</span>
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      {...register('isHot')}
                      className="rounded text-primary-600"
                    />
                    <span className="text-sm font-medium text-gray-700">Sản phẩm hot</span>
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      {...register('isFeatured')}
                      className="rounded text-primary-600"
                    />
                    <span className="text-sm font-medium text-gray-700">Sản phẩm nổi bật</span>
                  </label>
                </div>
              </div>

              {/* Thông số kỹ thuật */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Mẫu thông số</label>
                <select
                  {...register('specTemplateId')}
                  className="input mt-1"
                  onChange={(e) => {
                    const template = specTemplates.find((t) => t.id === e.target.value);
                    setSelectedTemplate(template);
                  }}
                >
                  <option value="">Chọn mẫu thông số</option>
                  {specTemplates.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Form thông số kỹ thuật */}
              {selectedTemplate && (
                <div className="space-y-4">
                  <h3 className="font-medium">Thông số kỹ thuật</h3>
                  {selectedTemplate.fields.map((field) => (
                    <div key={field.key}>
                      <label className="block text-sm font-medium text-gray-700">
                        {field.label}
                        {field.required && <span className="text-red-500 ml-1">*</span>}
                      </label>

                      {field.type === 'select' ? (
                        <select
                          {...register(`specifications.${field.key}`, {
                            required: field.required && 'Trường này là bắt buộc',
                          })}
                          className="input mt-1"
                        >
                          <option value="">Chọn {field.label}</option>
                          {field.options?.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={field.type}
                          {...register(`specifications.${field.key}`, {
                            required: field.required && 'Trường này là bắt buộc',
                          })}
                          placeholder={field.placeholder}
                          className="input mt-1"
                        />
                      )}
                      {errors.specifications?.[field.key] && (
                        <span className="text-sm text-red-500">
                          {errors.specifications[field.key].message}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="border-t px-6 py-4 bg-gray-50">
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Hủy
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`btn btn-primary ${isSubmitting ? 'opacity-75 cursor-not-allowed' : ''}`}
              >
                {isSubmitting ? (
                  <div className="flex items-center gap-2">
                    <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    {imageFiles.length ? 'Đang tải lên...' : 'Đang xử lý...'}
                  </div>
                ) : editingProduct ? (
                  'Cập nhật'
                ) : (
                  'Thêm mới'
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProductForm;
