import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSpecTemplate,
  updateSpecTemplate,
  clearActiveTemplate,
} from '../../../store/specTemplateSlice';
import { DEVICE_TYPES } from '../../../models/specTemplates';
import { X, Save, AlertTriangle } from 'lucide-react';
import toast from 'react-hot-toast';
import SpecFieldManager from './SpecFieldManager';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const SpecTemplateForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const activeTemplate = useSelector((state) => state.specTemplates.activeTemplate);
  const [fields, setFields] = useState(activeTemplate?.fields || []);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { canCreate, canUpdate } = usePermissions();
  const hasCreatePermission = canCreate(MODULES.SPECS);
  const hasUpdatePermission = canUpdate(MODULES.SPECS);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    reset,
  } = useForm({
    defaultValues: activeTemplate || {
      name: '',
      type: DEVICE_TYPES.LAPTOP,
      description: '',
      isActive: true,
    },
  });

  // Prevent form submission on Enter key
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  // Handle modal close
  const handleClose = useCallback(
    (e) => {
      e?.preventDefault();
      e?.stopPropagation();

      const hasChanges = isDirty || fields !== activeTemplate?.fields;
      if (hasChanges) {
        const confirmClose = window.confirm(
          'Bạn có chắc muốn hủy? Các thay đổi sẽ không được lưu.'
        );
        if (confirmClose) {
          dispatch(clearActiveTemplate());
          reset();
          onClose();
        }
      } else {
        dispatch(clearActiveTemplate());
        onClose();
      }
    },
    [dispatch, isDirty, fields, activeTemplate, reset, onClose]
  );

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (fields.length === 0) {
        toast.error('Vui lòng thêm ít nhất một trường thông số');
        return;
      }

      setIsSubmitting(true);
      const templateData = {
        ...formData,
        fields: fields.map((field, index) => ({
          ...field,
          order: index,
        })),
      };

      if (activeTemplate?.id) {
        if (!hasUpdatePermission) {
          toast.error('Bạn không có quyền cập nhật mẫu thông số');
          return;
        }

        await dispatch(
          updateSpecTemplate({
            id: activeTemplate.id,
            data: templateData,
          })
        ).unwrap();
        toast.success('Cập nhật mẫu thành công');
      } else {
        if (!hasCreatePermission) {
          toast.error('Bạn không có quyền tạo mẫu thông số');
          return;
        }
        await dispatch(addSpecTemplate(templateData)).unwrap();
        toast.success('Thêm mẫu mới thành công');
      }

      dispatch(clearActiveTemplate());
      onClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl m-4 max-h-[90vh] overflow-hidden flex flex-col">
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-bold">
            {activeTemplate ? 'Sửa mẫu thông số' : 'Thêm mẫu thông số mới'}
          </h2>
          <button onClick={handleClose} className="p-2 hover:bg-gray-100 rounded" type="button">
            <X size={20} />
          </button>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={handleKeyDown}
          className="flex-1 overflow-auto"
        >
          <div className="p-6 space-y-6">
            {/* Basic Info */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Tên mẫu <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  {...register('name', {
                    required: 'Vui lòng nhập tên mẫu',
                    minLength: {
                      value: 3,
                      message: 'Tên mẫu phải có ít nhất 3 ký tự',
                    },
                  })}
                  className={`input ${errors.name ? 'border-red-500' : ''}`}
                />
                {errors.name && (
                  <span className="text-sm text-red-500 flex items-center gap-1 mt-1">
                    <AlertTriangle size={14} />
                    {errors.name.message}
                  </span>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Loại thiết bị
                </label>
                <select {...register('type')} className="input">
                  {Object.entries(DEVICE_TYPES).map(([key, value]) => (
                    <option key={value} value={value}>
                      {key}
                    </option>
                  ))}
                </select>
              </div>

              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Mô tả</label>
                <textarea
                  {...register('description')}
                  rows={3}
                  className="input"
                  placeholder="Nhập mô tả cho mẫu thông số này..."
                />
              </div>

              <div className="md:col-span-2">
                <label className="flex items-center space-x-2 cursor-pointer">
                  <input
                    type="checkbox"
                    {...register('isActive')}
                    className="rounded text-primary-600 cursor-pointer"
                  />
                  <span className="text-sm font-medium text-gray-700">Kích hoạt</span>
                </label>
              </div>
            </div>

            {/* Fields Configuration */}
            <div className="border-t pt-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Cấu hình trường thông số
                {fields.length === 0 && (
                  <span className="text-sm text-red-500 ml-2">
                    (Vui lòng thêm ít nhất một trường)
                  </span>
                )}
              </h3>
              <SpecFieldManager fields={fields} onChange={setFields} parentType={watch('type')} />
            </div>
          </div>

          {/* Footer */}
          <div className="flex justify-end gap-3 p-6 border-t bg-gray-50">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-secondary"
              disabled={isSubmitting}
            >
              Hủy
            </button>
            <button type="submit" className="btn btn-primary min-w-[100px]" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {activeTemplate ? 'Đang cập nhật...' : 'Đang thêm...'}
                </>
              ) : (
                <>
                  <Save className="w-5 h-5 mr-2" />
                  {activeTemplate ? 'Cập nhật' : 'Thêm mới'}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SpecTemplateForm;
