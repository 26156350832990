// src/store/heroSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';

// Convert timestamps
const convertHeroData = (data) => {
  const converted = { ...data };
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  return converted;
};

// Thunks
export const fetchHeroSlides = createAsyncThunk('hero/fetchAll', async () => {
  try {
    const q = query(collection(db, 'hero_slides'), orderBy('order', 'asc'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) =>
      convertHeroData({
        id: doc.id,
        ...doc.data(),
      })
    );
  } catch (error) {
    throw new Error('Failed to fetch hero slides: ' + error.message);
  }
});

export const addHeroSlide = createAsyncThunk('hero/add', async (slideData) => {
  try {
    const docRef = await addDoc(collection(db, 'hero_slides'), {
      ...slideData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    return {
      id: docRef.id,
      ...slideData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  } catch (error) {
    throw new Error('Failed to add hero slide: ' + error.message);
  }
});

export const updateHeroSlide = createAsyncThunk('hero/update', async ({ id, data }) => {
  try {
    const docRef = doc(db, 'hero_slides', id);
    const updateData = {
      ...data,
      updatedAt: serverTimestamp(),
    };

    await updateDoc(docRef, updateData);
    return { id, ...data };
  } catch (error) {
    throw new Error('Failed to update hero slide: ' + error.message);
  }
});

export const deleteHeroSlide = createAsyncThunk('hero/delete', async (id) => {
  try {
    await deleteDoc(doc(db, 'hero_slides', id));
    return id;
  } catch (error) {
    throw new Error('Failed to delete hero slide: ' + error.message);
  }
});

// Slice
const heroSlice = createSlice({
  name: 'hero',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch slides
      .addCase(fetchHeroSlides.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHeroSlides.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchHeroSlides.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add slide
      .addCase(addHeroSlide.fulfilled, (state, action) => {
        state.items.push(action.payload);
        state.items.sort((a, b) => a.order - b.order);
      })
      // Update slide
      .addCase(updateHeroSlide.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
          state.items.sort((a, b) => a.order - b.order);
        }
      })
      // Delete slide
      .addCase(deleteHeroSlide.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload);
      });
  },
});

export default heroSlice.reducer;
