import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search, Plus } from 'lucide-react';
import { fetchUsers, deleteUser } from '../../../store/userSlice';
import { fetchRoles } from '../../../store/roleSlice';
import { MODULES } from '../../../models/userModels';
import UserList from './UserList';
import UserForm from './UserForm';
import { usePermissions } from '../../../hooks/usePermissions';
import toast from 'react-hot-toast';
import LoadingScreen from '../Layout/LoadingScreen';

const UserManager = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users?.items || []);
  const roles = useSelector((state) => state.roles?.roles || []);
  const status = useSelector((state) => state.users?.status);
  const rolesStatus = useSelector((state) => state.roles?.status);
  const error = useSelector((state) => state.users?.error);
  const currentUser = useSelector((state) => state.auth.user);

  // Lấy quyền cụ thể cho module USERS
  const {
    canCreate: canCreateUsers,
    canUpdate: canUpdateUsers,
    canDelete: canDeleteUsers,
    isSuperAdmin,
  } = usePermissions();

  const hasCreatePermission = canCreateUsers(MODULES.USERS);
  const hasUpdatePermission = canUpdateUsers(MODULES.USERS);
  const hasDeletePermission = canDeleteUsers(MODULES.USERS);

  const [showForm, setShowForm] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchUsers());
    }
    if (rolesStatus === 'idle') {
      dispatch(fetchRoles());
    }
  }, [status, rolesStatus, dispatch]);

  // Kiểm tra quyền quản lý user
  const canManageUser = (user) => {
    // Không thể thao tác với chính mình
    if (user.id === currentUser.id) return false;

    // Chỉ Super Admin mới có thể thao tác với tài khoản Super Admin khác
    const userRole = roles.find((r) => r.id === user.roleId);
    if (userRole?.name === 'Super Admin' && !isSuperAdmin) return false;

    return true;
  };

  const handleAddNew = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền thêm người dùng');
      return;
    }
    setEditingUser(null);
    setShowForm(true);
  };

  const handleEdit = (user) => {
    if (!hasUpdatePermission || !canManageUser(user)) {
      toast.error('Bạn không có quyền chỉnh sửa người dùng này');
      return;
    }
    setEditingUser(user);
    setShowForm(true);
  };

  const handleDelete = async (user) => {
    if (!hasDeletePermission || !canManageUser(user)) {
      toast.error('Bạn không có quyền xóa người dùng này');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa người dùng này?')) {
      try {
        await dispatch(deleteUser(user.id)).unwrap();
        toast.success('Xóa người dùng thành công');
      } catch (error) {
        toast.error('Xóa người dùng thất bại: ' + error.message);
      }
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditingUser(null);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(
    (user) =>
      user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.phone?.includes(searchTerm)
  );

  // Tính toán thống kê người dùng
  const userStats = {
    total: users.length,
    active: users.filter((u) => u.status === 'active').length,
    inactive: users.filter((u) => u.status === 'inactive').length,
    superAdmin: users.filter((u) => {
      const role = roles.find((r) => r.id === u.roleId);
      return role?.name === 'Super Admin';
    }).length,
  };

  if (status === 'loading' || rolesStatus === 'loading') {
    return <LoadingScreen />;
  }

  if (status === 'failed') {
    toast.error(error);
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-600">{error}</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Quản lý người dùng</h1>
          <p className="text-sm text-gray-500 mt-1">
            Quản lý thông tin và phân quyền người dùng trong hệ thống
          </p>
        </div>
        {hasCreatePermission && (
          <button onClick={handleAddNew} className="btn btn-primary flex items-center gap-2">
            <Plus size={20} />
            Thêm người dùng
          </button>
        )}
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Tổng người dùng</div>
          <div className="mt-2 flex items-baseline">
            <div className="text-2xl font-semibold text-gray-900">{userStats.total}</div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Đang hoạt động</div>
          <div className="mt-2 flex items-baseline">
            <div className="text-2xl font-semibold text-green-600">{userStats.active}</div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Không hoạt động</div>
          <div className="mt-2 flex items-baseline">
            <div className="text-2xl font-semibold text-red-600">{userStats.inactive}</div>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-sm font-medium text-gray-500">Super Admin</div>
          <div className="mt-2 flex items-baseline">
            <div className="text-2xl font-semibold text-purple-600">{userStats.superAdmin}</div>
          </div>
        </div>
      </div>

      {/* Search Bar */}
      <div className="bg-white rounded-lg shadow mb-6">
        <div className="p-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Tìm kiếm theo tên, tài khoản, email, số điện thoại..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input pl-10 w-full"
            />
            <Search size={20} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          </div>
        </div>
      </div>

      {/* User List */}
      <div className="bg-white rounded-lg shadow">
        <UserList
          users={filteredUsers}
          onEdit={handleEdit}
          onDelete={handleDelete}
          currentUser={currentUser}
          roles={roles}
          canManageUser={canManageUser}
          hasUpdatePermission={hasUpdatePermission}
          hasDeletePermission={hasDeletePermission}
        />
      </div>

      {/* User Form Modal */}
      {showForm && (
        <UserForm
          user={editingUser}
          onClose={handleCloseForm}
          roles={roles}
          isSuperAdmin={isSuperAdmin}
        />
      )}
    </div>
  );
};

export default UserManager;
