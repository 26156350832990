// src/utils/cloudinary.js

export const uploadToCloudinary = async (file, options = {}) => {
  // Validate file input
  if (!file) {
    throw new Error('No file provided');
  }

  // Default options
  const {
    uploadPreset = 'mapple_store_preset',
    cloudName = 'dc59rpref',
    folder = 'hero-slides', // Organize uploads in folders
    maxFileSize = 10 * 1024 * 1024, // 10MB
    allowedTypes = ['image/jpeg', 'image/png', 'image/webp'],
    transformation = 'q_auto,f_auto', // Auto quality and format
  } = options;

  // Validate file size
  if (file.size > maxFileSize) {
    throw new Error(`File size too large. Maximum size is ${maxFileSize / (1024 * 1024)}MB`);
  }

  // Validate file type
  if (!allowedTypes.includes(file.type)) {
    throw new Error(`Invalid file type. Allowed types are: ${allowedTypes.join(', ')}`);
  }

  // Prepare form data
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', uploadPreset);
  formData.append('cloud_name', cloudName);
  formData.append('folder', folder);

  try {
    // Add timestamp to prevent caching
    const timestamp = new Date().getTime();
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload?_t=${timestamp}`;

    const response = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Upload failed');
    }

    const data = await response.json();

    // Return optimized URL with transformations
    return `${data.secure_url.split('/upload/')[0]}/upload/${transformation}/${data.secure_url.split('/upload/')[1]}`;
  } catch (error) {
    console.error('Cloudinary upload error:', error);
    throw new Error(`Failed to upload image: ${error.message}`);
  }
};

// Usage example in component:
/*
try {
  const imageUrl = await uploadToCloudinary(file, {
    folder: 'products',
    transformation: 'w_800,h_600,c_fill',
    maxFileSize: 5 * 1024 * 1024 // 5MB
  });
  console.log('Upload success:', imageUrl);
} catch (error) {
  console.error('Upload failed:', error.message);
}
*/
