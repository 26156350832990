import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { X, Upload } from 'lucide-react';
import toast from 'react-hot-toast';
import { addPromotion, updatePromotion } from '../../../store/promotionSlice';
import { clearEditingPromotion } from '../../../store/uiSlice';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const ImageUploader = ({ label, dimension, value, onChange, description }) => {
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      // Compress image before upload
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);

      // Convert to base64 for preview
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange({
          file: compressedFile,
          preview: reader.result,
        });
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      toast.error('Lỗi xử lý ảnh: ' + error.message);
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex justify-between">
        <label className="block text-sm font-medium text-gray-700">{label}</label>
        <span className="text-xs text-gray-500">{dimension}</span>
      </div>
      {description && <p className="text-xs text-gray-500">{description}</p>}

      {value?.preview ? (
        <div className="relative group">
          <img src={value.preview} alt={label} className="w-full h-40 object-cover rounded-lg" />
          <button
            type="button"
            onClick={() => onChange(null)}
            className="absolute -top-2 -right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
          >
            <X size={16} />
          </button>
        </div>
      ) : (
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            className="hidden"
            id={`image-${label}`}
          />
          <label htmlFor={`image-${label}`} className="cursor-pointer flex flex-col items-center">
            <Upload className="h-8 w-8 text-gray-400" />
            <span className="mt-2 text-sm text-gray-500">Click để tải ảnh lên</span>
            <span className="mt-1 text-xs text-gray-400">PNG, JPG tối đa 1MB</span>
          </label>
        </div>
      )}
    </div>
  );
};

const CurrencyInput = ({ value, onChange }) => {
  const formatCurrency = (value) => {
    const number = value.replace(/\D/g, '');
    return new Intl.NumberFormat('vi-VN').format(number);
  };

  const parseCurrency = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleChange = (e) => {
    const formatted = formatCurrency(e.target.value);
    const parsed = parseCurrency(e.target.value);
    e.target.value = formatted;
    onChange(Number(parsed));
  };

  return (
    <input
      type="text"
      value={value ? new Intl.NumberFormat('vi-VN').format(value) : ''}
      onChange={handleChange}
      className="input"
      placeholder="Nhập số tiền"
    />
  );
};

const ImageViewerModal = ({ images, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-4xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Hình ảnh khuyến mãi</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-6">
          {Object.entries(images).map(([key, value]) => {
            if (!value?.preview) return null;
            return (
              <div key={key} className="space-y-2">
                <div className="font-medium capitalize">{key}</div>
                <img src={value.preview} alt={key} className="w-full rounded shadow-lg" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const PromotionForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { canCreate, canUpdate } = usePermissions();
  const hasCreatePermission = canCreate(MODULES.PROMOTIONS);
  const hasUpdatePermission = canUpdate(MODULES.PROMOTIONS);

  const editingPromotion = useSelector((state) => state.ui.editingPromotion);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const [images, setImages] = useState(() => {
    if (editingPromotion?.images) {
      return Object.entries(editingPromotion.images).reduce((acc, [key, url]) => {
        acc[key] = url ? { preview: url } : null;
        return acc;
      }, {});
    }
    return {
      banner: null,
      thumbnail: null,
      mobile: null,
      popup: null,
      social: null,
    };
  });

  const [meta, setMeta] = useState(
    editingPromotion?.meta || {
      title: '',
      description: '',
      keywords: '',
    }
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: editingPromotion
      ? {
          ...editingPromotion,
          // Format date string to local datetime-local format
          startDate: editingPromotion.startDate
            ? new Date(editingPromotion.startDate).toISOString().slice(0, 16)
            : '',
          endDate: editingPromotion.endDate
            ? new Date(editingPromotion.endDate).toISOString().slice(0, 16)
            : '',
        }
      : {
          name: '',
          description: '',
          type: 'percentage',
          value: 0,
          min_order_value: 0,
          usage_limit: null,
          used_count: 0,
          priority: 0,
          startDate: '',
          endDate: '',
          isActive: true,
        },
  });

  const promotionType = watch('type');

  const uploadToCloudinary = async (file) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'mapple_store_preset');
    formData.append('cloud_name', 'dc59rpref');

    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/dc59rpref/image/upload`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (!response.ok) throw new Error(data.message || 'Upload failed');
      return data.secure_url;
    } catch (error) {
      throw new Error('Error uploading image: ' + error.message);
    }
  };

  const processAllImages = async (images) => {
    const processedImages = {};
    const uploadPromises = Object.entries(images).map(async ([key, value]) => {
      if (value?.file) {
        const url = await uploadToCloudinary(value.file);
        return [key, url];
      } else if (value?.preview && value.preview.startsWith('http')) {
        return [key, value.preview];
      }
      return [key, null];
    });

    const results = await Promise.all(uploadPromises);
    results.forEach(([key, url]) => {
      if (url) processedImages[key] = url;
    });

    return processedImages;
  };

  const handleClose = () => {
    dispatch(clearEditingPromotion());
    reset();
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const loadingToast = toast.loading('Đang xử lý...');

      const processedImages = await processAllImages(images);

      const formData = {
        ...data,
        images: processedImages,
        meta,
        startDate: new Date(data.startDate).toISOString(),
        endDate: new Date(data.endDate).toISOString(),
        value: Number(data.value),
        min_order_value: Number(data.min_order_value),
        usage_limit: data.usage_limit ? Number(data.usage_limit) : null,
        priority: Number(data.priority),
        used_count: Number(data.used_count || 0),
      };

      if (editingPromotion) {
        if (!hasUpdatePermission) {
          toast.error('Bạn không có quyền cập nhật khuyến mãi');
          return;
        }
        await dispatch(
          updatePromotion({
            id: editingPromotion.id,
            data: formData,
          })
        ).unwrap();
        toast.success('Cập nhật khuyến mãi thành công');
      } else {
        if (!hasCreatePermission) {
          toast.error('Bạn không có quyền tạo khuyến mãi');
          return;
        }
        await dispatch(addPromotion(formData)).unwrap();
        toast.success('Thêm khuyến mãi thành công');
      }

      if (editingPromotion) {
        await dispatch(updatePromotion({ id: editingPromotion.id, data: formData })).unwrap();
        toast.success('Cập nhật khuyến mãi thành công');
      } else {
        const result = await dispatch(addPromotion(formData)).unwrap();
        toast.success('Thêm khuyến mãi thành công');
        navigate(`/admin/promotions/${result.id}/products`);
      }

      toast.dismiss(loadingToast);
      handleClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 pt-[5vh]">
      <div className="bg-white rounded-lg w-full max-w-4xl mx-4 flex flex-col h-[90vh]">
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-bold">
            {editingPromotion ? 'Sửa khuyến mãi' : 'Thêm khuyến mãi mới'}
          </h2>
          <button onClick={handleClose} className="p-1 hover:bg-gray-100 rounded">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-1 overflow-hidden">
          <div className="flex-1 overflow-y-auto p-6">
            <div className="space-y-6">
              {/* Thông tin cơ bản */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tên chương trình
                  </label>
                  <input
                    type="text"
                    {...register('name', { required: 'Vui lòng nhập tên chương trình' })}
                    className="input mt-1"
                  />
                  {errors.name && (
                    <span className="text-sm text-red-500">{errors.name.message}</span>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Code</label>
                  <input
                    type="text"
                    {...register('code')}
                    className="input mt-1"
                    placeholder="Mã khuyến mãi (không bắt buộc)"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Mô tả</label>
                <textarea {...register('description')} rows={3} className="input mt-1" />
              </div>

              {/* Giá trị khuyến mãi */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Loại giảm giá</label>
                  <select {...register('type')} className="input mt-1">
                    <option value="percentage">Theo phần trăm</option>
                    <option value="fixed">Số tiền cố định</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {promotionType === 'percentage' ? 'Phần trăm giảm' : 'Số tiền giảm'}
                  </label>
                  {promotionType === 'percentage' ? (
                    <input
                      type="number"
                      {...register('value', {
                        required: 'Vui lòng nhập giá trị giảm',
                        min: { value: 0, message: 'Giá trị không được âm' },
                        max: { value: 100, message: 'Phần trăm không được vượt quá 100%' },
                      })}
                      className="input mt-1"
                      onFocus={(e) => {
                        if (e.target.value === '0') {
                          e.target.value = '';
                        }
                      }}
                      onBlur={(e) => {
                        if (!e.target.value) {
                          setValue('value', 0);
                        }
                      }}
                    />
                  ) : (
                    <CurrencyInput
                      value={watch('value')}
                      onChange={(value) => setValue('value', value)}
                    />
                  )}
                  {errors.value && (
                    <span className="text-sm text-red-500">{errors.value.message}</span>
                  )}
                </div>
              </div>

              {/* Điều kiện và giới hạn */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Giá trị đơn tối thiểu
                  </label>
                  <CurrencyInput
                    value={watch('min_order_value')}
                    onChange={(value) => setValue('min_order_value', value)}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Giới hạn sử dụng
                  </label>
                  <input
                    type="number"
                    {...register('usage_limit')}
                    className="input mt-1"
                    placeholder="Để trống nếu không giới hạn"
                  />
                </div>
              </div>

              {/* Thời gian */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Thời gian bắt đầu
                  </label>
                  <input
                    type="datetime-local"
                    {...register('startDate', {
                      required: 'Vui lòng chọn thời gian bắt đầu',
                    })}
                    className="input mt-1"
                  />
                  {errors.startDate && (
                    <span className="text-sm text-red-500">{errors.startDate.message}</span>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Thời gian kết thúc
                  </label>
                  <input
                    type="datetime-local"
                    {...register('endDate', {
                      required: 'Vui lòng chọn thời gian kết thúc',
                    })}
                    className="input mt-1"
                  />
                  {errors.endDate && (
                    <span className="text-sm text-red-500">{errors.endDate.message}</span>
                  )}
                </div>
              </div>

              {/* Hình ảnh */}
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="font-medium">Hình ảnh khuyến mãi</h3>
                  <button
                    type="button"
                    onClick={() => setIsImageModalOpen(true)}
                    className="text-sm text-primary-600 hover:text-primary-700"
                  >
                    Xem tất cả ảnh
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <ImageUploader
                    label="Banner chính"
                    dimension="1920x600"
                    value={images.banner}
                    onChange={(value) => setImages({ ...images, banner: value })}
                    description="Banner hiển thị trên desktop"
                  />
                  <ImageUploader
                    label="Thumbnail"
                    dimension="400x400"
                    value={images.thumbnail}
                    onChange={(value) => setImages({ ...images, thumbnail: value })}
                    description="Ảnh thu nhỏ hiển thị trong danh sách"
                  />
                  <ImageUploader
                    label="Banner Mobile"
                    dimension="600x800"
                    value={images.mobile}
                    onChange={(value) => setImages({ ...images, mobile: value })}
                    description="Banner hiển thị trên mobile"
                  />
                  <ImageUploader
                    label="Popup"
                    dimension="800x600"
                    value={images.popup}
                    onChange={(value) => setImages({ ...images, popup: value })}
                    description="Ảnh popup quảng cáo"
                  />
                  <ImageUploader
                    label="Social Share"
                    dimension="1200x630"
                    value={images.social}
                    onChange={(value) => setImages({ ...images, social: value })}
                    description="Ảnh khi chia sẻ lên mạng xã hội"
                  />
                </div>
              </div>

              {/* SEO & Meta */}
              <div className="space-y-4">
                <h3 className="font-medium">Thông tin SEO</h3>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Meta Title</label>
                  <input
                    type="text"
                    value={meta.title}
                    onChange={(e) => setMeta({ ...meta, title: e.target.value })}
                    className="input mt-1"
                    placeholder="Tiêu đề SEO"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Meta Description
                  </label>
                  <textarea
                    value={meta.description}
                    onChange={(e) => setMeta({ ...meta, description: e.target.value })}
                    className="input mt-1"
                    rows={2}
                    placeholder="Mô tả SEO"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Meta Keywords</label>
                  <input
                    type="text"
                    value={meta.keywords}
                    onChange={(e) => setMeta({ ...meta, keywords: e.target.value })}
                    className="input mt-1"
                    placeholder="Từ khóa SEO (phân cách bằng dấu phẩy)"
                  />
                </div>
              </div>

              {/* Cấu hình khác */}
              <div className="space-y-4">
                <h3 className="font-medium">Cấu hình khác</h3>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Độ ưu tiên</label>
                  <input
                    type="number"
                    {...register('priority')}
                    className="input mt-1"
                    placeholder="0 = thấp nhất"
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                    onBlur={(e) => {
                      if (!e.target.value) {
                        setValue('priority', 0);
                      }
                    }}
                  />
                </div>

                <div className="flex items-center gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      {...register('isActive')}
                      className="rounded text-primary-600"
                    />
                    <span className="text-sm font-medium text-gray-700">Kích hoạt</span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="border-t px-6 py-4 bg-gray-50">
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Hủy
              </button>
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                {isSubmitting ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    {editingPromotion ? 'Đang cập nhật...' : 'Đang thêm...'}
                  </>
                ) : editingPromotion ? (
                  'Cập nhật'
                ) : (
                  'Thêm mới'
                )}
              </button>
            </div>
          </div>
        </form>
        <ImageViewerModal
          images={images}
          isOpen={isImageModalOpen}
          onClose={() => setIsImageModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default PromotionForm;
