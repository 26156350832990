// src/client/components/order/OrderStatusTimeline.jsx
import React from 'react';
import { CheckCircle, Clock, Truck, Package, ShoppingBag, RotateCcw, XCircle } from 'lucide-react';

const orderSteps = [
  {
    status: 'pending',
    label: 'Chờ xử lý',
    icon: Clock,
    description: 'Đơn hàng đang chờ xác nhận',
  },
  {
    status: 'processing',
    label: 'Đang xử lý',
    icon: ShoppingBag,
    description: 'Đơn hàng đang được chuẩn bị',
  },
  {
    status: 'shipping',
    label: 'Đang giao',
    icon: Truck,
    description: 'Đơn hàng đang được vận chuyển',
  },
  {
    status: 'delivered',
    label: 'Đã giao',
    icon: CheckCircle,
    description: 'Đơn hàng đã được giao thành công',
  },
];

const getStepIndex = (currentStatus) => {
  if (currentStatus === 'cancelled') return -1;
  if (currentStatus === 'returned') return -2;
  return orderSteps.findIndex((step) => step.status === currentStatus);
};

const OrderStatusTimeline = ({ order }) => {
  const currentStepIndex = getStepIndex(order.status);

  // Nếu đơn hàng đã hủy hoặc hoàn trả
  if (currentStepIndex < 0) {
    const isCancelled = currentStepIndex === -1;
    return (
      <div className="p-4 rounded-lg bg-gray-50">
        <div className="flex items-center gap-3">
          {isCancelled ? (
            <XCircle className="w-8 h-8 text-red-500" />
          ) : (
            <RotateCcw className="w-8 h-8 text-orange-500" />
          )}
          <div>
            <h4 className="font-medium text-gray-900">
              {isCancelled ? 'Đơn hàng đã bị hủy' : 'Đơn hàng đã hoàn trả'}
            </h4>
            {order.status_history?.map(
              (history, index) =>
                history.status === order.status && (
                  <p key={index} className="text-sm text-gray-600 mt-1">
                    Lý do: {history.note}
                  </p>
                )
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="py-4">
      <div className="relative">
        {/* Timeline line */}
        <div className="absolute left-[17px] top-[28px] h-[calc(100%-56px)] w-0.5 bg-gray-200" />

        {/* Steps */}
        <div className="space-y-8">
          {orderSteps.map((step, index) => {
            const isPast = index < currentStepIndex;
            const isCurrent = index === currentStepIndex;
            const StepIcon = step.icon;

            return (
              <div key={step.status} className="relative flex items-start gap-4">
                <div
                  className={`relative z-10 flex h-9 w-9 items-center justify-center rounded-full border-2 
                  ${
                    isPast
                      ? 'border-primary-600 bg-primary-600 text-white'
                      : isCurrent
                        ? 'border-primary-600 bg-white'
                        : 'border-gray-300 bg-white'
                  }`}
                >
                  <StepIcon
                    className={`w-5 h-5 ${
                      isPast ? 'text-white' : isCurrent ? 'text-primary-600' : 'text-gray-400'
                    }`}
                  />
                </div>

                <div className="flex min-w-0 flex-1 flex-col">
                  <div className="flex items-center justify-between gap-4">
                    <p
                      className={`text-sm font-medium ${
                        isPast || isCurrent ? 'text-gray-900' : 'text-gray-500'
                      }`}
                    >
                      {step.label}
                    </p>
                    {(isPast || isCurrent) &&
                      order.status_history?.map(
                        (history, idx) =>
                          history.status === step.status && (
                            <time key={idx} className="text-xs text-gray-500 whitespace-nowrap">
                              {new Date(history.timestamp).toLocaleString('vi-VN', {
                                hour: '2-digit',
                                minute: '2-digit',
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                              })}
                            </time>
                          )
                      )}
                  </div>
                  <p
                    className={`text-sm ${isPast || isCurrent ? 'text-gray-600' : 'text-gray-400'}`}
                  >
                    {step.description}
                  </p>
                  {isCurrent &&
                    order.shipping_info?.tracking_number &&
                    step.status === 'shipping' && (
                      <div className="mt-2 text-sm">
                        <p className="text-gray-600">
                          Mã vận đơn:{' '}
                          <span className="font-medium">{order.shipping_info.tracking_number}</span>
                        </p>
                        {order.shipping_info.provider && (
                          <p className="text-gray-600">
                            Đơn vị vận chuyển:{' '}
                            <span className="font-medium">{order.shipping_info.provider}</span>
                          </p>
                        )}
                      </div>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OrderStatusTimeline;
