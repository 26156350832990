// Validate email format
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Validate password strength
export const validatePassword = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);

  return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumbers;
};

// Validate phone number format
export const validatePhone = (phone) => {
  const re = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;
  return re.test(phone);
};

// Validate required field
export const validateRequired = (value, fieldName) => {
  if (!value || (typeof value === 'string' && !value.trim())) {
    return `${fieldName} là bắt buộc`;
  }
  return '';
};

// Get password strength message
export const getPasswordStrength = (password) => {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (password.length < minLength) {
    return {
      strength: 'weak',
      message: 'Mật khẩu phải có ít nhất 8 ký tự',
      color: 'text-red-500',
    };
  }

  let strength = 0;
  if (hasUpperCase) strength++;
  if (hasLowerCase) strength++;
  if (hasNumbers) strength++;
  if (hasSpecialChar) strength++;

  if (strength <= 2) {
    return {
      strength: 'weak',
      message: 'Mật khẩu yếu',
      color: 'text-red-500',
    };
  } else if (strength === 3) {
    return {
      strength: 'medium',
      message: 'Mật khẩu trung bình',
      color: 'text-yellow-500',
    };
  } else {
    return {
      strength: 'strong',
      message: 'Mật khẩu mạnh',
      color: 'text-green-500',
    };
  }
};
