// src/admin/components/CustomerManager/index.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Search, Plus, FileText } from 'lucide-react';
import CustomerList from './CustomerList';
import CustomerForm from './CustomerForm';
import { fetchCustomers } from '../../../store/userSlice';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';
import StatCard from '../common/StatCard';
import toast from 'react-hot-toast';

const CustomerManager = () => {
  const dispatch = useDispatch();
  const { customers, status } = useSelector((state) => state.users);
  const [showForm, setShowForm] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Kiểm tra quyền quản lý khách hàng
  const {
    canView: canViewCustomers,
    canCreate: canCreateCustomers,
    canUpdate: canUpdateCustomers,
    canDelete: canDeleteCustomers,
  } = usePermissions();

  const hasViewPermission = canViewCustomers(MODULES.CUSTOMERS);
  const hasCreatePermission = canCreateCustomers(MODULES.CUSTOMERS);
  const hasUpdatePermission = canUpdateCustomers(MODULES.CUSTOMERS);
  const hasDeletePermission = canDeleteCustomers(MODULES.CUSTOMERS);

  useEffect(() => {
    if (status === 'idle' && hasViewPermission) {
      dispatch(fetchCustomers());
    }
  }, [status, dispatch, hasViewPermission]);

  // Filter customers based on search term
  const filteredCustomers = customers.filter(
    (customer) =>
      customer.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      customer.phone?.includes(searchTerm)
  );

  const handleAddNew = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền thêm khách hàng');
      return;
    }
    setEditingCustomer(null);
    setShowForm(true);
  };

  const handleEdit = (customer) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền chỉnh sửa khách hàng');
      return;
    }
    setEditingCustomer(customer);
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setEditingCustomer(null);
  };

  // Calculate customer statistics
  const stats = {
    total: customers.length,
    active: customers.filter((c) => c.status === 'active').length,
    totalOrders: customers.reduce((sum, c) => sum + (c.orderCount || 0), 0),
    totalSpent: customers.reduce((sum, c) => sum + (c.totalSpent || 0), 0),
  };

  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý khách hàng</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Quản lý khách hàng</h1>
          <p className="text-sm text-gray-500 mt-1">
            Quản lý thông tin và theo dõi hoạt động của khách hàng
          </p>
        </div>
        {hasCreatePermission && (
          <button onClick={handleAddNew} className="btn btn-primary flex items-center gap-2">
            <Plus size={20} />
            Thêm khách hàng
          </button>
        )}
      </div>

      {/* Statistics */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
        <StatCard
          title="Tổng khách hàng"
          value={stats.total}
          icon={FileText}
          colorClass="bg-primary-500"
        />
        <StatCard
          title="Khách hàng hoạt động"
          value={stats.active}
          icon={FileText}
          colorClass="bg-green-500"
        />
        <StatCard
          title="Tổng đơn hàng"
          value={stats.totalOrders}
          icon={FileText}
          colorClass="bg-blue-500"
        />
        <StatCard
          title="Tổng chi tiêu"
          value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(
            stats.totalSpent
          )}
          icon={FileText}
          colorClass="bg-purple-500"
        />
      </div>

      {/* Search Bar */}
      <div className="bg-white rounded-lg shadow mb-6">
        <div className="p-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Tìm kiếm theo tên, email, số điện thoại..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="input pl-10 w-full"
            />
            <Search size={20} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
          </div>
        </div>
      </div>

      {/* Customer List */}
      <div className="bg-white rounded-lg shadow">
        <CustomerList
          customers={filteredCustomers}
          onEdit={handleEdit}
          hasUpdatePermission={hasUpdatePermission}
          hasDeletePermission={hasDeletePermission}
        />
      </div>

      {/* Customer Form Modal */}
      {showForm && <CustomerForm customer={editingCustomer} onClose={handleCloseForm} />}
    </div>
  );
};

export default CustomerManager;
