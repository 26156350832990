import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../store/categorySlice';
import { toggleCategoryForm } from '../../../store/uiSlice';
import CategoryList from './CategoryList';
import CategoryForm from './CategoryForm';
import { Plus } from 'lucide-react';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const CategoryManager = () => {
  const dispatch = useDispatch();
  const { items: categories, status, error } = useSelector((state) => state.categories);
  const { categoryFormOpen } = useSelector((state) => state.ui);

  const { canView, canCreate } = usePermissions();
  const hasViewPermission = canView(MODULES.CATEGORIES);
  const hasCreatePermission = canCreate(MODULES.CATEGORIES);

  useEffect(() => {
    if (status === 'idle' && hasViewPermission) {
      dispatch(fetchCategories());
    }
  }, [status, dispatch, hasViewPermission]);

  // Thêm loading state
  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  // Thêm error state
  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  const handleAddNew = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền thêm danh mục mới');
      return;
    }
    dispatch(toggleCategoryForm());
  };
  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý danh mục</p>
        </div>
      </div>
    );
  }
  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    toast.error(error);
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Quản lý danh mục</h1>
        <button onClick={handleAddNew} className="btn btn-primary flex items-center gap-2">
          <Plus size={20} />
          Thêm danh mục
        </button>
      </div>

      <div className="bg-white rounded-lg shadow">
        <CategoryList categories={categories} />
      </div>

      {categoryFormOpen && <CategoryForm />}
    </div>
  );
};

export default CategoryManager;
