// Model
// Base Model với các trường common
const BaseModel = {
  id: '',
  isActive: true,
  createdAt: null,
  updatedAt: null,
};

// Spec Field Model - Mô tả một trường thông số
export const SpecFieldModel = {
  key: '', // Định danh trường
  label: '', // Tên hiển thị
  type: 'text', // text, number, select, boolean
  placeholder: '',
  description: '',
  required: false,
  defaultValue: '',
  order: 0, // Thứ tự hiển thị
  options: [], // Cho trường select
  unit: '', // Đơn vị (nếu có) - VD: GB, MHz, etc.
  validation: {
    // Quy tắc validate (tùy chọn)
    min: null,
    max: null,
    pattern: null,
  },
};

// Spec Template Model - Mẫu thông số kỹ thuật
export const SpecTemplateModel = {
  ...BaseModel,
  name: '', // Tên mẫu
  slug: '', // URL friendly name
  type: '', // laptop, pc, monitor, etc.
  description: '', // Mô tả mẫu
  fields: [], // Mảng các SpecFieldModel
  parentId: null, // Template cha (nếu kế thừa)
  version: 1, // Phiên bản template
  tags: [], // Tags để phân loại
};

// Định nghĩa các loại field có thể sử dụng
export const FIELD_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select',
  BOOLEAN: 'boolean',
  TEXTAREA: 'textarea',
  MULTISELECT: 'multiselect',
  URL: 'url',
  EMAIL: 'email',
};

// Định nghĩa các loại thiết bị
export const DEVICE_TYPES = {
  LAPTOP: 'laptop',
  DESKTOP: 'desktop',
  MONITOR: 'monitor',
  STORAGE: 'storage',
  COMPONENT: 'component',
  PERIPHERAL: 'peripheral', // Thiết bị ngoại vi
  NETWORK: 'network', // Thiết bị mạng
  ACCESSORY: 'accessory', // Phụ kiện
};

// Helper function để tạo slug từ text
export const createSlug = (text) => {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[đĐ]/g, 'd')
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '');
};

// Helper function để validate spec value dựa trên field type
export const validateSpecValue = (value, field) => {
  if (field.required && !value) {
    return false;
  }

  switch (field.type) {
    case FIELD_TYPES.NUMBER:
      const num = Number(value);
      if (isNaN(num)) return false;
      if (field.validation.min !== null && num < field.validation.min) return false;
      if (field.validation.max !== null && num > field.validation.max) return false;
      return true;

    case FIELD_TYPES.SELECT:
      return field.options.includes(value);

    case FIELD_TYPES.MULTISELECT:
      return Array.isArray(value) && value.every((v) => field.options.includes(v));

    case FIELD_TYPES.URL:
      try {
        new URL(value);
        return true;
      } catch {
        return false;
      }

    case FIELD_TYPES.EMAIL:
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    default:
      return true;
  }
};
