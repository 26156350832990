// src/client/features/wishlistSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { secureStorage } from '../../utils/cryptoUtils';

// Helper function to load wishlist from storage
const loadWishlistFromStorage = () => {
  try {
    const wishlist = secureStorage.getItem('wishlist');
    return wishlist || { items: [] };
  } catch (error) {
    console.error('Error loading wishlist from storage:', error);
    return { items: [] };
  }
};

// Helper function to save wishlist to storage
const saveWishlistToStorage = (wishlist) => {
  try {
    secureStorage.setItem('wishlist', wishlist);
  } catch (error) {
    console.error('Error saving wishlist to storage:', error);
  }
};

// Initial state
const initialState = {
  ...loadWishlistFromStorage(),
  loading: false,
  error: null,
};

// Add to wishlist
export const addToWishlist = createAsyncThunk(
  'wishlist/add',
  async (product, { getState, rejectWithValue }) => {
    try {
      const { wishlist } = getState();

      // Check if already in wishlist
      if (wishlist.items.some((item) => item.productId === product.id)) {
        throw new Error('Sản phẩm đã có trong danh sách yêu thích');
      }

      const wishlistItem = {
        productId: product.id,
        addedAt: new Date().toISOString(),
      };

      return wishlistItem;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Remove from wishlist
export const removeFromWishlist = createAsyncThunk(
  'wishlist/remove',
  async (productId, { getState, rejectWithValue }) => {
    try {
      const { wishlist } = getState();

      // Check if exists in wishlist
      if (!wishlist.items.some((item) => item.productId === productId)) {
        throw new Error('Sản phẩm không có trong danh sách yêu thích');
      }

      return productId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Clear wishlist
export const clearWishlist = createAsyncThunk('wishlist/clear', async (_, { rejectWithValue }) => {
  try {
    return null;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    setWishlistItems: (state, action) => {
      state.items = action.payload;
      saveWishlistToStorage({ items: state.items });
    },
  },
  extraReducers: (builder) => {
    builder
      // Add to wishlist
      .addCase(addToWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.items.push(action.payload);
        saveWishlistToStorage({ items: state.items });
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Remove from wishlist
      .addCase(removeFromWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeFromWishlist.fulfilled, (state, action) => {
        state.loading = false;
        state.items = state.items.filter((item) => item.productId !== action.payload);
        saveWishlistToStorage({ items: state.items });
      })
      .addCase(removeFromWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Clear wishlist
      .addCase(clearWishlist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(clearWishlist.fulfilled, (state) => {
        state.loading = false;
        state.items = [];
        saveWishlistToStorage({ items: [] });
      })
      .addCase(clearWishlist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setWishlistItems } = wishlistSlice.actions;

// Selectors
export const selectWishlistItems = (state) => state.wishlist.items;
export const selectWishlistLoading = (state) => state.wishlist.loading;
export const selectWishlistError = (state) => state.wishlist.error;
export const selectIsInWishlist = (productId) => (state) =>
  state.wishlist.items.some((item) => item.productId === productId);

export default wishlistSlice.reducer;
