import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setEditingPromotion } from '../../../store/uiSlice';
import { deletePromotion } from '../../../store/promotionSlice';
import { Edit2, Trash2, Package, Tag, Calendar, Clock } from 'lucide-react';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const PromotionList = ({ promotions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { canUpdate, canDelete } = usePermissions();
  const hasUpdatePermission = canUpdate(MODULES.PROMOTIONS);
  const hasDeletePermission = canDelete(MODULES.PROMOTIONS);

  const handleEdit = (promotion) => {
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền chỉnh sửa khuyến mãi');
      return;
    }
    dispatch(setEditingPromotion(promotion));
  };

  const handleDelete = async (promotion) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa khuyến mãi');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa khuyến mãi này?')) {
      try {
        await dispatch(deletePromotion(promotion.id)).unwrap();
        toast.success('Xóa khuyến mãi thành công');
      } catch (error) {
        toast.error('Xóa khuyến mãi thất bại: ' + error.message);
      }
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('vi-VN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(value);
  };

  const getPromotionStatus = (promotion) => {
    const now = new Date();
    const startDate = new Date(promotion.startDate);
    const endDate = new Date(promotion.endDate);

    if (!promotion.isActive) return { text: 'Vô hiệu hóa', color: 'bg-gray-100 text-gray-800' };
    if (now < startDate) return { text: 'Sắp diễn ra', color: 'bg-yellow-100 text-yellow-800' };
    if (now > endDate) return { text: 'Đã kết thúc', color: 'bg-red-100 text-red-800' };
    return { text: 'Đang diễn ra', color: 'bg-green-100 text-green-800' };
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Chương trình
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Thời gian
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Giảm giá
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Trạng thái
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Sản phẩm
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {promotions.map((promotion) => {
            const status = getPromotionStatus(promotion);
            return (
              <tr key={promotion.id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="flex items-center space-x-4">
                    {promotion.images?.thumbnail ? (
                      <img
                        src={promotion.images.thumbnail}
                        alt={promotion.name}
                        className="w-12 h-12 rounded-lg object-cover"
                      />
                    ) : (
                      <div className="w-12 h-12 rounded-lg bg-gray-100 flex items-center justify-center">
                        <Tag className="w-6 h-6 text-gray-400" />
                      </div>
                    )}
                    <div>
                      <div className="font-medium text-gray-900">{promotion.name}</div>
                      <div className="text-sm text-gray-500 mt-1">
                        {promotion.code && (
                          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                            {promotion.code}
                          </span>
                        )}
                      </div>
                      {promotion.description && (
                        <div className="text-sm text-gray-500 mt-1">{promotion.description}</div>
                      )}
                    </div>
                  </div>
                </td>

                <td className="px-6 py-4">
                  <div className="text-sm space-y-1">
                    <div className="flex items-center text-gray-600">
                      <Calendar className="w-4 h-4 mr-1" />
                      {formatDate(promotion.startDate)}
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Clock className="w-4 h-4 mr-1" />
                      {formatDate(promotion.endDate)}
                    </div>
                  </div>
                </td>

                <td className="px-6 py-4">
                  <div className="text-sm">
                    <div className="font-medium">
                      {promotion.type === 'percentage' ? (
                        <span>{promotion.value}%</span>
                      ) : (
                        <span>{formatCurrency(promotion.value)}</span>
                      )}
                    </div>
                    {promotion.min_order_value > 0 && (
                      <div className="text-gray-500 text-xs mt-1">
                        Đơn tối thiểu: {formatCurrency(promotion.min_order_value)}
                      </div>
                    )}
                    {promotion.usage_limit && (
                      <div className="text-gray-500 text-xs mt-1">
                        Giới hạn: {promotion.used_count}/{promotion.usage_limit}
                      </div>
                    )}
                  </div>
                </td>

                <td className="px-6 py-4">
                  <span
                    className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${status.color}`}
                  >
                    {status.text}
                  </span>
                </td>

                <td className="px-6 py-4 text-center">
                  <div className="flex flex-col items-center gap-2">
                    <span className="text-sm text-gray-500">
                      {promotion.productCount || 0} sản phẩm
                    </span>
                    <button
                      onClick={() => navigate(`/admin/promotions/${promotion.id}/products`)}
                      className="btn btn-secondary btn-sm inline-flex items-center gap-2"
                    >
                      <Package size={16} />
                      Quản lý
                    </button>
                  </div>
                </td>

                <td className="px-6 py-4 text-center">
                  <div className="flex items-center justify-center space-x-3">
                    <button
                      onClick={() => handleEdit(promotion)}
                      className="text-indigo-600 hover:text-indigo-900"
                      title="Sửa"
                    >
                      <Edit2 size={16} />
                    </button>
                    <button
                      onClick={() => handleDelete(promotion)}
                      className="text-red-600 hover:text-red-900"
                      title="Xóa"
                    >
                      <Trash2 size={16} />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}

          {promotions.length === 0 && (
            <tr>
              <td colSpan={6} className="px-6 py-8 text-center text-gray-500">
                Chưa có chương trình khuyến mãi nào
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PromotionList;
