import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShoppingCart } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { toggleCart } from '../../features/cartSlice';

const CartButton = () => {
  const dispatch = useDispatch();
  const { itemCount } = useSelector((state) => state.cart);

  return (
    <button
      onClick={() => dispatch(toggleCart())}
      className="relative flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100 transition-colors"
    >
      <ShoppingCart size={24} />
      <AnimatePresence>
        {itemCount > 0 && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="absolute -top-1 -right-1 w-5 h-5 bg-primary-600 text-white text-xs 
              font-medium rounded-full flex items-center justify-center"
          >
            {itemCount}
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
};

export default CartButton;
