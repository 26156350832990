// src/admin/components/OrderManager/OrderDetailModal/components/ShippingForm.jsx
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { SHIPPING_PROVIDERS } from '../../constants';
import { formatCurrency } from '../../utils';
import CurrencyInput from '../../../common/CurrencyInput';
import { updateOrderTotal } from '../../../../../store/orderSlice';
import { useDispatch } from 'react-redux';

export const ShippingForm = ({ order, onUpdateShipping, isSubmitting, canUpdate }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [shippingInfo, setShippingInfo] = useState(() => ({
    provider: order.shipping_info?.provider || '',
    tracking_number: order.shipping_info?.tracking_number || '',
    fee: order.shipping_info?.fee || 0,
    note: order.shipping_info?.note || '',
    estimated_delivery: order.shipping_info?.estimated_delivery || null,
  }));
  // Thêm useEffect để cập nhật state khi order thay đổi
  useEffect(() => {
    if (order.shipping_info) {
      setShippingInfo({
        provider: order.shipping_info.provider || '',
        tracking_number: order.shipping_info.tracking_number || '',
        fee: order.shipping_info.fee || 0,
        note: order.shipping_info.note || '',
        estimated_delivery: order.shipping_info.estimated_delivery || null,
      });
    }
  }, [order.shipping_info]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onUpdateShipping({
        ...shippingInfo,
        fee: Number(shippingInfo.fee),
        updated_at: new Date().toISOString(),
      });

      // Update order total after shipping fee change
      const newTotal = order.subtotal - order.discount + Number(shippingInfo.fee);
      await dispatch(
        updateOrderTotal({
          orderId: order.id,
          total: newTotal,
          shipping_fee: Number(shippingInfo.fee),
        })
      );
      setIsEditing(false);
    } catch (error) {
      console.error('Shipping update failed:', error);
    }
  };

  if (!isEditing) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Thông tin vận chuyển</h3>
          {!['delivered', 'cancelled', 'returned'].includes(order.status) && canUpdate && (
            <button
              onClick={() => setIsEditing(true)}
              className="btn btn-secondary btn-sm"
              disabled={isSubmitting}
            >
              Cập nhật
            </button>
          )}
        </div>

        {order.shipping_info ? (
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="text-sm text-gray-500">Đơn vị vận chuyển</label>
              <p className="font-medium">
                {
                  SHIPPING_PROVIDERS.find((opt) => opt.value === order.shipping_info.provider)
                    ?.label
                }
              </p>
            </div>
            <div>
              <label className="text-sm text-gray-500">Mã vận đơn</label>
              <p className="font-medium">{order.shipping_info.tracking_number}</p>
            </div>
            {order.shipping_info.fee > 0 && (
              <div>
                <label className="text-sm text-gray-500">Phí vận chuyển</label>
                <p className="font-medium">{formatCurrency(order.shipping_info.fee)}</p>
              </div>
            )}
            {order.shipping_info.estimated_delivery && (
              <div>
                <label className="text-sm text-gray-500">Ngày giao dự kiến</label>
                <p className="font-medium">
                  {new Date(order.shipping_info.estimated_delivery).toLocaleString()}
                </p>
              </div>
            )}
            {order.shipping_info.note && (
              <div className="col-span-2">
                <label className="text-sm text-gray-500">Ghi chú</label>
                <p className="font-medium">{order.shipping_info.note}</p>
              </div>
            )}
          </div>
        ) : (
          <p className="text-gray-500">Chưa có thông tin vận chuyển</p>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h3 className="text-lg font-medium">Cập nhật thông tin vận chuyển</h3>

      <div>
        <label className="block text-sm font-medium mb-1">
          Đơn vị vận chuyển <span className="text-red-500">*</span>
        </label>
        <Select
          value={SHIPPING_PROVIDERS.find((opt) => opt.value === shippingInfo.provider)}
          onChange={(opt) => setShippingInfo((prev) => ({ ...prev, provider: opt.value }))}
          options={SHIPPING_PROVIDERS}
          isDisabled={isSubmitting}
          className="w-full"
          classNamePrefix="select"
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">
          Mã vận đơn <span className="text-red-500">*</span>
        </label>
        <input
          type="text"
          value={shippingInfo.tracking_number}
          onChange={(e) =>
            setShippingInfo((prev) => ({ ...prev, tracking_number: e.target.value }))
          }
          className="input w-full"
          disabled={isSubmitting}
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Phí vận chuyển</label>
        <CurrencyInput
          value={shippingInfo.fee}
          onChange={(value) => setShippingInfo((prev) => ({ ...prev, fee: value }))}
          className="w-full"
          disabled={isSubmitting}
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Ngày giao dự kiến</label>
        <input
          type="datetime-local"
          value={shippingInfo.estimated_delivery || ''}
          onChange={(e) =>
            setShippingInfo((prev) => ({
              ...prev,
              estimated_delivery: e.target.value,
            }))
          }
          className="input w-full"
          disabled={isSubmitting}
        />
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">Ghi chú</label>
        <textarea
          value={shippingInfo.note || ''}
          onChange={(e) => setShippingInfo((prev) => ({ ...prev, note: e.target.value }))}
          className="input w-full"
          rows={3}
          placeholder="Nhập ghi chú vận chuyển..."
          disabled={isSubmitting}
        />
      </div>

      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={() => setIsEditing(false)}
          className="btn btn-secondary"
          disabled={isSubmitting}
        >
          Hủy
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          disabled={isSubmitting || !shippingInfo.provider || !shippingInfo.tracking_number}
        >
          Lưu thay đổi
        </button>
      </div>
    </form>
  );
};
