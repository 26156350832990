import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  getDoc,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { DEFAULT_SPECS } from '../models/defaultSpecs';

// Cải thiện hàm convert timestamps
const convertTimestamps = (data) => {
  if (!data) return data;

  const converted = { ...data };
  const timestampFields = ['createdAt', 'updatedAt', 'startDate', 'endDate'];

  timestampFields.forEach((field) => {
    if (converted[field]?.toDate) {
      converted[field] = converted[field].toDate().toISOString();
    } else if (
      converted[field] &&
      !(converted[field] instanceof Date) &&
      !isNaN(new Date(converted[field]))
    ) {
      converted[field] = new Date(converted[field]).toISOString();
    }
  });

  return converted;
};

// Format dữ liệu trước khi lưu
const formatDataForSave = (data) => {
  const formatted = { ...data };

  // Đảm bảo fields luôn là array và có order
  if (formatted.fields) {
    formatted.fields = formatted.fields.map((field, index) => ({
      ...field,
      order: index,
    }));
  }

  // Loại bỏ các trường không cần thiết
  delete formatted.id;
  delete formatted.status;

  return formatted;
};

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  activeTemplate: null,
};

// Cập nhật thunks
export const fetchSpecTemplates = createAsyncThunk(
  'specTemplates/fetchAll',
  async (_, { rejectWithValue }) => {
    try {
      const querySnapshot = await getDocs(collection(db, 'spec_templates'));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...convertTimestamps(doc.data()),
      }));
    } catch (error) {
      return rejectWithValue('Failed to fetch spec templates: ' + error.message);
    }
  }
);

export const addSpecTemplate = createAsyncThunk(
  'specTemplates/add',
  async (templateData, { rejectWithValue }) => {
    try {
      const formattedData = formatDataForSave(templateData);
      const saveData = {
        ...formattedData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const docRef = await addDoc(collection(db, 'spec_templates'), saveData);
      const newDoc = await getDoc(docRef);

      if (!newDoc.exists()) {
        throw new Error('Failed to create template');
      }

      return {
        id: docRef.id,
        ...convertTimestamps(newDoc.data()),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateSpecTemplate = createAsyncThunk(
  'specTemplates/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const formattedData = formatDataForSave(data);
      const updateData = {
        ...formattedData,
        updatedAt: serverTimestamp(),
      };

      const docRef = doc(db, 'spec_templates', id);
      await updateDoc(docRef, updateData);

      const updatedDoc = await getDoc(docRef);
      if (!updatedDoc.exists()) {
        throw new Error('Template not found after update');
      }

      return {
        id,
        ...convertTimestamps(updatedDoc.data()),
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thêm action kiểm tra dependencies
export const checkTemplateDependencies = createAsyncThunk(
  'specTemplates/checkDependencies',
  async (templateId) => {
    const productsQuery = query(
      collection(db, 'product_specs'),
      where('templateId', '==', templateId)
    );
    const snapshot = await getDocs(productsQuery);
    return {
      hasProducts: !snapshot.empty,
      productCount: snapshot.size,
    };
  }
);

// Action di chuyển sản phẩm sang template mới
export const migrateProducts = createAsyncThunk(
  'specTemplates/migrateProducts',
  async ({ oldTemplateId, newTemplateId }, { getState }) => {
    const { specTemplates } = getState();
    const newTemplate = specTemplates.items.find((t) => t.id === newTemplateId);

    // Lấy danh sách sản phẩm cần migrate
    const productsQuery = query(
      collection(db, 'product_specs'),
      where('templateId', '==', oldTemplateId)
    );
    const snapshot = await getDocs(productsQuery);

    // Tạo batch để update nhiều document
    const batch = writeBatch(db);

    snapshot.docs.forEach((doc) => {
      const oldSpecs = doc.data().specs;
      const newSpecs = {};

      // Map các giá trị từ template cũ sang mới
      newTemplate.fields.forEach((field) => {
        newSpecs[field.key] = oldSpecs[field.key] || field.defaultValue || '';
      });

      batch.update(doc.ref, {
        templateId: newTemplateId,
        specs: newSpecs,
        updatedAt: serverTimestamp(),
      });
    });

    await batch.commit();
    return { oldTemplateId, newTemplateId, count: snapshot.size };
  }
);

// Action xóa template
export const deleteSpecTemplate = createAsyncThunk(
  'specTemplates/delete',
  async (templateId, { dispatch, rejectWithValue }) => {
    try {
      // Kiểm tra dependencies trước
      const { hasProducts } = await dispatch(checkTemplateDependencies(templateId)).unwrap();

      if (hasProducts) {
        throw new Error('Template đang được sử dụng bởi sản phẩm');
      }

      // Nếu không có dependencies thì xóa
      await deleteDoc(doc(db, 'spec_templates', templateId));
      return templateId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const importDefaultSpecs = createAsyncThunk(
  'specTemplates/importDefaults',
  async (_, { rejectWithValue }) => {
    try {
      const importPromises = DEFAULT_SPECS.map(async (spec) => {
        const formattedSpec = formatDataForSave(spec);
        const saveData = {
          ...formattedSpec,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        const docRef = await addDoc(collection(db, 'spec_templates'), saveData);
        const newDoc = await getDoc(docRef);

        return {
          id: docRef.id,
          ...convertTimestamps(newDoc.data()),
        };
      });

      const results = await Promise.all(importPromises);
      return results;
    } catch (error) {
      return rejectWithValue('Failed to import default specs: ' + error.message);
    }
  }
);

const specTemplateSlice = createSlice({
  name: 'specTemplates',
  initialState,
  reducers: {
    setActiveTemplate: (state, action) => {
      state.activeTemplate = action.payload;
    },
    clearActiveTemplate: (state) => {
      state.activeTemplate = null;
    },
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch all
      .addCase(fetchSpecTemplates.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchSpecTemplates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchSpecTemplates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Add
      .addCase(addSpecTemplate.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(addSpecTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(action.payload);
      })
      .addCase(addSpecTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Update
      .addCase(updateSpecTemplate.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateSpecTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateSpecTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Delete
      .addCase(deleteSpecTemplate.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(deleteSpecTemplate.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = state.items.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteSpecTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Import defaults
      .addCase(importDefaultSpecs.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(importDefaultSpecs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(...action.payload);
      })
      .addCase(importDefaultSpecs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setActiveTemplate, clearActiveTemplate, resetStatus } = specTemplateSlice.actions;
export default specTemplateSlice.reducer;
