import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus } from 'lucide-react';
import { fetchOrders } from '../../../store/orderSlice';
import { fetchProducts } from '../../../store/productSlice';
import OrderStats from './components/OrderStats';
import OrderFilters from './components/OrderFilters';
import OrderList from './components/OrderList';
import CreateOrderModal from './components/CreateOrder';
import OrderDetailModal from './OrderDetailModal';
import LoadingScreen from '../Layout/LoadingScreen';
import { useOrderPermissions } from '../../../hooks/useOrderPermissions';
import { useOrderActions } from './hooks/useOrderActions';

const OrderManager = () => {
  const dispatch = useDispatch();

  // Selectors
  const {
    items: orders,
    status: orderStatus,
    error: orderError,
  } = useSelector((state) => state.orders);

  const { items: products, status: productStatus } = useSelector((state) => state.products);

  // Permissions
  const { hasViewPermission, hasCreatePermission, hasUpdatePermission } = useOrderPermissions();

  // Actions & States
  const {
    showCreateModal,
    showDetailModal,
    selectedOrder,
    isSubmitting,
    handleCreateOrder,
    handleShowCreateModal,
    handleCloseCreateModal,
    handleShowDetailModal,
    handleCloseDetailModal,
    handleUpdateOrderStatus,
    handleUpdateCustomer,
    handleUpdatePayment,
    handleUpdateShipping,
  } = useOrderActions();

  // Initial Data Fetching
  useEffect(() => {
    if (hasViewPermission) {
      if (orderStatus === 'idle') {
        dispatch(fetchOrders());
      }
      if (productStatus === 'idle') {
        dispatch(fetchProducts());
      }
    }
  }, [dispatch, hasViewPermission, orderStatus, productStatus]);

  // Loading States
  const isLoading = orderStatus === 'loading' || productStatus === 'loading';
  const hasError = orderStatus === 'failed' || productStatus === 'failed';

  // Permission Check
  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý đơn hàng</p>
        </div>
      </div>
    );
  }

  // Loading Screen
  if (isLoading && (!orders.length || !products.length)) {
    return <LoadingScreen />;
  }

  // Error Screen
  if (hasError) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-600">
          {orderError || 'Có lỗi xảy ra khi tải dữ liệu'}
        </h2>
        <button
          onClick={() => {
            dispatch(fetchOrders());
            dispatch(fetchProducts());
          }}
          className="mt-4 btn btn-primary"
        >
          Thử lại
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-[1600px] mx-auto px-4 sm:px-6 lg:px-8 py-6">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Quản lý đơn hàng</h1>
          <p className="text-sm text-gray-500 mt-1">Quản lý và theo dõi đơn hàng trong hệ thống</p>
        </div>

        {hasCreatePermission && (
          <button
            onClick={handleShowCreateModal}
            className="btn btn-primary flex items-center gap-2"
            disabled={isSubmitting}
          >
            <Plus className="w-5 h-5" />
            Tạo đơn hàng
          </button>
        )}
      </div>

      {/* Stats Dashboard */}
      <div className="mb-6">
        <OrderStats orders={orders} />
      </div>

      {/* Filters Section */}
      <div className="mb-6">
        <OrderFilters />
      </div>

      {/* Orders List */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <OrderList
          orders={orders}
          onViewDetail={handleShowDetailModal}
          onUpdateStatus={handleUpdateOrderStatus}
          isSubmitting={isSubmitting}
          canUpdate={hasUpdatePermission}
        />
      </div>

      {/* Create Order Modal */}
      {showCreateModal && (
        <CreateOrderModal
          onClose={handleCloseCreateModal}
          onCreateOrder={handleCreateOrder}
          products={products}
          isSubmitting={isSubmitting}
        />
      )}

      {/* Order Detail Modal */}
      {showDetailModal && selectedOrder && (
        <OrderDetailModal
          order={selectedOrder}
          onClose={handleCloseDetailModal}
          onUpdateStatus={handleUpdateOrderStatus}
          onUpdateShipping={handleUpdateShipping}
          onUpdatePayment={handleUpdatePayment}
          onUpdateCustomer={handleUpdateCustomer}
          isSubmitting={isSubmitting}
          canUpdate={hasUpdatePermission}
        />
      )}
    </div>
  );
};

export default OrderManager;
