import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Eye, EyeOff, Shield, Lock, Mail, AlertTriangle } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { getPasswordStrength } from '../../../utils/validation.js';
import { changePassword } from '../../../features/authSlice.js';

const SecurityTab = ({ user }) => {
  const dispatch = useDispatch();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      toast.error('Mật khẩu xác nhận không khớp');
      return;
    }

    const passwordStrength = getPasswordStrength(formData.newPassword);
    if (passwordStrength.strength === 'weak') {
      toast.error(passwordStrength.message);
      return;
    }

    try {
      setLoading(true);
      await dispatch(
        changePassword({
          userId: user.id,
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        })
      ).unwrap();

      toast.success('Đổi mật khẩu thành công');
      setFormData({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    } catch (error) {
      toast.error(error.message || 'Có lỗi xảy ra khi đổi mật khẩu');
    } finally {
      setLoading(false);
    }
  };

  const passwordStrength = formData.newPassword ? getPasswordStrength(formData.newPassword) : null;

  return (
    <div>
      <h2 className="text-xl font-semibold mb-6">Bảo mật tài khoản</h2>

      <div className="space-y-8">
        {/* Account Info Section */}
        <div className="bg-white rounded-lg border p-6">
          <h3 className="text-lg font-medium mb-4">Thông tin đăng nhập</h3>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-primary-50 text-primary-600 rounded-lg">
                  <Mail size={20} />
                </div>
                <div>
                  <div className="font-medium">Email</div>
                  <div className="text-sm text-gray-500">{user.email}</div>
                </div>
              </div>
              <button className="text-primary-600 hover:text-primary-700 font-medium">
                Thay đổi
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-primary-50 text-primary-600 rounded-lg">
                  <Lock size={20} />
                </div>
                <div>
                  <div className="font-medium">Mật khẩu</div>
                  <div className="text-sm text-gray-500">••••••••</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Change Password Form */}
        <div className="bg-white rounded-lg border p-6">
          <div className="flex items-center gap-3 mb-6">
            <Shield className="w-6 h-6 text-primary-600" />
            <h3 className="text-lg font-medium">Đổi mật khẩu</h3>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Old Password */}
            <div>
              <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Mật khẩu hiện tại
              </label>
              <div className="relative">
                <input
                  type={showOldPassword ? 'text' : 'password'}
                  id="oldPassword"
                  value={formData.oldPassword}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, oldPassword: e.target.value }))
                  }
                  className="w-full pl-4 pr-10 py-2 rounded-lg border focus:ring-2 focus:ring-primary-500"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  {showOldPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            {/* New Password */}
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700 mb-1">
                Mật khẩu mới
              </label>
              <div className="relative">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  value={formData.newPassword}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, newPassword: e.target.value }))
                  }
                  className="w-full pl-4 pr-10 py-2 rounded-lg border focus:ring-2 focus:ring-primary-500"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  {showNewPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              {passwordStrength && (
                <div className={`text-sm mt-1 ${passwordStrength.color}`}>
                  {passwordStrength.message}
                </div>
              )}
            </div>

            {/* Confirm Password */}
            <div>
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Xác nhận mật khẩu mới
              </label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, confirmPassword: e.target.value }))
                  }
                  className="w-full pl-4 pr-10 py-2 rounded-lg border focus:ring-2 focus:ring-primary-500"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
              {formData.confirmPassword && formData.newPassword !== formData.confirmPassword && (
                <div className="text-sm text-red-500 mt-1">Mật khẩu xác nhận không khớp</div>
              )}
            </div>

            {/* Password Requirements */}
            <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
              <div className="flex items-center gap-2 text-yellow-800 mb-2">
                <AlertTriangle size={20} />
                <span className="font-medium">Yêu cầu mật khẩu</span>
              </div>
              <ul className="list-disc list-inside text-sm text-yellow-700 space-y-1">
                <li>Ít nhất 8 ký tự</li>
                <li>Bao gồm chữ hoa và chữ thường</li>
                <li>Ít nhất 1 số</li>
                <li>Không chứa thông tin cá nhân dễ đoán</li>
              </ul>
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={loading}
                className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 
                  transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
              >
                {loading ? 'Đang cập nhật...' : 'Đổi mật khẩu'}
              </button>
            </div>
          </form>
        </div>

        {/* Security Log */}
        <div className="bg-white rounded-lg border p-6">
          <h3 className="text-lg font-medium mb-4">Lịch sử hoạt động</h3>

          <div className="space-y-4">
            <div className="text-sm text-gray-500">
              Đăng nhập gần đây:{' '}
              {user.lastLoginAt
                ? new Date(user.lastLoginAt).toLocaleString('vi-VN')
                : 'Chưa có thông tin'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecurityTab;
