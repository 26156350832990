import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from '../../../store/productSlice';
import { fetchSpecTemplates } from '../../../store/specTemplateSlice';
import { clearEditingProduct, toggleProductForm } from '../../../store/uiSlice';
import ProductList from './ProductList';
import ProductForm from './ProductForm';
import { Plus, Search, Filter } from 'lucide-react';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const ProductManager = () => {
  const dispatch = useDispatch();
  const productsState = useSelector((state) => state.products);
  const specTemplatesState = useSelector((state) => state.specTemplates);
  const { productFormOpen } = useSelector((state) => state.ui);

  const { canView, canCreate } = usePermissions();
  const hasViewPermission = canView(MODULES.PRODUCTS);
  const hasCreatePermission = canCreate(MODULES.PRODUCTS);

  // Local state for search and filter
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActive, setFilterActive] = useState('all'); // 'all' | 'active' | 'inactive'

  const { items: products, status, error } = productsState;

  useEffect(() => {
    if (status === 'idle' && hasViewPermission) {
      dispatch(fetchProducts());
    }
    if (specTemplatesState.status === 'idle') {
      dispatch(fetchSpecTemplates());
    }
  }, [status, specTemplatesState.status, dispatch, hasViewPermission]);

  const handleAddNew = () => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền thêm sản phẩm mới');
      return;
    }
    dispatch(clearEditingProduct());
    if (specTemplatesState.items.length === 0) {
      toast.error('Vui lòng tạo mẫu thông số kỹ thuật trước khi thêm sản phẩm');
      return;
    }
    dispatch(toggleProductForm());
  };
  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý sản phẩm</p>
        </div>
      </div>
    );
  }
  // Filter products
  const filteredProducts = products.filter((product) => {
    const matchSearch =
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.sku?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.tags?.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchFilter =
      filterActive === 'all'
        ? true
        : filterActive === 'active'
          ? product.isActive
          : !product.isActive;

    return matchSearch && matchFilter;
  });

  if (status === 'loading') {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="p-8">
        <div className="text-red-500 text-center">
          Có lỗi xảy ra: {error}
          <button onClick={() => dispatch(fetchProducts())} className="btn btn-primary mt-4">
            Thử lại
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Quản lý sản phẩm</h1>
        <button onClick={handleAddNew} className="btn btn-primary flex items-center gap-2">
          <Plus size={20} />
          Thêm sản phẩm
        </button>
      </div>

      {/* Search and Filter */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="flex gap-4">
          <div className="flex-1">
            <div className="relative">
              <input
                type="text"
                placeholder="Tìm kiếm theo tên, SKU, tag..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="input pl-10"
              />
              <Search
                size={20}
                className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
              />
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Filter size={20} className="text-gray-400" />
            <select
              value={filterActive}
              onChange={(e) => setFilterActive(e.target.value)}
              className="input min-w-[150px]"
            >
              <option value="all">Tất cả</option>
              <option value="active">Đang bán</option>
              <option value="inactive">Ngừng bán</option>
            </select>
          </div>
        </div>
      </div>

      {/* Product List */}
      <div className="bg-white rounded-lg shadow">
        <ProductList products={filteredProducts} />
      </div>

      {/* Product Form Modal */}
      {productFormOpen && <ProductForm />}
    </div>
  );
};

export default ProductManager;
