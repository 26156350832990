import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOrderPermissions } from '../../../../hooks/useOrderPermissions';
import { ORDER_STATUS, PAYMENT_STATUS } from '../constants';
import {
  addOrder,
  updateOrderStatus,
  updatePaymentStatus,
  updateShippingInfo,
  updateCustomerInfo,
  fetchOrders,
} from '../../../../store/orderSlice';
import toast from 'react-hot-toast';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebase/config';

export const getOrderDetails = async (orderId) => {
  const docRef = doc(db, 'orders', orderId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    throw new Error('Order not found');
  }
  return { id: docSnap.id, ...docSnap.data() };
};

export const useOrderActions = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);

  const { hasCreatePermission, hasUpdatePermission, hasDeletePermission, checkPermission } =
    useOrderPermissions();

  // Create Order
  const handleCreateOrder = async (orderData) => {
    if (!checkPermission('create')) return;

    try {
      setIsSubmitting(true);

      if (!orderData.items?.length) {
        throw new Error('Vui lòng thêm sản phẩm vào đơn hàng');
      }

      const newOrder = await dispatch(addOrder(orderData)).unwrap();

      // Update orders list immediately
      dispatch(fetchOrders());

      toast.success('Tạo đơn hàng thành công');
      setShowCreateModal(false);
      return newOrder;
    } catch (error) {
      toast.error(`Lỗi tạo đơn hàng: ${error.message}`);
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update Order Status
  const handleUpdateOrderStatus = async (orderId, newStatus, note) => {
    if (!checkPermission('update')) return;

    try {
      setIsSubmitting(true);

      const orderDetails = await getOrderDetails(orderId);

      // Validate shipping info trước khi chuyển sang trạng thái shipping
      if (newStatus === ORDER_STATUS.SHIPPING && !orderDetails?.shipping_info?.tracking_number) {
        toast.error('Vui lòng cập nhật thông tin vận chuyển trước');
        return;
      }

      // Validate ghi chú khi hủy hoặc trả hàng
      if ([ORDER_STATUS.CANCELLED, ORDER_STATUS.RETURNED].includes(newStatus) && !note?.trim()) {
        toast.error(
          `Vui lòng nhập lý do ${newStatus === ORDER_STATUS.CANCELLED ? 'hủy' : 'trả'} đơn hàng`
        );
        return;
      }

      // Cập nhật trạng thái đơn hàng
      const result = await dispatch(
        updateOrderStatus({
          orderId,
          status: newStatus,
          note: note?.trim(),
        })
      ).unwrap();

      // Xử lý tự động cập nhật trạng thái thanh toán
      if ([ORDER_STATUS.CANCELLED, ORDER_STATUS.RETURNED].includes(newStatus)) {
        if (selectedOrder.payment_status === PAYMENT_STATUS.PAID) {
          // Nếu đã thanh toán -> chuyển sang chờ hoàn tiền
          await dispatch(
            updatePaymentStatus({
              orderId,
              status: PAYMENT_STATUS.PENDING_REFUND,
              note: `Tự động chuyển sang chờ hoàn tiền do đơn hàng ${
                newStatus === ORDER_STATUS.CANCELLED ? 'bị hủy' : 'được trả lại'
              }`,
            })
          ).unwrap();

          // Cập nhật local state
          setSelectedOrder((prev) => ({
            ...prev,
            ...result,
            payment_status: PAYMENT_STATUS.PENDING_REFUND,
            payment_history: [
              ...(prev.payment_history || []),
              {
                status: PAYMENT_STATUS.PENDING_REFUND,
                note: `Tự động chuyển sang chờ hoàn tiền do đơn hàng ${
                  newStatus === ORDER_STATUS.CANCELLED ? 'bị hủy' : 'được trả lại'
                }`,
                timestamp: new Date().toISOString(),
              },
            ],
          }));
        } else {
          // Nếu chưa thanh toán -> giữ nguyên trạng thái thanh toán
          setSelectedOrder((prev) => ({
            ...prev,
            ...result,
          }));
        }
      } else {
        // Các trường hợp khác -> chỉ cập nhật trạng thái đơn hàng
        setSelectedOrder((prev) => ({
          ...prev,
          ...result,
        }));
      }

      toast.success('Cập nhật trạng thái đơn hàng thành công');
    } catch (error) {
      console.error('Update order status error:', error);
      toast.error(error.message || 'Lỗi cập nhật trạng thái đơn hàng');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update Payment Status
  const handleUpdatePayment = async (orderId, newStatus, note) => {
    if (!checkPermission('update')) return;

    try {
      setIsSubmitting(true);

      const result = await dispatch(
        updatePaymentStatus({
          orderId,
          status: newStatus,
          note: note.trim(),
        })
      ).unwrap();

      setSelectedOrder((prev) => ({
        ...prev,
        payment_status: result.payment_status,
        payment_history: [...(prev.payment_history || []), result.payment_history],
      }));

      toast.success('Cập nhật trạng thái thanh toán thành công');
      return result;
    } catch (error) {
      toast.error('Lỗi cập nhật trạng thái thanh toán');
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update Shipping Info
  const handleUpdateShipping = async (orderId, shippingInfo) => {
    if (!checkPermission('update')) return;

    try {
      setIsSubmitting(true);

      // Validate thông tin vận chuyển
      if (!shippingInfo.provider || !shippingInfo.tracking_number) {
        toast.error('Vui lòng điền đầy đủ thông tin vận chuyển');
        return;
      }

      const result = await dispatch(
        updateShippingInfo({
          orderId,
          shippingInfo: {
            ...shippingInfo,
            updated_at: new Date().toISOString(),
          },
        })
      ).unwrap();

      // Cập nhật local state
      setSelectedOrder((prev) => ({
        ...prev,
        shipping_info: result.shipping_info,
      }));

      // Tự động chuyển trạng thái sang shipping nếu đang ở processing
      if (selectedOrder.status === ORDER_STATUS.PROCESSING) {
        await handleUpdateOrderStatus(
          orderId,
          ORDER_STATUS.SHIPPING,
          `Tự động chuyển sang trạng thái đang giao. Mã vận đơn: ${shippingInfo.tracking_number}`
        );
      }

      toast.success('Cập nhật thông tin vận chuyển thành công');
    } catch (error) {
      console.error('Shipping update error:', error);
      toast.error(error.message || 'Lỗi cập nhật thông tin vận chuyển');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Update Customer Info
  const handleUpdateCustomer = async (orderId, customerInfo, note) => {
    if (!checkPermission('update')) return;

    try {
      setIsSubmitting(true);

      if (!customerInfo.phone || !customerInfo.fullName || !customerInfo.address) {
        toast.error('Vui lòng điền đầy đủ thông tin bắt buộc');
        return;
      }

      const result = await dispatch(
        updateCustomerInfo({
          orderId,
          customerInfo,
          note: note?.trim(),
        })
      ).unwrap();

      setSelectedOrder((prev) => ({
        ...prev,
        shipping_address: result.shipping_address,
        customer_history: [...(prev.customer_history || []), result.customer_history],
      }));

      toast.success('Cập nhật thông tin khách hàng thành công');
    } catch (error) {
      toast.error(error.message || 'Lỗi cập nhật thông tin khách hàng');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Modal Controls
  const handleShowCreateModal = () => setShowCreateModal(true);
  const handleCloseCreateModal = () => setShowCreateModal(false);
  const handleShowDetailModal = (order) => {
    setSelectedOrder(order);
    setShowDetailModal(true);
  };
  const handleCloseDetailModal = () => {
    setSelectedOrder(null);
    setShowDetailModal(false);
  };

  return {
    // States
    isSubmitting,
    selectedOrder,
    showCreateModal,
    showDetailModal,

    // Permissions
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,

    // Action Handlers
    handleCreateOrder,
    handleUpdateOrderStatus,
    handleUpdatePayment,
    handleUpdateShipping,
    handleUpdateCustomer,

    // Modal Controls
    handleShowCreateModal,
    handleCloseCreateModal,
    handleShowDetailModal,
    handleCloseDetailModal,
  };
};

export default useOrderActions;
