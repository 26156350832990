// src/client/components/common/product/ProductCard.jsx

import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Heart, ShoppingCart, Star, Shield, Clock, BarChart2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase/config';
import { fetchSpecTemplates } from '../../../../store/specTemplateSlice';
import StatusBadge from '../badges/StatusBadge';
import TagBadge from '../badges/TagBadge';
import { calculateTimeLeft } from '../calculateTimeLeft';
import { addToCart } from '../../../features/cartSlice';
import clsx from 'clsx';
import WishlistButton from '../WishlistButton';

// Format currency helper
const formatCurrency = (amount) => {
  return new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  }).format(amount);
};

// Format discount percentage
const formatDiscount = (originalPrice, salePrice) => {
  return Math.round(((originalPrice - salePrice) / originalPrice) * 100);
};

// Progress bar component for flash sales
const ProgressBar = ({ value, total, colorClass = 'bg-primary-600' }) => {
  const percentage = Math.min(100, Math.round((value / total) * 100));
  return (
    <div className="relative pt-1">
      <div className="flex items-center justify-between text-xs mb-1">
        <span>
          Còn lại {value}/{total}
        </span>
        <span>{percentage}%</span>
      </div>
      <div className="overflow-hidden h-2 text-xs flex rounded-full bg-gray-200">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${percentage}%` }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${colorClass}`}
        />
      </div>
    </div>
  );
};

// Countdown timer component
const CountdownTimer = ({ endTime, className }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endTime));

  useEffect(() => {
    const timer = setInterval(() => {
      const remaining = calculateTimeLeft(endTime);
      if (!remaining) clearInterval(timer);
      setTimeLeft(remaining);
    }, 1000);
    return () => clearInterval(timer);
  }, [endTime]);

  if (!timeLeft) return null;

  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <Clock className="w-4 h-4" />
      <div className="flex gap-1 text-sm">
        <div className="bg-gray-800 text-white px-2 py-1 rounded">
          {String(timeLeft.hours).padStart(2, '0')}h
        </div>
        <div className="bg-gray-800 text-white px-2 py-1 rounded">
          {String(timeLeft.minutes).padStart(2, '0')}m
        </div>
        <div className="bg-gray-800 text-white px-2 py-1 rounded">
          {String(timeLeft.seconds).padStart(2, '0')}s
        </div>
      </div>
    </div>
  );
};
const ProductStatuses = ({ product }) => {
  return (
    <div className="absolute top-2 left-2 flex flex-col gap-1">
      {product.isHot && <StatusBadge status="hot" />}
      {product.isNew && <StatusBadge status="new" />}
      {product.isFeatured && <StatusBadge status="featured" />}
      {!product.isActive && <StatusBadge status="discontinued" />}
      {product.stock === 0 && <StatusBadge status="out-stock" />}
    </div>
  );
};
// Compare button component
const CompareButton = ({ onCompare, isCompared, product, className = '' }) => {
  if (!onCompare) return null;

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onCompare(product);
      }}
      className={clsx(
        'p-2 rounded-lg transition-colors',
        isCompared ? 'bg-primary-50 text-primary-600' : 'hover:bg-gray-100',
        className
      )}
      title={isCompared ? 'Đã thêm vào so sánh' : 'Thêm vào so sánh'}
    >
      <BarChart2 size={20} />
    </button>
  );
};

// Style configurations for different display types
const typeConfig = {
  flashSale: {
    badgeColor: 'bg-red-500',
    priceColor: 'text-red-500',
    buttonGradient: 'from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600',
    progressColor: 'bg-red-500',
  },
  promotion: {
    badgeColor: 'bg-orange-500',
    priceColor: 'text-orange-500',
    buttonGradient: 'from-orange-500 to-yellow-500 hover:from-orange-600 hover:to-yellow-600',
    progressColor: 'bg-orange-500',
  },
  normal: {
    badgeColor: 'bg-green-500',
    priceColor: 'text-gray-900',
    buttonGradient: 'from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700',
    progressColor: 'bg-primary-500',
  },
};

// Animation variants
const cardVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 },
  },
  hover: {
    y: -5,
    transition: { duration: 0.2 },
  },
};

const ProductCard = ({
  product,
  displayType = 'normal',
  viewMode = 'grid',
  onCompare,
  isCompared,
  index,
}) => {
  const dispatch = useDispatch();
  const [specs, setSpecs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addingToCart, setAddingToCart] = useState(false);
  const specTemplates = useSelector((state) => state.specTemplates.items);
  const specTemplatesStatus = useSelector((state) => state.specTemplates.status);

  // Fetch spec templates if needed
  useEffect(() => {
    if (specTemplatesStatus === 'idle') {
      dispatch(fetchSpecTemplates());
    }
  }, [dispatch, specTemplatesStatus]);

  // Fetch product specs
  useEffect(() => {
    const fetchSpecs = async () => {
      try {
        const specsQuery = query(
          collection(db, 'product_specs'),
          where('productId', '==', product.id)
        );
        const specsSnap = await getDocs(specsQuery);

        if (!specsSnap.empty) {
          setSpecs(specsSnap.docs[0].data());
        } else if (product.specifications && product.specTemplateId) {
          setSpecs({
            specs: product.specifications,
            templateId: product.specTemplateId,
          });
        }
      } catch (error) {
        console.error('Error fetching specs:', error);
      } finally {
        setLoading(false);
      }
    };

    if (product.id) {
      fetchSpecs();
    }
  }, [product]);

  // Process and normalize specs
  const displaySpecs = useMemo(() => {
    if (!specs?.specs) return [];

    const template = specTemplates.find((t) => t.id === specs.templateId);
    let processedSpecs = [];

    if (!template) {
      // Fallback specs if template not found
      if (specs.specs.cpu) processedSpecs.push({ label: 'CPU', value: specs.specs.cpu });
      if (specs.specs.ram) processedSpecs.push({ label: 'RAM', value: specs.specs.ram });
      if (specs.specs.gpu) processedSpecs.push({ label: 'Card đồ họa', value: specs.specs.gpu });
      if (specs.specs.storage) processedSpecs.push({ label: 'Ổ cứng', value: specs.specs.storage });
    } else {
      processedSpecs = template.fields
        .map((field) => ({
          label: field.label,
          value: specs.specs[field.key],
        }))
        .filter((spec) => spec.value);
    }

    // Ensure exactly 4 specs
    while (processedSpecs.length < 4) {
      processedSpecs.push({ label: '', value: '' });
    }

    return processedSpecs.slice(0, 4);
  }, [specs, specTemplates]);

  const handleAddToCart = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!product.isActive) {
      toast.error('Sản phẩm đã ngừng kinh doanh');
      return;
    }

    if (product.stock === 0) {
      toast.error('Sản phẩm đã hết hàng');
      return;
    }

    try {
      setAddingToCart(true);
      dispatch(
        addToCart({
          product: {
            id: product.id,
            name: product.name,
            price: product.price,
            images: product.images,
            stock: product.stock,
          },
          quantity: 1,
        })
      );
      toast.success(`Đã thêm ${product.name} vào giỏ hàng`);
    } catch (error) {
      toast.error('Có lỗi xảy ra khi thêm vào giỏ hàng');
    } finally {
      setAddingToCart(false);
    }
  };

  const handleAddToWishlist = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toast.success(`Đã thêm ${product.name} vào danh sách yêu thích`);
  };

  // Horizontal list view
  if (viewMode === 'horizontal-list') {
    return (
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        whileHover="hover"
        custom={index}
        className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
      >
        {/* Mobile Layout (Stack) */}
        <div className="block sm:hidden p-4">{/* ... Mobile Layout Code ... */}</div>

        {/* Tablet/Desktop Layout */}
        <div className="hidden sm:block p-4">
          <div className="flex gap-4">
            {/* Image Section - Fixed size */}
            <Link
              to={`/product/${product.id}`}
              className="relative w-40 md:w-48 h-40 md:h-48 flex-shrink-0"
            >
              <img
                src={product.images[0]}
                alt={product.name}
                className="w-full h-full object-cover rounded-lg group-hover:scale-105 transition-transform duration-500"
              />
              <ProductStatuses product={product} />
              {product.originalPrice > product.price && (
                <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded-full text-xs">
                  -{formatDiscount(product.originalPrice, product.price)}%
                </div>
              )}
            </Link>

            {/* Main Content Section */}
            <div className="flex-1 min-w-0 flex flex-col lg:flex-row">
              {/* Product Info */}
              <div className="flex-1 min-w-0 pr-4 lg:border-r space-y-2 lg:space-y-3">
                <Link
                  to={`/product/${product.id}`}
                  className="block text-base lg:text-lg font-medium text-gray-900 hover:text-primary-600 line-clamp-2"
                >
                  {product.name}
                </Link>

                <div className="flex flex-wrap gap-1">
                  {product.tags?.map((tag, idx) => (
                    <TagBadge key={idx} tag={tag} className="text-xs" />
                  ))}
                </div>

                {/* Product Metadata */}
                <div className="flex flex-wrap gap-3 text-xs lg:text-sm text-gray-500">
                  {product.warranty?.months > 0 && (
                    <div className="flex items-center gap-1">
                      <Shield className="w-4 h-4" />
                      <span>BH {product.warranty.months}T</span>
                    </div>
                  )}
                  {product.sold > 0 && (
                    <div className="flex items-center gap-1">
                      <BarChart2 className="w-4 h-4" />
                      <span>Đã bán {product.sold}</span>
                    </div>
                  )}
                  {product.rating > 0 && (
                    <div className="flex items-center text-yellow-400">
                      <Star size={16} fill="currentColor" />
                      <span className="ml-1">{product.rating}</span>
                    </div>
                  )}
                </div>

                {/* Specifications - Responsive Grid */}
                {!loading && displaySpecs.length > 0 && (
                  <div className="hidden sm:grid grid-cols-1 lg:grid-cols-2 gap-2 pt-2">
                    {displaySpecs.map(
                      (spec, idx) =>
                        spec.label && (
                          <div key={idx} className="flex items-center text-xs lg:text-sm">
                            <span className="text-gray-500 w-24 flex-shrink-0">{spec.label}:</span>
                            <span className="text-gray-700 font-medium ml-2 truncate">
                              {spec.value}
                            </span>
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>

              {/* Price and Actions - Right Side */}
              <div className="flex-shrink-0 lg:w-56 mt-3 lg:mt-0 lg:pl-4 flex flex-row lg:flex-col justify-between items-center lg:items-stretch">
                {/* Price Section */}
                <div className="text-right lg:mb-auto">
                  <div className="text-lg lg:text-xl font-bold text-primary-600">
                    {formatCurrency(product.price)}
                  </div>
                  {product.originalPrice > product.price && (
                    <div className="text-sm text-gray-400 line-through">
                      {formatCurrency(product.originalPrice)}
                    </div>
                  )}
                  <div className="text-xs lg:text-sm text-gray-500 mt-1">
                    {product.stock > 0 ? `Còn ${product.stock}` : 'Hết hàng'}
                  </div>
                </div>

                {/* Actions */}
                <div className="flex lg:flex-col items-center gap-2 lg:gap-3">
                  <button
                    onClick={handleAddToCart}
                    disabled={!product.isActive || product.stock === 0 || addingToCart}
                    className="flex items-center gap-1 px-3 py-2 text-white text-sm rounded-lg
                      bg-primary-600 hover:bg-primary-700 disabled:bg-gray-300 disabled:cursor-not-allowed
                      w-auto lg:w-full justify-center"
                  >
                    <ShoppingCart size={16} />
                    <span className="hidden lg:inline">
                      {addingToCart ? 'Đang thêm...' : 'Thêm vào giỏ'}
                    </span>
                  </button>

                  <div className="flex lg:w-full lg:justify-center gap-1">
                    {onCompare && (
                      <button
                        onClick={() => onCompare(product)}
                        className={clsx(
                          'p-2 rounded-lg transition-colors',
                          isCompared ? 'bg-primary-50 text-primary-600' : 'hover:bg-gray-100'
                        )}
                        title={isCompared ? 'Đã thêm vào so sánh' : 'Thêm vào so sánh'}
                      >
                        <BarChart2 size={18} />
                      </button>
                    )}
                    <WishlistButton product={product} size="small" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }

  // Vertical list view
  if (viewMode === 'vertical-list') {
    return (
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        whileHover="hover"
        custom={index}
        className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
      >
        {/* Mobile Layout (Stack) */}
        <div className="flex flex-col md:hidden">
          <Link
            to={`/product/${product.id}`}
            className="relative w-full aspect-square flex-shrink-0 group overflow-hidden"
          >
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            />
            <ProductStatuses product={product} />
            {product.originalPrice > product.price && (
              <div className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                -{formatDiscount(product.originalPrice, product.price)}%
              </div>
            )}
          </Link>

          <div className="p-4 space-y-3">
            <Link
              to={`/product/${product.id}`}
              className="text-base font-medium text-gray-900 hover:text-primary-600 line-clamp-2"
            >
              {product.name}
            </Link>

            <div className="flex flex-wrap gap-1.5">
              {product.tags?.map((tag, idx) => (
                <TagBadge key={idx} tag={tag} className="text-xs" />
              ))}
            </div>

            <div className="space-y-2">
              <div className="flex items-baseline justify-between">
                <span className="text-xl font-bold text-primary-600">
                  {formatCurrency(product.price)}
                </span>
                {product.originalPrice > product.price && (
                  <span className="text-sm text-gray-400 line-through">
                    {formatCurrency(product.originalPrice)}
                  </span>
                )}
              </div>
              <div className="text-sm text-gray-500">
                {product.stock > 0 ? `Còn ${product.stock} sản phẩm` : 'Hết hàng'}
              </div>
            </div>

            <div className="flex items-center justify-between pt-3 border-t">
              <div className="flex gap-2">
                {product.warranty?.months > 0 && (
                  <div className="flex items-center gap-1 text-xs text-gray-500">
                    <Shield className="w-4 h-4" />
                    <span>BH {product.warranty.months}T</span>
                  </div>
                )}
                {product.sold > 0 && (
                  <div className="flex items-center gap-1 text-xs text-gray-500">
                    <BarChart2 className="w-4 h-4" />
                    <span>Đã bán {product.sold}</span>
                  </div>
                )}
              </div>
              <div className="flex gap-1">
                {onCompare && (
                  <button
                    onClick={() => onCompare(product)}
                    className={clsx(
                      'p-1.5 rounded-lg transition-colors',
                      isCompared ? 'bg-primary-50 text-primary-600' : 'hover:bg-gray-100'
                    )}
                  >
                    <BarChart2 size={18} />
                  </button>
                )}
                <button
                  onClick={handleAddToCart}
                  disabled={!product.isActive || product.stock === 0 || addingToCart}
                  className="flex items-center gap-1 px-3 py-1.5 text-white text-sm rounded-lg bg-primary-600
                    hover:bg-primary-700 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                >
                  <ShoppingCart size={16} />
                  {addingToCart ? 'Đang thêm...' : 'Thêm vào giỏ'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Tablet/Desktop Layout (Horizontal) */}
        <div className="hidden md:flex">
          <Link
            to={`/product/${product.id}`}
            className="relative w-[200px] lg:w-[280px] h-[200px] lg:h-[280px] flex-shrink-0 group overflow-hidden"
          >
            <img
              src={product.images[0]}
              alt={product.name}
              className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            />
            <ProductStatuses product={product} />
            {product.originalPrice > product.price && (
              <div className="absolute top-2 right-2 bg-red-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                -{formatDiscount(product.originalPrice, product.price)}%
              </div>
            )}
          </Link>

          <div className="flex-1 p-4 flex flex-col justify-between min-w-0">
            <div>
              <Link
                to={`/product/${product.id}`}
                className="text-base lg:text-lg font-medium text-gray-900 hover:text-primary-600 line-clamp-2"
              >
                {product.name}
              </Link>
              <div className="mt-2 flex flex-wrap gap-1.5">
                {product.tags?.map((tag, idx) => (
                  <TagBadge key={idx} tag={tag} />
                ))}
              </div>
            </div>

            <div className="space-y-4">
              {/* Specifications Grid */}
              {!loading && displaySpecs.length > 0 && (
                <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-2">
                  {displaySpecs.map(
                    (spec, idx) =>
                      spec.label && (
                        <div key={idx} className="flex items-center text-sm">
                          <span className="text-gray-500 min-w-[100px]">{spec.label}:</span>
                          <span className="text-gray-700 font-medium ml-2">{spec.value}</span>
                        </div>
                      )
                  )}
                </div>
              )}

              {/* Product Metadata */}
              <div className="flex items-center gap-4 text-sm text-gray-500">
                {product.warranty?.months > 0 && (
                  <div className="flex items-center gap-1">
                    <Shield className="w-4 h-4" />
                    <span>BH {product.warranty.months} tháng</span>
                  </div>
                )}
                {product.sold > 0 && (
                  <div className="flex items-center gap-1">
                    <BarChart2 className="w-4 h-4" />
                    <span>Đã bán {product.sold}</span>
                  </div>
                )}
              </div>

              {/* Price and Actions Section */}
              <div className="flex items-center justify-between pt-4 border-t">
                <div className="w-[160px] lg:w-[200px]">
                  <div className="flex flex-col">
                    <span className="text-xl lg:text-2xl font-bold text-primary-600 truncate">
                      {formatCurrency(product.price)}
                    </span>
                    {product.originalPrice > product.price && (
                      <span className="text-sm text-gray-400 line-through truncate">
                        {formatCurrency(product.originalPrice)}
                      </span>
                    )}
                    <div className="text-sm text-gray-500 mt-1">
                      {product.stock > 0 ? `Còn ${product.stock} sản phẩm` : 'Hết hàng'}
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  {onCompare && (
                    <button
                      onClick={() => onCompare(product)}
                      className={clsx(
                        'p-2 rounded-lg transition-colors',
                        isCompared ? 'bg-primary-50 text-primary-600' : 'hover:bg-gray-100'
                      )}
                      title={isCompared ? 'Đã thêm vào so sánh' : 'Thêm vào so sánh'}
                    >
                      <BarChart2 size={20} />
                    </button>
                  )}
                  <WishlistButton product={product} size="small" />
                  <button
                    onClick={handleAddToCart}
                    disabled={!product.isActive || product.stock === 0 || addingToCart}
                    className="flex items-center gap-2 px-4 lg:px-6 py-2 text-white rounded-lg bg-primary-600 
                      hover:bg-primary-700 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                  >
                    <ShoppingCart size={18} />
                    {addingToCart ? 'Đang thêm...' : 'Thêm vào giỏ'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }

  // Grid view (default)
  return (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      custom={index}
      className="h-full group"
    >
      <Link
        to={`/product/${product.id}`}
        className="block h-full bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 relative overflow-hidden flex flex-col"
      >
        {/* Image Section */}
        <div className="relative aspect-square rounded-t-xl overflow-hidden bg-gray-100 shrink-0">
          <img
            src={product.images?.[0] || '/api/placeholder/400/400'}
            alt={product.name}
            className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-105"
            loading="lazy"
          />

          {/* Status Badges */}
          <ProductStatuses product={product} />

          {/* Discount Badge */}
          {product.originalPrice > product.price && (
            <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-bl-lg">
              -{formatDiscount(product.originalPrice, product.price)}%
            </div>
          )}

          {/* Quick Actions */}
          <div className="absolute top-2 right-2 flex items-center gap-2">
            <CompareButton
              onCompare={onCompare}
              isCompared={isCompared}
              product={product}
              className="bg-white shadow-lg hover:bg-gray-100"
            />
            <WishlistButton product={product} size="small" />
          </div>
        </div>

        {/* Content */}
        <div className="p-4 flex flex-col flex-1">
          {/* Tags */}
          {product.tags && product.tags.length > 0 && (
            <div className="flex flex-wrap gap-1 mb-2">
              {product.tags.map((tag, index) => (
                <TagBadge key={index} tag={tag} />
              ))}
            </div>
          )}

          {/* Title */}
          <h3 className="font-medium text-gray-900 group-hover:text-primary-600 line-clamp-2 mb-2">
            {product.name}
          </h3>

          {/* Specs */}
          {!loading && displaySpecs.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-1 gap-x-4 gap-y-1.5">
              {displaySpecs.map((spec, idx) => (
                <div key={idx} className="flex items-start gap-2 text-xs h-6">
                  {spec.label && (
                    <>
                      <span className="text-gray-500 min-w-[80px] shrink-0">{spec.label}:</span>
                      <span className="text-gray-700 line-clamp-1">{spec.value}</span>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Price and Actions Section */}
          <div className="mt-4 space-y-4">
            {/* Price & Rating Section */}
            <div className="flex justify-between items-start">
              {/* Rating & Sold */}
              <div className="flex items-center gap-2">
                {product.rating > 0 && (
                  <div className="flex items-center text-yellow-400">
                    <Star size={14} fill="currentColor" />
                    <span className="ml-1 text-sm">{product.rating}</span>
                  </div>
                )}
                {product.sold > 0 && (
                  <span className="text-xs text-gray-500">Đã bán {product.sold}</span>
                )}
              </div>

              {/* Price */}
              <div
                className={`flex flex-col items-end ${typeConfig[displayType].priceColor} group-hover:text-primary-600`}
              >
                <div className="text-xl font-bold">{formatCurrency(product.price)}</div>
                {product.originalPrice > product.price && (
                  <div className="text-sm text-gray-400 line-through">
                    {formatCurrency(product.originalPrice)}
                  </div>
                )}
              </div>
            </div>

            {/* Flash Sale Section nếu có */}
            {displayType === 'flashSale' && (
              <div className="space-y-2">
                <ProgressBar
                  value={product.stockLeft}
                  total={product.stockLimit || product.stock}
                  colorClass={typeConfig.flashSale.progressColor}
                />
                {product.flashSaleInfo?.endTime && (
                  <CountdownTimer endTime={product.flashSaleInfo.endTime} />
                )}
              </div>
            )}

            {/* Actions Section */}
            <div className="mt-auto pt-2 flex flex-col gap-2">
              {/* Quick Actions */}
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <CompareButton
                    onCompare={onCompare}
                    isCompared={isCompared}
                    product={product}
                    className="hover:bg-gray-100"
                  />
                  <WishlistButton product={product} size="small" />
                </div>

                {/* Warranty Badge if available */}
                {product.warranty?.months > 0 && (
                  <div className="flex items-center gap-1 text-xs text-gray-500">
                    <Shield size={12} />
                    <span>BH {product.warranty.months} Tháng</span>
                  </div>
                )}
              </div>

              {/* Add to Cart Button */}
              <button
                onClick={handleAddToCart}
                disabled={!product.isActive || product.stock === 0 || addingToCart}
                className={clsx(
                  'w-full py-2.5 rounded-lg font-medium text-sm',
                  'flex items-center justify-center gap-2',
                  'transition-all duration-300',
                  'bg-gradient-to-r',
                  typeConfig[displayType].buttonGradient,
                  'text-white',
                  'disabled:from-gray-300 disabled:to-gray-300 disabled:cursor-not-allowed',
                  'hover:-translate-y-0.5 active:translate-y-0'
                )}
              >
                {addingToCart ? (
                  <span className="flex items-center">
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    Đang thêm...
                  </span>
                ) : (
                  <>
                    <ShoppingCart size={16} />
                    <span>
                      {!product.isActive
                        ? 'Ngừng bán'
                        : product.stock === 0
                          ? 'Hết hàng'
                          : 'Thêm vào giỏ'}
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default ProductCard;
