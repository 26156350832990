import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';

// Direct imports for core pages
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import AccountPage from './pages/Account';
import ProfileTab from './pages/Account/tabs/ProfileTab';
import OrdersTab from './pages/Account/tabs/OrdersTab';
import SecurityTab from './pages/Account/tabs/SecurityTab';
import WishlistTab from './pages/Account/tabs/WishlistTab';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import PolicyPage from './pages/Policy';
// Lazy load other pages
const Products = React.lazy(() => import('./pages/Products'));
const BestSellers = React.lazy(() => import('./pages/BestSellers'));
const NewProducts = React.lazy(() => import('./pages/NewProducts'));
const Promotion = React.lazy(() => import('./pages/Promotion'));
const PromotionDetail = React.lazy(() => import('./pages/Promotion/Detail'));
const ProductDetail = React.lazy(() => import('./pages/ProductDetail'));
const Cart = React.lazy(() => import('./pages/Cart'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const OrderSuccess = React.lazy(() => import('./pages/Checkout/components/OrderSuccess'));
const Account = React.lazy(() => import('./pages/Account'));
const Wishlist = React.lazy(() => import('./pages/Wishlist'));
const FlashSale = React.lazy(() => import('./pages/FlashSale'));
const Login = React.lazy(() => import('./pages/Auth/Login'));
const Register = React.lazy(() => import('./pages/Auth/Register'));
const SearchResults = React.lazy(() => import('./pages/SearchResults'));

const PageLoader = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
  </div>
);
// HOC to wrap lazy loaded components
const withSuspense = (Component) => (
  <Suspense fallback={<PageLoader />}>
    <Component />
  </Suspense>
);

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={withSuspense(Login)} />
      <Route path="register" element={withSuspense(Register)} />
      <Route path="/" element={<MainLayout />}>
        {/* Core routes */}
        <Route index element={<Home />} />

        {/* Lazy loaded routes */}
        <Route path="products" element={withSuspense(Products)} />
        <Route path="best-sellers" element={withSuspense(BestSellers)} />
        <Route path="new-products" element={withSuspense(NewProducts)} />
        <Route path="promotion" element={withSuspense(Promotion)} />
        <Route path="promotion/:id" element={withSuspense(PromotionDetail)} />
        <Route path="product/:id" element={withSuspense(ProductDetail)} />
        <Route path="category/:categoryId" element={withSuspense(Products)} />
        <Route path="hot-products" element={withSuspense(Products)} />
        <Route path="featured-products" element={withSuspense(Products)} />
        <Route path="cart" element={withSuspense(Cart)} />
        <Route path="checkout" element={withSuspense(Checkout)} />
        <Route path="order-success" element={withSuspense(OrderSuccess)} />
        <Route path="account" element={withSuspense(Account)} />
        <Route path="wishlist" element={withSuspense(Wishlist)} />
        <Route path="flash-sale" element={withSuspense(FlashSale)} />
        <Route path="/search" element={withSuspense(SearchResults)} />
        <Route path="/account" element={<AccountPage />}>
          <Route index element={<ProfileTab />} />
          <Route path="orders" element={<OrdersTab />} />
          <Route path="wishlist" element={<WishlistTab />} />
          <Route path="security" element={<SecurityTab />} />
        </Route>
        <Route path="/gioi-thieu" element={<AboutPage />} />
        <Route path="/lien-he" element={<ContactPage />} />
        <Route path="/chinh-sach" element={<PolicyPage />} />
        {/* Catch all route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default ClientRoutes;
