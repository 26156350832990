// src/client/pages/Policy/index.jsx
import React from 'react';
import { Shield, Truck, RotateCw, FileText, HelpCircle, ScrollText } from 'lucide-react';
import { Link } from 'react-router-dom';

const policies = [
  {
    id: 'remote-purchase',
    title: 'Chính sách mua hàng từ xa - kiểm hàng',
    description: 'Quy định và hướng dẫn về quy trình mua hàng từ xa và kiểm tra sản phẩm',
    icon: FileText,
  },
  {
    id: 'deposit',
    title: 'Chính sách đặt cọc sản phẩm',
    description: 'Thông tin về quy trình và điều kiện đặt cọc sản phẩm',
    icon: ScrollText,
  },
  {
    id: 'shipping-returns',
    title: 'Chính sách giao nhận - đổi trả',
    description: 'Quy định về giao hàng và quy trình đổi trả sản phẩm',
    icon: Truck,
  },
  {
    id: 'warranty',
    title: 'Chính sách bảo hành',
    description: 'Thông tin về chế độ bảo hành sản phẩm',
    icon: Shield,
  },
  {
    id: 'complaints',
    title: 'Quy trình tiếp nhận và giải quyết khiếu nại',
    description: 'Hướng dẫn quy trình giải quyết khiếu nại của khách hàng',
    icon: HelpCircle,
  },
  {
    id: 'terms',
    title: 'Thỏa thuận sử dụng và quy định giao dịch chung',
    description: 'Các điều khoản và điều kiện khi sử dụng dịch vụ',
    icon: RotateCw,
  },
];

const PolicyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Chính sách</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {policies.map((policy) => {
              const Icon = policy.icon;
              return (
                <Link
                  key={policy.id}
                  to={`/policies/${policy.id}`}
                  className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow"
                >
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 rounded-lg bg-primary-50 flex items-center justify-center flex-shrink-0">
                      <Icon className="w-6 h-6 text-primary-600" />
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900 mb-2">{policy.title}</h3>
                      <p className="text-sm text-gray-600">{policy.description}</p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyPage;
