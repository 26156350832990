// src/client/components/home/HotProducts.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Zap } from 'lucide-react';
import { Link } from 'react-router-dom';
import ProductGrid from '../common/product/ProductGrid';

const HotProducts = () => {
  const products = useSelector((state) => state.products.items);

  const hotProducts = products.filter((product) => product.isActive && product.isHot).slice(0, 4);

  if (hotProducts.length === 0) return null;

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center mb-8">
        <div>
          <div className="flex items-center gap-2 mb-2">
            <Zap className="w-6 h-6 text-red-500" />
            <h2 className="text-2xl font-bold text-gray-900">Sản phẩm HOT</h2>
          </div>
          <p className="text-gray-600">Sản phẩm được quan tâm nhiều nhất</p>
        </div>

        <Link
          to="/hot-products"
          className="text-primary-600 hover:text-primary-700 font-medium hidden md:block"
        >
          Xem tất cả
        </Link>
      </div>

      <ProductGrid
        products={hotProducts}
        columns={{
          grid: { sm: 2, md: 3, lg: 4 },
          list: { sm: 1, md: 1, lg: 2 },
        }}
      />
    </div>
  );
};

export default HotProducts;
