// src/client/features/orderSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/config';

export const fetchUserOrders = createAsyncThunk(
  'userOrders/fetchAll',
  async (userId, { rejectWithValue }) => {
    try {
      const ordersRef = collection(db, 'orders');
      const q = query(ordersRef, where('userId', '==', userId), orderBy('createdAt', 'desc'));

      const snapshot = await getDocs(q);
      const orders = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate().toISOString(),
        updatedAt: doc.data().updatedAt?.toDate().toISOString(),
        orderDate: doc.data().orderDate?.toDate().toISOString(),
      }));

      // Load order items for each order
      const ordersWithItems = await Promise.all(
        orders.map(async (order) => {
          const itemsQuery = query(collection(db, 'order_items'), where('orderId', '==', order.id));
          const itemsSnapshot = await getDocs(itemsQuery);
          const items = itemsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          return { ...order, items };
        })
      );

      return ordersWithItems;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userOrdersSlice = createSlice({
  name: 'userOrders',
  initialState: {
    orders: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearOrders: (state) => {
      state.orders = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(fetchUserOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearOrders } = userOrdersSlice.actions;
export default userOrdersSlice.reducer;
