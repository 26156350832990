import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, ArrowRight, Clock } from 'lucide-react';
import { fetchHeroSlides } from '../../../store/heroSlice';
import OptimizedImage from '../common/OptimizedImage';

const slideVariants = {
  enter: (direction) => ({
    x: direction > 0 ? '100%' : '-100%',
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction < 0 ? '100%' : '-100%',
    opacity: 0,
  }),
};

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
};

const ProgressBar = ({ progress }) => (
  <div className="w-full h-1 bg-white/20">
    <motion.div
      className="h-full bg-white"
      initial={{ width: 0 }}
      animate={{ width: `${progress}%` }}
      transition={{ duration: 0.1, ease: 'linear' }}
    />
  </div>
);

const HeroSection = () => {
  const dispatch = useDispatch();
  const slides = useSelector((state) => state.hero.items);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    dispatch(fetchHeroSlides());
  }, [dispatch]);

  useEffect(() => {
    if (!isAutoPlaying || slides.length === 0) return;

    let startTime = Date.now();
    const duration = 5000;

    const timer = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const newProgress = (elapsed / duration) * 100;

      if (newProgress >= 100) {
        startTime = Date.now();
        setDirection(1);
        setCurrentSlide((prev) => (prev + 1) % slides.length);
      } else {
        setProgress(newProgress);
      }
    }, 50);

    return () => clearInterval(timer);
  }, [isAutoPlaying, currentSlide, slides.length]);

  const handleSlideChange = (newDirection) => {
    setIsAutoPlaying(false);
    setDirection(newDirection);
    setCurrentSlide((prev) => {
      if (newDirection === 1) {
        return (prev + 1) % slides.length;
      }
      return (prev - 1 + slides.length) % slides.length;
    });

    setTimeout(() => {
      setIsAutoPlaying(true);
      setProgress(0);
    }, 5000);
  };

  if (!slides.length) {
    return <div className="w-full h-[500px] bg-gray-100 rounded-2xl animate-pulse" />;
  }

  return (
    <div className="relative w-full h-[500px] rounded-2xl overflow-hidden group">
      {/* Main Slider */}
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentSlide}
          custom={direction}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          className="absolute inset-0"
        >
          {/* Background Image */}
          <div className="relative w-full h-full">
            <OptimizedImage
              src={slides[currentSlide].image}
              alt={slides[currentSlide].title}
              containerClassName="h-full" // Chỉ định chiều cao đầy đủ
              objectFit="contain" // Hiển thị toàn bộ hình ảnh
              aspectRatio="auto" // Không cố định tỷ lệ
            />
            <div className="absolute inset-0 bg-gradient-to-bl from-black/30 via-black/15 to-transparent" />
          </div>

          {/* Content - Positioned bottom-right */}
          <div className="absolute inset-0 flex items-end justify-end">
            <div className="max-w-2xl p-8">
              {/* Badge */}
              {slides[currentSlide].badge && (
                <motion.div
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  className="inline-flex items-center gap-2 px-4 py-1.5 bg-primary-600 
                    text-white rounded-full text-sm font-medium mb-4"
                >
                  <Clock size={16} />
                  {slides[currentSlide].badge}
                </motion.div>
              )}

              {/* Title */}
              <motion.h2
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                className="text-4xl font-bold text-white mb-4"
              >
                {slides[currentSlide].title}
              </motion.h2>

              {/* Description */}
              <motion.p
                variants={contentVariants}
                initial="hidden"
                animate="visible"
                transition={{ delay: 0.2 }}
                className="text-lg text-white/90 mb-6"
              >
                {slides[currentSlide].description}
              </motion.p>

              {/* CTA Button */}
              {slides[currentSlide].link && (
                <motion.div
                  variants={contentVariants}
                  initial="hidden"
                  animate="visible"
                  transition={{ delay: 0.3 }}
                >
                  <Link
                    to={slides[currentSlide].link}
                    className="inline-flex items-center gap-2 px-6 py-3 bg-white text-gray-900 
                      rounded-xl font-medium hover:bg-gray-100 transition-colors group/button"
                  >
                    <span>{slides[currentSlide].buttonText || 'Xem thêm'}</span>
                    <ArrowRight className="w-5 h-5 transition-transform group-hover/button:translate-x-1" />
                  </Link>
                </motion.div>
              )}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>

      {/* Navigation Buttons - Moved to the sides */}
      <div className="absolute inset-y-0 left-4 right-4 flex items-center justify-between pointer-events-none">
        <button
          onClick={() => handleSlideChange(-1)}
          className="w-10 h-10 rounded-full bg-white/10 hover:bg-white/20 backdrop-blur-sm
            flex items-center justify-center transition-colors
            opacity-0 group-hover:opacity-100 pointer-events-auto"
          aria-label="Previous slide"
        >
          <ChevronLeft className="w-6 h-6 text-white" />
        </button>
        <button
          onClick={() => handleSlideChange(1)}
          className="w-10 h-10 rounded-full bg-white/10 hover:bg-white/20 backdrop-blur-sm
            flex items-center justify-center transition-colors
            opacity-0 group-hover:opacity-100 pointer-events-auto"
          aria-label="Next slide"
        >
          <ChevronRight className="w-6 h-6 text-white" />
        </button>
      </div>

      {/* Progress Bar */}
      <div className="absolute bottom-0 left-0 right-0">
        <ProgressBar progress={isAutoPlaying ? progress : 0} />
      </div>

      {/* Slide Counter */}
      <div
        className="absolute top-8 right-8 bg-white/10 backdrop-blur-sm 
        rounded-full px-4 py-2 text-white font-medium"
      >
        {currentSlide + 1} / {slides.length}
      </div>
    </div>
  );
};

export default HeroSection;
