import React from 'react';
import { useSelector } from 'react-redux';
import OrderItem from './OrderItem';
import { useOrderPermissions } from '../../../../../hooks/useOrderPermissions';

const OrderList = ({ onViewDetail, onUpdateStatus, isSubmitting }) => {
  const { items: orders, status } = useSelector((state) => state.orders);
  const { hasViewPermission } = useOrderPermissions();

  const handleUpdateStatus = async (orderId, status, note) => {
    try {
      await onUpdateStatus(orderId, status, note);
    } catch (error) {
      // Error already handled by toast in useOrderActions
      console.error('Error updating status:', error);
    }
  };
  // Format orders data
  const formattedOrders = orders.map((order) => ({
    ...order,
    total: Number(order.total) || 0,
    shipping_fee: Number(order.shipping_fee) || 0,
    subtotal: Number(order.subtotal) || 0,
  }));

  if (status === 'loading') {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (!hasViewPermission || orders.length === 0) {
    return (
      <div className="text-center py-12 border-t">
        <p className="text-gray-500">
          {!hasViewPermission ? 'Không có quyền xem danh sách' : 'Không có đơn hàng nào'}
        </p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Mã đơn hàng
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Khách hàng
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Giá trị đơn
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Nguồn đơn
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Ngày đặt
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Trạng thái
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {formattedOrders.map((order) => (
            <OrderItem
              key={order.id}
              order={order}
              onViewDetail={onViewDetail}
              onUpdateStatus={handleUpdateStatus}
              isSubmitting={isSubmitting}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderList;
