// src/client/components/home/FeaturedTags.jsx
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Tag, Laptop, Monitor, Cpu, HardDrive, Mouse } from 'lucide-react';

const FeaturedTags = () => {
  const products = useSelector((state) => state.products.items);

  // Get unique tags and their product counts
  const tagStats = useMemo(() => {
    const stats = {};
    products.forEach((product) => {
      if (product.isActive && product.tags) {
        product.tags.forEach((tag) => {
          if (!stats[tag]) {
            stats[tag] = { count: 0, products: [] };
          }
          stats[tag].count += 1;
          stats[tag].products.push(product);
        });
      }
    });
    return stats;
  }, [products]);

  // Get top tags by product count
  const topTags = useMemo(() => {
    return Object.entries(tagStats)
      .sort(([, a], [, b]) => b.count - a.count)
      .slice(0, 6)
      .map(([tag, stats]) => ({
        name: tag,
        count: stats.count,
        // Calculate total products and average price
        productCount: stats.products.length,
        averagePrice: Math.floor(
          stats.products.reduce((sum, p) => sum + p.price, 0) / stats.products.length
        ),
      }));
  }, [tagStats]);

  // Icon mapping for common tags
  const getTagIcon = (tagName) => {
    const mapping = {
      laptop: Laptop,
      monitor: Monitor,
      cpu: Cpu,
      storage: HardDrive,
      mouse: Mouse,
    };

    const key = Object.keys(mapping).find((k) => tagName.toLowerCase().includes(k));

    return mapping[key] || Tag;
  };

  return (
    <div className="bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-10">
          <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">Danh mục phổ biến</h2>
          <p className="text-gray-600">Khám phá các danh mục sản phẩm được tìm kiếm nhiều nhất</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {topTags.map((tag, index) => {
            const TagIcon = getTagIcon(tag.name);

            return (
              <motion.div
                key={tag.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link
                  to={`/search?tags=${encodeURIComponent(tag.name)}`}
                  className="group flex flex-col items-center p-6 bg-gray-50 
                    rounded-2xl hover:bg-gray-100 transition-colors"
                >
                  <div
                    className="w-12 h-12 bg-white rounded-xl flex items-center 
                    justify-center shadow-sm group-hover:scale-110 transition-transform"
                  >
                    <TagIcon className="w-6 h-6 text-primary-600" />
                  </div>

                  <h3 className="mt-4 font-medium text-gray-900 text-center">{tag.name}</h3>

                  <div className="mt-2 text-sm text-gray-500 text-center">
                    {tag.productCount} sản phẩm
                  </div>

                  <div className="mt-1 text-xs text-gray-400">
                    Từ{' '}
                    {new Intl.NumberFormat('vi-VN', {
                      style: 'currency',
                      currency: 'VND',
                    }).format(tag.averagePrice)}
                  </div>
                </Link>
              </motion.div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturedTags;
