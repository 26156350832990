import React from 'react';
import { motion } from 'framer-motion';
import { X, Package, MapPin, Truck, Clock, Receipt } from 'lucide-react';
import OrderStatusTimeline from '../../../components/common/order/OrderStatusTimeline';

const OrderDetailModal = ({ order, onClose }) => {
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(amount);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('vi-VN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const getStatusColor = (status) => {
    const colors = {
      pending: 'bg-yellow-100 text-yellow-800',
      processing: 'bg-blue-100 text-blue-800',
      shipping: 'bg-purple-100 text-purple-800',
      delivered: 'bg-green-100 text-green-800',
      cancelled: 'bg-red-100 text-red-800',
      returned: 'bg-gray-100 text-gray-800',
    };
    return colors[status] || 'bg-gray-100 text-gray-800';
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        className="bg-white rounded-lg w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col"
      >
        {/* Header */}
        <div className="p-6 border-b flex items-center justify-between">
          <div>
            <h2 className="text-xl font-bold">Chi tiết đơn hàng #{order.orderNumber}</h2>
            <p className="text-sm text-gray-500 mt-1">Đặt ngày {formatDate(order.orderDate)}</p>
          </div>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {/* Left Column */}
            <div className="lg:col-span-2 space-y-6">
              {/* Current Status */}
              <div className="bg-white rounded-lg border p-4">
                <h3 className="font-medium flex items-center gap-2 mb-4">
                  <Clock className="w-5 h-5" />
                  Trạng thái đơn hàng
                </h3>
                <OrderStatusTimeline order={order} />
              </div>

              {/* Order Items */}
              <div className="bg-white rounded-lg border p-4">
                <h3 className="font-medium flex items-center gap-2 mb-4">
                  <Package className="w-5 h-5" />
                  Sản phẩm đã mua
                </h3>
                <div className="divide-y">
                  {order.items?.map((item) => (
                    <div key={item.id} className="py-4 first:pt-0 last:pb-0">
                      <div className="flex gap-4">
                        <div className="w-20 h-20 bg-gray-100 rounded-lg overflow-hidden">
                          {item.image ? (
                            <img
                              src={item.image}
                              alt={item.name}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center">
                              <Package className="w-6 h-6 text-gray-400" />
                            </div>
                          )}
                        </div>
                        <div className="flex-1">
                          <h4 className="font-medium">{item.name}</h4>
                          <p className="text-sm text-gray-500 mt-1">
                            {item.quantity} x {formatCurrency(item.price)}
                          </p>
                          <p className="text-sm font-medium text-primary-600 mt-1">
                            {formatCurrency(item.price * item.quantity)}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Shipping Info */}
              <div className="bg-white rounded-lg border p-4">
                <h3 className="font-medium flex items-center gap-2 mb-4">
                  <Truck className="w-5 h-5" />
                  Thông tin vận chuyển
                </h3>
                <div className="space-y-4">
                  <div>
                    <div className="text-sm text-gray-500 mb-1">Đơn vị vận chuyển</div>
                    <div className="font-medium">
                      {order.shipping_info?.provider || 'Chưa có thông tin'}
                    </div>
                  </div>
                  {order.shipping_info?.tracking_number && (
                    <div>
                      <div className="text-sm text-gray-500 mb-1">Mã vận đơn</div>
                      <div className="font-medium">{order.shipping_info.tracking_number}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Status History */}
              {order.status_history?.length > 0 && (
                <div className="bg-white rounded-lg border p-4">
                  <h3 className="font-medium mb-4">Lịch sử đơn hàng</h3>
                  <div className="space-y-4">
                    {order.status_history.map((history, index) => (
                      <div key={index} className="flex items-start gap-4">
                        <div
                          className={`w-2 h-2 rounded-full mt-2 ${getStatusColor(history.status)}`}
                        ></div>
                        <div className="flex-1">
                          <div className="flex justify-between">
                            <span
                              className={`text-sm font-medium ${getStatusColor(history.status)}`}
                            >
                              {history.status}
                            </span>
                            <span className="text-sm text-gray-500">
                              {formatDate(history.timestamp)}
                            </span>
                          </div>
                          {history.note && (
                            <p className="text-sm text-gray-600 mt-1">{history.note}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Right Column */}
            <div className="space-y-6">
              {/* Shipping Address */}
              <div className="bg-white rounded-lg border p-4">
                <h3 className="font-medium flex items-center gap-2 mb-4">
                  <MapPin className="w-5 h-5" />
                  Địa chỉ nhận hàng
                </h3>
                <div className="space-y-2">
                  <p className="font-medium">{order.shipping_address.fullName}</p>
                  <p className="text-gray-600">{order.shipping_address.phone}</p>
                  <p className="text-gray-600">
                    {order.shipping_address.address}, {order.shipping_address.ward},{' '}
                    {order.shipping_address.district}, {order.shipping_address.city}
                  </p>
                </div>
              </div>

              {/* Payment Summary */}
              <div className="bg-white rounded-lg border p-4">
                <h3 className="font-medium flex items-center gap-2 mb-4">
                  <Receipt className="w-5 h-5" />
                  Thanh toán
                </h3>
                <div className="space-y-3">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Tạm tính</span>
                    <span>{formatCurrency(order.subtotal)}</span>
                  </div>
                  {order.discount > 0 && (
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-500">Giảm giá</span>
                      <span className="text-green-600">-{formatCurrency(order.discount)}</span>
                    </div>
                  )}
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-500">Phí vận chuyển</span>
                    <span>{formatCurrency(order.shipping_fee)}</span>
                  </div>
                  <div className="flex justify-between pt-3 border-t">
                    <span className="font-medium">Tổng cộng</span>
                    <span className="text-lg font-bold text-primary-600">
                      {formatCurrency(order.total)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Additional Information */}
              {order.note && (
                <div className="bg-white rounded-lg border p-4">
                  <h3 className="font-medium mb-2">Ghi chú</h3>
                  <p className="text-gray-600 text-sm">{order.note}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default OrderDetailModal;
