export const OrderStatusBadge = ({ status }) => {
  const getStatusInfo = (status) => {
    const statusConfig = {
      pending: {
        text: 'Chờ xử lý',
        className: 'bg-yellow-100 text-yellow-800',
      },
      processing: {
        text: 'Đang xử lý',
        className: 'bg-blue-100 text-blue-800',
      },
      shipping: {
        text: 'Đang giao',
        className: 'bg-purple-100 text-purple-800',
      },
      delivered: {
        text: 'Đã giao',
        className: 'bg-green-100 text-green-800',
      },
      cancelled: {
        text: 'Đã hủy',
        className: 'bg-red-100 text-red-800',
      },
      returned: {
        text: 'Đã trả hàng',
        className: 'bg-gray-100 text-gray-800',
      },
      returning: { text: 'Hoàn trả hàng', className: 'bg-orange-100 text-orange-800' },
    };

    return (
      statusConfig[status] || {
        text: status,
        className: 'bg-gray-100 text-gray-800',
      }
    );
  };

  const { text, className } = getStatusInfo(status);

  return (
    <span
      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${className}`}
    >
      {text}
    </span>
  );
};

export const PaymentStatusBadge = ({ status, orderStatus }) => {
  const displayStatus =
    ['cancelled', 'returned'].includes(orderStatus) && status === 'pending' ? 'none' : status;

  const getPaymentStatusInfo = (status) => {
    const statusConfig = {
      none: {
        text: 'Không có thanh toán',
        className: 'bg-gray-100 text-gray-800',
      },
      pending: {
        text: 'Chờ thanh toán',
        className: 'bg-yellow-100 text-yellow-800',
      },
      paid: {
        text: 'Đã thanh toán',
        className: 'bg-green-100 text-green-800',
      },
      refunded: {
        text: 'Đã hoàn tiền',
        className: 'bg-gray-100 text-gray-800',
      },
      pending_refund: {
        text: 'Chờ hoàn tiền',
        className: 'bg-orange-100 text-orange-800',
      },
      failed: {
        text: 'Thanh toán thất bại',
        className: 'bg-red-100 text-red-800',
      },
    };

    return (
      statusConfig[status] || {
        text: status,
        className: 'bg-gray-100 text-gray-800',
      }
    );
  };

  const { text, className } = getPaymentStatusInfo(displayStatus);

  return (
    <span
      className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${className}`}
    >
      {text}
    </span>
  );
};
