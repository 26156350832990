// src/store/flashSaleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { uploadToCloudinary } from '../utils/cloudinary';

// Convert timestamps to ISO string
const convertFlashSaleData = (data) => {
  const converted = { ...data };
  if (converted.startTime?.toDate) {
    converted.startTime = converted.startTime.toDate().toISOString();
  }
  if (converted.endTime?.toDate) {
    converted.endTime = converted.endTime.toDate().toISOString();
  }
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  return converted;
};

// Process images for upload
const processImages = async (images) => {
  const processedImages = {};

  // Handle banner image
  if (images.bannerImage?.file) {
    try {
      processedImages.bannerImage = await uploadToCloudinary(images.bannerImage.file);
    } catch (error) {
      console.error('Error uploading banner image:', error);
    }
  } else if (typeof images.bannerImage === 'string') {
    processedImages.bannerImage = images.bannerImage;
  }

  // Handle thumbnail image
  if (images.thumbnailImage?.file) {
    try {
      processedImages.thumbnailImage = await uploadToCloudinary(images.thumbnailImage.file);
    } catch (error) {
      console.error('Error uploading thumbnail image:', error);
    }
  } else if (typeof images.thumbnailImage === 'string') {
    processedImages.thumbnailImage = images.thumbnailImage;
  }

  return processedImages;
};
export const updateFlashSaleProductCount = createAsyncThunk(
  'flashSales/updateProductCount',
  async ({ flashSaleId, count }) => {
    try {
      const docRef = doc(db, 'flash_sales', flashSaleId);
      await updateDoc(docRef, {
        productCount: count,
        updatedAt: serverTimestamp(),
      });
      return { id: flashSaleId, productCount: count };
    } catch (error) {
      throw new Error('Failed to update product count: ' + error.message);
    }
  }
);

// Thunks
export const fetchFlashSales = createAsyncThunk('flashSales/fetchAll', async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'flash_sales'));
    return querySnapshot.docs.map((doc) =>
      convertFlashSaleData({
        id: doc.id,
        ...doc.data(),
      })
    );
  } catch (error) {
    throw new Error('Failed to fetch flash sales: ' + error.message);
  }
});

export const addFlashSale = createAsyncThunk(
  'flashSales/add',
  async (flashSaleData, { rejectWithValue }) => {
    try {
      // Process images
      let bannerUrl = null;
      let thumbnailUrl = null;

      if (flashSaleData.bannerImage?.file) {
        try {
          bannerUrl = await uploadToCloudinary(flashSaleData.bannerImage.file);
        } catch (error) {
          console.error('Error uploading banner:', error);
        }
      }

      if (flashSaleData.thumbnailImage?.file) {
        try {
          thumbnailUrl = await uploadToCloudinary(flashSaleData.thumbnailImage.file);
        } catch (error) {
          console.error('Error uploading thumbnail:', error);
        }
      }

      // Create flash sale data
      const docRef = await addDoc(collection(db, 'flash_sales'), {
        ...flashSaleData,
        bannerImage: bannerUrl,
        thumbnailImage: thumbnailUrl,
        productCount: 0,
        totalSold: 0,
        status: 'upcoming',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      return {
        id: docRef.id,
        ...flashSaleData,
        bannerImage: bannerUrl,
        thumbnailImage: thumbnailUrl,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateFlashSale = createAsyncThunk(
  'flashSales/update',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      // Process images if updated
      const processedImages = await processImages(data);

      const updateData = {
        ...data,
        ...processedImages,
        updatedAt: serverTimestamp(),
      };

      // Calculate status based on time
      const now = new Date();
      const startTime = new Date(data.startTime);
      const endTime = new Date(data.endTime);

      if (now < startTime) {
        updateData.status = 'upcoming';
      } else if (now >= startTime && now <= endTime) {
        updateData.status = 'active';
      } else {
        updateData.status = 'ended';
      }

      // Update in Firestore
      const docRef = doc(db, 'flash_sales', id);
      await updateDoc(docRef, updateData);

      return {
        id,
        ...data,
        ...processedImages,
        updatedAt: new Date().toISOString(),
        status: updateData.status,
      };
    } catch (error) {
      return rejectWithValue('Failed to update flash sale: ' + error.message);
    }
  }
);

export const deleteFlashSale = createAsyncThunk(
  'flashSales/delete',
  async (id, { rejectWithValue }) => {
    try {
      // Delete the flash sale
      await deleteDoc(doc(db, 'flash_sales', id));

      // Also delete associated products
      const productsQuery = query(
        collection(db, 'flash_sale_products'),
        where('flashSaleId', '==', id)
      );
      const productsSnapshot = await getDocs(productsQuery);

      const deletePromises = productsSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      return id;
    } catch (error) {
      return rejectWithValue('Failed to delete flash sale: ' + error.message);
    }
  }
);

// Helper function to update flash sale status based on time
const getFlashSaleStatus = (startTime, endTime) => {
  const now = new Date();
  const start = new Date(startTime);
  const end = new Date(endTime);

  if (now < start) return 'upcoming';
  if (now > end) return 'ended';
  return 'active';
};

// Slice
const flashSaleSlice = createSlice({
  name: 'flashSales',
  initialState: {
    items: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {
    // Update status based on time
    updateStatuses: (state) => {
      state.items.forEach((item) => {
        item.status = getFlashSaleStatus(item.startTime, item.endTime);
      });
    },
    // Update stats
    updateFlashSaleStats: (state, action) => {
      const { id, stats } = action.payload;
      const flashSale = state.items.find((item) => item.id === id);
      if (flashSale) {
        Object.assign(flashSale, stats);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch flash sales
      .addCase(fetchFlashSales.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlashSales.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload.map((item) => ({
          ...item,
          status: getFlashSaleStatus(item.startTime, item.endTime),
        }));
      })
      .addCase(fetchFlashSales.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add flash sale
      .addCase(addFlashSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addFlashSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(action.payload);
      })
      .addCase(addFlashSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Update flash sale
      .addCase(updateFlashSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateFlashSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateFlashSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Delete flash sale
      .addCase(deleteFlashSale.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteFlashSale.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = state.items.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteFlashSale.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateFlashSaleProductCount.fulfilled, (state, action) => {
        const flashSale = state.items.find((item) => item.id === action.payload.id);
        if (flashSale) {
          flashSale.productCount = action.payload.productCount;
        }
      });
  },
});

export const { updateStatuses, updateFlashSaleStats } = flashSaleSlice.actions;
export default flashSaleSlice.reducer;
