// store/promotionProductSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';

// Convert timestamps
const convertTimestamps = (data) => {
  const converted = { ...data };
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  return converted;
};

// Initial state
const initialState = {
  items: [],
  status: 'idle',
  error: null,
  selectedProducts: [],
};

// Thunks
export const fetchPromotionProducts = createAsyncThunk(
  'promotionProducts/fetchByPromotion',
  async (promotionId) => {
    try {
      const q = query(
        collection(db, 'promotion_products'),
        where('promotionId', '==', promotionId),
        where('isActive', '==', true)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => convertTimestamps({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new Error('Failed to fetch promotion products: ' + error.message);
    }
  }
);

// Trong promotionProductSlice.js
export const addPromotionProducts = createAsyncThunk(
  'promotionProducts/addProducts',
  async ({ promotionId, products }) => {
    try {
      const batch = writeBatch(db);
      const addedProducts = [];

      for (const product of products) {
        const docRef = doc(collection(db, 'promotion_products'));
        const timestamp = serverTimestamp();
        const productData = {
          promotionId,
          productId: product.id,
          name: product.name,
          originalPrice: product.originalPrice,
          discountPrice: product.discountPrice,
          maxQuantity: product.maxQuantity,
          isActive: true,
          createdAt: timestamp,
          updatedAt: timestamp,
        };

        batch.set(docRef, productData);
        // Convert timestamp to Date for Redux store
        addedProducts.push({
          id: docRef.id,
          ...productData,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      }

      await batch.commit();
      return addedProducts;
    } catch (error) {
      throw new Error('Failed to add promotion products: ' + error.message);
    }
  }
);

export const updatePromotionProduct = createAsyncThunk(
  'promotionProducts/updateProduct',
  async ({ id, data }) => {
    try {
      const docRef = doc(db, 'promotion_products', id);
      const updateData = {
        ...data,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(docRef, updateData);
      return { id, ...data };
    } catch (error) {
      throw new Error('Failed to update promotion product: ' + error.message);
    }
  }
);

export const removePromotionProducts = createAsyncThunk(
  'promotionProducts/removeProducts',
  async ({ promotionId, productIds }) => {
    try {
      const batch = writeBatch(db);

      // Thay đổi query để lấy đúng document cần xóa
      const q = query(collection(db, 'promotion_products'), where('productId', 'in', productIds));
      const querySnapshot = await getDocs(q);

      // Xóa các document
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      return productIds;
    } catch (error) {
      throw new Error('Không thể xóa sản phẩm: ' + error.message);
    }
  }
);
export const updatePromotionProductCount = createAsyncThunk(
  'promotions/updateProductCount',
  async ({ promotionId, count }) => {
    try {
      const docRef = doc(db, 'promotions', promotionId);
      await updateDoc(docRef, {
        productCount: count,
        updatedAt: serverTimestamp(),
      });
      return { id: promotionId, productCount: count };
    } catch (error) {
      throw new Error('Failed to update product count: ' + error.message);
    }
  }
);
// Slice
const promotionProductSlice = createSlice({
  name: 'promotionProducts',
  initialState,
  reducers: {
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch promotion products
      .addCase(fetchPromotionProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPromotionProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchPromotionProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Add promotion products
      .addCase(addPromotionProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addPromotionProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(...action.payload);
        state.selectedProducts = [];
      })
      .addCase(addPromotionProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Update promotion product
      .addCase(updatePromotionProduct.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePromotionProduct.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updatePromotionProduct.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Remove promotion products
      .addCase(removePromotionProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removePromotionProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = state.items.filter((item) => !action.payload.includes(item.productId));
      })
      .addCase(removePromotionProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSelectedProducts, clearSelectedProducts } = promotionProductSlice.actions;
export default promotionProductSlice.reducer;
