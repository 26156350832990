// src/store/roleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase/config';
import { DEFAULT_PERMISSIONS, SUPER_ADMIN_ROLE, STAFF_ROLE } from '../models/userModels';

const convertTimestamps = (data) => {
  const converted = { ...data };
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  return converted;
};

// Thunks
export const fetchRoles = createAsyncThunk('roles/fetchAll', async () => {
  try {
    // 1. Lấy danh sách roles
    const rolesSnapshot = await getDocs(collection(db, 'roles'));
    const roles = rolesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // 2. Lấy số lượng users cho mỗi role
    const usersCountPromises = roles.map(async (role) => {
      const usersQuery = query(collection(db, 'users'), where('roleId', '==', role.id));
      const usersSnapshot = await getDocs(usersQuery);
      return {
        roleId: role.id,
        count: usersSnapshot.size,
      };
    });

    const usersCounts = await Promise.all(usersCountPromises);

    // 3. Map số lượng users vào roles
    const rolesWithUserCount = roles.map((role) => {
      const userCount = usersCounts.find((uc) => uc.roleId === role.id)?.count || 0;
      return {
        ...role,
        userCount,
      };
    });

    return rolesWithUserCount;
  } catch (error) {
    throw new Error('Failed to fetch roles: ' + error.message);
  }
});

export const addRole = createAsyncThunk('roles/add', async (roleData) => {
  try {
    const docRef = await addDoc(collection(db, 'roles'), {
      ...roleData,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    return {
      id: docRef.id,
      ...roleData,
    };
  } catch (error) {
    throw new Error('Failed to add role: ' + error.message);
  }
});

export const updateRole = createAsyncThunk('roles/update', async ({ id, data }) => {
  try {
    const docRef = doc(db, 'roles', id);
    const updateData = {
      ...data,
      updatedAt: serverTimestamp(),
    };

    await updateDoc(docRef, updateData);

    return {
      id,
      ...data,
    };
  } catch (error) {
    throw new Error('Failed to update role: ' + error.message);
  }
});

export const deleteRole = createAsyncThunk('roles/delete', async (id) => {
  try {
    // Check if role has any users
    const usersQuery = query(collection(db, 'users'), where('roleId', '==', id));
    const usersSnapshot = await getDocs(usersQuery);

    if (!usersSnapshot.empty) {
      throw new Error('Cannot delete role that has users assigned');
    }

    await deleteDoc(doc(db, 'roles', id));
    return id;
  } catch (error) {
    throw new Error('Failed to delete role: ' + error.message);
  }
});

// Import default roles and permissions
export const importDefaultData = createAsyncThunk(
  'roles/importDefaults',
  async (_, { rejectWithValue }) => {
    try {
      // Add default permissions
      const permissionPromises = DEFAULT_PERMISSIONS.map(async (permission) => {
        const docRef = await addDoc(collection(db, 'permissions'), {
          ...permission,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        return { id: docRef.id, ...permission };
      });

      const permissions = await Promise.all(permissionPromises);

      // Add default roles
      const roles = [SUPER_ADMIN_ROLE, STAFF_ROLE];
      const rolePromises = roles.map(async (role) => {
        const docRef = await addDoc(collection(db, 'roles'), {
          ...role,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        return { id: docRef.id, ...role };
      });

      const addedRoles = await Promise.all(rolePromises);

      return {
        permissions,
        roles: addedRoles,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialState = {
  roles: [],
  permissions: DEFAULT_PERMISSIONS,
  status: 'idle',
  error: null,
};

// Slice
const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch roles
      .addCase(fetchRoles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.roles = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add role
      .addCase(addRole.fulfilled, (state, action) => {
        state.roles.push(action.payload);
      })
      // Update role
      .addCase(updateRole.fulfilled, (state, action) => {
        const index = state.roles.findIndex((role) => role.id === action.payload.id);
        if (index !== -1) {
          state.roles[index] = action.payload;
        }
      })
      // Delete role
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.roles = state.roles.filter((role) => role.id !== action.payload);
      })
      // Import defaults
      .addCase(importDefaultData.fulfilled, (state, action) => {
        state.roles = action.payload.roles;
        state.permissions = action.payload.permissions;
      });
  },
});

export const { resetStatus } = roleSlice.actions;
export default roleSlice.reducer;
