// src/admin/components/SpecTemplateManager/SpecFieldManager.jsx
import React, { useState, useCallback } from 'react';
import { Plus, X, GripVertical, Edit2 } from 'lucide-react';
import { FIELD_TYPES } from '../../../models/specTemplates';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

// Form Component cho việc thêm/sửa trường
const FieldForm = ({ onSubmit, onClose, initialData }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {
      key: '',
      label: '',
      type: 'text',
      placeholder: '',
      description: '',
      required: false,
      options: [],
    },
  });

  const fieldType = watch('type');
  const [options, setOptions] = useState(initialData?.options || []);
  const [newOption, setNewOption] = useState('');

  const handleAddOption = (e) => {
    e.preventDefault();
    if (newOption.trim()) {
      if (options.includes(newOption.trim())) {
        toast.error('Tùy chọn này đã tồn tại');
        return;
      }
      setOptions([...options, newOption.trim()]);
      setNewOption('');
    }
  };

  const handleRemoveOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const onFormSubmit = (data, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    try {
      // Format key
      data.key = data.key.toLowerCase().replace(/\s+/g, '_');

      // Add options if select/multiselect
      if (fieldType === FIELD_TYPES.SELECT || fieldType === FIELD_TYPES.MULTISELECT) {
        if (options.length === 0) {
          toast.error('Vui lòng thêm ít nhất một tùy chọn');
          return;
        }
        data.options = options;
      }

      onSubmit(data);
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    }
  };

  const handleFormSubmit = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    await handleSubmit(onFormSubmit)(e);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={(e) => {
        e.preventDefault();
        onClose();
      }}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-lg" onClick={(e) => e.stopPropagation()}>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">{initialData ? 'Sửa trường' : 'Thêm trường mới'}</h3>
          <button
            type="button"
            onClick={() => onClose()}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleFormSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Key (định danh)</label>
            <input
              type="text"
              {...register('key', { required: 'Key là bắt buộc' })}
              className={`input mt-1 ${errors.key ? 'border-red-500' : ''}`}
            />
            {errors.key && <p className="mt-1 text-sm text-red-500">{errors.key.message}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Tên trường</label>
            <input
              type="text"
              {...register('label', { required: 'Tên trường là bắt buộc' })}
              className={`input mt-1 ${errors.label ? 'border-red-500' : ''}`}
            />
            {errors.label && <p className="mt-1 text-sm text-red-500">{errors.label.message}</p>}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Loại dữ liệu</label>
            <select {...register('type')} className="input mt-1">
              {Object.entries(FIELD_TYPES).map(([key, value]) => (
                <option key={value} value={value}>
                  {key}
                </option>
              ))}
            </select>
          </div>

          {(fieldType === FIELD_TYPES.SELECT || fieldType === FIELD_TYPES.MULTISELECT) && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Tùy chọn</label>
              <div className="flex gap-2 mt-1">
                <input
                  type="text"
                  value={newOption}
                  onChange={(e) => setNewOption(e.target.value)}
                  className="input flex-1"
                  placeholder="Nhập tùy chọn"
                />
                <button type="button" onClick={handleAddOption} className="btn btn-secondary">
                  Thêm
                </button>
              </div>
              <div className="mt-2 space-y-2">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-gray-50 p-2 rounded"
                  >
                    <span>{option}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveOption(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X size={16} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Placeholder</label>
            <input type="text" {...register('placeholder')} className="input mt-1" />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Mô tả</label>
            <textarea {...register('description')} rows={2} className="input mt-1" />
          </div>

          <div className="flex items-center gap-2">
            <input type="checkbox" {...register('required')} className="rounded text-primary-600" />
            <label className="text-sm font-medium text-gray-700">Bắt buộc</label>
          </div>

          <div className="flex justify-end gap-3 pt-4">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
              className="btn btn-secondary"
            >
              Hủy
            </button>
            <button type="submit" onClick={handleFormSubmit} className="btn btn-primary">
              {initialData ? 'Cập nhật' : 'Thêm'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Main SpecFieldManager Component
const SpecFieldManager = ({ fields = [], onChange }) => {
  const [showFieldForm, setShowFieldForm] = useState(false);
  const [editingField, setEditingField] = useState(null);

  const { canUpdate } = usePermissions();
  const hasUpdatePermission = canUpdate(MODULES.SPECS);

  const handleAddField = useCallback(
    (fieldData) => {
      if (!hasUpdatePermission) {
        toast.error('Bạn không có quyền thêm trường thông số');
        return;
      }
      try {
        if (editingField) {
          // Update existing field
          const updatedFields = fields.map((field) =>
            field.key === editingField.key ? { ...fieldData } : field
          );
          onChange(updatedFields);
          toast.success('Cập nhật trường thành công');
        } else {
          // Check for duplicate key
          if (fields.some((f) => f.key === fieldData.key)) {
            toast.error('Key đã tồn tại');
            return;
          }
          const newFields = [...fields, fieldData];
          onChange(newFields);
          toast.success('Thêm trường mới thành công');
        }
        setShowFieldForm(false);
        setEditingField(null);
      } catch (error) {
        toast.error('Có lỗi xảy ra: ' + error.message);
      }
    },
    [fields, editingField, onChange, hasUpdatePermission]
  );
  const handleOpenAddForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setEditingField(null);
    setShowFieldForm(true);
  };

  const handleEditField = useCallback((field, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setEditingField(field);
    setShowFieldForm(true);
  }, []);

  const handleRemoveField = useCallback(
    (fieldToRemove) => {
      if (!hasUpdatePermission) {
        toast.error('Bạn không có quyền xóa trường thông số');
        return;
      }

      try {
        // Thay vì dùng confirm trực tiếp
        if (!window.confirm('Bạn có chắc muốn xóa trường này?')) return;

        const newFields = fields.filter((field) => field.key !== fieldToRemove.key);
        onChange(newFields);
        toast.success('Xóa trường thành công');
      } catch (error) {
        toast.error('Có lỗi xảy ra: ' + error.message);
      }
    },
    [fields, onChange, hasUpdatePermission]
  );

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;

      try {
        const items = Array.from(fields);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedFields = items.map((field, index) => ({
          ...field,
          order: index,
        }));

        onChange(updatedFields);
      } catch (error) {
        toast.error('Có lỗi xảy ra khi di chuyển trường');
      }
    },
    [fields, onChange]
  );

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="font-medium">Danh sách trường thông số</h3>
        <button
          type="button"
          onClick={handleOpenAddForm}
          className="btn btn-primary flex items-center gap-2"
        >
          <Plus size={16} />
          Thêm trường
        </button>
      </div>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="fields">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
              {fields.map((field, index) => (
                <Draggable key={field.key} draggableId={field.key} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className="bg-white p-4 rounded-lg shadow-sm hover:shadow transition-shadow"
                    >
                      <div className="flex items-center gap-4">
                        <div {...provided.dragHandleProps} className="cursor-move">
                          <GripVertical className="text-gray-400" />
                        </div>

                        <div className="flex-1">
                          <div className="flex items-center gap-2">
                            <span className="font-medium">{field.label}</span>
                            {field.required && <span className="text-xs text-red-500">*</span>}
                          </div>
                          <div className="text-sm text-gray-500 mt-1">
                            <span className="inline-block px-2 py-1 rounded-full bg-gray-100 text-xs">
                              {field.type}
                            </span>
                            {field.description && <span className="ml-2">{field.description}</span>}
                          </div>
                        </div>

                        <div className="flex items-center gap-2">
                          <button
                            onClick={(e) => handleEditField(field, e)}
                            className="p-1 text-blue-600 hover:bg-blue-50 rounded"
                          >
                            <Edit2 size={16} />
                          </button>
                          <button
                            onClick={() => handleRemoveField(field)}
                            className="p-1 text-red-600 hover:bg-red-50 rounded"
                          >
                            <X size={16} />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {showFieldForm && (
        <FieldForm
          onSubmit={handleAddField}
          onClose={() => {
            setShowFieldForm(false);
            setEditingField(null);
          }}
          initialData={editingField}
        />
      )}
    </div>
  );
};

export default SpecFieldManager;
