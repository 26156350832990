import React from 'react';
import { Zap, Tag, BarChart, AlertTriangle, Star, XCircle } from 'lucide-react';

const STATUS_CONFIG = {
  hot: { icon: Zap, color: 'bg-red-500', text: 'HOT' },
  new: { icon: Tag, color: 'bg-green-500', text: 'NEW' },
  sale: { icon: BarChart, color: 'bg-blue-500', text: 'SALE' },
  'out-stock': { icon: AlertTriangle, color: 'bg-gray-500', text: 'Hết hàng' },
  featured: { icon: Star, color: 'bg-yellow-500', text: 'Nổi bật' },
  discontinued: { icon: XCircle, color: 'bg-red-500', text: 'Ngừng kinh doanh' },
};

const StatusBadge = ({ status, className = '' }) => {
  const config = STATUS_CONFIG[status];
  if (!config) return null;

  const Icon = config.icon;
  return (
    <span
      className={`inline-flex items-center gap-1 px-2 py-1 text-xs font-medium text-white rounded-full ${config.color} ${className}`}
    >
      <Icon size={12} />
      {config.text}
    </span>
  );
};

export default StatusBadge;
