// src/components/admin/OrderManager/components/OrderStats.jsx
import React from 'react';
import { ShoppingCart, CheckCircle, DollarSign, RotateCcw } from 'lucide-react';
import { ORDER_STATUS, PAYMENT_STATUS } from '../../constants';

const OrderStats = ({ orders = [] }) => {
  const totalOrders = orders.length;

  const getStats = () => {
    if (totalOrders === 0) return { completed: 0, paid: 0 };

    const completed = orders.filter((order) => order.status === ORDER_STATUS.DELIVERED).length;

    const paid = orders.filter((order) => {
      // Chỉ tính các đơn PAID và không phải đơn hủy/trả hàng
      return (
        order.payment_status === PAYMENT_STATUS.PAID &&
        !['cancelled', 'returned'].includes(order.status)
      );
    }).length;

    return {
      completed: Math.round((completed / totalOrders) * 100),
      paid: Math.round((paid / totalOrders) * 100),
    };
  };

  const stats = getStats();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-sm font-medium text-gray-600">Tổng đơn hàng</p>
            <p className="text-2xl font-semibold mt-2">{totalOrders}</p>
          </div>
          <div className="p-3 bg-primary-100 rounded-lg">
            <ShoppingCart className="w-6 h-6 text-primary-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-sm font-medium text-gray-600">Hoàn thành</p>
            <p className="text-2xl font-semibold mt-2">
              {orders.filter((order) => order.status === ORDER_STATUS.DELIVERED).length}
            </p>
            {totalOrders > 0 && (
              <p className="text-sm text-gray-500 mt-1">{stats.completed}% tổng đơn</p>
            )}
          </div>
          <div className="p-3 bg-green-100 rounded-lg">
            <CheckCircle className="w-6 h-6 text-green-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-sm font-medium text-gray-600">Đã thanh toán</p>
            <p className="text-2xl font-semibold mt-2">
              {/* Chỉ đếm đơn có trạng thái PAID */}
              {orders.filter((order) => order.payment_status === PAYMENT_STATUS.PAID).length}
            </p>
            {totalOrders > 0 && (
              <p className="text-sm text-gray-500 mt-1">{stats.paid}% tổng đơn</p>
            )}
          </div>
          <div className="p-3 bg-blue-100 rounded-lg">
            <DollarSign className="w-6 h-6 text-blue-600" />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="flex justify-between items-start">
          <div>
            <p className="text-sm font-medium text-gray-600">Hoàn/Trả</p>
            <p className="text-2xl font-semibold mt-2">
              {
                orders.filter(
                  (order) =>
                    order.status === ORDER_STATUS.RETURNED ||
                    order.status === ORDER_STATUS.RETURNING
                ).length
              }
            </p>
          </div>
          <div className="p-3 bg-orange-100 rounded-lg">
            <RotateCcw className="w-6 h-6 text-orange-600" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStats;
