// src/admin/components/OrderManager/components/CreateOrder/index.jsx
import React, { useState } from 'react';
import { X } from 'lucide-react';
import { toast } from 'react-hot-toast';

import { OrderSourceSection } from './components/OrderForm';
import { PaymentSection } from './components/PaymentSection';
import { ShippingSection } from './components/ShippingSection';
import { OrderSummary } from './components/OrderSummary';
import SelectedProducts from './components/SelectedProducts';
import { ProductSection } from './components/ProductSection';
import ProductSelector from './components/ProductSelector';
const CreateOrderModal = ({ onClose, onCreateOrder }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  // Form state
  const [orderData, setOrderData] = useState({
    source: 'WEBSITE',
    external_id: '',
    payment_method: 'COD',
    payment_status: 'pending',
    shipping_provider: '',
    tracking_number: '',
    shipping_fee: 0,
    note: '',
    shipping_address: {
      fullName: '',
      phone: '',
      email: '',
      address: '',
      ward: '',
      district: '',
      city: '',
    },
  });

  const updateOrderData = (field, value) => {
    setOrderData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleShippingChange = {
    provider: (value) => updateOrderData('shipping_provider', value),
    tracking_number: (value) => updateOrderData('tracking_number', value),
    fee: (value) => updateOrderData('shipping_fee', value),
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const calculateTotal = () => {
    const subtotal = selectedProducts.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const shipping_fee = Number(orderData.shipping_fee) || 0; // Lấy từ orderData
    return {
      subtotal,
      shipping_fee,
      total: subtotal + shipping_fee,
    };
  };

  const validateForm = () => {
    const errors = {};

    if (!orderData.shipping_address.fullName) {
      errors.fullName = 'Vui lòng nhập họ tên người nhận';
    }

    if (!orderData.shipping_address.phone) {
      errors.phone = 'Vui lòng nhập số điện thoại';
    }

    if (selectedProducts.length === 0) {
      errors.products = 'Vui lòng chọn ít nhất một sản phẩm';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      setIsSubmitting(true);
      const totals = calculateTotal();

      const formattedOrder = {
        ...orderData,
        items: selectedProducts.map((item) => ({
          productId: item.id,
          name: item.name,
          sku: item.sku,
          image: item.image,
          price: item.price,
          quantity: item.quantity,
          total: item.price * item.quantity,
        })),
        shipping_info: {
          provider: orderData.shipping_provider || '',
          tracking_number: orderData.tracking_number || '',
          fee: orderData.shipping_fee || 0,
          estimated_delivery: null,
        },
        ...totals,
        orderDate: new Date().toISOString(),
      };

      await onCreateOrder(formattedOrder);
      onClose();
    } catch (error) {
      toast.error('Lỗi khi tạo đơn hàng: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 overflow-y-auto">
      <div className="bg-gray-100 w-full max-w-6xl mx-auto my-4 flex flex-col max-h-[90vh] rounded-lg shadow-xl">
        {/* Header */}
        <div className="px-6 py-4 bg-white rounded-t-lg border-b flex items-center justify-between">
          <h2 className="text-xl font-bold text-gray-900">Tạo đơn hàng mới</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
            disabled={isSubmitting}
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-auto p-6">
          <div className="grid grid-cols-3 gap-6">
            {/* Left Column - Main Form */}
            <div className="col-span-2 space-y-6">
              {/* Order Source */}
              <OrderSourceSection
                source={orderData.source}
                externalId={orderData.external_id}
                onSourceChange={(option) =>
                  setOrderData((prev) => ({ ...prev, source: option.value }))
                }
                onExternalIdChange={(value) =>
                  setOrderData((prev) => ({ ...prev, external_id: value }))
                }
                disabled={isSubmitting}
              />

              {/* Products Section */}
              <ProductSection>
                <div className="space-y-4">
                  <div className="relative">
                    <ProductSelector
                      selectedProducts={selectedProducts}
                      onChange={setSelectedProducts}
                      disabled={isSubmitting}
                    />
                    {errors.products && (
                      <p className="absolute -bottom-6 left-0 text-red-500 text-sm">
                        {errors.products}
                      </p>
                    )}
                  </div>

                  <div className="mt-6">
                    <SelectedProducts
                      products={selectedProducts}
                      onChange={setSelectedProducts}
                      disabled={isSubmitting}
                    />
                  </div>
                </div>
              </ProductSection>

              {/* Payment Info */}
              <PaymentSection
                method={orderData.payment_method}
                status={orderData.payment_status}
                onMethodChange={(option) => {
                  setOrderData((prev) => ({
                    ...prev,
                    payment_method: option.value,
                    payment_status: option.value === 'COD' ? 'pending' : prev.payment_status,
                  }));
                }}
                onStatusChange={(option) =>
                  setOrderData((prev) => ({ ...prev, payment_status: option.value }))
                }
                disabled={isSubmitting}
              />

              {/* Shipping Info */}
              <ShippingSection
                address={orderData.shipping_address}
                tracking_number={orderData.tracking_number}
                provider={orderData.shipping_provider}
                onTrackingNumberChange={handleShippingChange.tracking_number}
                fee={orderData.shipping_fee}
                errors={errors}
                onAddressChange={(field, value) =>
                  setOrderData((prev) => ({
                    ...prev,
                    shipping_address: {
                      ...prev.shipping_address,
                      [field]: value,
                    },
                  }))
                }
                onProviderChange={(option) =>
                  setOrderData((prev) => ({ ...prev, shipping_provider: option.value }))
                }
                onFeeChange={(value) => setOrderData((prev) => ({ ...prev, shipping_fee: value }))}
                disabled={isSubmitting}
              />
            </div>

            {/* Right Column - Order Summary */}
            <div className="space-y-6">
              <div className="sticky top-6">
                <OrderSummary
                  subtotal={calculateTotal().subtotal}
                  shippingFee={orderData.shipping_fee}
                  total={calculateTotal().total}
                />

                {/* Note */}
                <div className="bg-white p-6 rounded-lg border mt-6">
                  <label className="block text-sm font-medium text-gray-700 mb-2">Ghi chú</label>
                  <textarea
                    value={orderData.note}
                    onChange={(e) => setOrderData((prev) => ({ ...prev, note: e.target.value }))}
                    rows={4}
                    className="input w-full"
                    placeholder="Nhập ghi chú cho đơn hàng..."
                    disabled={isSubmitting}
                  />
                </div>

                {/* Create Order Button */}
                <button
                  onClick={handleSubmit}
                  disabled={isSubmitting || selectedProducts.length === 0}
                  className="w-full btn btn-primary mt-6 py-3 text-base font-medium"
                >
                  {isSubmitting ? (
                    <div className="flex items-center justify-center gap-2">
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      <span>Đang tạo đơn...</span>
                    </div>
                  ) : (
                    'Tạo đơn hàng'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 bg-white border-t rounded-b-lg">
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-500">{selectedProducts.length} sản phẩm</div>
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Hủy
              </button>
            </div>
          </div>
        </div>

        {/* Loading Overlay */}
        {isSubmitting && (
          <div className="absolute inset-0 bg-white/50 flex items-center justify-center rounded-lg">
            <div className="bg-white p-4 rounded-full shadow-lg">
              <div className="w-8 h-8 border-4 border-primary-600 border-t-transparent rounded-full animate-spin" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateOrderModal;
