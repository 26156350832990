import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    sidebarOpen: true,
    // Category UI states
    categoryFormOpen: false,
    editingCategory: null,
    // Product UI states
    productFormOpen: false,
    editingProduct: null,
    // Spec Template UI states
    specTemplateFormOpen: false,
    editingSpecTemplate: null,
    editingSpecField: null,
    // Promotion UI states
    promotionFormOpen: false,
    editingPromotion: null,
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen;
    },
    // Category actions
    toggleCategoryForm: (state) => {
      state.categoryFormOpen = !state.categoryFormOpen;
      if (!state.categoryFormOpen) {
        state.editingCategory = null;
      }
    },
    setEditingCategory: (state, action) => {
      state.editingCategory = action.payload;
      state.categoryFormOpen = true;
    },
    clearEditingCategory: (state) => {
      state.editingCategory = null;
      state.categoryFormOpen = false;
    },
    // Product actions
    toggleProductForm: (state) => {
      state.productFormOpen = !state.productFormOpen;
      if (!state.productFormOpen) {
        state.editingProduct = null;
      }
    },
    setEditingProduct: (state, action) => {
      state.editingProduct = action.payload;
      state.productFormOpen = true;
    },
    clearEditingProduct: (state) => {
      state.editingProduct = null;
      state.productFormOpen = false;
    },
    // Spec Template actions
    toggleSpecTemplateForm: (state) => {
      state.specTemplateFormOpen = !state.specTemplateFormOpen;
      if (!state.specTemplateFormOpen) {
        state.editingSpecTemplate = null;
        state.editingSpecField = null;
      }
    },
    setEditingSpecTemplate: (state, action) => {
      state.editingSpecTemplate = action.payload;
      state.specTemplateFormOpen = true;
    },
    clearEditingSpecTemplate: (state) => {
      state.editingSpecTemplate = null;
      state.editingSpecField = null;
      state.specTemplateFormOpen = false;
    },
    setEditingSpecField: (state, action) => {
      state.editingSpecField = action.payload;
    },
    clearEditingSpecField: (state) => {
      state.editingSpecField = null;
    },
    // Promotion actions
    togglePromotionForm: (state) => {
      state.promotionFormOpen = !state.promotionFormOpen;
      if (!state.promotionFormOpen) {
        state.editingPromotion = null;
      }
    },
    setEditingPromotion: (state, action) => {
      state.editingPromotion = action.payload;
      state.promotionFormOpen = true;
    },
    clearEditingPromotion: (state) => {
      state.editingPromotion = null;
      state.promotionFormOpen = false;
    },
  },
});

export const {
  toggleSidebar,
  // Category actions
  toggleCategoryForm,
  setEditingCategory,
  clearEditingCategory,
  // Product actions
  toggleProductForm,
  setEditingProduct,
  clearEditingProduct,
  // Spec Template actions
  toggleSpecTemplateForm,
  setEditingSpecTemplate,
  clearEditingSpecTemplate,
  setEditingSpecField,
  clearEditingSpecField,
  // Promotion actions
  togglePromotionForm,
  setEditingPromotion,
  clearEditingPromotion,
} = uiSlice.actions;

export default uiSlice.reducer;
