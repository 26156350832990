import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addCategory, fetchCategories, updateCategory } from '../../../store/categorySlice';
import { clearEditingCategory } from '../../../store/uiSlice';
import {
  // Giao diện chung
  X,
  Search,
  Home,
  ShoppingCart,
  Tags,
  LayoutDashboard,
  Settings,
  ShoppingBag,
  Package,
  Percent,
  Users,
  UserCog,
  FileText,
  ImagePlus,
  Trash2,

  // Thiết bị điện tử
  Laptop,
  Monitor,
  Cpu,
  HardDrive,
  Keyboard,
  Mouse,
  Headphones,
  Speaker,
  Smartphone,
  Tablet,
  Router,
  Wifi,
  Bluetooth,
  Battery,
  Camera,
  Radio,
  Printer,
  Server,
  Database,
  MemoryStick,
  Microchip,
  Disc,
  Usb,
  Cable,

  // Linh kiện máy tính
  Fan,
  PowerSquare,
  Gauge,
  CircuitBoard,
  Component,
  MonitorDot,
  Power,
  PcCase,
  MonitorUp,
  MonitorCheck,
  GalleryVerticalEnd,

  // Gaming & Accessories
  Gamepad2,
  Dices,
  Joystick,
  MonitorPlay,
  HeadphonesIcon,
} from 'lucide-react';
import toast from 'react-hot-toast';
import { MODULES } from '../../../models/userModels';
import { usePermissions } from '../../../hooks/usePermissions';
import Select from 'react-select';
import IconSelector from './IconSelector';
import { BRAND_ICONS } from '../../constants/icons';
// import BrandIconSelector from './BrandIconSelector';

const BrandIconSelector = ({ value, onChange }) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  return (
    <div className="space-y-4">
      <select
        className="input w-full"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">Chọn nhóm thương hiệu</option>
        {Object.keys(BRAND_ICONS).map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>

      {selectedCategory && (
        <div className="grid grid-cols-4 md:grid-cols-6 gap-4 p-4 bg-gray-50 rounded-lg">
          {Object.entries(BRAND_ICONS[selectedCategory]).map(([key, { icon, label, url }]) => (
            <button
              key={key}
              type="button"
              onClick={() => onChange(icon)}
              className={`flex flex-col items-center gap-2 p-3 rounded-lg hover:bg-white 
                transition-colors ${value === icon ? 'bg-white ring-2 ring-primary-500' : ''}`}
              title={`${label} - ${url}`}
            >
              <div className="w-8 h-8 relative">
                <img
                  src={icon}
                  alt={label}
                  className="w-full h-full object-contain"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/fallback-icon.png';
                  }}
                />
              </div>
              <span className="text-xs text-gray-600 text-center">{label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const CategoryForm = () => {
  const dispatch = useDispatch();
  const { editingCategory } = useSelector((state) => state.ui);
  const { items: categories, status } = useSelector((state) => state.categories);
  const { canCreate, canUpdate } = usePermissions();
  const hasCreatePermission = canCreate(MODULES.CATEGORIES);
  const hasUpdatePermission = canUpdate(MODULES.CATEGORIES);

  // State for image handling
  const [imagePreview, setImagePreview] = useState(editingCategory?.image || null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const fileInputRef = React.useRef(null);
  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchCategories());
    }
  }, [dispatch, status]);

  // Định nghĩa danh sách icons có sẵn
  const iconComponents = {
    // Giao diện chung
    Search,
    Home,
    ShoppingCart,
    Tags,
    LayoutDashboard,
    Settings,
    ShoppingBag,
    Package,
    Percent,
    Users,
    UserCog,
    FileText,

    // Thiết bị điện tử
    Laptop,
    Monitor,
    Cpu,
    HardDrive,
    Keyboard,
    Mouse,
    Headphones,
    Speaker,
    Smartphone,
    Tablet,
    Router,
    Wifi,
    Bluetooth,
    Battery,
    Camera,
    Radio,
    Printer,
    Server,
    Database,
    MemoryStick,
    Microchip,
    Disc,
    Usb,
    Cable,

    // Linh kiện máy tính
    Fan,
    PowerSquare,
    Gauge,
    CircuitBoard,
    Component,
    MonitorDot,
    Power,
    PcCase,
    MonitorUp,
    MonitorCheck,
    GalleryVerticalEnd,

    // Gaming & Accessories
    Gamepad2,
    Dices,
    Joystick,
    MonitorPlay,
    HeadphonesIcon,
  };

  // Form initialization
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: editingCategory || {
      name: '',
      description: '',
      parent_id: '',
      display_order: 0,
      isActive: true,
      icon: '',
      iconType: '',
      image: '',
    },
  });
  const iconTypeOptions = [
    { value: '', label: 'Không có icon' },
    { value: 'lucide', label: 'Lucide Icon' },
    { value: 'brand', label: 'Brand Icon' },
    { value: 'url', label: 'URL Icon' },
  ];
  // Image handling functions
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        toast.error('Vui lòng chọn file ảnh');
        return;
      }

      // Validate file size (max 2MB)
      if (file.size > 2 * 1024 * 1024) {
        toast.error('Kích thước ảnh tối đa là 2MB');
        return;
      }

      setUploadedImage(file);
      setImagePreview(URL.createObjectURL(file));
      setValue('image', URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setUploadedImage(null);
    setValue('image', '');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  const renderIconSelection = () => {
    const iconType = watch('iconType');

    switch (iconType) {
      case 'lucide':
        return (
          <div className="flex-1">
            <IconSelector value={watch('icon')} onChange={(icon) => setValue('icon', icon)} />
          </div>
        );

      case 'brand':
        return (
          <div className="flex-1">
            <BrandIconSelector value={watch('icon')} onChange={(icon) => setValue('icon', icon)} />
          </div>
        );

      case 'url':
        return (
          <input
            type="text"
            {...register('icon')}
            placeholder="Nhập URL icon"
            className="input flex-1"
          />
        );

      default:
        return null;
    }
  };
  // Render icon preview function
  const renderIconPreview = (iconName) => {
    const IconComponent = iconComponents[iconName];
    return IconComponent ? <IconComponent size={24} /> : null;
  };

  // Form submission
  const onSubmit = async (data) => {
    try {
      const submitData = {
        ...data,
        uploadedImage: uploadedImage, // Pass uploaded image file
      };

      if (editingCategory) {
        if (!hasUpdatePermission) {
          toast.error('Bạn không có quyền cập nhật danh mục');
          return;
        }
        await dispatch(updateCategory({ id: editingCategory.id, data: submitData })).unwrap();
        toast.success('Cập nhật danh mục thành công');
      } else {
        if (!hasCreatePermission) {
          toast.error('Bạn không có quyền thêm danh mục');
          return;
        }
        await dispatch(addCategory(submitData)).unwrap();
        toast.success('Thêm danh mục thành công');
      }
      handleClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    }
  };

  // Close form and reset
  const handleClose = () => {
    dispatch(clearEditingCategory());
    reset();
    setImagePreview(null);
    setUploadedImage(null);
  };

  // Get all descendant category IDs
  const getDescendantIds = useCallback(
    (categoryId) => {
      const descendants = [];
      const findChildren = (parentId) => {
        categories
          .filter((cat) => cat.parent_id === parentId)
          .forEach((cat) => {
            descendants.push(cat.id);
            findChildren(cat.id);
          });
      };
      findChildren(categoryId);
      return descendants;
    },
    [categories]
  );

  // Usage in component:
  const categoryOptions = useMemo(() => {
    const buildOptions = (parentId = null, level = 0) => {
      const currentParentId = parentId || '';
      const levelCategories = categories.filter((cat) => (cat.parent_id || '') === currentParentId);

      let options = [];
      levelCategories.forEach((category) => {
        if (editingCategory && category.id === editingCategory.id) {
          return;
        }

        options.push({
          value: category.id,
          label: `${'--'.repeat(level)} ${category.name}`,
        });

        const childOptions = buildOptions(category.id, level + 1);
        options = [...options, ...childOptions];
      });

      return options;
    };

    // Luôn trả về ít nhất option "Không có" ngay cả khi không có categories
    return [{ value: '', label: 'Không có' }, ...buildOptions()];
  }, [categories, editingCategory]);
  if (status === 'loading') {
    return <div>Đang tải danh mục...</div>;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl my-4">
        {/* Add scrollable content area */}
        <div className="max-h-[80vh] overflow-y-auto">
          {/* Header - keep fixed */}
          <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold">
                {editingCategory ? 'Sửa danh mục' : 'Thêm danh mục mới'}
              </h2>
              <button onClick={handleClose} className="p-1 hover:bg-gray-100 rounded">
                <X size={20} />
              </button>
            </div>
          </div>
          <div className="p-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
              {/* Category Name */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Tên danh mục</label>
                <input
                  type="text"
                  {...register('name', { required: 'Vui lòng nhập tên danh mục' })}
                  className="input mt-1"
                />
                {errors.name && <span className="text-sm text-red-500">{errors.name.message}</span>}
              </div>

              {/* Icon Selection */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">Icon</label>
                <div className="space-y-4">
                  <Select
                    {...register('iconType')}
                    options={iconTypeOptions}
                    onChange={(selectedOption) => {
                      setValue('iconType', selectedOption?.value || '');
                      setValue('icon', '');
                    }}
                    value={iconTypeOptions.find((option) => option.value === watch('iconType'))}
                    isClearable={false}
                  />

                  {renderIconSelection()}
                </div>
              </div>

              {/* Icon Preview */}
              {watch('icon') && (
                <div className="p-4 bg-gray-50 rounded-lg">
                  <p className="text-sm font-medium mb-2">Preview Icon:</p>
                  <div className="w-8 h-8 flex items-center justify-center">
                    {watch('iconType') === 'lucide' && renderIconPreview(watch('icon'))}
                    {watch('iconType') === 'url' && (
                      <img src={watch('icon')} alt="Icon" className="w-8 h-8 object-contain" />
                    )}
                  </div>
                </div>
              )}

              {/* Image Upload */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Ảnh danh mục</label>
                <div className="space-y-2">
                  {/* Image Preview */}
                  {imagePreview && (
                    <div className="relative w-40 h-40 group">
                      <img
                        src={imagePreview}
                        alt="Category preview"
                        className="w-full h-full object-cover rounded-lg border border-gray-200"
                      />
                      <button
                        type="button"
                        onClick={handleRemoveImage}
                        className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  )}

                  {/* Upload Button */}
                  {!imagePreview && (
                    <div
                      onClick={() => fileInputRef.current?.click()}
                      className="w-40 h-40 border-2 border-dashed border-gray-300 rounded-lg flex flex-col items-center justify-center cursor-pointer hover:border-primary-500 transition-colors"
                    >
                      <ImagePlus className="w-8 h-8 text-gray-400" />
                      <span className="mt-2 text-sm text-gray-500">Tải ảnh lên</span>
                    </div>
                  )}

                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    accept="image/*"
                    className="hidden"
                  />

                  <p className="text-xs text-gray-500">Cho phép PNG, JPG, JPEG (tối đa 2MB)</p>
                </div>
              </div>

              {/* Parent Category */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Danh mục cha</label>
                <Select
                  {...register('parent_id')}
                  className="mt-1"
                  options={categoryOptions}
                  onChange={(selectedOption) => {
                    const event = {
                      target: {
                        name: 'parent_id',
                        value: selectedOption ? selectedOption.value : '',
                      },
                    };
                    register('parent_id').onChange(event);
                  }}
                  value={
                    categoryOptions.find((option) => option.value === watch('parent_id')) || null
                  }
                  isClearable
                  placeholder="Chọn danh mục cha"
                  classNamePrefix="select"
                  isOptionDisabled={(option) => {
                    if (!editingCategory) return false;
                    return (
                      option.value === editingCategory.id ||
                      getDescendantIds(editingCategory.id).includes(option.value)
                    );
                  }}
                />
              </div>

              {/* Description */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Mô tả</label>
                <textarea {...register('description')} className="input mt-1" rows={3} />
              </div>

              {/* Display Order */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Thứ tự hiển thị</label>
                <input
                  type="number"
                  {...register('display_order', { min: 0 })}
                  className="input mt-1"
                />
              </div>

              {/* Active Status */}
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  {...register('isActive')}
                  className="rounded text-primary-600"
                />
                <label className="text-sm font-medium text-gray-700">Kích hoạt</label>
              </div>

              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  {...register('isFeatured')}
                  className="rounded text-primary-600"
                />
                <label className="text-sm font-medium text-gray-700">Hiển thị trên trang chủ</label>
              </div>

              {/* Form Actions */}
              <div className="sticky bottom-0 bg-white px-6 py-4 border-t border-gray-200">
                <div className="flex justify-end gap-3">
                  <button type="button" onClick={handleClose} className="btn btn-secondary">
                    Hủy
                  </button>
                  <button
                    type="submit" // Thêm type="submit"
                    className="btn btn-primary"
                    disabled={status === 'loading'} // Thêm disabled state
                  >
                    {status === 'loading' ? (
                      <div className="flex items-center gap-2">
                        <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                        Đang xử lý...
                      </div>
                    ) : editingCategory ? (
                      'Cập nhật'
                    ) : (
                      'Thêm mới'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryForm;
