import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase/config';
import { uploadToCloudinary } from '../utils/cloudinary';

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  isLoading: false,
};

// const convertTimestampToDate = (data) => {
//   const converted = { ...data };
//   if (converted.createdAt && typeof converted.createdAt.toDate === 'function') {
//     converted.createdAt = converted.createdAt.toDate().toISOString();
//   }
//   if (converted.updatedAt && typeof converted.updatedAt.toDate === 'function') {
//     converted.updatedAt = converted.updatedAt.toDate().toISOString();
//   }
//   return converted;
// };

const prepareDataForFirestore = (data) => {
  // Explicitly remove uploadedImage and handle other undefined values
  const { uploadedImage, ...rest } = data;

  // Create clean object without undefined values
  const cleanData = Object.entries(rest).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});

  return cleanData;
};

export const fetchCategories = createAsyncThunk('categories/fetchCategories', async () => {
  try {
    // Thêm điều kiện kiểm tra trước khi fetch
    const querySnapshot = await getDocs(collection(db, 'categories'));
    const categories = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return categories;
  } catch (error) {
    throw new Error('Failed to fetch categories: ' + error.message);
  }
});

export const addCategory = createAsyncThunk('categories/addCategory', async (categoryData) => {
  try {
    // Handle image upload
    let imageUrl = null;
    if (categoryData.uploadedImage) {
      try {
        imageUrl = await uploadToCloudinary(categoryData.uploadedImage);
      } catch (uploadError) {
        throw new Error('Failed to upload image: ' + uploadError.message);
      }
    }

    // Prepare data for Firestore
    const dataToSave = prepareDataForFirestore({
      ...categoryData,
      image: imageUrl || null, // Ensure image is null if no URL
      createdAt: new Date(),
      updatedAt: new Date(),
    });

    // Save to Firestore
    const docRef = await addDoc(collection(db, 'categories'), dataToSave);

    return {
      id: docRef.id,
      ...dataToSave,
    };
  } catch (error) {
    throw new Error('Failed to add category: ' + error.message);
  }
});

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async ({ id, data }) => {
    try {
      // Get current category data to preserve existing image if no new upload
      const docRef = doc(db, 'categories', id);

      // Handle image upload if there's a new image
      let imageUrl = data.image; // Keep existing image URL by default
      if (data.uploadedImage) {
        try {
          imageUrl = await uploadToCloudinary(data.uploadedImage);
        } catch (uploadError) {
          throw new Error('Failed to upload image: ' + uploadError.message);
        }
      }

      // Prepare data for Firestore
      const dataToUpdate = prepareDataForFirestore({
        ...data,
        image: imageUrl || null, // Ensure image is null if no URL
        updatedAt: new Date(),
      });

      // Update in Firestore
      await updateDoc(docRef, dataToUpdate);

      return {
        id,
        ...dataToUpdate,
      };
    } catch (error) {
      throw new Error('Failed to update category: ' + error.message);
    }
  }
);

export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (id) => {
  try {
    await deleteDoc(doc(db, 'categories', id));
    return id;
  } catch (error) {
    throw new Error('Failed to delete category: ' + error.message);
  }
});

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch categories
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add category
      .addCase(addCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items.push(action.payload);
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Update category
      .addCase(updateCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Delete category
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload);
      });
  },
});

export default categorySlice.reducer;
