// src/client/pages/Home/index.jsx
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchProducts } from '../../../store/productSlice';
import { fetchClientCategories } from '../../features/categorySlice';
import { fetchFlashSales } from '../../../store/flashSaleSlice';

import CategorySection from '../../components/home/CategorySection';
import FlashSaleSection from '../../components/home/FlashSaleSection';
import FeaturedProducts from '../../components/home/FeaturedProducts';
import HotProducts from '../../components/home/HotProducts';
import BestSellers from '../../components/home/BestSellers';
import NewArrivals from '../../components/home/NewArrivals';
import FeaturedTags from '../../components/home/FeaturedTags';
import Newsletter from '../../components/home/Newsletter';
import TrustBadges from '../../components/home/TrustBadges';
import HeroWithMenu from '../../components/home/HeroWithMenu';

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchClientCategories());
    dispatch(fetchFlashSales());
  }, [dispatch]);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section with Category Menu */}
      <section className=" py-8">
        <HeroWithMenu />
      </section>

      {/* Categories */}
      {/* <section className="py-1">
        <CategorySection />
      </section> */}

      {/* Flash Sale */}
      <section className="bg-gradient-to-br from-red-500 to-purple-600 py-12">
        <FlashSaleSection />
      </section>

      {/* Hot Products */}
      <section className="py-12 bg-white">
        <HotProducts />
      </section>

      {/* Featured Products */}
      <section className="py-12 bg-gray-50">
        <FeaturedProducts />
      </section>

      {/* Best Sellers */}
      <section className="py-12 bg-white">
        <BestSellers />
      </section>

      {/* New Arrivals */}
      <section className="py-12 bg-gray-50">
        <NewArrivals />
      </section>

      {/* Featured Tags */}
      <section className="py-12 bg-white">
        <FeaturedTags />
      </section>

      {/* Trust Badges */}
      <section className="py-12 bg-gray-50">
        <TrustBadges />
      </section>

      {/* Newsletter */}
      <Newsletter />
    </div>
  );
};

export default HomePage;
