import { collection, getDocs, query, where, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './config';
import { USER_TYPE, DEFAULT_PERMISSIONS, SUPER_ADMIN_ROLE } from '../models/userModels';
import { hashPassword } from '../utils/passwordUtils';

const DEFAULT_ADMIN = {
  username: 'admin', // Tài khoản mặc định
  password: 'Admin@123', // Sẽ được hash trước khi lưu
  email: 'admin@example.com',
  fullName: 'Super Admin',
  type: USER_TYPE.ADMIN,
  roleId: 'super_admin',
  status: 'active',
  isDefaultPassword: true,
};

const initializeAdmin = async () => {
  try {
    // Kiểm tra role Super Admin
    const rolesQuery = query(collection(db, 'roles'), where('name', '==', 'Super Admin'));
    const rolesSnapshot = await getDocs(rolesQuery);

    let superAdminRoleId;

    if (rolesSnapshot.empty) {
      // Tạo role Super Admin
      const roleDoc = await addDoc(collection(db, 'roles'), {
        ...SUPER_ADMIN_ROLE,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      superAdminRoleId = roleDoc.id;
    } else {
      superAdminRoleId = rolesSnapshot.docs[0].id;
    }

    // Kiểm tra xem đã có tài khoản admin chưa
    const adminQuery = query(
      collection(db, 'users'),
      where('username', '==', DEFAULT_ADMIN.username)
    );

    const adminSnapshot = await getDocs(adminQuery);

    if (adminSnapshot.empty) {
      // Hash password trước khi lưu
      const hashedPassword = await hashPassword(DEFAULT_ADMIN.password);

      // Tạo tài khoản admin với role Super Admin
      const adminData = {
        ...DEFAULT_ADMIN,
        password: hashedPassword,
        roleId: superAdminRoleId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'users'), adminData);
      console.log('Created default admin account');
    }

    // Kiểm tra và tạo permissions nếu chưa tồn tại
    for (const permission of DEFAULT_PERMISSIONS) {
      const permQuery = query(collection(db, 'permissions'), where('code', '==', permission.code));
      const permSnapshot = await getDocs(permQuery);

      if (permSnapshot.empty) {
        await addDoc(collection(db, 'permissions'), {
          ...permission,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
      }
    }

    console.log('Successfully initialized admin account and permissions');
  } catch (error) {
    console.error('Error initializing admin:', error);
  }
};

export default initializeAdmin;
