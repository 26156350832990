import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Zap, Timer, ArrowRight } from 'lucide-react';
import { fetchFlashSaleProducts } from '../../../store/flashSaleProductSlice';
import ProductGrid from '../common/product/ProductGrid';

const calculateTimeLeft = (endTime) => {
  const difference = new Date(endTime) - new Date();
  if (difference <= 0) return null;

  return {
    hours: Math.floor(difference / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((difference % (1000 * 60)) / 1000),
  };
};

const FlashSaleSection = () => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(null);

  // Selectors
  const flashSales = useSelector((state) => state.flashSales.items);
  const flashSaleProducts = useSelector((state) => state.flashSaleProducts.items);
  const allProducts = useSelector((state) => state.products.items);

  // Find active flash sale - memoized to prevent unnecessary recalculations
  const activeFlashSale = useMemo(() => {
    return flashSales.find((sale) => {
      const now = new Date();
      const startTime = new Date(sale.startTime);
      const endTime = new Date(sale.endTime);
      return now >= startTime && now <= endTime;
    });
  }, [flashSales]);

  // Prepare sale products - memoized
  const saleProducts = useMemo(() => {
    if (!activeFlashSale || !flashSaleProducts.length || !allProducts.length) return [];

    return flashSaleProducts
      .filter((product) => product.flashSaleId === activeFlashSale.id)
      .map((flashSaleProduct) => {
        const baseProduct = allProducts.find((p) => p.id === flashSaleProduct.productId);
        if (!baseProduct) return null;

        return {
          ...baseProduct,
          originalPrice: baseProduct.price,
          price: flashSaleProduct.salePrice,
          stockLimit: flashSaleProduct.stockLimit,
          stockLeft: flashSaleProduct.stockLeft || baseProduct.stock,
          soldCount: flashSaleProduct.soldCount || 0,
          isFlashSale: true,
          flashSaleInfo: {
            ...flashSaleProduct,
            endTime: activeFlashSale.endTime,
          },
        };
      })
      .filter(Boolean)
      .slice(0, 4); // Limit to 4 products
  }, [activeFlashSale, flashSaleProducts, allProducts]);

  // Fetch flash sale products when component mounts
  useEffect(() => {
    if (activeFlashSale?.id && flashSaleProducts.length === 0) {
      dispatch(fetchFlashSaleProducts(activeFlashSale.id));
    }
  }, [dispatch, activeFlashSale?.id, flashSaleProducts.length]);

  // Timer effect
  useEffect(() => {
    if (!activeFlashSale) return;

    const timer = setInterval(() => {
      const remaining = calculateTimeLeft(activeFlashSale.endTime);
      if (remaining) {
        setTimeLeft(remaining);
      } else {
        clearInterval(timer);
      }
    }, 1000);

    // Initial calculation
    setTimeLeft(calculateTimeLeft(activeFlashSale.endTime));

    return () => clearInterval(timer);
  }, [activeFlashSale]);

  if (!activeFlashSale || !timeLeft) return null;

  return (
    <div className="container mx-auto px-4">
      {/* Header with Flash Sale Info */}
      <div className="text-center mb-8">
        <div className="inline-flex items-center bg-white/10 rounded-full px-4 py-2 mb-4">
          <Zap className="text-yellow-400 mr-2" size={20} />
          <span className="text-white font-medium">{activeFlashSale.name}</span>
        </div>

        {/* Timer Display */}
        <div className="flex items-center justify-center space-x-4">
          <Timer size={24} className="text-yellow-400" />
          <div className="flex items-center space-x-2">
            <div className="bg-white/10 rounded-lg px-3 py-2">
              <span className="text-2xl font-bold text-white">
                {String(timeLeft.hours).padStart(2, '0')}
              </span>
              <span className="text-sm block text-white/80">Giờ</span>
            </div>
            <span className="text-2xl text-white">:</span>
            <div className="bg-white/10 rounded-lg px-3 py-2">
              <span className="text-2xl font-bold text-white">
                {String(timeLeft.minutes).padStart(2, '0')}
              </span>
              <span className="text-sm block text-white/80">Phút</span>
            </div>
            <span className="text-2xl text-white">:</span>
            <div className="bg-white/10 rounded-lg px-3 py-2">
              <span className="text-2xl font-bold text-white">
                {String(timeLeft.seconds).padStart(2, '0')}
              </span>
              <span className="text-sm block text-white/80">Giây</span>
            </div>
          </div>
        </div>
      </div>

      {/* Products Display */}
      <div className="relative">
        {flashSaleProducts.length === 0 ? (
          <div className="text-center text-white py-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mx-auto mb-4"></div>
            <p>Đang tải sản phẩm Flash Sale...</p>
          </div>
        ) : saleProducts.length > 0 ? (
          <div>
            <ProductGrid products={saleProducts} displayType="flashSale" gap="gap-4 lg:gap-6" />
            {/* Sale Progress */}
            {/* <div className="mt-6 text-center text-white">
              <p>
                Đã bán {saleProducts.reduce((acc, prod) => acc + (prod.soldCount || 0), 0)} sản phẩm
              </p>
            </div> */}
          </div>
        ) : (
          <div className="text-center text-white py-8">
            <p>Chưa có sản phẩm trong chương trình Flash Sale này</p>
          </div>
        )}
      </div>

      {/* View All Link */}
      <div className="text-center mt-8">
        <Link
          to="/flash-sale"
          className="inline-flex items-center bg-white/10 hover:bg-white/20 rounded-lg px-6 py-3 
            text-white transition-colors"
        >
          Xem tất cả sản phẩm Flash Sale
          <ArrowRight size={20} className="ml-2" />
        </Link>
      </div>
    </div>
  );
};

export default FlashSaleSection;
