import { PAYMENT_METHODS } from '../../../constants';
import Select from 'react-select';

export const PaymentSection = ({ method, status, onMethodChange, onStatusChange, disabled }) => {
  const canSelectStatus = method !== 'COD';
  return (
    <div className="bg-white p-6 rounded-lg border space-y-4">
      <h3 className="text-lg font-medium text-gray-900">3. Thông tin thanh toán</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phương thức thanh toán <span className="text-red-500">*</span>
          </label>
          <Select
            options={PAYMENT_METHODS}
            value={PAYMENT_METHODS.find((x) => x.value === method)}
            onChange={(option) => {
              onMethodChange(option);
              if (option.value === 'COD') {
                onStatusChange({ value: 'pending', label: 'Chờ thanh toán' });
              }
            }}
            isDisabled={disabled}
            className="mt-1"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Trạng thái thanh toán</label>
          <Select
            options={[
              { value: 'pending', label: 'Chờ thanh toán' },
              { value: 'paid', label: 'Đã thanh toán' },
            ]}
            value={{
              value: status,
              label: status === 'pending' ? 'Chờ thanh toán' : 'Đã thanh toán',
            }}
            onChange={onStatusChange}
            isDisabled={disabled || !canSelectStatus}
            className="mt-1"
          />
        </div>
      </div>
    </div>
  );
};
