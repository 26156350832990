import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { ArrowLeft, Tag, Award, ShieldCheck, Package } from 'lucide-react';
import { fetchCategories } from '../../../store/categorySlice';
import { fetchSpecTemplates } from '../../../store/specTemplateSlice';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [specs, setSpecs] = useState(null);
  const [loading, setLoading] = useState(true);

  const { canView } = usePermissions();
  const hasViewPermission = canView(MODULES.PRODUCTS);

  const { items: categories, status: categoryStatus } = useSelector((state) => state.categories);
  const { items: specTemplates } = useSelector((state) => state.specTemplates);

  useEffect(() => {
    if (categoryStatus === 'idle') {
      dispatch(fetchCategories());
    }
    dispatch(fetchSpecTemplates());
  }, [dispatch, categoryStatus]);

  useEffect(() => {
    if (!hasViewPermission) {
      navigate('/admin/unauthorized');
      return;
    }
    const fetchProductAndSpecs = async () => {
      try {
        // Fetch product
        const docRef = doc(db, 'products', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const productData = docSnap.data();
          setProduct({ id: docSnap.id, ...productData });

          // Fetch specs
          const specsQuery = query(collection(db, 'product_specs'), where('productId', '==', id));
          const specsSnap = await getDocs(specsQuery);

          if (!specsSnap.empty) {
            setSpecs(specsSnap.docs[0].data());
          }
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductAndSpecs();
  }, [id, hasViewPermission, navigate]);

  const getCategoryNames = (categoryIds) => {
    if (!categoryIds || !Array.isArray(categoryIds)) return 'Chưa phân loại';
    if (categoryStatus !== 'succeeded') return 'Đang tải...';

    return (
      categoryIds
        .map((id) => categories.find((c) => c.id === id)?.name)
        .filter(Boolean)
        .join(', ') || 'Chưa phân loại'
    );
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  const getSpecTemplate = (templateId) => {
    return specTemplates.find((t) => t.id === templateId);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-600">Không tìm thấy sản phẩm</h2>
        <button onClick={() => navigate('/admin/products')} className="mt-4 btn btn-primary">
          Quay lại danh sách
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <button
        onClick={() => navigate('/admin/products')}
        className="flex items-center text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="mr-2" size={20} />
        Quay lại danh sách
      </button>

      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-6">
          {/* Images */}
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2 rounded-lg overflow-hidden">
              {product.images?.[0] ? (
                <img
                  src={product.images[0]}
                  alt={product.name}
                  className="object-cover w-full h-full"
                />
              ) : (
                <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                  <Package className="w-12 h-12 text-gray-400" />
                </div>
              )}
            </div>
            <div className="grid grid-cols-4 gap-4">
              {product.images?.slice(1).map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name} ${index + 2}`}
                  className="rounded-lg w-full h-24 object-cover"
                />
              ))}
            </div>
          </div>

          {/* Info */}
          <div className="space-y-6">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">{product.name}</h1>
              {product.sku && <p className="text-sm text-gray-500 mt-1">SKU: {product.sku}</p>}
              <p className="mt-2 text-sm text-gray-500">
                Danh mục: {getCategoryNames(product.categoryIds)}
              </p>
              {product.tags && product.tags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {product.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2 py-1 rounded text-sm bg-gray-100 text-gray-800"
                    >
                      <Tag size={14} className="mr-1" />
                      {tag}
                    </span>
                  ))}
                </div>
              )}
            </div>

            <div>
              <p className="text-3xl font-bold text-primary-600">{formatPrice(product.price)}</p>
              <div className="mt-4 flex flex-wrap gap-2">
                <span
                  className={`px-3 py-1 rounded-full text-sm font-semibold ${
                    product.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}
                >
                  {product.isActive ? 'Đang bán' : 'Ngừng bán'}
                </span>
                {product.isHot && (
                  <span className="px-3 py-1 rounded-full bg-red-100 text-red-800 text-sm font-semibold">
                    Hot
                  </span>
                )}
                {product.isFeatured && (
                  <span className="px-3 py-1 rounded-full bg-blue-100 text-blue-800 text-sm font-semibold">
                    Nổi bật
                  </span>
                )}
              </div>
            </div>

            <div className="border-t pt-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="text-sm text-gray-500">Tồn kho</span>
                  <p className="text-lg font-medium">{product.stock} sản phẩm</p>
                </div>
                <div>
                  <span className="text-sm text-gray-500">Đã bán</span>
                  <p className="text-lg font-medium">{product.sold || 0} sản phẩm</p>
                </div>
              </div>
            </div>

            {/* Warranty Info */}
            {product.warranty && (
              <div className="border-t pt-4">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                  <ShieldCheck className="text-primary-600" />
                  Thông tin bảo hành
                </h3>
                <div className="mt-2 space-y-2">
                  <p className="text-gray-700">Thời gian: {product.warranty.months} tháng</p>
                  {product.warranty.description && (
                    <p className="text-gray-600">{product.warranty.description}</p>
                  )}
                </div>
              </div>
            )}

            {/* Specifications */}
            {specs && specs.templateId && (
              <div className="border-t pt-4">
                <h3 className="text-lg font-semibold flex items-center gap-2">
                  <Award className="text-primary-600" />
                  Thông số kỹ thuật
                </h3>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {getSpecTemplate(specs.templateId)?.fields.map((field) => (
                    <div key={field.key} className="text-sm">
                      <span className="font-medium text-gray-700">{field.label}:</span>{' '}
                      <span className="text-gray-900">{specs.specs[field.key] || 'N/A'}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Description */}
            <div className="border-t pt-4">
              <h3 className="text-lg font-semibold mb-2">Mô tả sản phẩm</h3>
              <p className="text-gray-600 whitespace-pre-line">{product.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
