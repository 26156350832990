// src/admin/components/CustomerManager/CustomerForm.jsx
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { X, Save } from 'lucide-react';
import { addUser, updateUser } from '../../../store/userSlice';
import { USER_STATUS, USER_TYPE } from '../../../models/userModels';
import imageCompression from 'browser-image-compression';
import toast from 'react-hot-toast';

const CustomerForm = ({ customer, onClose }) => {
  const dispatch = useDispatch();
  const [avatar] = useState(customer?.avatar ? { preview: customer.avatar } : null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: customer || {
      username: '',
      email: '',
      fullName: '',
      phone: '',
      type: USER_TYPE.CUSTOMER,
      status: USER_STATUS.ACTIVE,
      addresses: [{}],
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      let avatarUrl = avatar?.preview;
      if (avatar?.file) {
        // Nén ảnh trước khi upload
        const compressedFile = await imageCompression(avatar.file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        });

        // Tạo FormData để upload
        const formData = new FormData();
        formData.append('file', compressedFile);
        formData.append('upload_preset', 'mapple_store_preset');
        formData.append('cloud_name', 'dc59rpref');

        // Upload ảnh
        const response = await fetch('https://api.cloudinary.com/v1_1/dc59rpref/image/upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload image');
        }

        const uploadResult = await response.json();
        avatarUrl = uploadResult.secure_url;
      }

      // Chuẩn bị dữ liệu người dùng
      const userData = {
        ...data,
        type: USER_TYPE.CUSTOMER,
        avatar: avatarUrl,
        // Nếu tạo mới thì thêm password
        ...(customer
          ? {}
          : {
              password: data.password,
              isDefaultPassword: false,
            }),
      };

      if (customer) {
        await dispatch(updateUser({ id: customer.id, data: userData })).unwrap();
        toast.success('Cập nhật khách hàng thành công');
      } else {
        await dispatch(addUser(userData)).unwrap();
        toast.success('Thêm khách hàng thành công');
      }

      onClose();
    } catch (error) {
      console.error('Error:', error);
      toast.error('Có lỗi xảy ra: ' + (error.message || 'Unknown error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl mx-4">
        <div className="px-6 py-4 border-b flex items-center justify-between">
          <h2 className="text-xl font-bold">
            {customer ? 'Chỉnh sửa khách hàng' : 'Thêm khách hàng mới'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="p-6">
          {/* Basic Info */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                {...register('email', {
                  required: 'Email là bắt buộc',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email không hợp lệ',
                  },
                })}
                className={`input mt-1 w-full ${errors.email ? 'border-red-500' : ''}`}
              />
              {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Họ tên <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                {...register('fullName', { required: 'Họ tên là bắt buộc' })}
                className={`input mt-1 w-full ${errors.fullName ? 'border-red-500' : ''}`}
              />
              {errors.fullName && (
                <p className="mt-1 text-sm text-red-500">{errors.fullName.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Số điện thoại</label>
              <input type="text" {...register('phone')} className="input mt-1 w-full" />
            </div>

            {!customer && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mật khẩu <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  {...register('password', {
                    required: 'Mật khẩu là bắt buộc',
                    minLength: {
                      value: 6,
                      message: 'Mật khẩu phải có ít nhất 6 ký tự',
                    },
                  })}
                  className={`input mt-1 w-full ${errors.password ? 'border-red-500' : ''}`}
                />
                {errors.password && (
                  <p className="mt-1 text-sm text-red-500">{errors.password.message}</p>
                )}
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary"
              disabled={isSubmitting}
            >
              Hủy
            </button>
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  <span className="ml-2">Đang xử lý...</span>
                </>
              ) : (
                <>
                  <Save size={20} />
                  <span className="ml-2">{customer ? 'Cập nhật' : 'Thêm mới'}</span>
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerForm;
