import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  orderBy,
  serverTimestamp,
  arrayUnion,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../firebase/config';

// Convert timestamps
export const updateSoldCount = createAsyncThunk(
  'flashSaleProducts/updateSoldCount',
  async ({ productId, quantity }) => {
    try {
      const docRef = doc(db, 'flash_sale_products', productId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Product not found');
      }

      const currentData = docSnap.data();

      const updateData = {
        soldCount: quantity,
        stockLeft: currentData.stockLimit ? currentData.stockLimit - quantity : null,
        updatedAt: serverTimestamp(),
      };

      if (updateData.stockLeft === 0) {
        updateData.status = 'out_of_stock';
      }

      await updateDoc(docRef, updateData);
      return { id: productId, ...updateData };
    } catch (error) {
      throw new Error('Không thể cập nhật số lượng đã bán: ' + error.message);
    }
  }
);
export const updateOrderTotal = createAsyncThunk(
  'orders/updateTotal',
  async ({ orderId, total, shipping_fee }) => {
    const docRef = doc(db, 'orders', orderId);
    await updateDoc(docRef, {
      total,
      shipping_fee,
      updatedAt: serverTimestamp(),
    });

    return { id: orderId, total, shipping_fee };
  }
);
// === Thunks ===
export const fetchOrders = createAsyncThunk('orders/fetchOrders', async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, 'orders'), orderBy('createdAt', 'desc'))
    );

    // Fetch chi tiết cho từng đơn hàng
    const ordersWithDetails = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const orderData = docSnapshot.data();

        // Fetch thông tin khách hàng nếu có userId
        let customerInfo = null;
        if (orderData.userId) {
          const userDocRef = doc(db, 'users', orderData.userId);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            customerInfo = userDocSnap.data();
          }
        }

        // Fetch items trong đơn hàng
        const itemsSnapshot = await getDocs(
          query(collection(db, 'order_items'), where('orderId', '==', docSnapshot.id))
        );
        const items = itemsSnapshot.docs.map((itemDoc) => ({
          id: itemDoc.id,
          ...itemDoc.data(),
        }));

        return {
          id: docSnapshot.id,
          ...orderData,
          customer: customerInfo,
          items,
        };
      })
    );

    return ordersWithDetails;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw new Error('Failed to fetch orders: ' + error.message);
  }
});

export const addOrder = createAsyncThunk('orders/addOrder', async (orderData) => {
  try {
    const orderNumber = `ORD${Date.now().toString().slice(-6)}`;
    const timestamp = serverTimestamp();

    const docRef = await addDoc(collection(db, 'orders'), {
      ...orderData,
      orderNumber,
      createdAt: timestamp,
      updatedAt: timestamp,
      orderDate: timestamp,
      status: 'pending',
      payment_status: orderData.payment_method === 'COD' ? 'pending' : orderData.payment_status,
      shipping_info: {
        provider: orderData.shipping_info?.provider || '',
        tracking_number: orderData.shipping_info?.tracking_number || '',
        fee: orderData.shipping_info?.fee || 0,
        estimated_delivery: null,
        updated_at: timestamp,
      },
    });

    // Create order items
    await Promise.all(
      orderData.items.map((item) =>
        addDoc(collection(db, 'order_items'), {
          orderId: docRef.id,
          ...item,
          createdAt: timestamp,
        })
      )
    );

    // Get complete order data
    const docSnap = await getDoc(docRef);
    return {
      id: docRef.id,
      ...docSnap.data(),
      items: orderData.items,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      orderDate: new Date().toISOString(),
    };
  } catch (error) {
    throw new Error('Failed to create order: ' + error.message);
  }
});

export const updateOrderStatus = createAsyncThunk(
  'orders/updateStatus',
  async ({ orderId, status, note }) => {
    try {
      const docRef = doc(db, 'orders', orderId);
      const docSnap = await getDoc(docRef);
      const currentOrder = docSnap.data();

      const timestamp = new Date().toISOString();
      const statusUpdate = {
        status,
        note,
        timestamp,
      };

      let updateData = {
        status,
        updatedAt: serverTimestamp(),
        status_history: arrayUnion(statusUpdate),
      };

      // Xử lý trạng thái thanh toán
      let newPaymentStatus;
      let paymentNote;

      if (['cancelled', 'returned'].includes(status)) {
        if (currentOrder.payment_status === 'paid') {
          // Nếu đã thanh toán -> chuyển sang chờ hoàn tiền
          newPaymentStatus = 'pending_refund';
          paymentNote = 'Đơn hàng trả/hoàn - Chờ hoàn tiền';
        } else if (currentOrder.payment_status === 'pending') {
          // Nếu chưa thanh toán -> chuyển sang none
          newPaymentStatus = 'none';
          paymentNote = `Chuyển sang không có thanh toán do đơn hàng ${status === 'cancelled' ? 'đã hủy' : 'đã trả'}`;
        } else {
          // Giữ nguyên các trạng thái khác
          newPaymentStatus = currentOrder.payment_status;
        }

        if (newPaymentStatus !== currentOrder.payment_status) {
          updateData = {
            ...updateData,
            payment_status: newPaymentStatus,
            payment_history: arrayUnion({
              status: newPaymentStatus,
              timestamp,
              note: paymentNote,
            }),
          };
        }
      }

      await updateDoc(docRef, updateData);

      return {
        id: orderId,
        status,
        status_history: statusUpdate,
        payment_status: updateData.payment_status,
        payment_history: updateData.payment_history?.[0],
      };
    } catch (error) {
      throw new Error('Failed to update order status: ' + error.message);
    }
  }
);

export const updatePaymentStatus = createAsyncThunk(
  'orders/updatePaymentStatus',
  async ({ orderId, status, note }) => {
    try {
      const docRef = doc(db, 'orders', orderId);
      const timestamp = new Date().toISOString();

      // Create history entry
      const historyEntry = {
        status,
        note,
        timestamp,
      };

      const updateData = {
        payment_status: status,
        updatedAt: serverTimestamp(),
        payment_history: arrayUnion(historyEntry),
      };

      await updateDoc(docRef, updateData);

      return {
        id: orderId,
        payment_status: status,
        payment_history: historyEntry,
      };
    } catch (error) {
      throw new Error('Failed to update payment status: ' + error.message);
    }
  }
);

export const updateShippingInfo = createAsyncThunk(
  'orders/updateShipping',
  async ({ orderId, shippingInfo }) => {
    try {
      const docRef = doc(db, 'orders', orderId);
      const fee = Number(shippingInfo.fee) || 0;

      // Get current order data to calculate new total
      const orderDoc = await getDoc(docRef);
      const orderData = orderDoc.data();
      const newTotal = (orderData.subtotal || 0) - (orderData.discount || 0) + fee;

      const updateData = {
        shipping_info: {
          ...shippingInfo,
          fee,
        },
        shipping_fee: fee,
        total: newTotal,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(docRef, updateData);

      return {
        id: orderId,
        ...updateData,
        updatedAt: new Date().toISOString(),
      };
    } catch (error) {
      throw new Error('Failed to update shipping info: ' + error.message);
    }
  }
);

export const updateCustomerInfo = createAsyncThunk(
  'orders/updateCustomer',
  async ({ orderId, customerInfo, note }) => {
    try {
      const docRef = doc(db, 'orders', orderId);
      const timestamp = new Date().toISOString();

      // Lấy thông tin cũ để lưu vào lịch sử
      const orderDoc = await getDoc(docRef);
      const oldData = orderDoc.data().shipping_address;

      const updateData = {
        shipping_address: customerInfo,
        updatedAt: serverTimestamp(),
        customer_history: arrayUnion({
          timestamp,
          note,
          old_data: oldData,
          new_data: customerInfo,
        }),
      };

      await updateDoc(docRef, updateData);

      return {
        id: orderId,
        shipping_address: customerInfo,
        customer_history: {
          timestamp,
          note,
          old_data: oldData,
          new_data: customerInfo,
        },
      };
    } catch (error) {
      throw new Error('Failed to update customer info: ' + error.message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  'orders/delete',
  async (orderId, { rejectWithValue }) => {
    try {
      const docRef = doc(db, 'orders', orderId);
      const orderDoc = await getDoc(docRef);

      if (!orderDoc.exists()) {
        throw new Error('Đơn hàng không tồn tại');
      }

      // Kiểm tra trạng thái đơn hàng
      const orderData = orderDoc.data();
      if (!['cancelled', 'returned'].includes(orderData.status)) {
        throw new Error('Chỉ có thể xóa đơn hàng đã hủy hoặc đã hoàn trả');
      }

      // Xóa các order items trước
      const itemsQuery = query(collection(db, 'order_items'), where('orderId', '==', orderId));
      const itemsSnapshot = await getDocs(itemsQuery);

      const batch = writeBatch(db);

      // Thêm các items vào batch để xóa
      itemsSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Thêm order vào batch để xóa
      batch.delete(docRef);

      // Thực hiện batch
      await batch.commit();

      return orderId;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  items: [],
  status: 'idle',
  error: null,
  filters: {
    status: 'all',
    payment_status: 'all',
    dateRange: null,
    searchTerm: '',
  },
  sort: {
    field: 'orderDate',
    direction: 'desc',
  },
  pagination: {
    currentPage: 1,
    pageSize: 10,
    total: 0,
  },
};
const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.pagination.currentPage = 1;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
      state.pagination.currentPage = 1;
    },
    setPage: (state, action) => {
      state.pagination.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pagination.pageSize = action.payload;
      state.pagination.currentPage = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Orders
      .addCase(fetchOrders.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
        state.pagination.total = action.payload.length;
        state.error = null;
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add Order
      .addCase(addOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.items = [action.payload, ...state.items];
        state.status = 'succeeded';
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Update Order Status
      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        const order = state.items.find((item) => item.id === action.payload.id);
        if (order) {
          // Update order status
          order.status = action.payload.status;
          if (!order.status_history) {
            order.status_history = [];
          }
          order.status_history.push(action.payload.status_history);

          // Update payment status if provided
          if (action.payload.payment_status) {
            order.payment_status = action.payload.payment_status;
            if (!order.payment_history) {
              order.payment_history = [];
            }
            if (action.payload.payment_history) {
              order.payment_history.push(action.payload.payment_history);
            }
          }

          // Auto set payment status to NONE for cancelled/returned orders with pending payment
          if (
            ['cancelled', 'returned'].includes(action.payload.status) &&
            order.payment_status === 'pending'
          ) {
            order.payment_status = 'none';
            if (!order.payment_history) {
              order.payment_history = [];
            }
            order.payment_history.push({
              status: 'none',
              timestamp: new Date().toISOString(),
              note: `Chuyển sang không có thanh toán do đơn hàng ${action.payload.status === 'cancelled' ? 'đã hủy' : 'đã trả'}`,
            });
          }
        }
      })
      .addCase(updateOrderTotal.fulfilled, (state, action) => {
        const order = state.items.find((item) => item.id === action.payload.id);
        if (order) {
          order.total = action.payload.total;
          order.shipping_fee = action.payload.shipping_fee;
        }
      })
      // Update Payment Status
      .addCase(updatePaymentStatus.fulfilled, (state, action) => {
        const order = state.items.find((item) => item.id === action.payload.id);
        if (order) {
          order.payment_status = action.payload.payment_status;
          if (!order.payment_history) {
            order.payment_history = [];
          }
          order.payment_history.push(action.payload.payment_history);
        }
      })
      // Update Shipping Info
      .addCase(updateShippingInfo.fulfilled, (state, action) => {
        const order = state.items.find((item) => item.id === action.payload.id);
        if (order) {
          order.shipping_info = action.payload.shipping_info;
          order.shipping_fee = action.payload.shipping_fee;
          order.total = action.payload.total;
          order.updatedAt = action.payload.updatedAt;
        }
      })
      .addCase(updateCustomerInfo.fulfilled, (state, action) => {
        const index = state.items.findIndex((order) => order.id === action.payload.id);
        if (index !== -1) {
          state.items[index].shipping_address = action.payload.shipping_address;
          state.items[index].customer_history = state.items[index].customer_history || [];
          state.items[index].customer_history.push(action.payload.customer_history);
        }
      })
      // Delete Order
      .addCase(deleteOrder.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = state.items.filter((item) => item.id !== action.payload);
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

// Actions
export const { setFilters, setSort, clearFilters, setPage, setPageSize } = orderSlice.actions;

// Selectors
const selectOrders = (state) => state.orders.items;
const selectFilters = (state) => state.orders.filters;
const selectSort = (state) => state.orders.sort;
const selectPagination = (state) => state.orders.pagination;

// Tạo memoized selector
export const selectFilteredOrders = createSelector(
  [selectOrders, selectFilters, selectSort, selectPagination],
  (items, filters, sort, pagination) => {
    let filteredItems = [...items];

    // Apply filters
    if (filters.status !== 'all') {
      filteredItems = filteredItems.filter((item) => item.status === filters.status);
    }

    if (filters.payment_status !== 'all') {
      filteredItems = filteredItems.filter(
        (item) => item.payment_status === filters.payment_status
      );
    }

    if (filters.dateRange?.startDate && filters.dateRange?.endDate) {
      filteredItems = filteredItems.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= filters.dateRange.startDate && orderDate <= filters.dateRange.endDate;
      });
    }

    // Apply search
    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      filteredItems = filteredItems.filter(
        (item) =>
          item.orderNumber?.toLowerCase().includes(searchLower) ||
          item.shipping_address?.fullName?.toLowerCase().includes(searchLower) ||
          item.shipping_address?.phone?.includes(searchLower)
      );
    }

    // Apply sort
    filteredItems.sort((a, b) => {
      const aValue = a[sort.field];
      const bValue = b[sort.field];

      if (sort.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });

    // Calculate pagination
    const start = (pagination.currentPage - 1) * pagination.pageSize;
    const end = start + pagination.pageSize;

    // Return memoized result
    return {
      items: filteredItems.slice(start, end),
      total: filteredItems.length,
    };
  }
);

export default orderSlice.reducer;
