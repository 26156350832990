export const ORDER_STATUS = {
  PENDING: 'pending', // Chờ xử lý
  PROCESSING: 'processing', // Đang xử lý
  SHIPPING: 'shipping', // Đang giao
  DELIVERED: 'delivered', // Đã giao
  RETURNING: 'returning', // Đang hoàn trả
  RETURNED: 'returned', // Đã trả hàng
  CANCELLED: 'cancelled', // Đã hủy
};

export const ORDER_STATUS_LABELS = {
  [ORDER_STATUS.PENDING]: 'Chờ xử lý',
  [ORDER_STATUS.PROCESSING]: 'Đang xử lý',
  [ORDER_STATUS.SHIPPING]: 'Đang giao',
  [ORDER_STATUS.DELIVERED]: 'Đã giao',
  [ORDER_STATUS.CANCELLED]: 'Đã hủy',
  [ORDER_STATUS.RETURNED]: 'Đã trả hàng',
  [ORDER_STATUS.RETURNING]: 'Hoàn trả hàng',
};

export const PAYMENT_STATUS = {
  NONE: 'none',
  PENDING: 'pending', // Chờ thanh toán
  PAID: 'paid', // Đã thanh toán
  PENDING_REFUND: 'pending_refund', // Chờ hoàn tiền
  REFUNDED: 'refunded', // Đã hoàn tiền
  CANCELLED: 'cancelled', // Đã hủy thanh toán
};
export const ORDER_STATUS_FLOW = {
  [ORDER_STATUS.PENDING]: [ORDER_STATUS.PROCESSING, ORDER_STATUS.CANCELLED],
  [ORDER_STATUS.PROCESSING]: [ORDER_STATUS.SHIPPING, ORDER_STATUS.CANCELLED],
  [ORDER_STATUS.SHIPPING]: [ORDER_STATUS.DELIVERED, ORDER_STATUS.CANCELLED],
  [ORDER_STATUS.DELIVERED]: [ORDER_STATUS.RETURNING],
  [ORDER_STATUS.RETURNING]: [ORDER_STATUS.RETURNED, ORDER_STATUS.DELIVERED],
  [ORDER_STATUS.RETURNED]: [], // Trạng thái cuối
  [ORDER_STATUS.CANCELLED]: [], // Trạng thái cuối
};

// Định nghĩa flow trạng thái thanh toán theo trạng thái đơn hàng
export const PAYMENT_STATUS_FLOW = {
  [ORDER_STATUS.CANCELLED]: {
    [PAYMENT_STATUS.PENDING]: [PAYMENT_STATUS.CANCELLED],
    [PAYMENT_STATUS.PAID]: [PAYMENT_STATUS.PENDING_REFUND, PAYMENT_STATUS.REFUNDED],
  },
  [ORDER_STATUS.RETURNING]: {
    [PAYMENT_STATUS.PAID]: [PAYMENT_STATUS.PENDING_REFUND],
  },
  [ORDER_STATUS.RETURNED]: {
    [PAYMENT_STATUS.PENDING_REFUND]: [PAYMENT_STATUS.REFUNDED],
  },
  [ORDER_STATUS.PENDING]: {
    [PAYMENT_STATUS.PENDING]: [PAYMENT_STATUS.PAID],
  },
  [ORDER_STATUS.PROCESSING]: {
    [PAYMENT_STATUS.PENDING]: [PAYMENT_STATUS.PAID],
  },
  [ORDER_STATUS.SHIPPING]: {
    [PAYMENT_STATUS.PENDING]: [PAYMENT_STATUS.PAID],
  },
  [ORDER_STATUS.DELIVERED]: {
    [PAYMENT_STATUS.PENDING]: [PAYMENT_STATUS.PAID],
  },
};
export const PAYMENT_STATUS_LABELS = {
  [PAYMENT_STATUS.NONE]: 'Không có thanh toán',
  [PAYMENT_STATUS.PENDING]: 'Chờ thanh toán',
  [PAYMENT_STATUS.PAID]: 'Đã thanh toán',
  [PAYMENT_STATUS.PENDING_REFUND]: 'Chờ hoàn tiền',
  [PAYMENT_STATUS.REFUNDED]: 'Đã hoàn tiền',
  [PAYMENT_STATUS.FAILED]: 'Thanh toán thất bại',
};

export const PAYMENT_METHODS = [
  { value: 'COD', label: 'Thanh toán khi nhận hàng' },
  { value: 'BANK', label: 'Chuyển khoản ngân hàng' },
  { value: 'MOMO', label: 'Ví Momo' },
  { value: 'SHOPEE', label: 'Shopee' },
  { value: 'TIKTOK', label: 'TikTok Shop' },
  { value: 'FACEBOOK', label: 'Facebook' },
];
export const PAYMENT_METHOD_LABELS = {
  COD: 'Thanh toán khi nhận hàng',
  BANK: 'Chuyển khoản ngân hàng',
  MOMO: 'Ví MoMo',
  SHOPEE: 'Ví ShopeePay',
  TIKTOK: 'TikTok Shop',
  FACEBOOK: 'Facebook Pay',
};
export const ORDER_SOURCES = [
  { value: 'WEBSITE', label: 'Website' },
  { value: 'SHOPEE', label: 'Shopee' },
  { value: 'TIKTOK', label: 'TikTok Shop' },
  { value: 'FACEBOOK', label: 'Facebook' },
  { value: 'ZALO', label: 'Zalo' },
  { value: 'OTHER', label: 'Khác' },
];

export const SHIPPING_PROVIDERS = [
  { value: 'GHN', label: 'Giao Hàng Nhanh' },
  { value: 'GHTK', label: 'Giao Hàng Tiết Kiệm' },
  { value: 'VNPOST', label: 'Vietnam Post' },
  { value: 'VIETTEL_POST', label: 'Viettel Post' },
  { value: 'J&T', label: 'J&T Express' },
  { value: 'NINJA_VAN', label: 'Ninja Van' },
  { value: 'DHL', label: 'DHL Express' },
  { value: 'FEDEX', label: 'FedEx' },
  { value: 'UPS', label: 'UPS' },
  { value: 'GRAB_EXPRESS', label: 'Grab Express' },
  { value: 'LALAMOVE', label: 'Lalamove' },
  { value: 'OTHER', label: 'Khác' },
];
export const SHIPPING_STATUS = {
  PENDING: 'pending',
  READY_TO_PICK: 'ready_to_pick',
  PICKING: 'picking',
  PICKED: 'picked',
  DELIVERING: 'delivering',
  DELIVERED: 'delivered',
  RETURN_REQUEST: 'return_request',
  RETURNING: 'returning',
  RETURNED: 'returned',
  CANCELLED: 'cancelled',
};

export const SHIPPING_STATUS_LABELS = {
  [SHIPPING_STATUS.PENDING]: 'Chờ xử lý',
  [SHIPPING_STATUS.READY_TO_PICK]: 'Sẵn sàng lấy hàng',
  [SHIPPING_STATUS.PICKING]: 'Đang lấy hàng',
  [SHIPPING_STATUS.PICKED]: 'Đã lấy hàng',
  [SHIPPING_STATUS.DELIVERING]: 'Đang giao hàng',
  [SHIPPING_STATUS.DELIVERED]: 'Đã giao hàng',
  [SHIPPING_STATUS.RETURN_REQUEST]: 'Yêu cầu trả hàng',
  [SHIPPING_STATUS.RETURNING]: 'Đang trả hàng',
  [SHIPPING_STATUS.RETURNED]: 'Đã trả hàng',
  [SHIPPING_STATUS.CANCELLED]: 'Đã hủy',
};
