// src/admin/components/OrderManager/components/CreateOrder/components/ProductSelector.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Package } from 'lucide-react';
import { formatPrice } from '../../../utils';

const ProductSelector = ({ selectedProducts, onChange, disabled }) => {
  const { items: allProducts, status } = useSelector((state) => state.products);

  // Lọc ra những sản phẩm chưa được thêm vào đơn hàng
  const availableProducts = allProducts.filter(
    (product) => !selectedProducts.find((sp) => sp.id === product.id)
  );

  const formatOptionLabel = ({ product }) => (
    <div className="flex items-center">
      {product.images?.[0] ? (
        <img
          src={product.images[0]}
          alt={product.name}
          className="w-10 h-10 object-cover rounded mr-3"
        />
      ) : (
        <div className="w-10 h-10 bg-gray-100 rounded flex items-center justify-center mr-3">
          <Package className="w-5 h-5 text-gray-400" />
        </div>
      )}
      <div>
        <div className="font-medium">{product.name}</div>
        <div className="text-sm text-gray-500">
          {formatPrice(product.price)}
          {product.stock > 0 && <span className="ml-2">(Còn {product.stock} sản phẩm)</span>}
        </div>
      </div>
    </div>
  );

  const options = availableProducts.map((product) => ({
    value: product.id,
    label: product.name,
    product: product,
  }));

  const handleSelect = (selected) => {
    if (selected) {
      const { product } = selected;
      onChange([
        ...selectedProducts,
        {
          id: product.id,
          name: product.name,
          image: product.images?.[0],
          sku: product.sku,
          originalPrice: product.price,
          price: product.price,
          quantity: 1,
          maxQuantity: product.stock,
        },
      ]);
    }
  };

  if (status === 'loading') {
    return <div>Đang tải danh sách sản phẩm...</div>;
  }

  if (status === 'failed') {
    return <div className="text-red-500">Không thể tải danh sách sản phẩm</div>;
  }

  return (
    <div className="space-y-2">
      <Select
        options={options}
        onChange={handleSelect}
        formatOptionLabel={formatOptionLabel}
        placeholder="Chọn sản phẩm..."
        noOptionsMessage={() => 'Không có sản phẩm phù hợp'}
        isDisabled={disabled || availableProducts.length === 0}
        className="basic-single"
        classNamePrefix="select"
      />

      {availableProducts.length === 0 && (
        <div className="text-sm text-gray-500">Tất cả sản phẩm đã được thêm vào đơn hàng</div>
      )}
    </div>
  );
};

export default ProductSelector;
