import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { X, Save } from 'lucide-react';
import { addRole, updateRole } from '../../../store/roleSlice';
import { DEFAULT_PERMISSIONS, MODULES, ACTIONS } from '../../../models/userModels';
import toast from 'react-hot-toast';

const PermissionGroup = ({ module, permissions, selectedPermissions, onChange }) => {
  // Lọc ra các quyền thuộc module hiện tại
  const modulePermissions = permissions.filter((p) => p.module === module);

  const isChecked = (permissionCode) => {
    return selectedPermissions.includes(permissionCode);
  };

  const handleToggle = (permissionCode, checked) => {
    if (checked) {
      onChange([...selectedPermissions, permissionCode]);
    } else {
      onChange(selectedPermissions.filter((p) => p !== permissionCode));
    }
  };

  const handleToggleAll = (checked) => {
    const moduleCodes = modulePermissions.map((p) => p.code);
    if (checked) {
      // Thêm tất cả quyền của module nếu chưa có
      const newPermissions = [...selectedPermissions];
      moduleCodes.forEach((code) => {
        if (!newPermissions.includes(code)) {
          newPermissions.push(code);
        }
      });
      onChange(newPermissions);
    } else {
      // Xóa tất cả quyền của module
      onChange(selectedPermissions.filter((p) => !moduleCodes.includes(p)));
    }
  };

  // Kiểm tra xem có tất cả quyền của module không
  const hasAllPermissions = modulePermissions.every((p) => selectedPermissions.includes(p.code));

  return (
    <div className="border rounded-lg p-4 hover:border-primary-500 transition-colors">
      <div className="flex items-center justify-between mb-4">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={hasAllPermissions}
            onChange={(e) => handleToggleAll(e.target.checked)}
            className="rounded text-primary-600 focus:ring-primary-500 h-4 w-4"
          />
          <span className="font-medium text-gray-700">{module.toUpperCase()}</span>
        </label>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {Object.values(ACTIONS).map((action) => {
          const permission = modulePermissions.find((p) => p.action === action);
          if (!permission) return null;

          return (
            <label key={permission.code} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={isChecked(permission.code)}
                onChange={(e) => handleToggle(permission.code, e.target.checked)}
                className="rounded text-primary-600 focus:ring-primary-500 h-4 w-4"
              />
              <span className="text-sm text-gray-600">{permission.name}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

const RoleForm = ({ role, onClose }) => {
  const dispatch = useDispatch();
  const [selectedPermissions, setSelectedPermissions] = useState(role?.permissions || []);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: role || {
      name: '',
      description: '',
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);

      if (!selectedPermissions.length) {
        toast.error('Vui lòng chọn ít nhất một quyền');
        return;
      }

      const roleData = {
        ...data,
        permissions: selectedPermissions,
      };

      if (role) {
        await dispatch(updateRole({ id: role.id, data: roleData })).unwrap();
        toast.success('Cập nhật vai trò thành công');
      } else {
        await dispatch(addRole(roleData)).unwrap();
        toast.success('Thêm vai trò thành công');
      }

      onClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-4xl mx-4 my-8">
        {/* Header */}
        <div className="px-6 py-4 border-b sticky top-0 bg-white">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-bold text-gray-900">
              {role ? 'Chỉnh sửa vai trò' : 'Thêm vai trò mới'}
            </h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              disabled={isSubmitting}
            >
              <X size={20} className="text-gray-500" />
            </button>
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
            <div className="space-y-6">
              {/* Basic Info */}
              <div className="grid grid-cols-1 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Tên vai trò <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    {...register('name', {
                      required: 'Tên vai trò là bắt buộc',
                      minLength: {
                        value: 3,
                        message: 'Tên vai trò phải có ít nhất 3 ký tự',
                      },
                    })}
                    className={`input mt-1 w-full ${errors.name ? 'border-red-500' : ''}`}
                    placeholder="Nhập tên vai trò"
                  />
                  {errors.name && (
                    <p className="mt-1 text-sm text-red-500">{errors.name.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Mô tả</label>
                  <textarea
                    {...register('description')}
                    rows={3}
                    className="input mt-1 w-full"
                    placeholder="Nhập mô tả về vai trò này"
                  />
                </div>
              </div>

              {/* Permissions */}
              <div>
                <h3 className="text-lg font-medium mb-4">Phân quyền</h3>
                <div className="space-y-4">
                  {Object.values(MODULES).map((module) => (
                    <PermissionGroup
                      key={module}
                      module={module}
                      permissions={DEFAULT_PERMISSIONS}
                      selectedPermissions={selectedPermissions}
                      onChange={setSelectedPermissions}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="px-6 py-4 bg-gray-50 border-t sticky bottom-0">
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="btn btn-secondary"
                disabled={isSubmitting}
              >
                Hủy
              </button>
              <button
                type="submit"
                className="btn btn-primary flex items-center gap-2"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Đang xử lý...
                  </>
                ) : (
                  <>
                    <Save size={20} />
                    {role ? 'Cập nhật' : 'Thêm mới'}
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoleForm;
