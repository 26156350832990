import React from 'react';

export const LoadingSpinner = ({ size = 'md', color = 'primary', className = '' }) => {
  const sizeClasses = {
    sm: 'w-4 h-4',
    md: 'w-8 h-8',
    lg: 'w-12 h-12',
  };

  const colorClasses = {
    primary: 'border-primary-600',
    white: 'border-white',
    gray: 'border-gray-600',
  };

  return (
    <div
      className={`
        ${sizeClasses[size]} 
        ${colorClasses[color]} 
        border-2 border-t-transparent 
        rounded-full animate-spin
        ${className}
      `}
    />
  );
};

export const LoadingOverlay = ({ message = 'Đang tải...' }) => (
  <div className="absolute inset-0 bg-white/70 flex flex-col items-center justify-center z-50">
    <LoadingSpinner size="lg" />
    {message && <p className="mt-4 text-gray-600">{message}</p>}
  </div>
);

export const PageLoading = () => (
  <div className="flex items-center justify-center h-64">
    <LoadingSpinner size="lg" />
  </div>
);

export const ButtonLoading = ({ children, isLoading, ...props }) => (
  <button
    {...props}
    disabled={isLoading || props.disabled}
    className={`btn ${props.className || ''}`}
  >
    {isLoading ? (
      <div className="flex items-center gap-2">
        <LoadingSpinner size="sm" color="white" />
        <span>Đang xử lý...</span>
      </div>
    ) : (
      children
    )}
  </button>
);
