// src/client/middleware/authMiddleware.js
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { login, logout } from '../features/authSlice';
import { secureStorage } from '../../utils/cryptoUtils';
import { setWishlistItems } from '../features/wishlistSlice';

export const authMiddleware = createListenerMiddleware();

// Listen for successful login
authMiddleware.startListening({
  actionCreator: login.fulfilled,
  effect: async (action, listenerApi) => {
    // Save user to localStorage
    secureStorage.setItem('user', action.payload);

    // Load wishlist for user from localStorage
    const wishlist = secureStorage.getItem('wishlist');
    if (wishlist?.items) {
      listenerApi.dispatch(setWishlistItems(wishlist.items));
    }
  },
});

// Listen for logout
authMiddleware.startListening({
  actionCreator: logout.fulfilled,
  effect: async (_, listenerApi) => {
    // Clear user from localStorage
    secureStorage.removeItem('user');

    // Clear wishlist
    secureStorage.removeItem('wishlist');
    listenerApi.dispatch(setWishlistItems([]));
  },
});
