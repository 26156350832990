// src/client/features/categorySlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';

// Thunk để lấy danh mục
export const fetchClientCategories = createAsyncThunk('clientCategories/fetchAll', async () => {
  try {
    // Lấy tất cả danh mục và filter/sort trong memory thay vì dùng query
    const querySnapshot = await getDocs(collection(db, 'categories'));

    const categories = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((cat) => cat.isActive !== false) // Lọc các danh mục active
      .sort((a, b) => (a.display_order || 0) - (b.display_order || 0)); // Sắp xếp theo display_order

    // Xây dựng cây danh mục
    const buildCategoryTree = (items, parentId = null) => {
      return items
        .filter((item) => {
          // Xử lý trường hợp parent_id là undefined hoặc null
          if (parentId === null) {
            return !item.parent_id;
          }
          return item.parent_id === parentId;
        })
        .map((item) => ({
          ...item,
          children: buildCategoryTree(items, item.id),
        }));
    };

    const tree = buildCategoryTree(categories);

    // Tạo mảng phẳng với thông tin level
    const flattenWithLevel = (items, level = 0) => {
      return items.reduce((acc, item) => {
        const { children, ...itemWithoutChildren } = item;
        return [
          ...acc,
          { ...itemWithoutChildren, level },
          ...flattenWithLevel(children || [], level + 1),
        ];
      }, []);
    };

    const flat = flattenWithLevel(tree);

    return {
      tree,
      flat,
    };
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw new Error('Không thể tải danh mục: ' + error.message);
  }
});

const categorySlice = createSlice({
  name: 'clientCategories',
  initialState: {
    tree: [], // Cấu trúc cây cho menu
    flat: [], // Mảng phẳng cho tìm kiếm và hiển thị dạng list
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClientCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tree = action.payload.tree;
        state.flat = action.payload.flat;
        state.error = null;
      })
      .addCase(fetchClientCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

// Selectors
export const selectCategoryTree = (state) => state.clientCategories.tree;
export const selectFlatCategories = (state) => state.clientCategories.flat;

// New selector for featured categories
export const selectFeaturedCategories = (state) => {
  return state.clientCategories.flat
    .filter((category) => category.isFeatured && category.isActive)
    .sort((a, b) => (a.display_order || 0) - (b.display_order || 0));
};

// Helper selector để lấy breadcrumb cho một category
export const selectCategoryBreadcrumb = (state, categoryId) => {
  const categories = state.clientCategories.flat;
  const breadcrumb = [];

  const findCategory = (id) => categories.find((cat) => cat.id === id);
  let current = findCategory(categoryId);

  while (current) {
    breadcrumb.unshift(current);
    current = findCategory(current.parent_id);
  }

  return breadcrumb;
};

export default categorySlice.reducer;
