import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Plus, Edit2, Copy, FileSpreadsheet, Loader2, Trash2 } from 'lucide-react';
import {
  fetchSpecTemplates,
  deleteSpecTemplate,
  checkTemplateDependencies,
  migrateProducts,
  clearActiveTemplate,
  setActiveTemplate,
} from '../../../store/specTemplateSlice';
import SpecTemplateForm from './SpecTemplateForm';
import MigrateTemplateDialog from './MigrateTemplateDialog';
import toast from 'react-hot-toast';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const SpecTemplateManager = () => {
  const dispatch = useDispatch();
  const { items: templates, status } = useSelector((state) => state.specTemplates);
  const [showForm, setShowForm] = useState(false);
  const [showMigrateDialog, setShowMigrateDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [productCount, setProductCount] = useState(0);

  const { canView, canCreate, canUpdate, canDelete } = usePermissions();
  const hasViewPermission = canView(MODULES.SPECS);
  const hasCreatePermission = canCreate(MODULES.SPECS);
  const hasUpdatePermission = canUpdate(MODULES.SPECS);
  const hasDeletePermission = canDelete(MODULES.SPECS);

  useEffect(() => {
    if (status === 'idle' && hasViewPermission) {
      dispatch(fetchSpecTemplates());
    }
  }, [status, dispatch, hasViewPermission]);

  const handleAdd = useCallback(() => {
    if (!hasCreatePermission) {
      toast.error('Bạn không có quyền tạo mẫu thông số mới');
      return;
    }
    dispatch(clearActiveTemplate());
    setShowForm(true);
  }, [dispatch, hasCreatePermission]);

  const handleEdit = useCallback(
    (template) => {
      if (!hasUpdatePermission) {
        toast.error('Bạn không có quyền chỉnh sửa mẫu thông số');
        return;
      }
      dispatch(setActiveTemplate(template));
      setShowForm(true);
    },
    [dispatch, hasUpdatePermission]
  );

  const handleClone = useCallback(
    (template) => {
      if (!hasCreatePermission) {
        toast.error('Bạn không có quyền tạo mẫu thông số mới');
        return;
      }
      dispatch(setActiveTemplate({ ...template, id: null, name: `${template.name} (Bản sao)` }));
      setShowForm(true);
    },
    [dispatch, hasCreatePermission]
  );

  const handleDeleteClick = async (template) => {
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa mẫu thông số');
      return;
    }

    try {
      const result = await dispatch(checkTemplateDependencies(template.id)).unwrap();

      if (result.hasProducts) {
        setSelectedTemplate(template);
        setProductCount(result.productCount);
        setShowMigrateDialog(true);
      } else {
        if (window.confirm('Bạn có chắc muốn xóa mẫu thông số này?')) {
          await dispatch(deleteSpecTemplate(template.id)).unwrap();
          toast.success('Xóa mẫu thông số thành công');
        }
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    }
  };

  const handleMigrateConfirm = async (newTemplateId) => {
    try {
      await dispatch(
        migrateProducts({
          oldTemplateId: selectedTemplate.id,
          newTemplateId,
        })
      ).unwrap();

      await dispatch(deleteSpecTemplate(selectedTemplate.id)).unwrap();
      toast.success('Di chuyển sản phẩm và xóa mẫu thông số thành công');
      setShowMigrateDialog(false);
      setSelectedTemplate(null);
      setProductCount(0);
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    }
  };

  if (!hasViewPermission) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-red-600">Không có quyền truy cập</h2>
          <p className="mt-2 text-gray-600">Bạn không có quyền xem quản lý mẫu thông số</p>
        </div>
      </div>
    );
  }

  if (status === 'loading') {
    return (
      <div className="flex items-center justify-center p-8">
        <Loader2 className="w-8 h-8 animate-spin text-primary-600" />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Quản lý mẫu thông số</h1>
          <p className="text-sm text-gray-500 mt-1">
            Quản lý các mẫu thông số kỹ thuật cho sản phẩm
          </p>
        </div>
        {hasCreatePermission && (
          <button onClick={handleAdd} className="btn btn-primary flex items-center gap-2">
            <Plus size={20} />
            Thêm mẫu mới
          </button>
        )}
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Tên mẫu
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Số trường
              </th>
              <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Thao tác
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {templates.map((template) => (
              <tr key={template.id} className="hover:bg-gray-50">
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <FileSpreadsheet className="flex-shrink-0 h-5 w-5 text-gray-400 mr-3" />
                    <div>
                      <div className="font-medium text-gray-900">{template.name}</div>
                      {template.description && (
                        <div className="text-sm text-gray-500">{template.description}</div>
                      )}
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 text-center">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                    {template.fields?.length || 0} trường
                  </span>
                </td>
                <td className="px-6 py-4 text-center">
                  <div className="flex items-center justify-center space-x-3">
                    {hasUpdatePermission && (
                      <button
                        onClick={() => handleEdit(template)}
                        className="p-1 rounded hover:bg-gray-100 text-blue-500 hover:text-blue-700"
                        title="Sửa mẫu thông số"
                      >
                        <Edit2 size={16} />
                      </button>
                    )}
                    {hasCreatePermission && (
                      <button
                        onClick={() => handleClone(template)}
                        className="p-1 rounded hover:bg-gray-100 text-green-500 hover:text-green-700"
                        title="Nhân bản mẫu"
                      >
                        <Copy size={16} />
                      </button>
                    )}
                    {hasDeletePermission && (
                      <button
                        onClick={() => handleDeleteClick(template)}
                        className="p-1 rounded hover:bg-gray-100 text-red-500 hover:text-red-700"
                        title="Xóa mẫu thông số"
                      >
                        <Trash2 size={16} />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
            {templates.length === 0 && (
              <tr>
                <td colSpan={3} className="px-6 py-4 text-center text-gray-500">
                  Chưa có mẫu thông số nào
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showForm && <SpecTemplateForm onClose={() => setShowForm(false)} />}

      {showMigrateDialog && (
        <MigrateTemplateDialog
          isOpen={showMigrateDialog}
          onClose={() => {
            setShowMigrateDialog(false);
            setSelectedTemplate(null);
            setProductCount(0);
          }}
          templates={templates.filter((t) => t.id !== selectedTemplate?.id)}
          productCount={productCount}
          onConfirm={handleMigrateConfirm}
        />
      )}
    </div>
  );
};

export default SpecTemplateManager;
