import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import {
  fetchFlashSaleProducts,
  addFlashSaleProducts,
  removeFlashSaleProducts,
  updateFlashSaleProduct,
} from '../../../store/flashSaleProductSlice';
import { updateFlashSaleProductCount } from '../../../store/flashSaleSlice';
import toast from 'react-hot-toast';
import ProductList from './ProductList';
import ProductSelector from './ProductSelector';
import SelectedProducts from './SelectedProducts';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const FlashSaleProductManager = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const flashSale = useSelector((state) => state.flashSales.items.find((p) => p.id === id));
  const flashSaleProducts = useSelector((state) => state.flashSaleProducts.items);
  const status = useSelector((state) => state.flashSaleProducts.status);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  const { canView, canUpdate } = usePermissions();
  const hasViewPermission = canView(MODULES.FLASH_SALES);
  const hasUpdatePermission = canUpdate(MODULES.FLASH_SALES);

  useEffect(() => {
    if (!hasViewPermission) {
      navigate('/admin/unauthorized');
      return;
    }
    if (id) {
      dispatch(fetchFlashSaleProducts(id));
    }
  }, [id, dispatch, hasViewPermission, navigate]);

  useEffect(() => {
    if (flashSaleProducts.length !== flashSale?.productCount) {
      dispatch(
        updateFlashSaleProductCount({
          flashSaleId: id,
          count: flashSaleProducts.length,
        })
      );
    }
  }, [flashSaleProducts.length, id, flashSale?.productCount, dispatch]);

  const handleAddProducts = async () => {
    if (selectedProducts.length === 0) return;
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền thêm sản phẩm vào Flash Sale');
      return;
    }
    try {
      setIsAdding(true);
      await dispatch(
        addFlashSaleProducts({
          flashSaleId: id,
          products: selectedProducts,
        })
      ).unwrap();

      // Cập nhật số lượng sản phẩm
      const newCount = flashSaleProducts.length + selectedProducts.length;
      await dispatch(
        updateFlashSaleProductCount({
          flashSaleId: id,
          count: newCount,
        })
      ).unwrap();

      setSelectedProducts([]);
      toast.success('Thêm sản phẩm thành công');
    } catch (error) {
      toast.error('Thêm sản phẩm thất bại: ' + error.message);
    } finally {
      setIsAdding(false);
    }
  };

  const handleUpdateProduct = async (productId, data) => {
    try {
      await dispatch(
        updateFlashSaleProduct({
          id: productId,
          data: {
            ...data,
            flashSaleId: id,
          },
        })
      ).unwrap();
      toast.success('Cập nhật sản phẩm thành công');
    } catch (error) {
      toast.error('Cập nhật sản phẩm thất bại: ' + error.message);
    }
  };

  const handleRemoveProducts = async (productIds) => {
    if (!window.confirm('Bạn có chắc muốn xóa sản phẩm này?')) return;

    try {
      await dispatch(
        removeFlashSaleProducts({
          flashSaleId: id,
          productIds: productIds,
        })
      ).unwrap();

      toast.success('Xóa sản phẩm thành công');
    } catch (error) {
      toast.error('Xóa sản phẩm thất bại: ' + error.message);
    }
  };

  if (status === 'loading') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    return date.toLocaleDateString('vi-VN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  };
  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-6">
        <button onClick={() => navigate('/admin/flash-sales')} className="btn btn-ghost">
          <ArrowLeft size={20} />
          Quay lại
        </button>
        <div>
          <h1 className="text-2xl font-bold">Quản lý sản phẩm Flash Sale: {flashSale?.name}</h1>
          <p className="text-sm text-gray-500">
            Thời gian: {formatDate(flashSale?.startTime)} - {formatDate(flashSale?.endTime)}
          </p>
        </div>
      </div>

      {/* Product Selection Section */}
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Thêm sản phẩm mới</h2>
          <div className="text-sm text-gray-500">Đã chọn: {selectedProducts.length} sản phẩm</div>
        </div>

        <ProductSelector selectedProducts={selectedProducts} onChange={setSelectedProducts} />

        {selectedProducts.length > 0 && (
          <>
            <SelectedProducts products={selectedProducts} onChange={setSelectedProducts} />

            <div className="mt-4 flex justify-end">
              <button onClick={handleAddProducts} disabled={isAdding} className="btn btn-primary">
                {isAdding ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                    Đang thêm...
                  </>
                ) : (
                  'Thêm sản phẩm'
                )}
              </button>
            </div>
          </>
        )}
      </div>

      {/* Product List Section */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6 border-b">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">
              Danh sách sản phẩm ({flashSaleProducts.length})
            </h2>
          </div>
        </div>

        <ProductList
          products={flashSaleProducts}
          onUpdate={handleUpdateProduct}
          onRemove={handleRemoveProducts}
        />
      </div>
    </div>
  );
};

export default FlashSaleProductManager;
