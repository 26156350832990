import React from 'react';
import { useDispatch } from 'react-redux';
import { Edit2, Trash2, Shield, Users } from 'lucide-react';
import { deleteRole } from '../../../store/roleSlice';
import toast from 'react-hot-toast';

const RoleList = ({ roles, onEdit, hasUpdatePermission, hasDeletePermission, isSuperAdmin }) => {
  const dispatch = useDispatch();

  const handleDelete = async (role) => {
    // Kiểm tra quyền xóa
    if (!hasDeletePermission) {
      toast.error('Bạn không có quyền xóa vai trò');
      return;
    }

    // Không cho phép xóa Super Admin
    if (role.name === 'Super Admin') {
      toast.error('Không thể xóa vai trò Super Admin');
      return;
    }

    // Chỉ Super Admin mới có thể xóa các vai trò đặc biệt
    if (role.name === 'Super Admin' && !isSuperAdmin) {
      toast.error('Chỉ Super Admin mới có thể xóa vai trò này');
      return;
    }

    if (window.confirm('Bạn có chắc muốn xóa vai trò này?')) {
      try {
        await dispatch(deleteRole(role.id)).unwrap();
        toast.success('Xóa vai trò thành công');
      } catch (error) {
        toast.error('Xóa vai trò thất bại: ' + error.message);
      }
    }
  };

  const canEditRole = (role) => {
    if (!hasUpdatePermission) return false;
    if (role.name === 'Super Admin' && !isSuperAdmin) return false;
    return true;
  };

  const canDeleteRole = (role) => {
    if (!hasDeletePermission) return false;
    if (role.name === 'Super Admin') return false;
    return true;
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Tên vai trò
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
              Mô tả
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Số quyền
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thành viên
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">
              Thao tác
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {roles.map((role) => (
            <tr key={role.id} className="hover:bg-gray-50">
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <Shield className="h-5 w-5 text-gray-400 mr-3" />
                  <div>
                    <div className="font-medium text-gray-900">{role.name}</div>
                    {role.name === 'Super Admin' && !isSuperAdmin && (
                      <span className="text-xs text-red-500">Không thể chỉnh sửa</span>
                    )}
                  </div>
                </div>
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">{role.description}</td>
              <td className="px-6 py-4 text-center">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  {role.permissions?.length || 0} quyền
                </span>
              </td>
              <td className="px-6 py-4 text-center">
                <div className="flex items-center justify-center">
                  <Users className="h-5 w-5 text-gray-400 mr-1" />
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                    {role.userCount || 0} thành viên
                  </span>
                </div>
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center justify-center space-x-3">
                  <button
                    onClick={() => onEdit(role)}
                    disabled={!canEditRole(role)}
                    className={`text-blue-600 hover:text-blue-900 ${!canEditRole(role) ? 'opacity-50 cursor-not-allowed' : ''}`}
                    title={canEditRole(role) ? 'Sửa' : 'Không có quyền sửa'}
                  >
                    <Edit2 size={16} />
                  </button>
                  <button
                    onClick={() => handleDelete(role)}
                    disabled={!canDeleteRole(role)}
                    className={`text-red-600 hover:text-red-900 ${!canDeleteRole(role) ? 'opacity-50 cursor-not-allowed' : ''}`}
                    title={canDeleteRole(role) ? 'Xóa' : 'Không có quyền xóa'}
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </td>
            </tr>
          ))}

          {roles.length === 0 && (
            <tr>
              <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                Không có vai trò nào
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RoleList;
