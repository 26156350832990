// src/client/components/layout/Header/Header.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Zap, Tag, Star, TrendingUp } from 'lucide-react';
import TopBar from './TopBar';
import SearchBar from './SearchBar';
import UserMenu from './UserMenu';
import MobileMenu from './MobileMenu';
import CartButton from '../../common/CartButton';

const NavigationLink = ({ to, icon: Icon, label, isSpecial, badgeText, badgeColor }) => (
  <Link
    to={to}
    className={`group relative flex items-center gap-2 px-4 py-2 rounded-full transition-all duration-300
      ${
        isSpecial
          ? 'bg-red-50 hover:bg-red-100 text-red-600 hover:text-red-700'
          : 'hover:bg-gray-100 text-gray-700 hover:text-primary-600'
      }`}
  >
    {Icon && <Icon size={18} className="transition-transform group-hover:scale-110" />}
    <span className="font-medium">{label}</span>
    {badgeText && (
      <span
        className={`absolute -top-2 -right-2 px-2 py-0.5 text-xs text-white rounded-full 
        ${badgeColor || 'bg-red-500'} animate-pulse`}
      >
        {badgeText}
      </span>
    )}
  </Link>
);

const Header = () => {
  return (
    <header className="bg-sky-100 shadow-sm">
      <TopBar />

      {/* Main Header */}
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2">
            <span className="text-4xl font-bold text-primary-600">
              Lap<span className="text-gray-600">py</span>
            </span>
          </Link>

          {/* Desktop Search Bar */}
          <div className="hidden md:flex flex-1 max-w-xl mx-8">
            <SearchBar />
          </div>

          {/* Desktop Actions */}
          <div className="hidden md:flex items-center space-x-4">
            <UserMenu />
            <CartButton />
          </div>

          {/* Mobile Menu */}
          <MobileMenu />
        </div>

        {/* Navigation */}
        <nav className="hidden md:flex items-center gap-6 mt-4">
          <div className="flex items-center gap-4">
            {/* Flash Sale - Special Highlight */}
            <NavigationLink
              to="/flash-sale"
              icon={Zap}
              label="Flash Sale"
              isSpecial={true}
              badgeText="HOT"
            />
            {/* Promotions */}
            <NavigationLink
              to="/promotion"
              icon={Tag}
              label="Khuyến Mãi"
              badgeText="SALE"
              badgeColor="bg-green-500"
            />
            {/* New Products */}
            <NavigationLink
              to="/new-products"
              icon={Star}
              label="Sản Phẩm Mới"
              badgeText="NEW"
              badgeColor="bg-yellow-500"
            />
            {/* Best Sellers */}
            <NavigationLink
              to="/best-sellers"
              icon={TrendingUp}
              label="Bán Chạy"
              badgeText="TOP"
              badgeColor="bg-blue-500"
            />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
