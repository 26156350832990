import { SHIPPING_PROVIDERS } from '../../../constants';
import CurrencyInput from '../../../../common/CurrencyInput';
import Select from 'react-select';

export const ShippingSection = ({
  address,
  provider,
  tracking_number,
  fee,
  errors,
  onAddressChange,
  onProviderChange,
  onTrackingNumberChange,
  onFeeChange,
  disabled,
}) => (
  <div className="bg-white p-6 rounded-lg border space-y-6">
    <h3 className="text-lg font-medium text-gray-900">4. Thông tin giao hàng</h3>

    {/* Contact Info */}
    <div className="space-y-4">
      <h4 className="font-medium text-gray-700">Thông tin người nhận</h4>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Họ tên <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={address.fullName}
            onChange={(e) => onAddressChange('fullName', e.target.value)}
            disabled={disabled}
            className={`input mt-1 w-full ${errors?.fullName ? 'border-red-500' : ''}`}
          />
          {errors?.fullName && <p className="text-red-500 text-sm mt-1">{errors.fullName}</p>}
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Số điện thoại <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={address.phone}
            onChange={(e) => onAddressChange('phone', e.target.value)}
            disabled={disabled}
            className={`input mt-1 w-full ${errors?.phone ? 'border-red-500' : ''}`}
          />
          {errors?.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
        </div>
      </div>

      {/* Address Fields */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <input
            type="email"
            value={address.email}
            onChange={(e) => onAddressChange('email', e.target.value)}
            disabled={disabled}
            className="input mt-1 w-full"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Địa chỉ</label>
          <input
            type="text"
            value={address.address}
            onChange={(e) => onAddressChange('address', e.target.value)}
            disabled={disabled}
            className="input mt-1 w-full"
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Phường/Xã</label>
          <input
            type="text"
            value={address.ward}
            onChange={(e) => onAddressChange('ward', e.target.value)}
            disabled={disabled}
            className="input mt-1 w-full"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Quận/Huyện</label>
          <input
            type="text"
            value={address.district}
            onChange={(e) => onAddressChange('district', e.target.value)}
            disabled={disabled}
            className="input mt-1 w-full"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Tỉnh/Thành phố</label>
          <input
            type="text"
            value={address.city}
            onChange={(e) => onAddressChange('city', e.target.value)}
            disabled={disabled}
            className="input mt-1 w-full"
          />
        </div>
      </div>
    </div>

    {/* Shipping Details */}
    <div className="space-y-4">
      <h4 className="font-medium text-gray-700">Thông tin vận chuyển</h4>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Đơn vị vận chuyển</label>
          <Select
            options={SHIPPING_PROVIDERS}
            value={SHIPPING_PROVIDERS.find((x) => x.value === provider)}
            onChange={onProviderChange}
            isDisabled={disabled}
            placeholder="Chọn đơn vị vận chuyển..."
            className="mt-1"
            classNamePrefix="select"
            isClearable
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Mã vận đơn</label>
          <input
            type="text"
            value={tracking_number}
            onChange={(e) => onTrackingNumberChange(e.target.value)}
            disabled={disabled}
            className="input mt-1 w-full"
            placeholder="Nhập mã vận đơn..."
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Phí vận chuyển</label>
          <CurrencyInput
            value={fee}
            onChange={onFeeChange}
            disabled={disabled}
            className="mt-1 w-full"
          />
        </div>
      </div>
    </div>
  </div>
);
