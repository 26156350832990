// src/client/components/home/TrustBadges.jsx
import React from 'react';
import { motion } from 'framer-motion';
import {
  Truck,
  ShieldCheck,
  RefreshCw,
  Clock,
  CreditCard,
  Award,
  MapPin,
  Headphones,
} from 'lucide-react';

const TrustBadges = () => {
  const badges = [
    {
      icon: <Truck />,
      title: 'Miễn phí vận chuyển toàn quốc',
      description: 'Tất cả các đơn hàng',
      color: 'text-blue-600',
      bgColor: 'bg-blue-50',
    },
    {
      icon: <ShieldCheck />,
      title: 'Bảo hành chính hãng',
      description: '6-12 tháng tùy sản phẩm',
      color: 'text-green-600',
      bgColor: 'bg-green-50',
    },
    {
      icon: <RefreshCw />,
      title: 'Đổi trả dễ dàng',
      description: 'Trong vòng 15 ngày',
      color: 'text-purple-600',
      bgColor: 'bg-purple-50',
    },
    {
      icon: <CreditCard />,
      title: 'Thanh toán an toàn',
      description: 'Nhiều phương thức thanh toán',
      color: 'text-indigo-600',
      bgColor: 'bg-indigo-50',
    },
    {
      icon: <Award />,
      title: 'Sản phẩm chính hãng',
      description: '100% authentic',
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-50',
    },
    {
      icon: <MapPin />,
      title: 'Hệ thống cửa hàng',
      description: 'Trải dài toàn quốc',
      color: 'text-red-600',
      bgColor: 'bg-red-50',
    },
    {
      icon: <Clock />,
      title: 'Giao hàng nhanh',
      description: '2-4 giờ trong nội thành',
      color: 'text-orange-600',
      bgColor: 'bg-orange-50',
    },
    {
      icon: <Headphones />,
      title: 'Hỗ trợ 24/7',
      description: 'Tư vấn nhiệt tình',
      color: 'text-teal-600',
      bgColor: 'bg-teal-50',
    },
  ];

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div className="container mx-auto px-4">
      <div className="text-center mb-10">
        <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
          Tại sao chọn chúng tôi?
        </h2>
        <p className="text-gray-600 max-w-2xl mx-auto">
          Chúng tôi cam kết mang đến trải nghiệm mua sắm tốt nhất với những dịch vụ và chính sách ưu
          việt
        </p>
      </div>

      <motion.div
        className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-100px' }}
      >
        {badges.map((badge, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            className="group p-6 bg-white rounded-2xl border border-gray-100 
              hover:border-gray-200 hover:shadow-lg transition-all duration-300"
          >
            <div
              className={`w-12 h-12 ${badge.bgColor} ${badge.color} rounded-xl 
                flex items-center justify-center mb-4 group-hover:scale-110 transition-transform`}
            >
              {React.cloneElement(badge.icon, { size: 24 })}
            </div>

            <h3 className="text-lg font-semibold text-gray-900 mb-2">{badge.title}</h3>

            <p className="text-sm text-gray-600">{badge.description}</p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default TrustBadges;
