// src/admin/components/FlashSaleManager/SelectedProducts/index.jsx
import React from 'react';
import { X, AlertCircle } from 'lucide-react';
import CurrencyInput from '../../../components/common/CurrencyInput';

const SelectedProducts = ({ products, onChange }) => {
  const handleRemove = (productId) => {
    onChange(products.filter((p) => p.id !== productId));
  };

  const handleSalePriceChange = (productId, price) => {
    onChange(products.map((p) => (p.id === productId ? { ...p, salePrice: price } : p)));
  };

  const handleStockLimitChange = (productId, limit) => {
    const product = products.find((p) => p.id === productId);
    const newLimit = limit ? Number(limit) : null;

    onChange(
      products.map((p) =>
        p.id === productId
          ? {
              ...p,
              stockLimit: newLimit,
              stockLeft: newLimit || product.stock,
              // Reset limitPerCustomer if new stockLimit is less
              limitPerCustomer: p.limitPerCustomer > newLimit ? newLimit : p.limitPerCustomer,
            }
          : p
      )
    );
  };

  const handleLimitPerCustomerChange = (productId, limit) => {
    const newLimit = limit ? Number(limit) : null;
    onChange(
      products.map((p) =>
        p.id === productId
          ? {
              ...p,
              limitPerCustomer: newLimit,
            }
          : p
      )
    );
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(price);
  };

  const calculateDiscount = (originalPrice, salePrice) => {
    const discount = ((originalPrice - salePrice) / originalPrice) * 100;
    return Math.round(discount);
  };

  return (
    <div className="mt-4">
      {products.map((product) => (
        <div key={product.id} className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg mb-2">
          <div className="flex-1">
            <div className="font-medium">{product.name}</div>
            <div className="text-sm text-gray-500">
              Giá gốc: {formatPrice(product.originalPrice)}
            </div>
            {product.salePrice < product.originalPrice && (
              <div className="text-sm text-green-600 mt-1">
                Giảm {calculateDiscount(product.originalPrice, product.salePrice)}%
              </div>
            )}
          </div>

          <div className="w-48">
            <label className="block text-sm font-medium text-gray-700 mb-1">Giá Flash Sale</label>
            <CurrencyInput
              value={product.salePrice}
              onChange={(value) => handleSalePriceChange(product.id, value)}
              className="w-full"
              placeholder="Nhập giá Flash Sale"
            />
            {product.salePrice >= product.originalPrice && (
              <div className="flex items-center gap-1 text-xs text-red-500 mt-1">
                <AlertCircle size={12} />
                Giá sale phải thấp hơn giá gốc
              </div>
            )}
          </div>

          <div className="w-36">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Số lượng giới hạn
            </label>
            <input
              type="number"
              value={product.stockLimit || ''}
              onChange={(e) => handleStockLimitChange(product.id, e.target.value)}
              className="input"
              placeholder="Không giới hạn"
              min="0"
              max={product.stock}
            />
            <div className="text-xs text-gray-500 mt-1">Tồn kho: {product.stock}</div>
            {product.stockLimit > product.stock && (
              <div className="flex items-center gap-1 text-xs text-red-500 mt-1">
                <AlertCircle size={12} />
                Không thể vượt quá số lượng tồn kho
              </div>
            )}
          </div>

          <div className="w-36">
            <label className="block text-sm font-medium text-gray-700 mb-1">Giới hạn/khách</label>
            <input
              type="number"
              value={product.limitPerCustomer || ''}
              onChange={(e) => handleLimitPerCustomerChange(product.id, e.target.value)}
              className="input"
              placeholder="Không giới hạn"
              min="0"
              max={product.stockLimit || product.stock}
            />
            {product.limitPerCustomer > (product.stockLimit || product.stock) && (
              <div className="flex items-center gap-1 text-xs text-red-500 mt-1">
                <AlertCircle size={12} />
                Không thể vượt quá số lượng giới hạn
              </div>
            )}
          </div>

          <button
            onClick={() => handleRemove(product.id)}
            className="p-2 text-red-600 hover:bg-red-50 rounded-full"
            title="Xóa sản phẩm"
          >
            <X size={20} />
          </button>
        </div>
      ))}

      {products.length === 0 && (
        <div className="text-center py-4 text-gray-500">Chưa có sản phẩm nào được chọn</div>
      )}

      {products.length > 0 && (
        <div className="mt-4 bg-blue-50 rounded-lg p-4">
          <div className="text-sm">
            <div className="font-medium text-blue-700">
              Tổng sản phẩm đã chọn: {products.length}
            </div>
            <div className="mt-1 text-blue-600">
              Tất cả sản phẩm sẽ được tự động kích hoạt khi Flash Sale bắt đầu
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectedProducts;
