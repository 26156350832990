import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

import toast from 'react-hot-toast';
import ProductList from './ProductList';
import ProductSelector from './ProductSelector';
import SelectedProducts from './SelectedProducts';
import { fetchPromotions } from '../../../../store/promotionSlice';
import {
  addPromotionProducts,
  fetchPromotionProducts,
  removePromotionProducts,
  updatePromotionProduct,
} from '../../../../store/promotionProductSlice';
import { usePermissions } from '../../../../hooks/usePermissions';
import { MODULES } from '../../../../models/userModels';

const PromotionProductManager = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { canView, canUpdate } = usePermissions();
  const hasViewPermission = canView(MODULES.PROMOTIONS);
  const hasUpdatePermission = canUpdate(MODULES.PROMOTIONS);

  // Thêm loading status
  const promotionsStatus = useSelector((state) => state.promotions.status);
  const productsStatus = useSelector((state) => state.promotionProducts.status);
  const promotion = useSelector((state) => state.promotions.items.find((p) => p.id === id));
  const promotionProducts = useSelector((state) => state.promotionProducts.items);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isAdding, setIsAdding] = useState(false);

  // Fetch data khi component mount
  useEffect(() => {
    if (!hasViewPermission) {
      navigate('/admin/unauthorized');
      return;
    }

    // Fetch promotions nếu chưa có
    if (promotionsStatus === 'idle') {
      dispatch(fetchPromotions());
    }
    // Luôn fetch products khi có id
    if (id) {
      dispatch(fetchPromotionProducts(id));
    }
  }, [id, promotionsStatus, dispatch, hasViewPermission, navigate]);

  const handleAddProducts = async () => {
    if (selectedProducts.length === 0) return;
    if (!hasUpdatePermission) {
      toast.error('Bạn không có quyền thêm sản phẩm vào khuyến mãi');
      return;
    }
    try {
      setIsAdding(true);
      await dispatch(
        addPromotionProducts({
          promotionId: id,
          products: selectedProducts,
        })
      ).unwrap();

      setSelectedProducts([]);
      toast.success('Thêm sản phẩm thành công');
    } catch (error) {
      toast.error('Thêm sản phẩm thất bại: ' + error.message);
    } finally {
      setIsAdding(false);
    }
  };

  const handleUpdateProduct = async (productId, data) => {
    try {
      await dispatch(
        updatePromotionProduct({
          id: productId,
          data: {
            ...data,
            promotionId: id,
          },
        })
      ).unwrap();
      toast.success('Cập nhật sản phẩm thành công');
    } catch (error) {
      toast.error('Cập nhật sản phẩm thất bại: ' + error.message);
    }
  };

  const handleRemoveProducts = async (productIds) => {
    if (!window.confirm('Bạn có chắc muốn xóa sản phẩm này?')) return;

    try {
      await dispatch(
        removePromotionProducts({
          promotionId: id,
          productIds: productIds,
        })
      ).unwrap();
      toast.success('Xóa sản phẩm thành công');
    } catch (error) {
      toast.error('Xóa sản phẩm thất bại: ' + error.message);
    }
  };

  // Loading state
  if (promotionsStatus === 'loading' || productsStatus === 'loading') {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  // Error state
  if (!promotion) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-red-600">Không tìm thấy chương trình khuyến mãi</h2>
        <button onClick={() => navigate('/admin/promotions')} className="mt-4 btn btn-primary">
          Quay lại danh sách
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-6">
      {/* Header */}
      <div className="flex items-center gap-4 mb-6">
        <button onClick={() => navigate('/admin/promotions')} className="btn btn-ghost">
          <ArrowLeft size={20} />
          Quay lại
        </button>
        <div>
          <h1 className="text-2xl font-bold">Quản lý sản phẩm khuyến mãi: {promotion.name}</h1>
          <p className="text-sm text-gray-500">
            Thời gian: {new Date(promotion.startDate).toLocaleDateString('vi-VN')} -{' '}
            {new Date(promotion.endDate).toLocaleDateString('vi-VN')}
          </p>
        </div>
      </div>

      {/* Product Selection Section */}
      <div className="bg-white rounded-lg shadow p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Thêm sản phẩm mới</h2>
          <div className="text-sm text-gray-500">Đã chọn: {selectedProducts.length} sản phẩm</div>
        </div>

        <ProductSelector selectedProducts={selectedProducts} onChange={setSelectedProducts} />

        {selectedProducts.length > 0 && (
          <>
            <SelectedProducts products={selectedProducts} onChange={setSelectedProducts} />
            <div className="mt-4 flex justify-end">
              <button onClick={handleAddProducts} disabled={isAdding} className="btn btn-primary">
                {isAdding ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>
                    Đang thêm...
                  </>
                ) : (
                  'Thêm sản phẩm'
                )}
              </button>
            </div>
          </>
        )}
      </div>

      {/* Product List Section */}
      <div className="bg-white rounded-lg shadow">
        <div className="p-6 border-b">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">
              Danh sách sản phẩm ({promotionProducts.length})
            </h2>
          </div>
        </div>

        <ProductList
          products={promotionProducts}
          onUpdate={handleUpdateProduct}
          onRemove={handleRemoveProducts}
          promotionType={promotion.type}
          promotionValue={promotion.value}
        />
      </div>
    </div>
  );
};

export default PromotionProductManager;
