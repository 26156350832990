// src/client/services/authService.js

import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
} from 'firebase/firestore';
import { db } from '../../firebase/config';
import { hashPassword, comparePassword } from '../../utils/passwordUtils';
import { USER_TYPE } from '../../models/userModels';
import { secureStorage } from '../../utils/cryptoUtils';

export const authService = {
  async register(userData) {
    try {
      // Validate required fields
      if (!userData.email || !userData.password || !userData.fullName) {
        throw new Error('Vui lòng điền đầy đủ thông tin bắt buộc');
      }

      // Check if email exists
      const emailQuery = query(
        collection(db, 'users'),
        where('email', '==', userData.email.toLowerCase().trim())
      );
      const emailSnapshot = await getDocs(emailQuery);

      if (!emailSnapshot.empty) {
        throw new Error('Email đã được sử dụng');
      }

      // Check if username exists (if provided)
      if (userData.username) {
        const usernameQuery = query(
          collection(db, 'users'),
          where('username', '==', userData.username.toLowerCase().trim())
        );
        const usernameSnapshot = await getDocs(usernameQuery);

        if (!usernameSnapshot.empty) {
          throw new Error('Username đã được sử dụng');
        }
      }

      // Hash password
      const hashedPassword = await hashPassword(userData.password);

      // Prepare user data following model structure
      const newUserData = {
        // Base model fields
        isActive: true,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),

        // User model required fields
        email: userData.email.toLowerCase().trim(),
        password: hashedPassword,
        fullName: userData.fullName.trim(),

        // User model optional fields
        phone: userData.phone?.trim() || '',
        avatar: userData.avatar || '',
        username: userData.username?.toLowerCase().trim() || '',

        // Customer specific fields
        type: USER_TYPE.CUSTOMER,
        status: 'active',
        addresses: [],
        wishlist: [],

        // Admin fields set to defaults
        roleId: '',
        isDefaultPassword: false,

        // Other fields
        lastLoginAt: null,
      };

      // Save to database
      const docRef = await addDoc(collection(db, 'users'), newUserData);

      // Return user data (excluding sensitive information)
      const { password, ...userWithoutPassword } = {
        id: docRef.id,
        ...newUserData,
        createdAt: new Date().toISOString(), // Convert for frontend
        updatedAt: new Date().toISOString(),
      };

      return userWithoutPassword;
    } catch (error) {
      console.error('Registration error:', error);
      throw new Error(error.message || 'Đăng ký thất bại');
    }
  },
  async login(credentials) {
    try {
      const { emailOrUsername, password } = credentials;
      console.log('Starting login process with:', {
        emailOrUsername,
        passwordLength: password?.length,
      });

      // Validate inputs
      if (!emailOrUsername?.trim()) {
        throw new Error('Email hoặc tên đăng nhập không được để trống');
      }

      if (!password?.trim()) {
        throw new Error('Mật khẩu không được để trống');
      }

      // Determine query field and normalize value
      const normalizedValue = emailOrUsername.toLowerCase().trim();
      const queryField = emailOrUsername.includes('@') ? 'email' : 'username';

      console.log('Query parameters:', {
        field: queryField,
        value: normalizedValue,
      });

      // Construct Firebase query
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('status', '==', 'active'),
        where(queryField, '==', normalizedValue)
      );

      const snapshot = await getDocs(q);
      console.log('Query result:', {
        found: !snapshot.empty,
        count: snapshot.size,
      });

      if (snapshot.empty) {
        throw new Error('Tài khoản hoặc mật khẩu không đúng');
      }

      const userData = {
        id: snapshot.docs[0].id,
        ...snapshot.docs[0].data(),
      };

      // Verify password
      const isValidPassword = await comparePassword(password, userData.password);
      if (!isValidPassword) {
        throw new Error('Tài khoản hoặc mật khẩu không đúng');
      }

      // Update last login
      await updateDoc(doc(db, 'users', userData.id), {
        lastLoginAt: serverTimestamp(),
      });

      // Remove sensitive data
      const { password: _, ...userToReturn } = userData;
      secureStorage.setItem('user', userToReturn);
      return userToReturn;
    } catch (error) {
      throw new Error(error.message || 'Đăng nhập thất bại');
    }
  },
  async logout() {
    secureStorage.removeItem('user');
    return true;
  },

  async verifyEmail(token) {
    try {
      // Implement email verification logic here
      // This is just a placeholder
      console.log('Verify email with token:', token);
      return true;
    } catch (error) {
      throw new Error('Xác thực email thất bại: ' + error.message);
    }
  },

  async resetPassword(email) {
    try {
      // Implement password reset logic here
      // This is just a placeholder
      console.log('Reset password for email:', email);
      return true;
    } catch (error) {
      throw new Error('Đặt lại mật khẩu thất bại: ' + error.message);
    }
  },

  async updateProfile(userId, profileData) {
    try {
      await updateDoc(doc(db, 'users', userId), {
        ...profileData,
        updatedAt: serverTimestamp(),
      });
      return true;
    } catch (error) {
      throw new Error('Cập nhật thông tin thất bại: ' + error.message);
    }
  },
  async changePassword(userId, oldPassword, newPassword) {
    try {
      // Get current user data
      const docRef = doc(db, 'users', userId);
      const userDoc = await getDoc(docRef);

      if (!userDoc.exists()) {
        throw new Error('Không tìm thấy người dùng');
      }

      const userData = userDoc.data();

      // Verify old password
      const isValidPassword = await comparePassword(oldPassword, userData.password);
      if (!isValidPassword) {
        throw new Error('Mật khẩu hiện tại không đúng');
      }

      // Hash new password
      const hashedPassword = await hashPassword(newPassword);

      // Update password
      await updateDoc(docRef, {
        password: hashedPassword,
        updatedAt: serverTimestamp(),
      });

      return true;
    } catch (error) {
      throw new Error(error.message || 'Đổi mật khẩu thất bại');
    }
  },
};
