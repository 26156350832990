import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// const firebaseConfig = {
//   apiKey: 'AIzaSyDR2Dg1TQTCtOt9igfBwxOb-_YsMBl6ng0',
//   authDomain: 'mapple-store.firebaseapp.com',
//   projectId: 'mapple-store',
//   storageBucket: 'mapple-store.firebasestorage.app',
//   messagingSenderId: '580873209148',
//   appId: '1:580873209148:web:e88f9e9b15805376614a39',
//   databaseURL: 'https://mapple-store-default-rtdb.asia-southeast1.firebasedatabase.app',
//   measurementId: 'G-1K6Y2QW307',
// };

const firebaseConfig = {
  apiKey: 'AIzaSyAnspEObkXWiCooBb01cyDDo1xQ10odLU4',
  authDomain: 'mapplestore-c8f29.firebaseapp.com',
  projectId: 'mapplestore-c8f29',
  storageBucket: 'mapplestore-c8f29.firebasestorage.app',
  messagingSenderId: '37741380772',
  appId: '1:37741380772:web:2b3518cb6ee1a05a841231',
  measurementId: 'G-531BMG4SGH',
};

const app = initializeApp(firebaseConfig);
console.log('Firebase initialized:', app.name);
const db = getFirestore(app); // const storage = getStorage(app);
// export const auth = getAuth(app);

// Configure Storage
// const storageRef = storage.ref();
// storageRef.setCustomMetadata({
//   'Access-Control-Allow-Origin': '*',
//   'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
// });

export { db };
