// src/admin/components/OrderManager/OrderDetailModal/components/CustomerInfoForm.jsx
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Edit2, Save } from 'lucide-react';
import { formatDate } from '../../utils';

export const CustomerInfoForm = ({ order, onUpdate, isSubmitting, canUpdate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(order.shipping_address);
  const [note, setNote] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!note.trim()) {
      toast.error('Vui lòng nhập lý do chỉnh sửa');
      return;
    }

    try {
      await onUpdate(customerInfo, note);
      setIsEditing(false);
      setNote('');
    } catch (error) {
      // Error handled by hook
    }
  };

  if (!isEditing) {
    return (
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Thông tin khách hàng</h3>
          {!['cancelled', 'returned'].includes(order.status) && canUpdate && (
            <button
              onClick={() => setIsEditing(true)}
              className="btn btn-secondary btn-sm flex items-center gap-2"
            >
              <Edit2 className="w-4 h-4" />
              Chỉnh sửa
            </button>
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="text-sm text-gray-500">Họ tên</label>
            <p className="font-medium">{order.shipping_address.fullName}</p>
          </div>
          <div>
            <label className="text-sm text-gray-500">Số điện thoại</label>
            <p className="font-medium">{order.shipping_address.phone}</p>
          </div>
          {order.shipping_address.email && (
            <div>
              <label className="text-sm text-gray-500">Email</label>
              <p className="font-medium">{order.shipping_address.email}</p>
            </div>
          )}
          <div className="col-span-2">
            <label className="text-sm text-gray-500">Địa chỉ</label>
            <p className="font-medium">
              {order.shipping_address.address}, {order.shipping_address.ward},{' '}
              {order.shipping_address.district}, {order.shipping_address.city}
            </p>
          </div>
        </div>

        {/* Hiển thị lịch sử chỉnh sửa */}
        {order.customer_history?.length > 0 && (
          <div className="border-t pt-4 mt-4">
            <h4 className="font-medium mb-3">Lịch sử chỉnh sửa</h4>
            <div className="space-y-4">
              {order.customer_history.map((history, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <div className="flex justify-between">
                    <div className="text-sm text-gray-500">{formatDate(history.timestamp)}</div>
                    <div className="text-sm font-medium">{history.note}</div>
                  </div>
                  <div className="mt-2 text-sm">
                    <div className="grid grid-cols-2 gap-2">
                      <div className="text-red-500">
                        <div>Số điện thoại cũ: {history.old_data.phone}</div>
                        <div>Địa chỉ cũ: {history.old_data.address}</div>
                      </div>
                      <div className="text-green-500">
                        <div>Số điện thoại mới: {history.new_data.phone}</div>
                        <div>Địa chỉ mới: {history.new_data.address}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h3 className="text-lg font-medium">Chỉnh sửa thông tin khách hàng</h3>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Họ tên <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={customerInfo.fullName}
            onChange={(e) =>
              setCustomerInfo((prev) => ({
                ...prev,
                fullName: e.target.value,
              }))
            }
            className="input w-full"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">
            Số điện thoại <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={customerInfo.phone}
            onChange={(e) =>
              setCustomerInfo((prev) => ({
                ...prev,
                phone: e.target.value,
              }))
            }
            className="input w-full"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-1">Email</label>
          <input
            type="email"
            value={customerInfo.email}
            onChange={(e) =>
              setCustomerInfo((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            className="input w-full"
          />
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1">
            Địa chỉ <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            value={customerInfo.address}
            onChange={(e) =>
              setCustomerInfo((prev) => ({
                ...prev,
                address: e.target.value,
              }))
            }
            className="input w-full"
            required
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium mb-1">Phường/Xã</label>
            <input
              type="text"
              value={customerInfo.ward}
              onChange={(e) =>
                setCustomerInfo((prev) => ({
                  ...prev,
                  ward: e.target.value,
                }))
              }
              className="input w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Quận/Huyện</label>
            <input
              type="text"
              value={customerInfo.district}
              onChange={(e) =>
                setCustomerInfo((prev) => ({
                  ...prev,
                  district: e.target.value,
                }))
              }
              className="input w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">Tỉnh/Thành phố</label>
            <input
              type="text"
              value={customerInfo.city}
              onChange={(e) =>
                setCustomerInfo((prev) => ({
                  ...prev,
                  city: e.target.value,
                }))
              }
              className="input w-full"
            />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium mb-1">
          Lý do chỉnh sửa <span className="text-red-500">*</span>
        </label>
        <textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
          className="input w-full"
          rows={3}
          placeholder="Nhập lý do chỉnh sửa thông tin..."
          required
        />
      </div>

      <div className="flex justify-end gap-2">
        <button
          type="button"
          onClick={() => setIsEditing(false)}
          className="btn btn-secondary"
          disabled={isSubmitting}
        >
          Hủy
        </button>
        <button
          type="submit"
          className="btn btn-primary flex items-center gap-2"
          disabled={isSubmitting}
        >
          <Save className="w-4 h-4" />
          Lưu thay đổi
        </button>
      </div>
    </form>
  );
};
