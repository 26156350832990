export const calculateTimeLeft = (endTime) => {
  const difference = new Date(endTime) - new Date();

  if (difference <= 0) {
    return null;
  }

  return {
    hours: Math.floor(difference / (1000 * 60 * 60)),
    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((difference % (1000 * 60)) / 1000),
  };
};
