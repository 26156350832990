// src/components/admin/CategoryManager/components/IconSelector.jsx

import React, { useState } from 'react';
import * as LucideIcons from 'lucide-react';
import { CATEGORIZED_ICONS } from '../../constants/icons';

const IconSelector = ({ value, onChange }) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  return (
    <div className="space-y-4">
      <select
        className="input w-full"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
      >
        <option value="">Chọn nhóm icon</option>
        {Object.keys(CATEGORIZED_ICONS).map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>

      {selectedCategory && (
        <div className="grid grid-cols-4 md:grid-cols-6 gap-4 p-4 bg-gray-50 rounded-lg">
          {Object.entries(CATEGORIZED_ICONS[selectedCategory]).map(([key, { icon, label }]) => {
            const IconComponent = LucideIcons[icon];
            if (!IconComponent) return null; // Thêm check này để tránh lỗi undefined

            return (
              <button
                key={key}
                type="button"
                onClick={() => onChange(icon)}
                className={`flex flex-col items-center gap-2 p-3 rounded-lg hover:bg-white 
                  transition-colors ${value === icon ? 'bg-white ring-2 ring-primary-500' : ''}`}
              >
                <IconComponent className="w-6 h-6 text-gray-600" />
                <span className="text-xs text-gray-600 text-center">{label}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default IconSelector;
