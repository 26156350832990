// src/client/components/order/OrderStatusStats.jsx
import React from 'react';
import { Package, Clock, Truck, CheckCircle, XCircle } from 'lucide-react';

const OrderStatusStats = ({ orders }) => {
  const getStatusCounts = () => {
    const counts = {
      pending: 0,
      processing: 0,
      shipping: 0,
      delivered: 0,
      cancelled: 0,
      total: orders.length,
    };

    orders.forEach((order) => {
      if (counts.hasOwnProperty(order.status)) {
        counts[order.status]++;
      }
    });

    return counts;
  };

  const stats = getStatusCounts();

  const statItems = [
    {
      label: 'Tổng đơn hàng',
      value: stats.total,
      icon: Package,
      color: 'text-gray-600',
      bgColor: 'bg-gray-100',
    },
    {
      label: 'Chờ xử lý',
      value: stats.pending,
      icon: Clock,
      color: 'text-yellow-600',
      bgColor: 'bg-yellow-100',
    },
    {
      label: 'Đang giao',
      value: stats.shipping,
      icon: Truck,
      color: 'text-blue-600',
      bgColor: 'bg-blue-100',
    },
    {
      label: 'Hoàn thành',
      value: stats.delivered,
      icon: CheckCircle,
      color: 'text-green-600',
      bgColor: 'bg-green-100',
    },
    {
      label: 'Đã hủy',
      value: stats.cancelled,
      icon: XCircle,
      color: 'text-red-600',
      bgColor: 'bg-red-100',
    },
  ];

  return (
    <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
      {statItems.map((item, index) => (
        <div
          key={index}
          className="bg-white rounded-lg border p-4 flex flex-col items-center justify-center text-center"
        >
          <div className={`${item.bgColor} p-3 rounded-full ${item.color} mb-3`}>
            <item.icon className="w-6 h-6" />
          </div>
          <div className="text-2xl font-bold mb-1">{item.value}</div>
          <div className="text-sm text-gray-500">{item.label}</div>
        </div>
      ))}
    </div>
  );
};

export default OrderStatusStats;
