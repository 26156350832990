// src/client/features/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '../services/authService';
import { secureStorage } from '../../utils/cryptoUtils';

// Action creators
export const register = createAsyncThunk('auth/register', async (userData, { rejectWithValue }) => {
  try {
    // Validate required fields
    if (!userData.email?.trim() || !userData.password?.trim() || !userData.fullName?.trim()) {
      return rejectWithValue('Vui lòng điền đầy đủ thông tin bắt buộc');
    }

    const user = await authService.register(userData);
    return user;
  } catch (error) {
    return rejectWithValue(error.message || 'Đăng ký thất bại');
  }
});
export const login = createAsyncThunk('auth/login', async (credentials, { rejectWithValue }) => {
  try {
    const user = await authService.login(credentials);
    secureStorage.setItem('user', user);
    return user;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});
export const logout = createAsyncThunk('auth/logout', async () => {
  secureStorage.removeItem('user');
  return null;
});

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async ({ userId, ...updateData }, { rejectWithValue }) => {
    try {
      await authService.updateProfile(userId, updateData);
      // Update local storage with new user data
      const currentUser = secureStorage.getItem('user');
      const updatedUser = { ...currentUser, ...updateData };
      secureStorage.setItem('user', updatedUser);
      return updatedUser;
    } catch (error) {
      return rejectWithValue(error.message || 'Cập nhật thông tin thất bại');
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ userId, oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      await authService.changePassword(userId, oldPassword, newPassword);
      return { success: true };
    } catch (error) {
      return rejectWithValue(error.message || 'Đổi mật khẩu thất bại');
    }
  }
);

// Initial state
const initialState = {
  user: secureStorage.getItem('user') || null,
  status: 'idle',
  error: null,
};

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      secureStorage.removeItem('user');
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Register
      .addCase(register.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(register.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Login cases
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = typeof action.payload === 'object' ? action.payload.message : action.payload;
      })
      // Logout
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload;
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      // Change Password
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});
export const { clearError } = authSlice.actions;
export default authSlice.reducer;
