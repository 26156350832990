import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/config';

// Fetch tất cả Flash Sales
export const fetchFlashSales = createAsyncThunk('flashSaleData/fetchAll', async () => {
  try {
    // Chỉ lấy các flash sales đang active
    const salesQuery = query(collection(db, 'flash_sales'), where('isActive', '==', true));

    const salesSnapshot = await getDocs(salesQuery);
    const sales = salesSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        startTime: data.startTime?.toDate?.()?.toISOString() || data.startTime,
        endTime: data.endTime?.toDate?.()?.toISOString() || data.endTime,
        createdAt: data.createdAt?.toDate?.()?.toISOString() || data.createdAt,
        updatedAt: data.updatedAt?.toDate?.()?.toISOString() || data.updatedAt,
      };
    });

    // Lấy flash sale products
    const productsQuery = query(
      collection(db, 'flash_sale_products'),
      where('isActive', '==', true)
    );

    const productsSnapshot = await getDocs(productsQuery);
    const products = productsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt?.toDate?.()?.toISOString() || data.createdAt,
        updatedAt: data.updatedAt?.toDate?.()?.toISOString() || data.updatedAt,
      };
    });

    console.log('Loaded sales:', sales);
    console.log('Loaded products:', products);

    return {
      sales,
      products,
    };
  } catch (error) {
    console.error('Error fetching flash sales:', error);
    throw error;
  }
});

const flashSaleDataSlice = createSlice({
  name: 'flashSaleData',
  initialState: {
    sales: [],
    products: [],
    status: 'idle',
    error: null,
    selectedSaleId: null,
  },
  reducers: {
    setSelectedSale: (state, action) => {
      state.selectedSaleId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFlashSales.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlashSales.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sales = action.payload.sales;
        state.products = action.payload.products;

        // Tự động chọn flash sale đang hoạt động đầu tiên
        const now = new Date();
        const activeSale = action.payload.sales.find((sale) => {
          const startTime = new Date(sale.startTime);
          const endTime = new Date(sale.endTime);
          return now >= startTime && now <= endTime;
        });

        if (activeSale && !state.selectedSaleId) {
          state.selectedSaleId = activeSale.id;
        }
      })
      .addCase(fetchFlashSales.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setSelectedSale } = flashSaleDataSlice.actions;

// Selectors
export const selectFlashSaleProducts = (state, saleId) => {
  return state.flashSaleData.products.filter((product) => product.flashSaleId === saleId);
};

export const selectFlashSaleStatus = (state) => ({
  isLoading: state.flashSaleData.status === 'loading',
  error: state.flashSaleData.error,
  hasData: state.flashSaleData.sales.length > 0 && state.flashSaleData.products.length > 0,
});

export default flashSaleDataSlice.reducer;
