import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { X, Upload, AlertCircle } from 'lucide-react';
import { addFlashSale, updateFlashSale } from '../../../store/flashSaleSlice';
import toast from 'react-hot-toast';
import imageCompression from 'browser-image-compression';
import CurrencyInput from '../common/CurrencyInput';
import { usePermissions } from '../../../hooks/usePermissions';
import { MODULES } from '../../../models/userModels';

const ImageUploader = ({ label, value, onChange, description }) => {
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange({
          file: compressedFile,
          preview: reader.result,
        });
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      toast.error('Lỗi xử lý ảnh: ' + error.message);
    }
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {description && <p className="text-xs text-gray-500">{description}</p>}

      {value?.preview ? (
        <div className="relative">
          <img src={value.preview} alt={label} className="w-full h-48 object-cover rounded-lg" />
          <button
            type="button"
            onClick={() => onChange(null)}
            className="absolute -top-2 -right-2 p-1.5 bg-red-500 text-white rounded-full shadow hover:bg-red-600"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      ) : (
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 transition hover:border-primary-500">
          <input
            type="file"
            onChange={handleImageChange}
            accept="image/*"
            className="hidden"
            id={`image-${label}`}
          />
          <label htmlFor={`image-${label}`} className="cursor-pointer flex flex-col items-center">
            <Upload className="w-10 h-10 text-gray-400" />
            <span className="mt-2 text-sm text-gray-600">Tải ảnh lên</span>
            <span className="mt-1 text-xs text-gray-500">PNG, JPG (Tối đa 1MB)</span>
          </label>
        </div>
      )}
    </div>
  );
};

const FlashSaleForm = ({ flashSale, onClose }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [images, setImages] = useState({
    bannerImage: flashSale?.bannerImage ? { preview: flashSale.bannerImage } : null,
    thumbnailImage: flashSale?.thumbnailImage ? { preview: flashSale.thumbnailImage } : null,
  });

  const { canCreate, canUpdate } = usePermissions();
  const hasCreatePermission = canCreate(MODULES.FLASH_SALES);
  const hasUpdatePermission = canUpdate(MODULES.FLASH_SALES);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: flashSale
      ? {
          ...flashSale,
          startTime: new Date(flashSale.startTime).toISOString().slice(0, 16),
          endTime: new Date(flashSale.endTime).toISOString().slice(0, 16),
        }
      : {
          name: '',
          description: '',
          startTime: '',
          endTime: '',
          priority: 0,
          isActive: true,
          displaySettings: {
            showCountdown: true,
            showStock: true,
            showSoldCount: true,
          },
          rules: {
            maxOrderPerCustomer: null,
            requireLogin: true,
            minOrderValue: 0,
          },
        },
  });

  const watchStartTime = watch('startTime');

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const loadingToast = toast.loading('Đang xử lý...');

      const formData = {
        ...data,
        bannerImage: images.bannerImage,
        thumbnailImage: images.thumbnailImage,
        startTime: new Date(data.startTime).toISOString(),
        endTime: new Date(data.endTime).toISOString(),
        priority: Number(data.priority),
        rules: {
          ...data.rules,
          maxOrderPerCustomer: data.rules.maxOrderPerCustomer
            ? Number(data.rules.maxOrderPerCustomer)
            : null,
          minOrderValue: Number(data.rules.minOrderValue),
        },
      };
      if (flashSale) {
        if (!hasUpdatePermission) {
          toast.error('Bạn không có quyền cập nhật Flash Sale');
          return;
        }
        await dispatch(
          updateFlashSale({
            id: flashSale.id,
            data: formData,
          })
        ).unwrap();
        toast.success('Cập nhật Flash Sale thành công');
      } else {
        if (!hasCreatePermission) {
          toast.error('Bạn không có quyền tạo Flash Sale');
          return;
        }
        await dispatch(addFlashSale(formData)).unwrap();
        toast.success('Tạo Flash Sale thành công');
      }
      if (flashSale) {
        await dispatch(updateFlashSale({ id: flashSale.id, data: formData })).unwrap();
        toast.success('Cập nhật Flash Sale thành công');
      } else {
        await dispatch(addFlashSale(formData)).unwrap();
        toast.success('Thêm Flash Sale thành công');
      }

      toast.dismiss(loadingToast);
      onClose();
    } catch (error) {
      toast.error('Có lỗi xảy ra: ' + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50 pt-[5vh] overflow-auto">
      <div className="bg-white rounded-xl w-full max-w-4xl mx-4 mb-8">
        {/* Header */}
        <div className="border-b px-6 py-4 flex items-center justify-between">
          <h2 className="text-xl font-semibold text-gray-900">
            {flashSale ? 'Chỉnh sửa Flash Sale' : 'Tạo Flash Sale mới'}
          </h2>
          <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
            <X className="w-5 h-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="px-6 py-4 space-y-6">
            {/* Basic Info */}
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Tên Flash Sale</label>
                <input
                  type="text"
                  {...register('name', { required: 'Vui lòng nhập tên Flash Sale' })}
                  className="mt-1 input"
                  placeholder="VD: Flash Sale cuối tuần"
                />
                {errors.name && (
                  <span className="text-sm text-red-500 flex items-center gap-1 mt-1">
                    <AlertCircle className="w-4 h-4" />
                    {errors.name.message}
                  </span>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Mô tả</label>
                <textarea
                  {...register('description')}
                  rows={3}
                  className="mt-1 input"
                  placeholder="Mô tả chi tiết về đợt Flash Sale"
                />
              </div>
            </div>

            {/* Time Settings */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Thời gian bắt đầu</label>
                <input
                  type="datetime-local"
                  {...register('startTime', {
                    required: 'Vui lòng chọn thời gian bắt đầu',
                  })}
                  className="mt-1 input"
                />
                {errors.startTime && (
                  <span className="text-sm text-red-500 flex items-center gap-1 mt-1">
                    <AlertCircle className="w-4 h-4" />
                    {errors.startTime.message}
                  </span>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Thời gian kết thúc
                </label>
                <input
                  type="datetime-local"
                  {...register('endTime', {
                    required: 'Vui lòng chọn thời gian kết thúc',
                    validate: (value) =>
                      new Date(value) > new Date(watchStartTime) ||
                      'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
                  })}
                  className="mt-1 input"
                />
                {errors.endTime && (
                  <span className="text-sm text-red-500 flex items-center gap-1 mt-1">
                    <AlertCircle className="w-4 h-4" />
                    {errors.endTime.message}
                  </span>
                )}
              </div>
            </div>

            {/* Images */}
            <div className="grid grid-cols-2 gap-6">
              <ImageUploader
                label="Banner"
                value={images.bannerImage}
                onChange={(value) => setImages((prev) => ({ ...prev, bannerImage: value }))}
                description="Kích thước đề xuất: 1920x600px"
              />

              <ImageUploader
                label="Thumbnail"
                value={images.thumbnailImage}
                onChange={(value) => setImages((prev) => ({ ...prev, thumbnailImage: value }))}
                description="Kích thước đề xuất: 400x400px"
              />
            </div>

            {/* Rules */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Quy tắc Flash Sale</h3>

              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Số đơn tối đa/khách
                  </label>
                  <input
                    type="number"
                    {...register('rules.maxOrderPerCustomer')}
                    className="mt-1 input"
                    placeholder="Không giới hạn"
                    min="0"
                    onFocus={(e) => {
                      if (e.target.value === '0') {
                        e.target.value = '';
                      }
                    }}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Giá trị đơn tối thiểu
                  </label>
                  <CurrencyInput
                    value={watch('rules.minOrderValue')}
                    onChange={(value) => setValue('rules.minOrderValue', value)}
                    className="mt-1 input"
                    placeholder="VD: 500.000"
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Để trống hoặc 0 nếu không yêu cầu giá trị tối thiểu
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-6">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    {...register('rules.requireLogin')}
                    className="rounded text-primary-600"
                  />
                  <span className="text-sm text-gray-700">Yêu cầu đăng nhập</span>
                </label>
              </div>
            </div>

            {/* Display Settings */}
            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">Cài đặt hiển thị</h3>

              <div className="grid grid-cols-1 gap-4">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    {...register('displaySettings.showCountdown')}
                    className="rounded text-primary-600"
                  />
                  <span className="text-sm text-gray-700">Hiển thị đếm ngược</span>
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    {...register('displaySettings.showStock')}
                    className="rounded text-primary-600"
                  />
                  <span className="text-sm text-gray-700">Hiển thị số lượng còn lại</span>
                </label>

                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    {...register('displaySettings.showSoldCount')}
                    className="rounded text-primary-600"
                  />
                  <span className="text-sm text-gray-700">Hiển thị số lượng đã bán</span>
                </label>
              </div>
            </div>

            {/* Other Settings */}
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">Độ ưu tiên</label>
                <input
                  type="number"
                  {...register('priority')}
                  className="mt-1 input"
                  min="0"
                  onFocus={(e) => {
                    if (e.target.value === '0') {
                      e.target.value = '';
                    }
                  }}
                />
              </div>

              <div className="flex items-center">
                <label className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    {...register('isActive')}
                    className="rounded text-primary-600"
                  />
                  <span className="text-sm text-gray-700">Kích hoạt</span>
                </label>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="border-t px-6 py-4 bg-gray-50 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="btn btn-secondary"
              disabled={isSubmitting}
            >
              Hủy
            </button>
            <button type="submit" disabled={isSubmitting} className="btn btn-primary min-w-[120px]">
              {isSubmitting ? (
                <div className="flex items-center gap-2">
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  {flashSale ? 'Đang cập nhật...' : 'Đang tạo...'}
                </div>
              ) : flashSale ? (
                'Cập nhật'
              ) : (
                'Tạo mới'
              )}
            </button>
          </div>
        </form>

        {/* Error Summary */}
        {Object.keys(errors).length > 0 && (
          <div className="absolute bottom-20 right-6 bg-red-50 text-red-500 p-4 rounded-lg shadow-lg">
            <h4 className="font-medium mb-2">Vui lòng kiểm tra lại các thông tin sau:</h4>
            <ul className="list-disc list-inside space-y-1">
              {errors.name && <li>{errors.name.message}</li>}
              {errors.startTime && <li>{errors.startTime.message}</li>}
              {errors.endTime && <li>{errors.endTime.message}</li>}
              {errors.rules?.maxOrderPerCustomer && <li>Số đơn tối đa/khách không hợp lệ</li>}
              {errors.rules?.minOrderValue && <li>Giá trị đơn tối thiểu không hợp lệ</li>}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlashSaleForm;
