import React, { useState } from 'react';
import { Search, Filter, XCircle } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilters, setFilters } from '../../../../../store/orderSlice';
import { ORDER_STATUS, PAYMENT_STATUS } from '../../constants';

const OrderFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.orders.filters);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleFilterChange = (field, value) => {
    dispatch(
      setFilters({
        ...filters,
        [field]: value,
      })
    );
  };

  const handleDateChange = (field, value) => {
    const dateRange = {
      ...filters.dateRange,
      [field]: value ? new Date(value) : null,
    };

    if (dateRange.from && dateRange.to) {
      // Set time to start/end of day
      dateRange.startDate = new Date(dateRange.from.setHours(0, 0, 0, 0));
      dateRange.endDate = new Date(dateRange.to.setHours(23, 59, 59, 999));
    }

    handleFilterChange('dateRange', dateRange);
  };

  const handleClearFilters = () => {
    // Reset date inputs
    const dateInputs = document.querySelectorAll('input[type="date"]');
    dateInputs.forEach((input) => (input.value = ''));

    dispatch(clearFilters());
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
      {/* Search Bar */}
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex-1 relative">
          <input
            type="text"
            placeholder="Tìm theo mã đơn, tên khách hàng, số điện thoại..."
            value={filters.searchTerm}
            onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
            className="input pl-10 w-full"
          />
          <Search size={20} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setShowAdvanced(!showAdvanced)}
            className="btn btn-secondary flex items-center gap-2"
          >
            <Filter size={20} />
            {showAdvanced ? 'Ẩn bộ lọc' : 'Hiện bộ lọc'}
          </button>
          {(filters.searchTerm || showAdvanced) && (
            <button
              onClick={handleClearFilters}
              className="btn btn-ghost text-gray-500 hover:text-gray-700"
              title="Xóa bộ lọc"
            >
              <XCircle size={20} />
            </button>
          )}
        </div>
      </div>

      {/* Advanced Filters */}
      {showAdvanced && (
        <div className="mt-4 pt-4 border-t">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {/* Status Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Trạng thái đơn hàng</label>
              <select
                value={filters.status}
                onChange={(e) => handleFilterChange('status', e.target.value)}
                className="input mt-1 w-full"
              >
                <option value="all">Tất cả trạng thái</option>
                {Object.entries(ORDER_STATUS).map(([key, value]) => (
                  <option key={value} value={value}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            {/* Payment Status Filter */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Trạng thái thanh toán
              </label>
              <select
                value={filters.payment_status}
                onChange={(e) => handleFilterChange('payment_status', e.target.value)}
                className="input mt-1 w-full"
              >
                <option value="all">Tất cả trạng thái</option>
                {Object.entries(PAYMENT_STATUS).map(([key, value]) => (
                  <option key={value} value={value}>
                    {key}
                  </option>
                ))}
              </select>
            </div>

            {/* Date Range Filters */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Từ ngày</label>
              <input
                type="date"
                value={filters.dateRange?.from || ''}
                onChange={(e) => handleDateChange('from', e.target.value)}
                className="input mt-1 w-full"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Đến ngày</label>
              <input
                type="date"
                value={filters.dateRange?.to || ''}
                onChange={(e) => handleDateChange('to', e.target.value)}
                className="input mt-1 w-full"
                min={filters.dateRange?.from || ''}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderFilters;
