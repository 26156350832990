import React from 'react';

const CurrencyInput = ({ value, onChange, className = '', placeholder = '', disabled = false }) => {
  const formatCurrency = (value) => {
    const number = value.replace(/\D/g, '');
    return new Intl.NumberFormat('vi-VN').format(number);
  };

  const handleChange = (e) => {
    const formatted = formatCurrency(e.target.value);
    const parsed = e.target.value.replace(/\D/g, '');
    e.target.value = formatted;
    onChange(Number(parsed));
  };

  const handleFocus = (e) => {
    if (e.target.value === '0' || e.target.value === '0 ₫') {
      e.target.value = '';
    }
  };

  const handleBlur = (e) => {
    if (!e.target.value) {
      e.target.value = '0';
      onChange(0);
    }
  };

  return (
    <input
      type="text"
      value={value === 0 ? '0' : new Intl.NumberFormat('vi-VN').format(value)}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className={`input ${className}`}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default CurrencyInput;
