import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePermissions } from '../hooks/usePermissions';
import LoadingScreen from '../admin/components/Layout/LoadingScreen';

export const PrivateRoute = ({ children, module, action }) => {
  const location = useLocation();
  const { hasPermission, isSuperAdmin } = usePermissions();
  const { loading } = useSelector((state) => state.globalUI);
  const { roles, status: rolesStatus } = useSelector((state) => state.roles);
  const user = useSelector((state) => state.auth.user);

  // Đợi cho roles load xong
  if (loading || rolesStatus === 'loading' || !user || roles.length === 0) {
    return <LoadingScreen />;
  }

  // Super Admin luôn có quyền truy cập
  if (isSuperAdmin) {
    return children;
  }

  // Kiểm tra quyền cho các user khác
  if (!hasPermission(module, action)) {
    return <Navigate to="/admin/unauthorized" state={{ from: location.pathname }} replace />;
  }

  return children;
};
