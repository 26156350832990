// src/client/components/layout/Footer/Footer.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Instagram, Youtube, MapPin, Phone, Mail, Clock, Info } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300">
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* About Section */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Về Lappy</h4>
            <p className="text-gray-400 mb-4">
              Công ty chúng tôi hoạt động trong lĩnh vực kinh doanh các dòng sản phẩm laptop,
              macbook tình trạng likenew. Thành lập từ năm 2020 đến nay, Mapple đã tạo dựng được chỗ
              đứng vững chắc trên thị trường và trở thành cái tên quen thuộc đối với khách hàng.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-primary-500">
                <Facebook size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-primary-500">
                <Instagram size={20} />
              </a>
              <a href="#" className="text-gray-400 hover:text-primary-500">
                <Youtube size={20} />
              </a>
            </div>
          </div>

          {/* Chính sách */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Chính sách</h4>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/policies/remote-purchase"
                  className="text-gray-400 hover:text-primary-500"
                >
                  Chính sách mua hàng từ xa - kiểm hàng
                </Link>
              </li>
              <li>
                <Link to="/policies/deposit" className="text-gray-400 hover:text-primary-500">
                  Chính sách đặt cọc sản phẩm
                </Link>
              </li>
              <li>
                <Link
                  to="/policies/shipping-returns"
                  className="text-gray-400 hover:text-primary-500"
                >
                  Chính sách giao nhận - đổi trả
                </Link>
              </li>
              <li>
                <Link to="/policies/warranty" className="text-gray-400 hover:text-primary-500">
                  Chính sách bảo hành
                </Link>
              </li>
              <li>
                <Link to="/policies/complaints" className="text-gray-400 hover:text-primary-500">
                  Quy trình tiếp nhận và giải quyết khiếu nại
                </Link>
              </li>
              <li>
                <Link to="/policies/terms" className="text-gray-400 hover:text-primary-500">
                  Thỏa thuận sử dụng và quy định giao dịch chung
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Thông tin liên hệ</h4>
            <ul className="space-y-3">
              <li className="flex items-start space-x-3">
                <MapPin size={20} className="text-primary-500 flex-shrink-0 mt-1" />
                <span className="text-gray-400">
                  Số 13 Đường Số 7 cityland, Phường 10, Quận Gò Vấp, Tp.Hồ Chí Minh
                </span>
              </li>
              <li className="flex items-center space-x-3">
                <Phone size={20} className="text-primary-500" />
                <span className="text-gray-400">09.3848.6358</span>
              </li>
              <li className="flex items-center space-x-3">
                <Mail size={20} className="text-primary-500" />
                <span className="text-gray-400">cuadt2.work@gmail.com</span>
              </li>
              <li className="flex items-start space-x-3">
                <Clock size={20} className="text-primary-500 flex-shrink-0 mt-1" />
                <div className="text-gray-400">
                  <p>Thứ 2 - Thứ 7: 9:00 - 21:00</p>
                  <p>Chủ nhật: 9:00 - 20:00</p>
                </div>
              </li>
            </ul>
          </div>

          {/* Google Map */}
          <div>
            <h4 className="text-lg font-semibold text-white mb-4">Bản đồ</h4>
            <div className="w-full h-64 rounded-lg overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.7878741515524!2d106.6661575!3d10.8340115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529b39eb5c699%3A0x21d7115aa645458e!2zMTMgxJDGsOG7nW5nIFPhu5EgNywgUGjGsOG7nW5nIDEwLCBHw7IgVuG6pXAsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1708176050095!5m2!1svi!2s"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-gray-800">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm mb-4 md:mb-0">
              © {new Date().getFullYear()} Lappy. Tất cả quyền được bảo lưu.
            </p>
            <div className="flex space-x-4 text-sm">
              <Link to="/privacy" className="text-gray-400 hover:text-primary-500">
                Chính sách bảo mật
              </Link>
              <Link to="/terms" className="text-gray-400 hover:text-primary-500">
                Điều khoản sử dụng
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
