// src/client/components/home/BestSellers.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import { TrendingUp } from 'lucide-react';
import ProductGrid from '../common/product/ProductGrid';

const BestSellers = () => {
  const products = useSelector((state) => state.products.items);

  // Get products with highest sold count
  const bestSellers = products
    .filter((p) => p.isActive)
    .sort((a, b) => (b.sold || 0) - (a.sold || 0))
    .slice(0, 4);

  if (bestSellers.length === 0) return null;

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center mb-8">
        <div>
          <div className="flex items-center gap-2 mb-2">
            <TrendingUp className="w-6 h-6 text-primary-600" />
            <h2 className="text-2xl font-bold text-gray-900">Sản phẩm bán chạy</h2>
          </div>
          <p className="text-gray-600">Những sản phẩm được khách hàng ưa chuộng nhất</p>
        </div>
      </div>

      <ProductGrid
        products={bestSellers}
        columns={{
          grid: { sm: 2, md: 3, lg: 4 },
          list: { sm: 1, md: 1, lg: 2 },
        }}
      />
    </div>
  );
};

export default BestSellers;
