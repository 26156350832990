import React from 'react';

const StatCard = ({ title, value, icon: Icon, colorClass = 'bg-primary-500', description }) => {
  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-start gap-4">
        {Icon && (
          <div className={`p-3 rounded-lg ${colorClass}`}>
            <Icon className="w-6 h-6 text-white" />
          </div>
        )}
        <div className="flex-1">
          <h3 className="text-sm font-medium text-gray-600">{title}</h3>
          <p className="text-2xl font-semibold mt-1">{value}</p>
          {description && <p className="text-sm text-gray-500 mt-1">{description}</p>}
        </div>
      </div>
    </div>
  );
};

export default StatCard;
