// src/client/components/layout/Header/UserMenu.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { User, Heart, LogOut, Settings, ShoppingBag, ChevronDown, UserCircle } from 'lucide-react';
import { logout } from '../../../features/authSlice';
import { selectWishlistItems } from '../../../features/wishlistSlice';
import { secureStorage } from '../../../../utils/cryptoUtils';

const UserMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Get user from Redux state
  const userData = useSelector((state) => state.authClient.user);
  // Get wishlist items count
  const wishlistItems = useSelector(selectWishlistItems);
  const wishlistCount = wishlistItems.length;

  const handleLogout = () => {
    dispatch(logout());
    secureStorage.removeItem('user');
    navigate('/login');
    setIsOpen(false);
  };

  const menuItems = userData
    ? [
        {
          label: 'Thông tin tài khoản',
          icon: <User size={16} />,
          path: '/account',
        },
        {
          label: 'Đơn hàng của tôi',
          icon: <ShoppingBag size={16} />,
          path: '/account/orders',
        },
        {
          label: 'Đăng xuất',
          icon: <LogOut size={16} />,
          onClick: handleLogout,
        },
      ]
    : [];

  return (
    <div className="flex items-center space-x-4">
      {/* Wishlist Button */}
      <Link
        to={userData ? '/wishlist' : '/login'}
        className="hidden sm:flex items-center text-gray-700 hover:text-primary-600 relative"
      >
        <div className="relative">
          <Heart size={24} className={`${wishlistCount > 0 ? 'fill-red-500 text-red-500' : ''}`} />
          {wishlistCount > 0 && (
            <span
              className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 
              flex items-center justify-center"
            >
              {wishlistCount}
            </span>
          )}
        </div>
      </Link>

      {/* Account Menu */}
      <div className="relative">
        {userData ? (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center space-x-2 text-gray-700 hover:text-primary-600"
          >
            <div className="relative w-8 h-8 rounded-full overflow-hidden">
              {userData.avatar ? (
                <img
                  src={userData.avatar}
                  alt={userData.fullName}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-primary-100 flex items-center justify-center">
                  <UserCircle className="w-6 h-6 text-primary-600" />
                </div>
              )}
              {/* Online status indicator */}
              <div
                className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-500 rounded-full 
                border-2 border-white"
              />
            </div>
            <span className="hidden md:block text-sm font-medium">{userData.fullName}</span>
            <ChevronDown size={16} />
          </button>
        ) : (
          <Link
            to="/login"
            className="flex items-center space-x-2 text-gray-700 hover:text-primary-600"
          >
            <User size={24} />
            <span className="hidden md:block text-sm font-medium">Đăng nhập</span>
          </Link>
        )}

        {/* Dropdown Menu */}
        {isOpen && userData && (
          <div
            className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 
            ring-black ring-opacity-5 z-50"
          >
            {/* User Info */}
            <div className="px-4 py-2 border-b">
              <p className="text-sm font-medium text-gray-900 truncate">{userData.fullName}</p>
              <p className="text-xs text-gray-500 truncate">{userData.email}</p>
            </div>

            {/* Wishlist Link with Counter */}
            <Link
              to="/wishlist"
              className="flex items-center justify-between px-4 py-2 text-sm text-gray-700 
                hover:bg-gray-100"
              onClick={() => setIsOpen(false)}
            >
              <div className="flex items-center">
                <Heart size={16} className="mr-2" />
                Danh sách yêu thích
              </div>
              {wishlistCount > 0 && (
                <span className="bg-red-100 text-red-600 text-xs px-2 py-0.5 rounded-full">
                  {wishlistCount}
                </span>
              )}
            </Link>

            {/* Menu Items */}
            {menuItems.map((item, index) =>
              item.onClick ? (
                <button
                  key={index}
                  onClick={() => {
                    setIsOpen(false);
                    item.onClick();
                  }}
                  className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </button>
              ) : (
                <Link
                  key={index}
                  to={item.path}
                  onClick={() => setIsOpen(false)}
                  className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  {item.icon}
                  <span className="ml-2">{item.label}</span>
                </Link>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
