import React from 'react';
import { formatPrice, formatDate } from '../../utils';
import { OrderStatusBadge, PaymentStatusBadge } from '../../OrderDetailModal/Badges';
import OrderActions from './OrderActions';
import { useOrderPermissions } from '../../../../../hooks/useOrderPermissions';

const OrderItem = ({ order, onViewDetail, onUpdateStatus, isSubmitting }) => {
  const { hasUpdatePermission } = useOrderPermissions();

  return (
    <tr className="hover:bg-gray-50">
      <td className="px-6 py-4">
        <div className="text-sm font-medium text-gray-900">#{order.orderNumber}</div>
        {order.external_id && (
          <div className="text-xs text-gray-500">Mã nguồn: {order.external_id}</div>
        )}
      </td>

      <td className="px-6 py-4">
        <div className="text-sm">
          <div className="font-medium text-gray-900">{order.shipping_address.fullName}</div>
          <div className="text-gray-500">{order.shipping_address.phone}</div>
          <div className="text-xs text-gray-500 truncate max-w-xs">
            {order.shipping_address.address}
          </div>
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="space-y-1">
          <div className="text-sm font-medium text-gray-900">{formatPrice(order.total)}</div>
          <PaymentStatusBadge status={order.payment_status} orderStatus={order.status} />
        </div>
      </td>

      <td className="px-6 py-4">
        <div className="text-sm">
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
            {order.source || 'Website'}
          </span>
          <div className="text-xs text-gray-500 mt-1">{order.payment_method}</div>
        </div>
      </td>

      <td className="px-6 py-4 whitespace-nowrap">
        <div className="text-sm text-gray-900">{formatDate(order.orderDate)}</div>
        <div className="text-xs text-gray-500">{formatDate(order.createdAt)}</div>
      </td>

      <td className="px-6 py-4 whitespace-nowrap">
        <OrderStatusBadge status={order.status} />
      </td>

      <td className="px-6 py-4">
        <OrderActions
          order={order}
          onViewDetail={onViewDetail}
          onUpdateStatus={onUpdateStatus}
          isSubmitting={isSubmitting}
          canUpdate={hasUpdatePermission}
        />
      </td>
    </tr>
  );
};

export default OrderItem;
