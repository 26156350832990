import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import * as Icons from 'lucide-react';
import { logout } from '../../../features/authSlice';
import { selectWishlistItems } from '../../../features/wishlistSlice';
import {
  Menu,
  X,
  Search,
  User,
  Heart,
  ShoppingBag,
  Phone,
  ChevronRight,
  ChevronLeft,
  LogOut,
  Zap,
  Tag,
  Clock,
  Bell,
  Box,
  Headphones,
  Settings,
  HelpCircle,
  MapPin,
} from 'lucide-react';
import { selectCategoryTree } from '../../../features/categorySlice';
import { secureStorage } from '../../../../utils/cryptoUtils';

// Component to render category icons
const CategoryIcon = ({ category, className = '' }) => {
  if (!category.icon) return null;

  switch (category.iconType) {
    case 'lucide':
      const IconComponent = Icons[category.icon];
      return IconComponent ? <IconComponent className={`${className} text-gray-600`} /> : null;
    case 'url':
      return <img src={category.icon} alt="" className={`${className} object-contain`} />;
    case 'custom':
      return <span className={`text-xl ${className}`}>{category.icon}</span>;
    default:
      return null;
  }
};

const MobileMenu = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [menuStack, setMenuStack] = useState([]);

  const categories = useSelector(selectCategoryTree);
  const cartItemCount = useSelector((state) => state.cart?.items?.length || 0);
  const user = useSelector((state) => state.authClient.user);
  const wishlistItems = useSelector(selectWishlistItems);
  const wishlistCount = wishlistItems.length;

  const handleLogout = () => {
    dispatch(logout());
    secureStorage.removeItem('user');
    navigate('/login');
    setIsOpen(false);
  };

  const currentCategories =
    menuStack.length === 0 ? categories : menuStack[menuStack.length - 1].children;

  const currentTitle = menuStack.length === 0 ? 'Menu' : menuStack[menuStack.length - 1].name;

  // Animation variants with smoother transitions
  const menuVariants = {
    hidden: {
      opacity: 0,
      x: '100%',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
        staggerChildren: 0.1,
      },
    },
    exit: {
      opacity: 0,
      x: '100%',
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      x: 20,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.2 },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
  };

  const quickLinks = [
    {
      id: 'orders',
      name: 'Đơn hàng',
      icon: Box,
      link: '/account/orders',
    },
    {
      id: 'wishlist',
      name: 'Yêu thích',
      icon: Heart,
      link: '/wishlist',
    },
    {
      id: 'support',
      name: 'Hỗ trợ',
      icon: Headphones,
      link: '/support',
    },
    {
      id: 'settings',
      name: 'Cài đặt',
      icon: Settings,
      link: '/settings',
    },
  ];

  const featuredItems = [
    {
      id: 'flash-sale',
      name: 'Flash Sale',
      description: 'Giảm giá sốc trong 24h',
      icon: Zap,
      iconColor: 'text-red-500',
      bgGradient: 'from-red-500/10 to-orange-500/10',
      badge: 'HOT',
      badgeColor: 'bg-red-500',
      link: '/flash-sale',
    },
    {
      id: 'promotions',
      name: 'Khuyến Mãi',
      description: 'Ưu đãi hấp dẫn',
      icon: Tag,
      iconColor: 'text-green-500',
      bgGradient: 'from-green-500/10 to-teal-500/10',
      badge: 'SALE',
      badgeColor: 'bg-green-500',
      link: '/promotions',
    },
    {
      id: 'new-arrivals',
      name: 'Hàng Mới Về',
      description: 'Sản phẩm mới nhất',
      icon: Bell,
      iconColor: 'text-blue-500',
      bgGradient: 'from-blue-500/10 to-indigo-500/10',
      badge: 'NEW',
      badgeColor: 'bg-blue-500',
      link: '/new-arrivals',
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery)}`);
      setIsSearchOpen(false);
      setSearchQuery('');
    }
  };

  const navigateToSubMenu = (category) => {
    if (category.children?.length > 0) {
      setMenuStack([...menuStack, category]);
    } else {
      navigate(`/category/${category.id}`);
      setIsOpen(false);
    }
  };

  const navigateBack = () => {
    setMenuStack(menuStack.slice(0, -1));
  };

  return (
    <>
      {/* Mobile Nav Icons */}
      <div className="flex items-center gap-6 md:hidden">
        <button
          onClick={() => setIsSearchOpen(true)}
          className="relative flex items-center justify-center w-10 h-10 text-gray-700 hover:text-primary-600 transition-colors"
          aria-label="Search"
        >
          <Search className="w-6 h-6" />
        </button>

        <Link
          to="/cart"
          className="relative flex items-center justify-center w-10 h-10 text-gray-700 hover:text-primary-600 transition-colors"
        >
          <ShoppingBag className="w-6 h-6" />
          {cartItemCount > 0 && (
            <span className="absolute -top-1 -right-1 w-5 h-5 flex items-center justify-center bg-red-500 text-white text-xs font-medium rounded-full">
              {cartItemCount}
            </span>
          )}
        </Link>

        <button
          onClick={() => setIsOpen(true)}
          className="relative flex items-center justify-center w-10 h-10 text-gray-700 hover:text-primary-600 transition-colors"
          aria-label="Menu"
        >
          <Menu className="w-6 h-6" />
        </button>
      </div>

      {/* Search Overlay */}
      <AnimatePresence>
        {isSearchOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={overlayVariants}
            className="fixed inset-0 z-50 bg-white"
          >
            <div className="safe-area-inset-top" />
            <div className="p-4">
              <form onSubmit={handleSearch} className="space-y-4">
                <div className="flex items-center gap-4">
                  <button
                    type="button"
                    onClick={() => setIsSearchOpen(false)}
                    className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100 transition-colors"
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <div className="flex-1 relative">
                    <input
                      type="search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Tìm kiếm sản phẩm..."
                      className="w-full h-12 pl-12 pr-4 bg-gray-100 rounded-2xl text-base placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                      autoFocus
                    />
                    <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
                  </div>
                </div>

                {/* Quick Search Categories */}
                <div className="pt-4">
                  <h3 className="text-sm font-medium text-gray-500 mb-3">Danh mục phổ biến</h3>
                  <div className="grid grid-cols-2 gap-3">
                    {categories.slice(0, 4).map((category) => (
                      <button
                        key={category.id}
                        onClick={() => {
                          setIsSearchOpen(false);
                          navigate(`/category/${category.id}`);
                        }}
                        className="flex items-center gap-3 p-3 bg-gray-100 rounded-xl hover:bg-gray-200 transition-colors text-left"
                      >
                        {category.icon && (
                          <img src={category.icon} alt="" className="w-6 h-6 object-contain" />
                        )}
                        <span className="text-sm font-medium">{category.name}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </form>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Menu Overlay */}
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={overlayVariants}
              className="fixed inset-0 z-50 bg-black/50 backdrop-blur-sm"
              onClick={() => setIsOpen(false)}
            />

            <motion.div
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={menuVariants}
              className="fixed right-0 top-0 bottom-0 z-50 w-[90vw] max-w-md bg-white shadow-xl"
            >
              {/* Header */}
              <div className="sticky top-0 z-10 px-4 h-16 flex items-center justify-between bg-white border-b border-gray-100">
                {menuStack.length > 0 ? (
                  <button
                    onClick={navigateBack}
                    className="flex items-center text-gray-700 hover:text-primary-600 transition-colors"
                  >
                    <ChevronLeft className="w-5 h-5 mr-1" />
                    <span className="font-medium">Quay lại</span>
                  </button>
                ) : (
                  <span className="text-lg font-semibold">{currentTitle}</span>
                )}
                <button
                  onClick={() => setIsOpen(false)}
                  className="flex items-center justify-center w-10 h-10 rounded-full hover:bg-gray-100 transition-colors"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              {/* Content */}
              <div className="overflow-y-auto h-[calc(100%-4rem)] pb-safe-area-inset-bottom">
                {menuStack.length === 0 ? (
                  <div className="py-4 space-y-6">
                    {/* User Section */}
                    <div className="px-4">
                      {user ? (
                        <motion.div variants={itemVariants}>
                          <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-2xl">
                            <div className="relative w-14 h-14">
                              {user.avatar ? (
                                <img
                                  src={user.avatar}
                                  alt={user.fullName}
                                  className="w-full h-full rounded-full object-cover"
                                />
                              ) : (
                                <div className="w-full h-full rounded-full bg-primary-100 flex items-center justify-center">
                                  <User className="w-6 h-6 text-primary-600" />
                                </div>
                              )}
                              <div className="absolute -bottom-1 -right-1 w-5 h-5 bg-green-500 rounded-full border-2 border-white" />
                            </div>
                            <div className="flex-1">
                              <div className="font-semibold text-lg">{user.fullName}</div>
                              <div className="text-sm text-gray-500">{user.email}</div>
                            </div>
                            <button
                              className="p-2 hover:bg-gray-200 rounded-full transition-colors"
                              onClick={handleLogout}
                            >
                              <LogOut className="w-5 h-5 text-gray-600" />
                            </button>
                          </div>
                        </motion.div>
                      ) : (
                        <motion.div variants={itemVariants} className="space-y-3">
                          <Link
                            to="/login"
                            onClick={() => setIsOpen(false)}
                            className="flex items-center justify-center w-full h-12 bg-primary-600 text-white font-medium rounded-xl hover:bg-primary-700 transition-colors"
                          >
                            Đăng nhập
                          </Link>
                          <Link
                            to="/register"
                            onClick={() => setIsOpen(false)}
                            className="flex items-center justify-center w-full h-12 bg-gray-100 text-gray-900 font-medium rounded-xl hover:bg-gray-200 transition-colors"
                          >
                            Đăng ký
                          </Link>
                        </motion.div>
                      )}
                    </div>

                    {/* Quick Links */}
                    <motion.div variants={itemVariants} className="grid grid-cols-4 px-4 gap-3">
                      {quickLinks.map((item) => (
                        <Link
                          key={item.id}
                          to={item.link}
                          onClick={() => setIsOpen(false)}
                          className="flex flex-col items-center gap-2 p-3 rounded-xl hover:bg-gray-100 transition-colors"
                        >
                          <div className="relative">
                            <div className="w-12 h-12 rounded-xl bg-gray-100 flex items-center justify-center">
                              <item.icon className="w-5 h-5 text-gray-600" />
                            </div>
                            {/* Thêm badge cho wishlist */}
                            {item.id === 'wishlist' && wishlistCount > 0 && (
                              <div
                                className="absolute -top-1 -right-1 w-5 h-5 bg-red-500 text-white text-xs 
            font-medium rounded-full flex items-center justify-center"
                              >
                                {wishlistCount}
                              </div>
                            )}
                          </div>
                          <span className="text-xs font-medium text-center">{item.name}</span>
                        </Link>
                      ))}
                    </motion.div>

                    {/* Featured Items */}
                    <motion.div variants={itemVariants} className="px-4 space-y-3">
                      {featuredItems.map((item) => (
                        <Link
                          key={item.id}
                          to={item.link}
                          onClick={() => setIsOpen(false)}
                          className={`flex items-center gap-4 p-4 rounded-2xl bg-gradient-to-r ${item.bgGradient}`}
                        >
                          <div
                            className={`w-12 h-12 rounded-xl bg-white/80 backdrop-blur-sm flex items-center justify-center ${item.iconColor}`}
                          >
                            <item.icon className="w-6 h-6" />
                          </div>
                          <div className="flex-1">
                            <div className="flex items-center gap-2">
                              <span className="font-medium">{item.name}</span>
                              <span
                                className={`px-2 py-0.5 text-[10px] font-medium text-white rounded-full ${item.badgeColor}`}
                              >
                                {item.badge}
                              </span>
                            </div>
                            <p className="text-sm text-gray-600">{item.description}</p>
                          </div>
                          <ChevronRight className="w-5 h-5 text-gray-400" />
                        </Link>
                      ))}
                    </motion.div>

                    {/* Categories */}
                    <div className="px-4">
                      <h3 className="text-sm font-medium text-gray-500 mb-3">Danh mục sản phẩm</h3>
                      <motion.div variants={itemVariants} className="space-y-2">
                        {categories?.map((category) => (
                          <button
                            key={category.id}
                            onClick={() => navigateToSubMenu(category)}
                            className="w-full flex items-center gap-4 p-4 rounded-xl hover:bg-gray-100 transition-colors"
                          >
                            {/* Category Icon */}
                            <div className="w-12 h-12 rounded-xl bg-gray-100 flex items-center justify-center">
                              <CategoryIcon category={category} className="w-6 h-6" />
                            </div>

                            <div className="flex-1 text-left">
                              <div className="font-medium">{category.name}</div>
                              {category.description && (
                                <p className="text-sm text-gray-500">{category.description}</p>
                              )}
                            </div>
                            {category.children?.length > 0 && (
                              <ChevronRight className="w-5 h-5 text-gray-400" />
                            )}
                          </button>
                        ))}
                      </motion.div>
                    </div>

                    {/* Contact & Support */}
                    <div className="px-4 mt-6 pt-6 border-t border-gray-100">
                      <motion.div variants={itemVariants} className="space-y-4">
                        {/* Support Hours */}
                        <div className="flex items-start gap-3">
                          <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center flex-shrink-0">
                            <Clock className="w-5 h-5 text-primary-600" />
                          </div>
                          <div>
                            <div className="font-medium">Giờ hỗ trợ</div>
                            <p className="text-sm text-gray-500">T2-CN: 08:00 - 21:00</p>
                          </div>
                        </div>

                        {/* Hotline */}
                        <div className="flex items-start gap-3">
                          <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center flex-shrink-0">
                            <Phone className="w-5 h-5 text-primary-600" />
                          </div>
                          <div>
                            <div className="font-medium">Hotline</div>
                            <a href="tel:1800123456" className="text-sm text-primary-600">
                              1800-123-456
                            </a>
                          </div>
                        </div>

                        {/* Store Location */}
                        <div className="flex items-start gap-3">
                          <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center flex-shrink-0">
                            <MapPin className="w-5 h-5 text-primary-600" />
                          </div>
                          <div>
                            <div className="font-medium">Địa chỉ</div>
                            <p className="text-sm text-gray-500">123 Đường ABC, Quận XYZ, TP.HCM</p>
                          </div>
                        </div>

                        {/* Help Center */}
                        <Link
                          to="/help"
                          onClick={() => setIsOpen(false)}
                          className="flex items-center justify-between w-full p-4 bg-gray-100 rounded-xl hover:bg-gray-200 transition-colors"
                        >
                          <div className="flex items-center gap-3">
                            <HelpCircle className="w-5 h-5 text-primary-600" />
                            <span className="font-medium">Trung tâm trợ giúp</span>
                          </div>
                          <ChevronRight className="w-5 h-5 text-gray-400" />
                        </Link>
                      </motion.div>
                    </div>
                  </div>
                ) : (
                  // Submenu categories
                  <div className="py-4">
                    <motion.div variants={itemVariants} className="space-y-2 px-4">
                      {currentCategories?.map((category) => (
                        <button
                          key={category.id}
                          onClick={() => navigateToSubMenu(category)}
                          className="w-full flex items-center gap-4 p-4 rounded-xl hover:bg-gray-100 transition-colors"
                        >
                          {category.icon && (
                            <div className="w-12 h-12 rounded-xl bg-gray-100 flex items-center justify-center p-2">
                              <CategoryIcon
                                category={category}
                                className="w-full h-full object-contain"
                              />
                              {/* <img
                                src={category.icon}
                                alt=""
                                className="w-full h-full object-contain"
                              /> */}
                            </div>
                          )}
                          <div className="flex-1 text-left">
                            <div className="font-medium">{category.name}</div>
                            {category.description && (
                              <p className="text-sm text-gray-500">{category.description}</p>
                            )}
                          </div>
                          {category.children?.length > 0 && (
                            <ChevronRight className="w-5 h-5 text-gray-400" />
                          )}
                        </button>
                      ))}
                    </motion.div>
                  </div>
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu;
