// src/store/flashSaleProductSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase/config';

const convertTimestamps = (data) => {
  const converted = { ...data };
  if (converted.createdAt?.toDate) {
    converted.createdAt = converted.createdAt.toDate().toISOString();
  }
  if (converted.updatedAt?.toDate) {
    converted.updatedAt = converted.updatedAt.toDate().toISOString();
  }
  return converted;
};

// Thunks
export const fetchFlashSaleProducts = createAsyncThunk(
  'flashSaleProducts/fetchByFlashSale',
  async (flashSaleId) => {
    try {
      const q = query(
        collection(db, 'flash_sale_products'),
        where('flashSaleId', '==', flashSaleId),
        where('isActive', '==', true)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) =>
        convertTimestamps({
          id: doc.id,
          ...doc.data(),
        })
      );
    } catch (error) {
      throw new Error('Không thể tải danh sách sản phẩm: ' + error.message);
    }
  }
);

export const addFlashSaleProducts = createAsyncThunk(
  'flashSaleProducts/addProducts',
  async ({ flashSaleId, products }) => {
    try {
      const batch = writeBatch(db);
      const addedProducts = [];

      for (const product of products) {
        const docRef = doc(collection(db, 'flash_sale_products'));
        const productData = {
          flashSaleId,
          productId: product.id,
          name: product.name,
          originalPrice: product.originalPrice,
          salePrice: product.salePrice,
          stockLimit: product.stockLimit || null,
          limitPerCustomer: product.limitPerCustomer || null,
          stockLeft: product.stockLimit || product.stock,
          soldCount: 0,
          status: 'available',
          isActive: true,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        };

        batch.set(docRef, productData);
        addedProducts.push({
          id: docRef.id,
          ...productData,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      }

      await batch.commit();
      return addedProducts;
    } catch (error) {
      throw new Error('Không thể thêm sản phẩm: ' + error.message);
    }
  }
);

export const updateFlashSaleProduct = createAsyncThunk(
  'flashSaleProducts/updateProduct',
  async ({ id, data }) => {
    try {
      const docRef = doc(db, 'flash_sale_products', id);
      const updateData = {
        ...data,
        updatedAt: serverTimestamp(),
      };

      await updateDoc(docRef, updateData);
      return { id, ...data };
    } catch (error) {
      throw new Error('Failed to update flash sale product: ' + error.message);
    }
  }
);

export const removeFlashSaleProducts = createAsyncThunk(
  'flashSaleProducts/removeProducts',
  async ({ flashSaleId, productIds }) => {
    try {
      const batch = writeBatch(db);

      // Lấy ra các document cần xóa
      const q = query(
        collection(db, 'flash_sale_products'),
        where('flashSaleId', '==', flashSaleId),
        where('productId', 'in', productIds)
      );
      const querySnapshot = await getDocs(q);

      // Thêm các document vào batch để xóa
      querySnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref); // Thay vì update isActive, ta sẽ xóa hoàn toàn
      });

      await batch.commit();
      return productIds;
    } catch (error) {
      throw new Error('Không thể xóa sản phẩm: ' + error.message);
    }
  }
);

export const updateSoldCount = createAsyncThunk(
  'flashSaleProducts/updateSoldCount',
  async ({ productId, quantity }) => {
    try {
      const docRef = doc(db, 'flash_sale_products', productId);
      const updateData = {
        soldCount: quantity,
        stockLeft: doc.data().stockLimit ? doc.data().stockLimit - quantity : null,
        updatedAt: serverTimestamp(),
      };

      if (updateData.stockLeft === 0) {
        updateData.status = 'out_of_stock';
      }

      await updateDoc(docRef, updateData);
      return { id: productId, ...updateData };
    } catch (error) {
      throw new Error('Không thể cập nhật số lượng đã bán: ' + error.message);
    }
  }
);

// Slice
const flashSaleProductSlice = createSlice({
  name: 'flashSaleProducts',
  initialState: {
    items: [],
    status: 'idle',
    error: null,
    selectedProducts: [],
  },
  reducers: {
    setSelectedProducts: (state, action) => {
      state.selectedProducts = action.payload;
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = [];
    },
    updateProductStatus: (state, action) => {
      const { productId, status } = action.payload;
      const product = state.items.find((item) => item.id === productId);
      if (product) {
        product.status = status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch products
      .addCase(fetchFlashSaleProducts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFlashSaleProducts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase(fetchFlashSaleProducts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add products
      .addCase(addFlashSaleProducts.fulfilled, (state, action) => {
        state.items.push(...action.payload);
        state.selectedProducts = [];
      })
      // Update product
      .addCase(updateFlashSaleProduct.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.items[index] = {
            ...state.items[index],
            ...action.payload,
          };
        }
      })
      // Remove products
      .addCase(removeFlashSaleProducts.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => !action.payload.includes(item.id));
      })
      // Update sold count
      .addCase(updateSoldCount.fulfilled, (state, action) => {
        const product = state.items.find((item) => item.id === action.payload.id);
        if (product) {
          product.soldCount = action.payload.soldCount;
          product.stockLeft = action.payload.stockLeft;
          product.status = action.payload.status;
        }
      });
  },
});

export const { setSelectedProducts, clearSelectedProducts, updateProductStatus } =
  flashSaleProductSlice.actions;

export default flashSaleProductSlice.reducer;
