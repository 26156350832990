// Model
import { DEVICE_TYPES, FIELD_TYPES } from './specTemplates';

export const DEFAULT_SPECS = [
  {
    name: 'Laptop Gaming',
    type: DEVICE_TYPES.LAPTOP,
    description: 'Mẫu thông số cho laptop gaming',
    fields: [
      {
        key: 'cpu',
        label: 'CPU (Bộ vi xử lý)',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: Intel Core i7-13700H, AMD Ryzen 9 7945HX',
        description: 'Bộ vi xử lý của laptop',
        required: true,
        order: 0,
      },
      {
        key: 'ram',
        label: 'RAM (Bộ nhớ)',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 32GB (2x16GB) DDR5 4800MHz',
        description: 'Bộ nhớ RAM',
        required: true,
        order: 1,
      },
      {
        key: 'storage',
        label: 'Ổ cứng',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 1TB PCIe NVMe Gen4 SSD',
        description: 'Ổ cứng SSD/HDD',
        required: true,
        order: 2,
      },
      {
        key: 'gpu',
        label: 'Card đồ họa',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: NVIDIA GeForce RTX 4060 8GB GDDR6',
        description: 'Card đồ họa',
        required: true,
        order: 3,
      },
      {
        key: 'display',
        label: 'Màn hình',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 15.6" QHD (2560x1440) IPS 240Hz',
        description: 'Thông số màn hình',
        required: true,
        order: 4,
      },
      {
        key: 'ports',
        label: 'Cổng kết nối',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 2x USB 3.2, 1x HDMI 2.1, 1x Thunderbolt 4',
        description: 'Các cổng kết nối',
        required: false,
        order: 5,
      },
      {
        key: 'wireless',
        label: 'Kết nối không dây',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: Intel WiFi 6E, Bluetooth 5.2',
        description: 'Kết nối WiFi và Bluetooth',
        required: false,
        order: 6,
      },
      {
        key: 'battery',
        label: 'Pin',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 90WHr',
        description: 'Dung lượng pin',
        required: false,
        order: 7,
      },
    ],
    isActive: true,
  },
  {
    name: 'PC Gaming',
    type: DEVICE_TYPES.DESKTOP,
    description: 'Mẫu thông số cho máy tính gaming',
    fields: [
      {
        key: 'cpu',
        label: 'CPU',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: Intel Core i9-13900K, AMD Ryzen 9 7950X',
        description: 'Bộ vi xử lý',
        required: true,
        order: 0,
      },
      {
        key: 'mainboard',
        label: 'Bo mạch chủ',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: ASUS ROG MAXIMUS Z790 HERO',
        description: 'Bo mạch chủ',
        required: true,
        order: 1,
      },
      {
        key: 'ram',
        label: 'RAM',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 64GB (4x16GB) DDR5 6000MHz',
        description: 'Bộ nhớ RAM',
        required: true,
        order: 2,
      },
      {
        key: 'gpu',
        label: 'Card đồ họa',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: NVIDIA GeForce RTX 4090 24GB',
        description: 'Card đồ họa',
        required: true,
        order: 3,
      },
      {
        key: 'storage',
        label: 'Lưu trữ',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 2TB NVMe Gen4 + 4TB HDD',
        description: 'Ổ cứng SSD/HDD',
        required: true,
        order: 4,
      },
      {
        key: 'psu',
        label: 'Nguồn',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 1000W 80+ Gold',
        description: 'Bộ nguồn',
        required: true,
        order: 5,
      },
      {
        key: 'case',
        label: 'Vỏ case',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: Lian Li O11 Dynamic EVO',
        description: 'Vỏ máy tính',
        required: false,
        order: 6,
      },
      {
        key: 'cooling',
        label: 'Tản nhiệt',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: NZXT Kraken Z73 360mm AIO',
        description: 'Hệ thống tản nhiệt',
        required: false,
        order: 7,
      },
    ],
    isActive: true,
  },
  {
    name: 'Màn hình Gaming',
    type: DEVICE_TYPES.MONITOR,
    description: 'Mẫu thông số cho màn hình gaming',
    fields: [
      {
        key: 'size',
        label: 'Kích thước',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 27 inch',
        description: 'Kích thước màn hình',
        required: true,
        order: 0,
      },
      {
        key: 'resolution',
        label: 'Độ phân giải',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 2560x1440 (2K)',
        description: 'Độ phân giải màn hình',
        required: true,
        order: 1,
      },
      {
        key: 'panel_type',
        label: 'Tấm nền',
        type: FIELD_TYPES.SELECT,
        placeholder: 'Chọn loại tấm nền',
        description: 'Công nghệ tấm nền',
        options: ['IPS', 'VA', 'TN', 'OLED'],
        required: true,
        order: 2,
      },
      {
        key: 'refresh_rate',
        label: 'Tần số quét',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 165Hz',
        description: 'Tốc độ làm mới',
        required: true,
        order: 3,
      },
      {
        key: 'response_time',
        label: 'Thời gian phản hồi',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 1ms GtG',
        description: 'Thời gian phản hồi',
        required: true,
        order: 4,
      },
      {
        key: 'brightness',
        label: 'Độ sáng',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 400 nits',
        description: 'Độ sáng màn hình',
        required: false,
        order: 5,
      },
      {
        key: 'ports',
        label: 'Cổng kết nối',
        type: FIELD_TYPES.TEXT,
        placeholder: 'VD: 2x HDMI 2.1, 1x DisplayPort 1.4',
        description: 'Các cổng kết nối',
        required: false,
        order: 6,
      },
    ],
    isActive: true,
  },
];
